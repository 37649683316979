<template>
  <r-paper round>
    <r-modal-sub-header :title="$t('config.categoryForm.categoryFormTitle')">
      <div class="main--content">
        <r-heading :level="4">{{
          $t("config.categoryForm.categoryAdd")
        }}</r-heading>
        <div class="category--registration--form">
          <r-textbox
            class="main--content--item config-category-input-form"
            type="string"
            :placeholder="$t('config.categoryForm.categoryAdd')"
            v-model="inputName"
            name="inputName"
          />
          <div class="config-btn-wrapper">
            <r-button
              class="main--content--item config-category-btn"
              buttonStyle="secondary"
              full
              @click="inputCategory('set')"
              >{{ $t("common.registration") }}</r-button
            >
          </div>
        </div>
      </div>

      <div class="main--content">
        <r-heading :level="4">{{
          $t("config.categoryForm.categoryDelete")
        }}</r-heading>
        <div class="category--delete--form">
          <r-checkbox
            class="main--content--item main--content--cat-button"
            :items="categories"
            @toggle="toggle"
          />
          <div class="config-btn-wrapper">
            <r-button
              class="main--content--item config-category-btn"
              buttonStyle="secondary"
              full
              @click="inputCategory('del')"
              >{{ $t("common.delete") }}</r-button
            >
          </div>
        </div>
      </div>
    </r-modal-sub-header>
  </r-paper>
</template>
<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RCheckbox from "@/plugins/atoms/RCheckbox.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";

import auth from "@/libs/Auth.js";
import categoryManager from "@/libs/Category.js";

export default {
  components: {
    RTextbox,
    RButton,
    RCheckbox,
    RPaper,
    RHeading,
    RModalSubHeader,
  },
  data() {
    return {
      categories: [],
      inputName: "",
    };
  },
  computed: {},
  methods: {
    toggle(index) {
      this.$set(this.categories[index], "bool", !this.categories[index].bool);
    },
    async inputCategory(mode) {
      if (mode === "set") {
        // 未入力の場合
        if (this.inputName === "") {
          alert(this.$i18n.t("config.categoryForm.categoryNotInput"));
          return;
        }
        // 既に登録されている場合
        if (this.categories.map((e) => e.name).includes(this.inputName)) {
          alert(this.$i18n.t("config.categoryForm.categoryAlreadyOccupied"));
          return;
        }
        this.categories.push({ name: this.inputName, bool: false });
      } else if (mode === "del") {
        if (this.categories.filter((e) => e.bool).length === 0) {
          //削除するカテゴリが未選択の場合
          alert(this.$i18n.t("config.categoryForm.selectCategoryToDelete"));
          return;
        }
        //選択中のカテゴリーの配列を取得
        const selectedCats = this.categories.filter(function (cat) {
          return cat.bool == true;
        });
        let recipeList = [];
        //該当店舗ID・顧客IDでレシピ検索
        recipeList = await categoryManager.getRecipeCourseUsingCategories(
          this.customerID,
          this.storeID,
        );
        //カテゴリーに該当するレシピを格納する配列
        let result = [];
        //削除しないカテゴリーを格納する配列
        let notDeleteCatArr = [];
        selectedCats.forEach((cat) => {
          result = categoryManager.searchRecipes(recipeList, cat.name);
          //選択したカテゴリーがレシピもしくはコースに利用されている場合
          if (result.length > 0) {
            //削除しない
            alert(
              "！「" +
                cat.name +
                "」" +
                this.$i18n.t("config.categoryForm.categoryDeleteNotAvailable"),
            );
            notDeleteCatArr.push(cat);
          } else {
            const categoryDeleteConfirm = window.confirm(
              this.$i18n.t("config.categoryForm.category") +
                "「" +
                cat.name +
                this.$i18n.t("config.categoryForm.categoryDeleteConfirm"),
            );
            if (!categoryDeleteConfirm) {
              //削除しないカテゴリ
              notDeleteCatArr.push(cat);
            }
          }
        });
        //削除しないカテゴリーを選択解除
        this.categories.forEach((e) => {
          notDeleteCatArr.forEach((cat) => {
            if (e.name == cat.name) {
              e.bool = false;
            }
          });
        });
        //最終的に選択されていないカテゴリーをcategoryに格納
        const tempArray = this.categories.filter(function (item) {
          return item.bool == false;
        });
        this.categories = tempArray;
      } else {
        return;
      }

      // firestoreに登録
      const setCategories = this.categories.map((category) => {
        return { name: category.name };
      });
      try {
        await categoryManager.setCategories(
          this.customerID,
          this.storeID,
          setCategories,
        );
        this.inputName = "";
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("ERROR", error);
        throw new Error(error);
      }
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }
      // カテゴリ一覧を取得
      this.categories = await categoryManager.getCategoryConfigList(
        this.customerID,
        this.storeID,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error("Forbidden");
    }
  },
};
</script>

<style scoped>
.main--content {
  margin-top: 20px;
}
.main--content--item {
  margin-top: 10px;
}
.main--content--cat-button {
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}
.main--content--cat-button >>> .checkbox--button {
  min-height: 32px;
}
/*iPad用css*/
@media screen and (min-width: 641px) {
  .main--content--item {
    margin-top: unset;
  }
  .category--registration--form {
    margin-top: 10px;
    display: grid;
    vertical-align: middle;
    grid-template-columns: 5fr 1fr;
  }
  .config-category-input-form {
    grid-template-columns: 5fr;
  }
  .config-category-input-form >>> .r-textbox--input {
    margin-right: 10px;
  }
  .category--delete--form {
    margin-top: 10px;
    display: grid;
    gap: 5px;
    grid-template-columns: 5fr 1fr;
  }
  .main--content--cat-button {
    grid-template-columns: 5fr;
    margin-right: 10px;
  }
  .config-btn-wrapper {
    grid-template-columns: 1fr;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .config-category-btn {
    height: 40px;
  }
}
/*PC用css*/
@media screen and (min-width: 1024px) {
}
</style>

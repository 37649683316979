<template>
  <div class="book-select-modal-wrapper">
    <!--books選択&&名前入力モーダル-->
    <div class="modal-overlay" @click.self="$emit('cancel')">
      <r-modal
        :title="
          isInputForm
            ? '' +
              `${currentName}` +
              $t('menu.menuTop.bookSelectForm.titleForInputName')
            : $t('menu.menuTop.bookSelectForm.titleForSelectBook')
        "
        RHr="primary"
        class="modal-content"
        :enter="isSelected ? true : false"
        @enter="toInputBookName"
        @cancel="closeModal"
      >
        <!-- books選択欄-->
        <div v-if="!isInputForm" class="booklist-wrapper">
          <span class="explain">{{
            $t("menu.menuTop.bookSelectForm.explain")
          }}</span>
          <div class="book-wrapper">
            <div
              v-for="(item, index) in menuBook"
              :key="index"
              class="book-selector"
              :class="[
                item.name !== '' ? 'book-selector-selected' : '',
                current === index ? 'book-selector-selecting' : '',
              ]"
              @click="selectBook(index)"
            >
              <span v-if="item.name !== ''"
                >{{ $t("menu.menuTop.bookSelectForm.alreadyInUse") }}[{{
                  item.name
                }}]</span
              >
              <span v-else
                >{{ $t("menu.menuTop.menuBook") }}{{ index + 1 }}</span
              >
            </div>
          </div>
        </div>
        <!--メニューブック名前入力-->
        <div v-else class="input-name-wrapper">
          <div class="error-message">{{ error.bookName }}</div>
          <!--メニューブック名前入力-->
          <r-textbox
            :placeholder="$t('menu.menuTop.bookSelectForm.bookNamePlaceholder')"
            v-model="bookName"
            :error="error.bookName !== ''"
          />
        </div>
      </r-modal>
    </div>
  </div>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import auth from "@/libs/Auth.js";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  components: {
    RModal,
    RTextbox,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      isInputForm: false,
      bookName: "",
      selectedStore: "",
      current: null,
      disable: true,
      isSelected: false,
      isBookNameNotInputed: false,
      error: {
        bookName: "",
      },
    };
  },
  props: {
    menuBook: Array, //booksオブジェクト
    store: String, //店舗ID
  },

  computed: {
    currentName() {
      const menuNo = Number(this.current) + 1;
      return this.$i18n.t("menu.menuTop.menuBook") + menuNo;
    },
  },
  methods: {
    selectBook(index) {
      this.current = index;
      this.isSelected = true;
    },
    toInputBookName() {
      if (!this.isInputForm) {
        //bookIputフォームに切り替え
        this.isInputForm = true;
      } else {
        //名前入力後
        // this.$emit("selectedBook", this.current, this.bookName);
        this.executeValidation();
        if (this.error.bookName !== "") {
          return;
        } else {
          //展開したモーダルのスクロールロックを解除する。
          clearAllBodyScrollLocks();
          //bookRegistration画面に遷移
          this.$router.push(
            {
              name: "menuRegistration",
              params: {
                index: this.current,
                name: this.bookName,
              },
            },
            () => {},
          );
        }
      }
    },
    closeModal() {
      // 条件見直し
      if (!this.isInputForm) {
        this.$emit("cancel");
      }
      this.isInputForm = false;
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    executeValidation() {
      if (this.bookName === "") {
        this.$set(
          this.error,
          "bookName",
          this.$i18n.t("menu.menuTop.bookSelectForm.bookNameValid"),
        );
      } else if (this.bookName.length > 50) {
        this.$set(
          this.error,
          "bookName",
          this.$i18n.t("menu.menuTop.bookSelectForm.bookNameLengthValidation"),
        );
      } else {
        this.$set(this.error, "bookName", "");
      }
    },
  },
  async created() {
    this.customerID = await auth.getCustomerID(this.$root);
    this.storeID = await auth.getStoreID(this.$root);
    if (!this.customerID || !this.storeID) {
      // customerID、StoreIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }
  },
  //PCサイズ対応
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.book-select-modal-wrapper {
  box-sizing: border-box;
}

.modal-content {
  font-size: 0.9rem;
}
.book-wrapper {
  margin-top: 10px;
}
.booklist-wrapper {
  margin-top: 20px;
}
.explain {
  display: block;
  text-align: left;
}
.book-selector {
  border: 2px solid #e87352;
  border-radius: 4px;
  margin-bottom: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 8px;
  max-width: 100%;
  text-align: left;
  font-weight: bold;
}
.book-selector-selected {
  border: 2px solid #517ce8;
  background-color: #517ce8;
  color: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px;
  box-sizing: border-box;
  pointer-events: none;
  text-align: left;
  font-weight: bold;
}
.book-selector-selecting {
  border: 2px solid #e87352;
  background-color: #e87352;
  color: #fff;
  border-radius: 4px;
  margin-bottom: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 8px;
  text-align: left;
  font-weight: bold;
}
.input-name {
  border: 2px solid #e87352;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px;
  width: 95%;
  align-content: center;
}
.error-message {
  text-align: left;
  color: #e85951;
  font-weight: bold;
}
/*モーダル関連*/
.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 5%;
  box-sizing: border-box;
}
/*iPad用css*/
@media (min-width: 641px) {
  .modal-content {
    padding: 24px 35px 32px;
  }
  .book-wrapper {
    display: flex;
  }
  .explain {
    text-align: left;
    margin-bottom: 20px;
  }
  .book-selector {
    border: 2px solid #e87352;
    background-color: #fff;
    color: #333;
    border-radius: 5px;
    padding: 13px 8px 0px;
    margin: 0px 5px 0px 5px;
    height: 56px;
    width: 23.5%;
    text-align: left;
  }
  .book-selector-selected {
    border: 2px solid #517ce8;
    background-color: #517ce8;
    color: #fff;
    border-radius: 5px;
    padding: 13px;
    margin: 0px 5px 0px 5px;
    height: 56px;
    width: 23.5%;
    text-align: left;
  }
  .book-selector-selecting {
    border: 2px solid #e87352;
    background-color: #e87352;
    color: #fff;
    border-radius: 5px;
    padding: 13px;
    margin: 0px 5px 0px 5px;
    height: 56px;
    width: 23.5%;
    text-align: left;
  }
  .input-name-wrapper {
    margin: 0 auto;
  }
  .input-name {
    font-size: 1.2rem;
    border: 2px solid #cdcdcd;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    line-height: 52px;
    height: 56px;
    margin: 16px 0px 16px 0px;
  }
  .book-name-validation {
    font-size: 1rem;
    color: red;
  }
  .modal-overlay {
    padding: 0 10%;
  }
}
@media screen and (min-width: 1024px) {
  .modal-content {
    padding: 24px 40px 32px;
    min-width: 880px;
  }
  .book-wrapper {
    display: flex;
  }
  .explain {
    text-align: left;
    margin-bottom: 20px;
  }
  .book-selector {
    border: 2px solid #e87352;
    background-color: #fff;
    color: #333;
    border-radius: 5px;
    padding: 13px;
    margin: 13px;
    height: 56px;
    width: 23.5%;
    text-align: left;
  }
  .book-selector-selected {
    border: 2px solid #517ce8;
    background-color: #517ce8;
    color: #fff;
    border-radius: 5px;
    padding: 13px;
    margin: 13px;
    height: 56px;
    width: 23.5%;
    text-align: left;
  }
  .book-selector-selecting {
    border: 2px solid #e87352;
    background-color: #e87352;
    color: #fff;
    border-radius: 5px;
    padding: 13px;
    margin: 13px;
    height: 56px;
    width: 23.5%;
    text-align: left;
  }
  .input-name-wrapper {
    margin: 0 auto;
  }
  .input-name {
    font-size: 1.2rem;
    border: 2px solid #cdcdcd;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    line-height: 52px;
    height: 56px;
    margin: 16px 0px 16px 0px;
  }
  .book-name-validation {
    font-size: 1rem;
    color: red;
  }
  .modal-overlay {
    padding: 0 25%;
  }
}
</style>

<template>
  <r-modal
    class="recipe-cost-registration"
    :title="$t('recipe.recipeCost.recipeCostRegist')"
    enter
    @enter="enter"
    @cancel="cancel"
  >
    <span class="recipe-cost-registration--content"
      >{{ $t("common.cost") }} : {{ costPerServ | localNumber }}
      {{ $t("common.currencyYen") }} / 1{{ $t("common.perPerson") }}</span
    >
    <r-hr
      hrStyle="sub-primary"
      class="recipe-cost-registration--content"
    ></r-hr>

    <!-- 売値の入力 -->
    <r-paper class="recipe-cost-registration--content" round paperStyle="gray">
      <span>{{ $t("recipe.recipeCost.calculateCostRate") }}</span>
      <div
        class="recipe-cost-registration--input-wrapper recipe-cost-registration--content--item"
      >
        {{ $t("common.price") }} :&nbsp;
        <r-textbox
          class="recipe-cost-registration--input"
          type="number"
          v-model.number="setPrice"
          :error="error.setPrice !== ''"
        />{{ $t("common.currencyYen") }}
      </div>
      <div class="error-message">
        {{ error.setPrice }}
      </div>
    </r-paper>

    <r-hr
      hrStyle="sub-primary"
      class="recipe-cost-registration--content"
    ></r-hr>

    <!-- 目標原価率の設定 -->
    <div class="recipe-cost-registration--content">
      <span>{{ $t("recipe.recipeCost.setTargetCostRate") }}</span>
      <div class="recipe-cost-registration--input-wrapper">
        <r-textbox
          class="recipe-cost-registration--input"
          type="number"
          v-model.number="setTargetRate"
          :error="error.setTargetRate !== ''"
        ></r-textbox
        >%
      </div>
      <div class="error-message">
        {{ error.setTargetRate }}
      </div>
    </div>

    <r-hr
      hrStyle="sub-primary"
      class="recipe-cost-registration--content"
    ></r-hr>

    <r-chart
      class="recipe-cost-registration--content--item"
      chartStyle="secondary"
      :label="$t('common.costRate')"
      :rate="costRate"
    ></r-chart>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RChart from "@/plugins/atoms/RChart.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";

import auth from "@/libs/Auth.js";
import recipeManager from "@/libs/Recipe.js";
import storeManager from "@/libs/Store.js";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  components: {
    RModal,
    RChart,
    RTextbox,
    RPaper,
    RHr,
  },
  props: {
    id: String, // レシピID
    price: Number, // 売価
    serv: Number, // 人前
    pastCostRate: Number, // 前回の原価率
    targetRate: Number, // 目標原価率
    items: Array,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      setPrice: 0,
      setTargetRate: 0,
      error: {
        setPrice: "",
        setTargetRate: "",
      },
    };
  },
  computed: {
    totalCost() {
      // レシピ全体の原価
      return recipeManager.calcTotalCost(this.items);
    },
    costPerServ() {
      // 一人前あたりの原価
      return recipeManager.calcTotalCostPerServ(this.items, this.serv);
    },
    costRate() {
      // 原価率の計算
      return recipeManager.calcCostRate(this.costPerServ, this.setPrice);
    },
    isUsingSmaregi() {
      return this.customer.smaregiStoreID ? true : false;
    },
  },
  methods: {
    async enter() {
      this.executeValidation();
      if (this.error.setPrice !== "" || this.error.setTargetRate) {
        return;
      }

      if (this.isUsingSmaregi) {
        if (
          !window.confirm(
            this.$i18n.t("recipe.recipeCost.confirmUpdateRecipeSmaregi"),
          )
        ) {
          return;
        }
      }
      // 原価率の登録
      try {
        // 自身のレシピの原価率をアップデート
        await recipeManager.updateCostRate(
          this.id, // レシピID
          this.setPrice, // 売価
          this.costRate, // 原価率
          this.pastCostRate, // 前回の原価率
          this.costPerServ, // 一人当たりの原価
          this.totalCost, // 人前全体の原価
          this.setTargetRate, // 目標原価率
          this.items, // 食材
        );

        // 関連するレシピの原価率をアップデート
        await recipeManager.updateCostHigherRecipe(
          this.customerID,
          this.storeID,
          this.id,
        );

        // 編集中解除
        await auth.unLockEditStatus();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw new Error(error);
      }
      /*登録完了後、背景固定処理を解除*/
      clearAllBodyScrollLocks();
      /*レシピ詳細画面に遷移*/
      this.$router.push(
        {
          name: "recipeDetail",
          params: {
            id: this.id,
            alertMessage: "registCost",
          },
        },
        () => {},
      );
    },
    cancel() {
      this.$emit("cancel");
    },
    isDecimalPointLengthLargeThan2(number) {
      const splitNumber = String(number).split(".");
      if (splitNumber[1]) {
        return splitNumber[1].length > 2;
      }
      return false;
    },
    executeValidation() {
      // 売値のバリデーション
      if (this.setPrice < 0 || this.setPrice === "") {
        this.$set(
          this.error,
          "setPrice",
          this.$t("recipe.recipeCost.recipePriceNotInputed"),
        );
      } else {
        this.$set(this.error, "setPrice", "");
      }

      // 目標原価率のバリデーション
      if (this.setTargetRate < 0 || this.setTargetRate === "") {
        this.$set(
          this.error,
          "setTargetRate",
          this.$t("recipe.recipeCost.targetCostRateNotSet"),
        );
        return;
      } else if (this.isDecimalPointLengthLargeThan2(this.setTargetRate)) {
        this.$set(
          this.error,
          "setTargetRate",
          this.$t("recipe.recipeCommon.inputDecimalPointTwo"),
        );
      } else {
        this.$set(this.error, "setTargetRate", "");
      }
    },
  },
  async created() {
    this.customerID = await auth.getCustomerID(this.$root);
    this.storeID = await auth.getStoreID(this.$root);
    if (!this.customerID || !this.storeID) {
      // customerID、StoreIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }
    this.setPrice = this.price;
    this.setTargetRate = this.targetRate;

    const customer = await storeManager.getStoreInfo(
      this.customerID,
      this.storeID,
    );
    this.customer = customer;
  },
};
</script>

<style scoped>
.recipe-cost-registration--content {
  margin-top: 20px;
}

.recipe-cost-registration--input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.recipe-cost-registration--input {
  width: 60%;
}
.recipe-cost-registration--content--item {
  margin-top: 10px;
}
.error-message {
  height: 10px;
  color: #e85951;
  font-weight: bold;
}
</style>

<template>
  <div class="out-number-list">
    <div class="out-number-list--header">
      <span class="out-number-list--header--item out-number-list--menu">{{
        $t("common.menu")
      }}</span>
      <span class="out-number-list--header--item out-number-list--out-number">{{
        $t("analysis.sellingAmount")
      }}</span>
      <span class="out-number-list--header--item out-number-list--abc"
        >ABC</span
      >
      <span class="out-number-list--header--item out-number-list--sales">{{
        $t("analysis.sales")
      }}</span>
    </div>

    <r-hr hrStyle="gray"></r-hr>

    <div
      class="out-number-list--main"
      v-for="(data, index) in sortedListDec"
      :key="index"
    >
      <div class="out-number-list--menu">{{ data.recipe }}</div>

      <div class="out-number-list--out-number">
        <div
          class="out-number-list--main--out-number-bar"
          :style="{
            width: `${outNumberWidth(data.outNumber)}%`,
            'background-color': data.outNumber
              ? backgroundColor(data.rank)
              : 'inherit',
            color: data.outNumber ? '#ffffff' : '#2c3e50',
          }"
        >
          {{ data.outNumber }}
        </div>
      </div>

      <div class="out-number-list--abc">
        <r-badge
          :badgeSize="badgeSize"
          :customBackground="backgroundColor(data.rank)"
          >{{ data.rank }}</r-badge
        >
      </div>

      <div class="out-number-list--sales">
        {{ calcSales(data.price, data.outNumber) | localNumber
        }}{{ $t("common.currencyYen") }}
      </div>
    </div>
  </div>
</template>

<script>
import RBadge from "@/plugins/atoms/RBadge.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";

export default {
  components: {
    RBadge,
    RHr,
  },
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    width: Number,
  },
  data() {
    return {
      sumOfSales: 0,
    };
  },
  methods: {
    calcSumOfSales() {
      const salesList = this.listData.map((e) => e.price * e.outNumber);
      this.sumOfSales = salesList.reduce((acc, val) => {
        acc += val;
        return acc;
      }, 0);
    },
  },
  filters: {
    localNumber(num) {
      return num.toLocaleString("ja-JP");
    },
  },
  computed: {
    calcSales() {
      return (price, outNumber) => {
        return price * outNumber;
      };
    },
    maxOutNumber() {
      return this.listData
        .map((e) => e.outNumber)
        .reduce((a, b) => (a > b ? a : b));
    },
    outNumberWidth() {
      // 最大出数を元に比率を計算
      return (outNumber) => {
        return Number((outNumber / this.maxOutNumber) * 100).toFixed();
      };
    },
    // 売上で昇順
    sortedListAsc() {
      return Array.from(this.listData).sort((a, b) => {
        let comparison = 0;
        if (a.sales > b.sales) {
          comparison = 1;
        } else if (a.sales < b.sales) {
          comparison = -1;
        }
        return comparison;
      });
    },
    // 売上で降順
    sortedListDec() {
      return Array.from(this.listData).sort((a, b) => {
        let comparison = 0;
        if (
          this.calcSales(a.price, a.outNumber) >
          this.calcSales(b.price, b.outNumber)
        ) {
          comparison = -1;
        } else if (
          this.calcSales(a.price, a.outNumber) <
          this.calcSales(b.price, b.outNumber)
        ) {
          comparison = 1;
        }
        return comparison;
      });
    },
    salesPerSum() {
      return (sales) => {
        return (sales / this.sumOfSales) * 100;
      };
    },
    backgroundColor() {
      return (rank) => {
        if (rank === "A") {
          return "#EA883B";
        } else if (rank === "B") {
          return "#52B395";
        } else {
          return "#3688A1";
        }
      };
    },
    badgeSize() {
      if (this.width >= 641) {
        return "large";
      } else {
        return "middle";
      }
    },
  },
  created() {
    // 売上の合計値を算出
    this.calcSumOfSales();
  },
};
</script>

<style scoped>
.out-number-list--header,
.out-number-list--main {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  box-sizing: border-box;
  font-weight: bold;
  align-items: center;
}

.out-number-list--main {
  margin-top: 20px;
}
.out-number-list--header--item {
  display: inline-block;
}
.out-number-list--menu {
  width: 100%;
}
/** 出数のグラフとバッジ */
.out-number-list--out-number {
  box-sizing: border-box;
  width: 100%;
}
.out-number-list--main--out-number-bar {
  width: 60%;
  color: white;
  font-weight: bold;
  padding: 8px;
  box-sizing: border-box;
}
.out-number-list--abc {
  width: 10%;
  margin-top: 10px;
}
/***************** */

/** 出数 */
.out-number-list--sales {
  width: 90%;
  font-weight: bold;
  text-align: right;
  margin-top: 10px;
}

/** PC */
@media screen and (min-width: 641px) {
  .out-number-list--header {
    padding: 20px;
  }
  /* .out-number-list--main {
  } */
  .out-number-list--menu {
    width: 20%;
  }
  /** 出数のグラフとバッジ */
  .out-number-list--out-number {
    width: 60%;
    margin-top: 0;
    padding: 0 10px;
  }
  .out-number-list--abc {
    width: 10%;
    margin-top: 0;
  }
  /******************** */

  /** 出数 */
  .out-number-list--sales {
    margin-top: 0;
    width: 10%;
  }
}
@media screen and (min-width: 1024px) {
  .out-number-list--menu {
    width: 20%;
  }
  /** 出数のグラフとバッジ */
  .out-number-list--out-number {
    width: 60%;
  }
  .out-number-list--abc {
    width: 10%;
  }
  /******************** */

  .out-number-list--sales {
    width: 10%;
  }
}
</style>

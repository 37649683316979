<template>
  <r-modal
    class="recipe-category-form-modal"
    :step="step ? '2' : ''"
    :title="$t('recipe.recipeRegistration.recipeCategoryForm.formTitle')"
    @enter="enter"
    @cancel="cancel"
    enter
  >
    <div class="error-message">{{ error }}</div>
    <r-checkbox
      :limit="3"
      :items="categories"
      :defaultCounter="defaultCounter"
      @toggle="toggle"
      class="recipe-category-form--category-check"
    />
  </r-modal>
</template>

<script>
import RCheckbox from "@/plugins/atoms/RCheckbox.vue";
import RModal from "@/plugins/molecules/RModal.vue";

import auth from "@/libs/Auth.js";
import categoryManager from "@/libs/Category.js";

export default {
  name: "recipe-category-form",
  props: {
    existingCat: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      categories: [],
      customerID: "",
      storeID: "",
      error: "",
      step: false,
    };
  },
  components: {
    RCheckbox,
    RModal,
  },
  methods: {
    toggle(index) {
      this.$set(this.categories[index], "bool", !this.categories[index].bool);
    },
    enter() {
      if (!this.categories.map((e) => e.bool).includes(true)) {
        this.error = this.$t(
          "recipe.recipeRegistration.recipeCategoryForm.notSelected",
        );
        return;
      }
      this.$emit(
        "enter",
        this.categories.filter((e) => e.bool !== false),
      );
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  computed: {
    defaultCounter() {
      const arrayMaster = this.existingCat.filter((val) => val);
      return arrayMaster.length;
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);

      if (!this.customerID || !this.storeID) {
        throw new Error("Forbidden");
      }
      this.categories = await categoryManager.getCategoryFormList(
        this.customerID,
        this.storeID,
        this.existingCat,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  mounted() {
    if (this.$route.path === "/recipe-registration") {
      this.step = true;
    }
  },
};
</script>

<style scoped>
.error-message {
  height: 10px;
  margin: 0 0 10px 0;
  color: #e85951;
  font-weight: bold;
}
.recipe-category-form-modal {
  height: 250px;
  overflow: auto;
}
.recipe-category-form--category-check {
  min-height: 50%;
  overflow: auto;
  max-height: 50vh;
}
@media screen and (min-width: 641px) {
  .recipe-category-form-modal {
    height: unset;
  }
  .recipe-category-form--category-check {
    min-height: 50%;
    overflow: auto;
  }
}
@media (min-width: 1024px) {
}
</style>

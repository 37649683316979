import { httpsCallable } from "firebase/functions";
import { collection, where, query, getDocs } from "firebase/firestore";
import { functions, db } from "@/libs";

export default {
  async createUser(userInfo) {
    try {
      const createUser = httpsCallable(functions, "createUser");
      await createUser({
        userID: userInfo.userID,
        password: userInfo.password,
        displayName: userInfo.displayName,
        customerID: userInfo.customerID,
        storeID: userInfo.storeID,
        headquarters: userInfo.headquarters,
        viewRp: userInfo.viewRp,
        viewOtherRp: userInfo.viewOtherRp,
        createRp: userInfo.createRp,
        viewCost: userInfo.viewCost,
        viewBook: userInfo.viewBook,
        createBook: userInfo.createBook,
        viewInfo: userInfo.viewInfo,
        editCat: userInfo.editCat,
        editUser: userInfo.editUser,
        selectSup: userInfo.selectSup,
        viewHidden: userInfo.viewHidden,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  async updateUser(userInfo) {
    try {
      const updateUser = httpsCallable(functions, "updateUser");
      await updateUser({
        uid: userInfo.id,
        userID: userInfo.userID,
        password: userInfo.password,
        displayName: userInfo.displayName,
        customerID: userInfo.customerID,
        storeID: userInfo.storeID,
        headquarters: userInfo.headquarters,
        viewRp: userInfo.viewRp,
        viewOtherRp: userInfo.viewOtherRp,
        createRp: userInfo.createRp,
        viewCost: userInfo.viewCost,
        viewBook: userInfo.viewBook,
        createBook: userInfo.createBook,
        viewInfo: userInfo.viewInfo,
        editCat: userInfo.editCat,
        editUser: userInfo.editUser,
        selectSup: userInfo.selectSup,
        viewHidden: userInfo.viewHidden,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  async deleteUser(userInfo) {
    try {
      const deleteUser = httpsCallable(functions, "deleteUser");
      await deleteUser({
        customerID: userInfo.customerID,
        storeID: userInfo.storeID,
        uid: userInfo.id,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
  // ユーザ一覧の取得XX ユーザ一覧
  async getUserList(customerID, storeID) {
    let userList = [];
    try {
      const usersRef = query(
        collection(db, "users"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
        where("role", "==", "store"),
      );
      const usersSnapshot = await getDocs(usersRef);
      if (!usersSnapshot.empty) {
        usersSnapshot.forEach((doc) => {
          if (doc.exists()) {
            const user = doc.data();
            user.id = doc.id;
            userList.push(user);
          }
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return userList;
  },
  // ユーザーの最終ログイン日時で使用
  async getUserListWithLastLoginDate(customerID, storeID) {
    let userList = [];
    try {
      const getUserList = httpsCallable(functions, "getUserList");
      const resp = await getUserList({ customerID, storeID });
      if (resp.data) {
        resp.data.forEach((doc) => {
          let strDate = "";
          const lastLoginDate = doc.lastLoginDate;
          if (lastLoginDate) {
            const dt = new Date(lastLoginDate);
            strDate = `${dt.getFullYear()}/${(dt.getMonth() + 1)
              .toString()
              .padStart(2, "0")}/${dt
              .getDate()
              .toString()
              .padStart(2, "0")} ${dt
              .getHours()
              .toString()
              .padStart(2, "0")}:${dt
              .getMinutes()
              .toString()
              .padStart(2, "0")}:${dt
              .getSeconds()
              .toString()
              .padStart(2, "0")}`;
          }
          userList.push({ name: doc.name, lastLoginDate: strDate || "" });
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return userList;
  },
  async getUserInfo(id) {
    let userInfo = {};
    try {
      if (!id) {
        throw new Error("No ID");
      }
      const getUserInfo = httpsCallable(functions, "getUserInfo");
      const resp = await getUserInfo({ uid: id });
      if (resp.data) {
        userInfo = { ...resp.data, id };
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return userInfo;
  },
  generateRandomString(limit) {
    const chars =
      "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";
    return Array.from({ length: limit }, () =>
      chars.charAt(Math.floor(Math.random() * chars.length)),
    ).join("");
  },
};

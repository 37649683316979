var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShowSpinner)?_c('r-spinner'):_c('div',[_c('r-heading',{attrs:{"level":1,"image":_vm.headingImage}},[_vm._v(" "+_vm._s(_vm.$t("menu.menuRegiLink.menuRegiTitle"))+" ")]),_c('div',{staticClass:"regi-link-wrapper"},[_c('div',{staticClass:"back-btn"},[_c('r-button',{staticClass:"back-btn-r",attrs:{"buttonStyle":"primary","outline":true},on:{"click":_vm.toMenuTop}},[_vm._v(_vm._s(_vm.$t("common.back")))])],1),_c('div',{staticClass:"select-book"},[_c('r-select',{attrs:{"options":_vm.bookList},on:{"input":_vm.selectBook}})],1),_c('div',{staticClass:"regi-linker"},[_vm._l((_vm.bookList),function(value,name,bookIdx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedBook === value),expression:"selectedBook === value"}],key:bookIdx,staticClass:"book-name-wrapper"},[_c('span',{staticClass:"book-name"},[_vm._v(_vm._s(name))])])}),_c('r-paper',{staticClass:"yellow-paper",attrs:{"paperStyle":"yellow"}},[_vm._l((_vm.sections),function(section,index){return _c('div',{key:index,staticClass:"section-list"},[_c('div',{staticClass:"section-title"},[_vm._v(_vm._s(section.name))]),_vm._l((_vm.sections[index].menus),function(menu,menuIdx){return _c('div',{key:menuIdx,staticClass:"menu-items"},[_c('div',{staticClass:"menu-name"},[_vm._v(_vm._s(menu.name))]),_c('div',{staticClass:"regi-id-input"},[(!_vm.isUsingSmaregi)?_c('span',[_c('label',[_vm._v(_vm._s(_vm.$t("menu.menuRegiLink.regiID")))]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(menu.regiID),expression:"menu.regiID",modifiers:{"number":true}}],staticClass:"input-id",class:{
                    ' duplicated-regi-id': _vm.duplicatedRegiID.includes(
                      menu.regiID,
                    ),
                  },domProps:{"value":(menu.regiID)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(menu, "regiID", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e(),(_vm.isUsingSmaregi)?_c('span',[_c('label',[_vm._v(_vm._s(_vm.$t("menu.menuRegiLink.smaregiProductID")))]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(menu.regiID),expression:"menu.regiID",modifiers:{"number":true}}],staticClass:"input-id",class:{
                    ' duplicated-regi-id': _vm.duplicatedRegiID.includes(
                      menu.regiID,
                    ),
                  },domProps:{"value":(menu.regiID)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(menu, "regiID", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v(_vm._s(_vm.$t("menu.menuRegiLink.smaregiCategoryID")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(menu.smaregiCategoryID),expression:"menu.smaregiCategoryID"}],staticClass:"input-id",class:{
                    ' duplicated-regi-id':
                      _vm.duplicatedSmaregiCategoryID.includes(
                        menu.smaregiCategoryID,
                      ),
                  },domProps:{"value":(menu.smaregiCategoryID)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(menu, "smaregiCategoryID", $event.target.value)}}})]):_vm._e()])])})],2)}),(!_vm.selectedBook)?_c('div',{staticClass:"section-list select-book-word"},[_vm._v(" "+_vm._s(_vm.$t("menu.menuRegiLink.askSelectMenuBook"))+" ")]):_vm._e(),(_vm.selectedBook)?_c('div',{staticClass:"regi-btn-wrapper"},[(_vm.isUsingSmaregi)?_c('div',[_c('r-button',{staticClass:"registration-regi-id",attrs:{"buttonStyle":"primary","outline":"","full":""},on:{"click":_vm.regiLinkComplete}},[_vm._v(_vm._s(_vm.$t("menu.menuRegiLink.registSmaregi"))+" ")]),_c('r-button',{staticClass:"registration-regi-id",attrs:{"buttonStyle":"primary","outline":"","full":""},on:{"click":_vm.linkSmaregi}},[_vm._v(_vm._s(_vm.$t("menu.menuRegiLink.connectSmaregi"))+" ")])],1):_c('div',[_c('r-button',{staticClass:"registration-regi-id",attrs:{"buttonStyle":"primary","outline":"","full":""},on:{"click":_vm.regiLinkComplete}},[_vm._v(_vm._s(_vm.$t("menu.menuRegiLink.regist"))+" ")])],1)]):_vm._e()],2)],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <r-spinner v-if="isShowSpinner" />
  <div v-else>
    <r-heading :level="1" :image="headingImage">
      <!--タイトル：レジ連携-->
      {{ $t("menu.menuRegiLink.menuRegiTitle") }}
    </r-heading>
    <div class="regi-link-wrapper">
      <!--「戻る」ボタン-->
      <div class="back-btn">
        <r-button
          buttonStyle="primary"
          :outline="true"
          @click="toMenuTop"
          class="back-btn-r"
          >{{ $t("common.back") }}</r-button
        >
      </div>
      <!--ブック選択-->
      <div class="select-book">
        <r-select :options="bookList" @input="selectBook"></r-select>
      </div>
      <!--レジ連携設定入力-->
      <div class="regi-linker">
        <div
          class="book-name-wrapper"
          v-for="(value, name, bookIdx) in bookList"
          :key="bookIdx"
          v-show="selectedBook === value"
        >
          <span class="book-name">{{ name }}</span>
        </div>
        <r-paper paperStyle="yellow" class="yellow-paper">
          <!--セクション情報表示領域-->
          <div
            class="section-list"
            v-for="(section, index) in sections"
            :key="index"
          >
            <div class="section-title">{{ section.name }}</div>
            <!--メニュー１個の名前、売値、原価率-->
            <div
              class="menu-items"
              v-for="(menu, menuIdx) in sections[index].menus"
              :key="menuIdx"
            >
              <div class="menu-name">{{ menu.name }}</div>
              <!--レジID入力欄-->
              <div class="regi-id-input">
                <span v-if="!isUsingSmaregi">
                  <!-- レジID -->
                  <label>{{ $t("menu.menuRegiLink.regiID") }}</label>
                  <input
                    class="input-id"
                    :class="{
                      ' duplicated-regi-id': duplicatedRegiID.includes(
                        menu.regiID,
                      ),
                    }"
                    v-model.number="menu.regiID"
                  />
                </span>

                <span v-if="isUsingSmaregi">
                  <!-- スマレジ商品コード -->
                  <label>{{ $t("menu.menuRegiLink.smaregiProductID") }}</label>
                  <input
                    class="input-id"
                    :class="{
                      ' duplicated-regi-id': duplicatedRegiID.includes(
                        menu.regiID,
                      ),
                    }"
                    v-model.number="menu.regiID"
                  />
                  <!-- スマレジ部門番号 -->
                  <label>{{ $t("menu.menuRegiLink.smaregiCategoryID") }}</label>
                  <input
                    class="input-id"
                    :class="{
                      ' duplicated-regi-id':
                        duplicatedSmaregiCategoryID.includes(
                          menu.smaregiCategoryID,
                        ),
                    }"
                    v-model="menu.smaregiCategoryID"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="section-list select-book-word" v-if="!selectedBook">
            {{ $t("menu.menuRegiLink.askSelectMenuBook") }}
          </div>
          <!--登録ボタン-->
          <div class="regi-btn-wrapper" v-if="selectedBook">
            <div v-if="isUsingSmaregi">
              <!-- スマレジを利用している場合の登録ボタン -->
              <r-button
                buttonStyle="primary"
                outline
                full
                @click="regiLinkComplete"
                class="registration-regi-id"
                >{{ $t("menu.menuRegiLink.registSmaregi") }}
              </r-button>

              <r-button
                buttonStyle="primary"
                outline
                full
                @click="linkSmaregi"
                class="registration-regi-id"
                >{{ $t("menu.menuRegiLink.connectSmaregi") }}
              </r-button>
            </div>
            <div v-else>
              <!-- スマレジを利用していない場合の登録ボタン -->
              <r-button
                buttonStyle="primary"
                outline
                full
                @click="regiLinkComplete"
                class="registration-regi-id"
                >{{ $t("menu.menuRegiLink.regist") }}
              </r-button>
            </div>
          </div>
        </r-paper>
      </div>
    </div>
  </div>
</template>
<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import menuManager from "@/libs/Menu.js";
import storeManager from "@/libs/Store.js";

export default {
  components: {
    RButton,
    RPaper,
    RSelect,
    RHeading,
    RSpinner,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      bookID: "",
      store: {},
      bookList: {},
      selectedBook: "",
      sections: {},
      headingImage: require("../../../assets/images/bookColor.png"),
      books: [],
      duplicatedRegiID: [],
      duplicatedSmaregiCategoryID: [],
      isShowSpinner: false,
    };
  },
  methods: {
    toMenuTop() {
      //戻るボタン
      this.$router.push({ name: "menu" }, () => {});
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    async selectBook(selected) {
      try {
        if (selected) {
          // 選択したセクション情報を取得
          this.bookID = selected;
          this.selectedBook = selected;
          // this.sections = await menuManager.getRegiInfo(this.bookID);
          this.books.forEach((book) => {
            if (book.bookID === this.selectedBook) {
              //ブックIDに該当するセクションを表示
              this.sections = book.sections;
            }
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw new Error(error);
      }
    },
    async regiLinkComplete() {
      // レジ情報を保存する
      // await this.saveRegiLink();
      // 重複しているレジIDを格納する配列
      this.duplicatedRegiID = menuManager.checkDuplicateRegiID(this.books);
      // this.duplicatedSmaregiCategoryID = menuManager.checkDuplicateSmaregiCategoryID(this.books)
      if (this.duplicatedRegiID.length > 0) {
        //重複しているレジIDが存在している場合
        alert(this.$i18n.t("menu.menuRegiLink.alreadyRegisteredID"));
        return;
      } else if (this.duplicatedSmaregiCategoryID.length > 0) {
        alert(
          this.$i18n.t("menu.menuRegiLink.alreadyRegisteredSmaregiCategoryID"),
        );
        return;
      } else {
        try {
          // レジIDを登録(作業中のメニューブック全てを登録)
          this.books.map(async (book) => {
            await menuManager.setRegiInfo(book.bookID, book.sections);
          });
          // await menuManager.setRegiInfo(this.bookID, this.sections);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("ERROR", error);
          throw new Error("Forbidden");
        }
      }
      //メニュートップに遷移
      this.$router.push({ name: "menu" }, () => {});
    },
    async linkSmaregi() {
      this.isShowSpinner = true;
      try {
        // レジ情報を保存する
        // 重複しているレジIDを格納する配列
        this.duplicatedRegiID = menuManager.checkDuplicateRegiID(this.books);
        // this.duplicatedSmaregiCategoryID = menuManager.checkDuplicateSmaregiCategoryID(this.books)
        if (this.duplicatedRegiID.length > 0) {
          //重複しているレジIDが存在している場合
          alert(this.$i18n.t("menu.menuRegiLink.alreadyRegisteredID"));
          return;
        } else if (this.duplicatedSmaregiCategoryID.length > 0) {
          alert(
            this.$i18n.t(
              "menu.menuRegiLink.alreadyRegisteredSmaregiCategoryID",
            ),
          );
          return;
        } else {
          try {
            // レジIDを登録(作業中のメニューブック全てを登録)
            this.books.map(async (book) => {
              await menuManager.setRegiInfo(book.bookID, book.sections);
            });
            // await menuManager.setRegiInfo(this.bookID, this.sections);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error("ERROR", error);
            throw new Error("Forbidden");
          }
        }
        // await this.saveRegiLink();
        // eslint-disable-next-line no-console
        console.log(this.customerID, this.storeID, this.bookID);
        await menuManager.linkSmaRegi(
          this.customerID,
          this.storeID,
          this.bookID,
        );
        alert(this.$i18n.t("menu.menuRegiLink.noticeSuccessSmaregiLink"));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        throw new Error(e);
      }
      this.isShowSpinner = false;
    },
    // async saveRegiLink() {
    //   // eslint-disable-next-line no-console
    //   console.log("saveRegiLink");
    //   // 重複しているレジIDを格納する配列
    //   this.duplicatedRegiID = menuManager.checkDuplicateRegiID(this.books);
    //   this.duplicatedSmaregiCategoryID = menuManager.checkDuplicateSmaregiCategoryID(this.books)
    //   if (this.duplicatedRegiID.length > 0) {
    //     //重複しているレジIDが存在している場合
    //     alert(this.$i18n.t("menu.menuRegiLink.alreadyRegisteredID"));
    //     return;
    //   } else if (this.duplicatedSmaregiCategoryID.length > 0){
    //     alert(this.$i18n.t("menu.menuRegiLink.alreadyRegisteredSmaregiCategoryID"))
    //     return;
    //   } else {
    //     try {
    //       // レジIDを登録(作業中のメニューブック全てを登録)
    //       this.books.map(async book => {
    //         await menuManager.setRegiInfo(book.bookID, book.sections);
    //       });
    //       // await menuManager.setRegiInfo(this.bookID, this.sections);
    //     } catch (error) {
    //       // eslint-disable-next-line no-console
    //       console.error("ERROR", error);
    //       throw new Error("Forbidden");
    //     }
    //   }
    // }
  },
  computed: {
    isUsingSmaregi() {
      // return true;
      if (this.store.smaregiStoreID) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    this.customerID = await auth.getCustomerID(this.$root);
    this.storeID = await auth.getStoreID(this.$root);
    if (!this.customerID || !this.storeID) {
      // customerID、StoreIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }

    try {
      this.bookList = await menuManager.getMenuInfo(
        this.customerID,
        this.storeID,
        this.$i18n,
      );
      let bookIDArr = Object.values(this.bookList);
      //「メニューブックを選択」を削除
      bookIDArr.shift();

      //全てのブックを呼び出す
      bookIDArr.forEach(async (id) => {
        const sections = await menuManager.getRegiInfo(id);
        this.books.push({ bookID: id, sections });
      });

      const store = await storeManager.getStoreInfo(
        this.customerID,
        this.storeID,
      );
      this.store = store;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  //PCサイズ対応
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style scoped>
.regi-link-wrapper {
  font-size: 1rem;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 40px;
}
.back-btn {
  display: flex;
  width: 100%;
}
.back-btn-r {
  color: #333;
  margin-left: 15px;
  margin-top: 15px;
}
.back-btn-r:hover {
  background-color: #e87352;
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
}
.select-book {
  background-color: #fff;
  border-radius: 5px;
  border: 4vw;
  padding: 4vw;
  margin: 15px 15px 15px 15px;
  text-align: left;
}
.regi-linker {
  display: block;
  min-height: 30vh;
  background-color: #4d261b;
}
.book-name-wrapper {
  padding-left: 4vw;
}
.book-name {
  color: #fff;
  text-align: left;
  font-weight: bold;
  display: block;
  padding-top: 4vw;
  padding-left: 4vw;
  width: 85%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.yellow-paper {
  min-height: 30vh;
  border: 4vw solid #4d261b;
  padding: 4vw #4d261b;
}
.section-list {
  padding: 10px;
}
.section-title {
  padding: 5px;
  font-weight: bold;
  text-align: left;
  border-top: 2px solid #e87352;
  border-bottom: 2px solid #e87352;
}
.menu-items {
  display: block;
  border-bottom: 2px solid #eaeaea;
  padding: 5px;
}
.menu-name {
  text-align: left;
  padding: 5px;
  border-top: 5px;
}
.regi-id-input {
  position: relative;
  text-align: right;
}
.input-id {
  border: 2px solid #cdcdcd;
  border-radius: 5px;
  outline: none;
  padding: 5px;
  margin-left: 10px;
  max-width: 80pt;
}
.duplicated-regi-id {
  border: 2px solid #ea5446;
}
.registration-regi-id {
  border-radius: 5px;
  margin: 10px auto;
  padding: 10px;
}

.registration-regi-id:hover {
  background-color: #e87352;
  color: #fff;
  transition: 0.5s;
}
.select-book-word {
  font-weight: bold;
  text-align: left;
}
/*iPad用css*/
@media (min-width: 641px) {
  .regi-link-wrapper {
    width: 100%;
    padding: 2%;
    margin-top: 20px;
  }
  .back-btn {
    display: flex;
    justify-content: left;
    position: relative;
    top: -2.5vh;
    width: 100%;
  }
  .back-btn-r {
    margin-left: unset;
  }
  .select-book {
    width: 240px;
    background-color: #fff;
    border-radius: 5px;
    border: 0px;
    padding: 0px;
    margin: 20px 0px 20px 0px;
    text-align: left;
  }
  .regi-linker {
    display: block;
    min-height: 30vh;
    background-color: #4d261b;
  }
  .book-name-wrapper {
    display: table-cell;
    font-weight: bold;
    padding-top: 0px;
    padding-left: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: auto;
  }
  .book-name {
    color: #fff;
    text-align: left;
    font-weight: bold;
    display: block;
    padding-top: 1vw;
    padding-left: 4vw;
    width: 85%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .yellow-paper {
    min-height: 30vh;
    border-top: 1vw solid #4d261b;
    border-right: 40px solid #4d261b;
    border-bottom: 40px solid #4d261b;
    border-left: 40px solid #4d261b;
    padding: 40px #4d261b;
  }
  .section-list {
    padding: 24px 24px 0px 24px;
  }
  .menu-items {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  .input-id {
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    padding: 10px;
    margin-left: 10px;
    max-width: 80px;
  }
  .duplicated-regi-id {
    border: 2px solid #ea5446;
  }
  .regi-btn-wrapper {
    margin: 40px;
  }
}
/*PC用css */
@media (min-width: 1024px) {
  .regi-link-wrapper {
    width: 1024px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 0px;
  }
  .back-btn {
    display: flex;
    justify-content: left;
    top: -0.4vh;
    width: 100%;
  }
  .select-book {
    width: 240px;
    background-color: #fff;
    border-radius: 5px;
    border: 0px;
    padding: 0px;
    margin: 60px 0px 20px 0px;
    text-align: left;
  }
  .regi-linker {
    display: block;
    min-height: 30vh;
    background-color: #4d261b;
  }
  .book-name-wrapper {
    display: table-cell;
    font-weight: bold;
    padding-top: 0px;
    padding-left: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: auto;
  }
  .book-name {
    color: #fff;
    text-align: left;
    font-weight: bold;
    display: block;
    padding-top: 1vw;
    padding-left: 4vw;
    width: 85%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .yellow-paper {
    min-height: 30vh;
    border-top: 1vw solid #4d261b;
    border-right: 40px solid #4d261b;
    border-bottom: 40px solid #4d261b;
    border-left: 40px solid #4d261b;
    padding: 40px #4d261b;
  }
  .section-list {
    padding: 40px 40px 0px 40px;
  }
  .menu-items {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  .input-id {
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    padding: 10px;
    margin-left: 10px;
    max-width: 80px;
  }
  .duplicated-regi-id {
    border: 2px solid #ea5446;
  }
  .regi-btn-wrapper {
    margin: 40px;
  }
}
</style>

<template>
  <div class="r-chart">
    <div
      class="r-chart--background"
      :style="{
        'background-image': `url(${graphBg})`,
      }"
    >
      <canvas
        id="r-chart--graph"
        ref="canvas"
        width="236"
        height="236"
      ></canvas>
      <div class="r-chart--inner">
        <p class="cost-rate-label" v-text="label" />
        <p class="cost-rate-val" :style="{ color: chartColor }">
          <b v-if="rate === Infinity"> _ _ </b>
          <b v-else>{{ rate.toFixed(2) }}</b>
          <span v-if="rate && rate !== Infinity">%</span>
        </p>
        <div v-if="pastCostRate > 0">
          <img
            :src="arrGray"
            :alt="$t('common.upwardArrow')"
            class="arr-image"
          />
          <div>{{ pastCostRate.toFixed(2) }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "r-chart",
  props: {
    chartStyle: String,
    label: String,
    rate: Number,
    pastCostRate: Number,
  },
  data() {
    return {
      radius: 118,
      graph: null,
      graphBg: require("../../assets/images/graphbg.png"),
      arrGray: require("../../assets/images/arr_up_gray.png"),
    };
  },
  computed: {
    angle() {
      return 360 * (this.rate / 100).toFixed(4);
    },
    startAngle() {
      return ((0 - 90) * Math.PI) / 180;
    },
    endAngle() {
      return ((this.angle - 90) * Math.PI) / 180;
    },
    xPos() {
      return this.graph.offsetWidth / 2;
    },
    yPos() {
      return this.graph.offsetHeight / 2;
    },
    chartColor() {
      if (this.chartStyle === "primary") {
        return "#e87352";
      } else {
        return "#517CE8";
      }
    },
  },
  methods: {
    graphDraw() {
      this.ctx.beginPath();
      this.ctx.moveTo(this.xPos, this.yPos);
      // canvas全体を削除
      this.ctx.clearRect(0, 0, 236, 236);
      this.ctx.arc(
        this.xPos,
        this.yPos,
        this.radius,
        this.startAngle,
        this.endAngle,
        false,
      );
      this.ctx.closePath();
      this.ctx.fillStyle = this.chartColor;
      this.ctx.fill();
    },
  },
  mounted() {
    this.graph = document.getElementById("r-chart--graph");
    this.ctx = this.$refs.canvas.getContext("2d");
    this.graphDraw();
  },
  watch: {
    rate() {
      this.graphDraw();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.r-chart {
  --primary-color: #e87352;
  --secondary-color: #517ce8;
}
.r-chart--background {
  border-radius: 118px;
  width: 236px;
  height: 236px;
  position: relative;
  margin: auto;
  background-repeat: no-repeat;
}
.r-chart--inner {
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  height: 204px;
  width: 204px;
  top: 16px;
  left: 16px;
}
.cost-rate-label {
  padding-top: 42px;
}
.cost-rate-val {
  color: var(--primary-color);
}
.cost-rate-val b {
  /*サイズ調整：3.6から48pxに調整*/
  font-size: 48px;
  font-weight: bold;
  vertical-align: baseline;
}
.cost-rate-val span {
  font-size: 2.4rem;
}
.arr-image {
  width: 24px;
  height: auto;
}
</style>

<template>
  <r-modal
    class="notification"
    :title="$t('reciproCommon.notificationModal.notice')"
    :cancelLabel="$t('reciproCommon.notificationModal.cancelLabel')"
    @cancel="cancel"
    :cancelHidden="isShowSpinner"
  >
    <r-button
      class="notification--update-all"
      buttonStyle="primary"
      @click="batchUpdate"
      v-if="notificationsNumber > 0 && !isHeadquarters"
      :disabled="isShowSpinner"
      outline
      >{{ $t("reciproCommon.notificationModal.doBatchUpdate") }}</r-button
    >
    <r-spinner v-if="isShowSpinner" />
    <div v-else>
      <r-list v-if="notificationsNumber > 0" class="notification--list-wrapper">
        <r-list-content
          v-for="(priceUpdate, index) in notifications"
          :key="index"
          class="notification-lists"
        >
          <r-list-item class="notificaiton-list">
            <template v-if="priceUpdate.recipeData.hidden && !viewHidden">
              <span>
                {{
                  $t(
                    "reciproCommon.notificationModal.foodstuffPriceUpdateInPrivateRecipe",
                  )
                }}
                <br />
                {{
                  $t(
                    "reciproCommon.notificationModal.priceUpdateNotAvailableAccount",
                  )
                }}
              </span>
            </template>
            <template v-else>
              <div>
                <span v-if="isHeadquarters"
                  >{{ convertFromStoreIDToName(priceUpdate.storeID) }}
                  {{ $t("reciproCommon.notificationModal.of") }}</span
                >
                {{ $t("reciproCommon.notificationModal.recipe") }}「{{
                  priceUpdate.recipeName
                }}」{{
                  $t("reciproCommon.notificationModal.foodstuffInUsed")
                }}「{{ priceUpdate.name }}」{{
                  $t("reciproCommon.notificationModal.hasBeenPriceUpdated")
                }}
              </div>
              <div
                class="notification--list--re-culc"
                @click="calcCostRate(index)"
                v-if="
                  !priceUpdate.recipeData.costRate ||
                  priceUpdate.recipeData.hidden
                "
              >
                {{ $t("reciproCommon.notificationModal.foodstuffPriceUpdate") }}
              </div>
              <div
                class="notification--list--re-culc"
                @click="calcCostRate(index)"
                v-else
              >
                {{ $t("reciproCommon.notificationModal.recalcCostRate") }}
              </div>
            </template>
          </r-list-item>
        </r-list-content>
      </r-list>

      <span v-else>{{
        $t("reciproCommon.notificationModal.noticeNotAvailable")
      }}</span>
    </div>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import recipeManager from "@/libs/Recipe.js";
import priceUpdateManager from "@/libs/PriceUpdate.js";

export default {
  components: {
    RModal,
    RList,
    RListContent,
    RListItem,
    RButton,
    RSpinner,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      viewHidden: false,
      isHeadquarters: false,
      notifications: [],
      storeList: [],
      isShowSpinner: false,
    };
  },
  methods: {
    async calcCostRate(index) {
      const priceUpdate = this.notifications[index];

      // コスト計算 priceUpdate={priceUpdate, recipeData, itemId}
      await recipeManager.updateItem(priceUpdate);

      // 更新データを設定
      this.$root.setPriceUpdateAction(priceUpdate);

      this.$emit("enter");
    },
    cancel() {
      this.$emit("cancel");
    },
    async batchUpdate() {
      // 一括更新
      const batchUpdateConfirm = window.confirm(
        this.$i18n.t("reciproCommon.notificationModal.confirmBatchUpdateMsg"),
      );
      if (batchUpdateConfirm) {
        this.$emit("batchUpdate");
        this.isShowSpinner = true;
        // 更新は同期実行
        for (let i = 0; i < this.notifications.length; i++) {
          const items = this.notifications[i];
          // コスト計算
          await recipeManager.updateItem(items);
        }
        // 更新チェック(一括更新)
        await priceUpdateManager.batchUpdate(this.customerID, this.storeID);
        this.isShowSpinner = false;
        alert(
          this.$i18n.t("reciproCommon.notificationModal.batchUpdateComplete"),
        );
        this.$emit("batchUpdate");
        this.$emit("cancel");
      }
    },
  },
  computed: {
    notificationsNumber() {
      return this.notifications.length;
    },
    convertFromStoreIDToName() {
      return (storeID) => {
        if (this.isHeadquarters) {
          const index = this.storeList.map((e) => e.id).indexOf(storeID);
          if (index !== -1) {
            return this.storeList[index].name;
          }
        } else {
          return "";
        }
      };
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      this.viewHidden = await auth.getViewHidden();
      this.isHeadquarters = await auth.isHeadquarters();
      if (!this.customerID || (!this.storeID && !this.isHeadquarters)) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      if (this.$root.privateState.notifications.length > 0) {
        this.notifications = this.$root.privateState.notifications;
      }

      if (this.isHeadquarters) {
        const storeArrayList = await auth.getStoreArrayList(this.customerID);
        storeArrayList.shift();
        this.storeList = storeArrayList;
      }

      // 前回表示分のデータを削除
      this.$root.clearPriceUpdateAction();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
.notification {
  position: relative;
  max-height: 80%;
}
.notification--update-all {
  position: absolute;
  right: 20px;
  top: 15px;
}
.notification--list--re-culc:hover {
  cursor: pointer;
}
.notification--list-wrapper {
  overflow: auto;
  max-height: 50vh;
}
.notification-lists {
  margin-top: 16px;
  padding-left: 8px;
}
.notification--list--re-culc {
  text-decoration: underline;
  color: #517ce8;
  margin-bottom: 16px;
}
.notification--list--re-culc:hover {
  cursor: pointer;
}
</style>

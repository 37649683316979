import { doc, getDoc } from "firebase/firestore";
import { db } from "@/libs";

export default {
  async getStoreInfo(customerID, storeID) {
    let storeInfo = {};
    try {
      if (!customerID || !storeID) {
        throw new Error("No IDs");
      }
      const docRef = doc(db, "customers", customerID, "stores", storeID);
      const store = await getDoc(docRef);
      if (store.exists()) {
        storeInfo = store.data();
      }
    } catch (e) {
      throw new Error(e);
    }
    return storeInfo;
  },
};

import Vue from "vue";
import Router from "vue-router";
import Login from "./components/auth/pages/Login.vue";
import Logout from "./components/auth/pages/Logout.vue";
import SelectCustomer from "./components/auth/pages/SelectCustomer.vue";
import MenuTop from "./components/menu/pages/MenuTop.vue";
import MenuRegistration from "./components/menu/pages/MenuRegistration.vue";
import MenuEdit from "./components/menu/pages/MenuEdit.vue";
import MenuList from "./components/menu/pages/MenuList.vue";
import MenuRegiLink from "./components/menu/pages/MenuRegiLink.vue";
import RecipeTop from "./components/recipe/pages/RecipeTop.vue";
import RecipeRegistration from "./components/recipe/pages/RecipeRegistration.vue";
import RecipeList from "./components/recipe/pages/RecipeList.vue";
import RecipeDetail from "./components/recipe/pages/RecipeDetail";
import RecipeProcedure from "./components/recipe/pages/RecipeProcedure";
import RecipeCost from "./components/recipe/pages/RecipeCost.vue";
import RecipeEdit from "./components/recipe/pages/RecipeEdit.vue";
import CourseTop from "./components/course/pages/CourseTop.vue";
import CourseRegistration from "./components/course/pages/CourseRegistration.vue";
import CourseList from "./components/course/pages/CourseList.vue";
import CourseDetail from "./components/course/pages/CourseDetail.vue";
import CourseEdit from "./components/course/pages/CourseEdit.vue";
import ConfigList from "./components/config/pages/ConfigList.vue";
import AnalysisNote from "./components/analysisNotes/pages/AnalysisNote.vue";
import RPageNotFound from "./components/design/RPageNotFound.vue";
import RError from "./components/design/RError.vue";

import { auth } from "@/libs";
import authCheck from "@/libs/Auth.js";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "login",
      component: Login,
    },
    {
      path: "/logout",
      name: "logout",
      component: Logout,
    },
    {
      path: "/select-customer",
      name: "selectCustomer",
      component: SelectCustomer,
      meta: { requiresAuth: true, admin: true },
    },
    {
      path: "/menu",
      name: "menu",
      component: MenuTop,
      meta: { requiresAuth: true },
    },
    {
      path: "/menu-registration",
      name: "menuRegistration",
      component: MenuRegistration,
      meta: { requiresAuth: true },
    },
    {
      path: "/menu-list",
      name: "menuList",
      component: MenuList,
      meta: { requiresAuth: true },
    },
    {
      path: "/menu-edit",
      name: "menuEdit",
      component: MenuEdit,
      meta: { requiresAuth: true },
    },
    {
      path: "/menu-regi-link",
      name: "menuRegiLink",
      component: MenuRegiLink,
      meta: { requiresAuth: true },
    },
    {
      path: "/recipe",
      name: "recipe",
      component: RecipeTop,
      meta: { requiresAuth: true },
    },
    {
      path: "/recipe-registration",
      name: "recipeRegistration",
      component: RecipeRegistration,
      meta: { requiresAuth: true },
    },
    {
      path: "/recipe-list",
      name: "recipeList",
      component: RecipeList,
      meta: { requiresAuth: true },
    },
    {
      path: "/recipe-detail/:id",
      name: "recipeDetail",
      component: RecipeDetail,
      meta: { requiresAuth: true },
    },
    {
      path: "/recipe-procedure",
      name: "recipeProcedure",
      component: RecipeProcedure,
      meta: { requiresAuth: true },
    },
    {
      path: "/recipe-cost",
      name: "recipeCost",
      component: RecipeCost,
      meta: { requiresAuth: true },
    },
    {
      path: "/recipe-edit",
      name: "recipeEdit",
      component: RecipeEdit,
      meta: { requiresAuth: true },
    },
    {
      path: "/course-top",
      name: "courseTop",
      component: CourseTop,
      meta: { requiresAuth: true },
    },
    {
      path: "/course-registration",
      name: "courseRegistration",
      component: CourseRegistration,
      meta: { requiresAuth: true },
    },
    {
      path: "/course-list",
      name: "courseList",
      component: CourseList,
      meta: { requiresAuth: true },
    },
    {
      path: "/course-detail",
      name: "courseDetail",
      component: CourseDetail,
      meta: { requiresAuth: true },
    },
    {
      path: "/course-edit",
      name: "courseEdit",
      component: CourseEdit,
      meta: { requiresAuth: true },
    },
    {
      path: "/config",
      name: "config",
      component: ConfigList,
      meta: { requiresAuth: true },
    },
    {
      path: "/analysis",
      name: "analysis",
      component: AnalysisNote,
      meta: { requiresAuth: true },
    },
    {
      path: "/error/:error",
      name: "error",
      component: RError,
    },
    //このパスは必ず最後に書く
    {
      path: "*",
      name: "not-found",
      component: RPageNotFound,
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    auth.onAuthStateChanged(async function (user) {
      if (user) {
        const route = await authCheck.isRoute(to);
        if (route) {
          // console.log('==next 1==');

          next();
        }
      } else {
        // console.log('==goto==');

        next({
          path: "/recipro",
        });
      }
    });
  } else {
    // console.log('==from==', from);
    // console.log('==to==', to);

    next();
  }
});

export default router;

<template>
  <div
    v-if="active"
    class="r-tab"
    :class="[
      balloonDirection ? `r-tab--balloon--${balloonDirection}` : '',
      {
        'r-tab--balloon': balloon,
      },
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "r-tab",
  inheritAttrs: false,
  props: {
    label: {
      default: "label",
      type: String,
    },
    balloon: Boolean,
  },
  data() {
    return {
      id: null,
      active: false,
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    balloonDirection() {
      if (!this.balloon) {
        return false;
      }
      switch (this.id) {
        case 0:
          return "left";
        case 1:
          return "middle";
        case 2:
          return "right";
      }
    },
  },
  mounted() {
    this.id = this.$parent.children.length;
    this.$parent.children.push({
      label: this.label,
      id: this.$parent.children.length,
      listeners: this.$listeners,
      attrs: this.$attrs,
    });
  },
};
</script>

<style scoped>
.r-tab--balloon {
  border: 2px solid #f8d6cd;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  height: 100%;
}
.r-tab--balloon::before {
  content: "";
  position: absolute;
  transform: rotate(-45deg);
  height: 36px;
  width: 36px;
  background: #ffffff;
  border-top: 2px solid #f8d6cd;
  border-right: 2px solid #f8d6cd;
  top: -20px;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 1s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

@media screen and (min-width: 641px) {
  .r-tab--balloon--left:before {
    left: 90px;
  }
  .r-tab--balloon--middle:before {
    left: 300px;
  }
  .r-tab--balloon--right:before {
    left: 520px;
  }
}

@media screen and (min-width: 767px) {
  .r-tab--balloon--left:before {
    left: 70px;
  }
  .r-tab--balloon--middle:before {
    left: 250px;
  }
  .r-tab--balloon--right:before {
    left: 430px;
  }
}
/**TODO 要調整 */
@media screen and (min-width: 1400px) {
  .r-tab--balloon--left:before {
    left: 90px;
  }
  .r-tab--balloon--middle:before {
    left: 300px;
  }
  .r-tab--balloon--right:before {
    left: 520px;
  }
}
</style>

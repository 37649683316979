<template>
  <div
    class="r-food-stuff"
    :class="{ 'r-food-stuff--white': white }"
    @click="clickHandler"
  >
    <div class="f-food-stuff--left">
      <div class="r-food-stuff--label">{{ label }}</div>
      <r-tag class="r-food-stuff--tag" v-if="tag" :label="tag" />
      <div v-if="caption" class="r-food-stuff--caption">{{ caption }}</div>
      <div v-if="caption2" class="r-food-stuff--caption">{{ caption2 }}</div>
    </div>
    <div class="r-food-stuff--close" v-if="close" @click.self="closeHandler">
      ×
    </div>
  </div>
</template>

<script>
import RTag from "@/plugins/atoms/RTag.vue";

export default {
  name: "r-food-stuff-card",
  props: {
    label: String,
    tag: String,
    caption: String,
    caption2: String,
    white: Boolean,
    close: Boolean,
  },
  components: {
    RTag,
  },
  methods: {
    clickHandler(e) {
      this.$emit("click", e);
    },
    closeHandler(e) {
      this.$emit("close", e);
    },
  },
};
</script>

<style scoped>
.r-food-stuff {
  border: 2px solid #e87352;
  border-radius: 5px;
  text-align: left;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.r-food-stuff--white {
  background-color: #ffffff;
  border: none;
}
.f-food-stuff--left {
  width: 90%;
}
.r-food-stuff--label {
  font-weight: bold;
}
.r-food-stuff--tag {
  display: inline-block;
  margin-top: 5px;
}
.r-food-stuff--caption {
  margin-top: 5px;
  width: 80%;
  text-overflow: ellipsis;
}
.r-food-stuff--close {
  border-radius: 50%;
  background-color: #e87352;
  color: #ffffff;
  font-size: 1.2rem;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.r-food-stuff--close:hover {
  cursor: pointer;
  opacity: 0.8;
}
</style>

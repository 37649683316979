import { render, staticRenderFns } from "./DetailedDataList.vue?vue&type=template&id=3ce3f85c&scoped=true"
import script from "./DetailedDataList.vue?vue&type=script&lang=js"
export * from "./DetailedDataList.vue?vue&type=script&lang=js"
import style0 from "./DetailedDataList.vue?vue&type=style&index=0&id=3ce3f85c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ce3f85c",
  null
  
)

export default component.exports
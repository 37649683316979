<template>
  <div>
    <div class="r-list-content" @click="clickHandler">
      <div class="r-list-content--icon" v-if="sub">
        <div v-if="!isShowSub">▼</div>
        <div v-else>▲</div>
      </div>
      <div
        class="r-list-content--wrapper"
        :class="{
          'r-list-content--clickable':
            (clickable && !hoverEffectOff) || (sub && !hoverEffectOff),
          'r-list-content--cursol-only':
            (clickable && hoverEffectOff) || (sub && hoverEffectOff),
        }"
      >
        <slot></slot>
      </div>
    </div>
    <r-hr :hrStyle="hrStyle" />
    <div class="r-list--sub-content" v-if="isShowSub">
      <slot name="sub"></slot>
    </div>
  </div>
</template>

<script>
import RHr from "@/plugins/atoms/RHorizontalRule.vue";

export default {
  name: "r-list-content",
  components: {
    RHr,
  },
  methods: {
    clickHandler(e) {
      if (this.clickable) {
        this.$emit("click", e);
      }

      if (this.sub) {
        this.isShowSub = !this.isShowSub;
      }
    },
  },
  props: {
    clickable: Boolean,
    sub: Boolean,
    heading: Boolean,
    hoverEffectOff: Boolean,
  },
  data() {
    return {
      isShowSub: false,
    };
  },
  computed: {
    hrStyle() {
      if (this.heading) {
        return "sub-primary";
      }
      return "gray";
    },
  },
};
</script>

<style scoped>
.r-list-content {
  display: flex;
  align-items: center;
  width: 100%;
}
.r-list-content--icon {
  color: #517ce8;
}
.r-list-content--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
}
.r-list-content--clickable:hover {
  cursor: pointer;
  background-color: rgba(212, 208, 208, 0.89);
}
.r-list-content--cursol-only {
  cursor: pointer;
}
</style>

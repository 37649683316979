import { db, imageStorage } from "@/libs";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import {
  ref as storageRef,
  getDownloadURL,
  uploadString,
} from "firebase/storage";

export default {
  // コースデータ作成
  async createCourse(customerID, storeID, courseData) {
    let courseID = "";
    try {
      // イメージのアップロード
      let imageFileName = "";
      if (courseData.image !== "") {
        imageFileName = await uploadFile(customerID, storeID, courseData.image);
      }

      // コース登録 Allergy
      const courseRef = collection(db, "courses");
      const result = await addDoc(courseRef, {
        customerID,
        storeID,
        title: courseData.title,
        categories: courseData.categories,
        price: courseData.price,
        hidden: courseData.hidden,
        image: imageFileName,
        createUser: courseData.createUser,
        createAt: serverTimestamp(),
        updateAt: serverTimestamp(),
      });

      courseID = result.id;
      const factors = courseData.factors;

      // メニューデータ登録
      const factorRef = collection(doc(db, "courses", courseID), "factors");

      await Promise.all(
        factors.map(async (factor) => {
          await addDoc(factorRef, {
            customerID,
            storeID,
            recipeRef: factor.ref,
            serv: factor.serv,
            createAt: serverTimestamp(),
            updateAt: serverTimestamp(),
          });
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return courseID;
  },
  // コースを検索(カテゴリがキー)
  async getCourseList(customerID, storeID, category, viewHidden) {
    const courseRecords = [];
    try {
      let courseQuery = query(
        collection(db, "courses"),
        where("customerID", "==", customerID),
      );

      if (storeID) {
        courseQuery = query(courseQuery, where("storeID", "==", storeID));
      }

      if (!viewHidden) {
        courseQuery = query(courseQuery, where("hidden", "==", false));
      }
      // カテゴリーの検索(配列の検索)
      if (category) {
        // categoryが含まれるドキュメントを抽出する条件を追加
        courseQuery = query(
          courseQuery,
          where("categories", "array-contains", category),
        );
      }

      const courseSnapshot = await getDocs(courseQuery);
      for (const doc of courseSnapshot.docs) {
        const courseData = doc.data();

        // 画像ファイルを取得
        let imageURL = "";
        let imageFileName = courseData.image || "";

        if (imageFileName) {
          try {
            const imageRef = storageRef(
              imageStorage,
              `images/${customerID}/${courseData.storeID}/${imageFileName}`,
            );
            imageURL = await getDownloadURL(imageRef);
          } catch (err) {
            console.log("==Image not found==", err);
          }
        }
        // リストデータの作成
        courseRecords.push({
          id: doc.id,
          customerID: courseData.customerID,
          storeID: courseData.storeID,
          title: courseData.title,
          categories: courseData.categories,
          price: courseData.price,
          image: imageURL,
          imageFileName,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return courseRecords;
  },
  async getCourseFactorsList(customerID, storeID, recipeID) {
    try {
      const recipeRef = doc(db, "recipes", recipeID);
      const recipeSnap = await getDoc(recipeRef);
      if (recipeSnap.exists()) {
        const ref = recipeSnap.ref;

        const factorQuery = query(
          collection(db, "courses"),
          where("customerID", "==", customerID),
          where("storeID", "==", storeID),
          where("recipeRef", "==", ref),
        );
        const querySnapshot = await getDocs(factorQuery);
        return querySnapshot.empty;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  // コース取得(画像付き)
  async getCourseDetailWithImage(customerID, storeID, courseID) {
    let course = {};
    try {
      const courseRef = doc(db, "courses", courseID);
      const documentSnapshot = await getDoc(courseRef);

      if (documentSnapshot.exists()) {
        const record = documentSnapshot.data();
        // 画像ファイルを取得,
        let imageFileName = record.image || "";
        let imageURL = "";

        if (imageFileName) {
          try {
            const imageRef = storageRef(
              imageStorage,
              `images/${customerID}/${storeID}/${imageFileName}`,
            );
            imageURL = await getDownloadURL(imageRef);
          } catch (err) {
            console.log("==Image not found==", err);
          }
        }

        course = {
          customerID,
          storeID,
          courseID,
          title: record.title || "",
          categories: record.categories,
          price: record.price || 0,
          hidden: record.hidden,
          image: imageURL || "",
          imageFileName: imageFileName,
          createUser: record.createUser || "",
        };

        const factorsRef = collection(courseRef, "factors");
        const querySnapshot = await getDocs(factorsRef);
        const factors = [];

        for (const factorDoc of querySnapshot.docs) {
          const data = factorDoc.data();
          const recipeSnapshot = await getDoc(data.recipeRef);

          if (recipeSnapshot.exists()) {
            const recipeData = recipeSnapshot.data();
            factors.push({
              ...recipeData,
              factorID: factorDoc.id,
              ref: data.recipeRef,
              serv: data.serv,
            });
          }
        }

        course.factors = factors;
      } else {
        // データが存在しないため、エラー
        throw new Error("No Data");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }

    return course;
  },
  // コース更新
  async updateCourse(setData, orgData) {
    try {
      // imageの変更の場合はアップロード処理
      let imageFileName = "";
      if (setData.image !== "" && setData.image.includes("base64")) {
        imageFileName = await uploadFile(
          setData.customerID,
          setData.storeID,
          setData.image,
        );
      } else if (setData.image !== "") {
        // 画像に変更がないとき
        imageFileName = setData.imageFileName;
      }

      const courseRef = doc(db, "courses", setData.courseID);
      await updateDoc(courseRef, {
        customerID: setData.customerID,
        storeID: setData.storeID,
        title: setData.title,
        categories: setData.categories,
        price: setData.price,
        hidden: setData.hidden,
        image: imageFileName,
        updateAt: serverTimestamp(),
      });

      // 登録したfactorを更新
      const factorCollectionRef = collection(courseRef, "factors");
      for (const factor of setData.factors) {
        if (factor.factorID) {
          const factorRef = doc(factorCollectionRef, factor.factorID);
          await updateDoc(factorRef, {
            customerID: setData.customerID,
            storeID: setData.storeID,
            recipeRef: factor.ref,
            serv: factor.serv,
            updateAt: serverTimestamp(),
          });
        } else {
          await addDoc(factorCollectionRef, {
            customerID: setData.customerID,
            storeID: setData.storeID,
            recipeRef: factor.ref,
            serv: factor.serv,
            createAt: serverTimestamp(),
            updateAt: serverTimestamp(),
          });
        }
      }
      // 画面上で削除したレシピを削除
      if (orgData.factors) {
        const orgFactors = orgData.factors;
        for (const orgFactor of orgFactors) {
          // 変更前のFacotor
          const orgID = orgFactor.factorID;

          // 更新後のfactors
          const targetFactor = setData.factors.find(
            (factor) => factor.factorID === orgID,
          );

          // 存在しないIDの場合は削除
          if (!targetFactor) {
            const factorRef = doc(factorCollectionRef, orgID);
            await deleteDoc(factorRef);
          }
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    return;
  },
  async deleteCourse(courseID) {
    try {
      // データの削除-サブコレクションはFunctionでコマンドで削除
      await deleteDoc(doc(db, "courses", courseID));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
};
async function uploadFile(customerID, storeID, image) {
  let imageFileName = "";
  const [imageInfo, imageBase64] = image.split(",");
  try {
    if (!imageInfo || !imageBase64 || !imageInfo.includes("image")) {
      throw new Error("not picture file");
    }

    const contentType = imageInfo.match(/data:(.*);base64/)[1];
    imageFileName = `${Date.now().toString(36)}_${Math.random()
      .toString(36)
      .substr(2)}`;

    const imageRef = storageRef(
      imageStorage,
      `images/${customerID}/${storeID}/${imageFileName}`,
    );
    await uploadString(imageRef, imageBase64, "base64", { contentType });
    return imageFileName;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("==Error on upload file==", JSON.stringify(error));
    throw new Error(error);
  }
}

<template>
  <r-spinner v-if="loading" />
  <div v-else>
    <r-heading :level="1" :image="headingImage">
      <span>{{ $t("course.courseTop.courseManagement") }}</span>
    </r-heading>
    <div class="course-top">
      <r-button
        class="course-top--button"
        buttonStyle="primary"
        full
        @click="toCourseRegistration"
        v-if="userAuthority.createRp"
      >
        <img :src="buttonImage" alt class="button-image" />
        &nbsp;&nbsp;{{ $t("course.courseTop.toCourseRegistration") }}
      </r-button>
      <r-button
        class="course-top--button"
        buttonStyle="primary"
        full
        @click="toCourseList"
      >
        <img :src="buttonImage" alt class="button-image" />
        &nbsp;&nbsp;{{ $t("course.courseTop.toCourseList") }}
      </r-button>
    </div>
  </div>
</template>

<script>
import RHeading from "@/plugins/atoms/RHeading.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";

export default {
  components: {
    RButton,
    RHeading,
    RSpinner,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      storeList: {},
      userAuthority: {
        createRp: false,
      },
      headingImage: require("../../../assets/images/courseColor.svg"),
      buttonImage: require("../../../assets/images/icon_course_white.svg"),
      loading: false,
    };
  },
  methods: {
    toCourseRegistration() {
      this.$router.push({ name: "courseRegistration" }, () => {});
    },
    toCourseList() {
      this.$router.push({ name: "courseList" }, () => {});
    },
    async setStore() {
      if (this.customerID) {
        this.storeList = await auth.getStoreArrayList(this.customerID);
      }
    },
  },
  async created() {
    this.loading = true;
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      if (!this.customerID) {
        throw new Error("Forbidden");
      }
      const isStore = await auth.isStore();
      if (isStore) {
        this.storeID = await auth.getStoreID(this.$root);
        if (!this.storeID) {
          throw new Error("Forbidden");
        }
      }

      // 権限取得
      const createRp = await auth.getCreateRp();
      this.$set(this.userAuthority, "createRp", createRp);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.course-top {
  padding: 60px 20px 0;
  position: relative;
}
.course-top--button {
  margin-top: 20px;
  padding: 20px 20px;
  font-size: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-top--button:hover {
  background-color: #ea5446;
  cursor: pointer;
  transition: all 0.5s;
}
.button-image {
  width: 30px;
  height: 30px;
}

@media screen and (min-width: 641px) {
  .course-top {
    padding-top: 120px;
    display: flex;
  }
  .course-top--button {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin-right: 20px;
  }
  .course-top--button:last-child {
    margin-right: unset;
  }
}

@media screen and (min-width: 1024px) {
  .course-top {
    padding-top: 140px;
    width: 1024px;
    margin: 0 auto;
  }
  .course-top--button {
    padding: 32px 32px 32px;
    height: 104px;
    font-size: 1.2rem;
  }
}
</style>

<template>
  <r-paper round class="config-createuser--main">
    <r-modal-sub-header :title="$t('config.createEditForm.createUser')">
      <div v-if="!confirm">
        <div class="config-createuser--main--content">
          <!--ユーザー登録：ID-->
          <r-heading :level="4"
            >{{ $t("config.createEditForm.userID") }}*</r-heading
          >
          <div class="config-createuser-id--registration--form">
            <form>
              <r-textbox
                class="config-createuser--main--content--item config-userid-input-form"
                v-model="userID"
                name="userID"
                :error="error.userID !== ''"
              />
            </form>
            <div class="config-btn-wrapper">
              <r-button
                class="config-createuser--main--content--item config-create-user-btn"
                buttonStyle="secondary"
                full
                @click="generateUserID"
                >{{ $t("config.createEditForm.generateUserID") }}</r-button
              >
            </div>
          </div>
          <!--validation message-->
          <div class="error-message">{{ error.userID }}</div>
        </div>
        <div class="config-createuser--main--content">
          <!--ユーザー登録：パスワード-->
          <r-heading :level="4">{{ $t("common.password") }}*</r-heading>
          <div class="config-createuser-pass--registration--form">
            <div class="config-userpass-input-form">
              <form>
                <r-textbox
                  class="config-createuser--main--content--item"
                  type="password"
                  v-model="password"
                  name="password"
                  v-if="!display"
                  :error="error.password !== ''"
                />
                <r-textbox
                  class="config-createuser--main--content--item"
                  v-model="password"
                  name="displayPassword"
                  v-if="display"
                  :error="error.password !== ''"
                />
              </form>
            </div>
            <div class="config-btn-wrapper">
              <r-button
                class="config-createuser--main--content--item config-create-user-btn"
                buttonStyle="secondary"
                full
                type="password"
                @click="generatePassword"
                >{{ $t("config.createEditForm.generatePassword") }}</r-button
              >
              <r-button
                class="config-createuser--main--content--item config-create-user-btn"
                buttonStyle="secondary"
                full
                @click="displayPassword"
                v-if="!display"
                >{{ $t("config.createEditForm.displayPassword") }}</r-button
              >
              <r-button
                class="config-createuser--main--content--item config-create-user-btn"
                buttonStyle="secondary"
                full
                @click="hiddenPassword"
                v-if="display"
                >{{ $t("config.createEditForm.hiddenPassword") }}</r-button
              >
            </div>
          </div>
          <div class="error-message">{{ error.password }}</div>
        </div>

        <div class="config-createuser--main--content">
          <r-heading :level="4"
            >{{ $t("config.createEditForm.userName") }}*</r-heading
          >
          <div class="config--createuser-display-name--registration--form">
            <r-textbox
              class="config-createuser--main--content--item config-userdisplayname-input-form"
              v-model="displayName"
              name="displayName"
              :error="error.displayName !== ''"
            />
          </div>
          <div class="error-message">{{ error.displayName }}</div>
        </div>

        <div class="config-createuser--main--content">
          <r-heading :level="4">{{
            $t("config.createEditForm.giveUserAuthority")
          }}</r-heading>
          <div class="config-createuser--main--content--explain">
            <div>{{ $t("config.createEditForm.userAuthCheckList") }}</div>
            <div>{{ $t("config.createEditForm.explain") }}</div>
          </div>
          <r-list>
            <r-list-content>
              <r-list-item>
                <input type="checkbox" id="viewRp" v-model="viewRp" disabled />
                {{ $t("config.createEditForm.viewRp") }}
              </r-list-item>
            </r-list-content>
            <r-list-content>
              <r-list-item>
                <input type="checkbox" id="viewOtherRp" v-model="viewOtherRp" />
                {{ $t("config.createEditForm.viewOtherRp") }}
              </r-list-item>
            </r-list-content>
            <r-list-content>
              <r-list-item>
                <input type="checkbox" id="createRp" v-model="createRp" />
                {{ $t("config.createEditForm.createRp") }}
              </r-list-item>
            </r-list-content>
            <r-list-content>
              <r-list-item>
                <input type="checkbox" id="viewCost" v-model="viewCost" />
                {{ $t("config.createEditForm.viewCost") }}
              </r-list-item>
            </r-list-content>
            <r-list-content>
              <r-list-item>
                <input type="checkbox" id="viewBook" v-model="viewBook" />
                {{ $t("config.createEditForm.viewBook") }}
              </r-list-item>
            </r-list-content>
            <r-list-content>
              <r-list-item>
                <input type="checkbox" id="createBook" v-model="createBook" />
                {{ $t("config.createEditForm.createBook") }}
              </r-list-item>
            </r-list-content>
            <r-list-content>
              <r-list-item>
                <input type="checkbox" id="viewInfo" v-model="viewInfo" />
                {{ $t("config.createEditForm.viewInfo") }}
              </r-list-item>
            </r-list-content>
            <r-list-content>
              <r-list-item>
                <input type="checkbox" id="editCat" v-model="editCat" />
                {{ $t("config.createEditForm.editCat") }}
              </r-list-item>
            </r-list-content>
            <r-list-content>
              <r-list-item>
                <input type="checkbox" id="editUser" v-model="editUser" />
                {{ $t("config.createEditForm.editUser") }}
              </r-list-item>
            </r-list-content>
            <r-list-content>
              <r-list-item>
                <input type="checkbox" id="selectSup" v-model="selectSup" />
                {{ $t("config.createEditForm.selectSup") }}
              </r-list-item>
            </r-list-content>
            <r-list-content>
              <r-list-item>
                <input type="checkbox" id="viewHidden" v-model="viewHidden" />
                {{ $t("config.createEditForm.viewHidden") }}
              </r-list-item>
            </r-list-content>
          </r-list>
        </div>

        <r-button
          class="config-createuser--main--content"
          buttonStyle="primary"
          full
          @click="inputConfirm"
          >{{ $t("config.createEditForm.inputConfirm") }}</r-button
        >
      </div>

      <div v-else>
        <!--確認画面-->
        {{ $t("config.createEditForm.inputEssentialItem") }}
        <dl class="config-createuser-dl">
          <dt class="config-createuser-id">
            {{ $t("config.createEditForm.userID") }}*
          </dt>
          <dd class="config-createuser--id-confirm">{{ userID }}</dd>
          <dt class="config-createuser-id">{{ $t("common.password") }}</dt>
          <dd>
            <div v-if="!displayConfirm" class="config-createuser--id-confirm">
              {{ maskPassword }}
              <r-button buttonStyle="primary" @click="displayConfirmPassword">{{
                $t("config.createEditForm.displayPassword")
              }}</r-button>
            </div>
            <div v-else class="config-createuser--id-confirm">
              {{ password }}
              <!--パスワード表示-->
              <r-button buttonStyle="primary" @click="hiddenConfirmPassword">{{
                $t("config.createEditForm.hiddenPassword")
              }}</r-button>
            </div>
          </dd>
          <dt class="config-createuser-id">
            {{ $t("config.createEditForm.userName") }}*
          </dt>
          <dd class="config-createuser--id-confirm config-displayName">
            {{ displayName }}
          </dd>
          <div class="config-createuser--main--content give-user-authority">
            {{ $t("config.createEditForm.giveUserAuthority") }}
          </div>
          <div
            class="config-createuser--main--content--item give-user-authority-explain"
          >
            {{ $t("config.createEditForm.explain") }}
          </div>

          <!--選択項目確認-->
          <r-list class="config-createuser--confirm-list">
            <r-list-content v-if="viewRp">
              <r-list-item>{{
                $t("config.createEditForm.viewRp")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="viewOtherRp">
              <r-list-item>{{
                $t("config.createEditForm.viewOtherRp")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="createRp">
              <r-list-item>{{
                $t("config.createEditForm.createRp")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="viewCost">
              <r-list-item>{{
                $t("config.createEditForm.viewCost")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="viewBook">
              <r-list-item>{{
                $t("config.createEditForm.viewBook")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="createBook">
              <r-list-item>{{
                $t("config.createEditForm.createBook")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="viewInfo">
              <r-list-item>{{
                $t("config.createEditForm.viewInfo")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="editCat">
              <r-list-item>{{
                $t("config.createEditForm.editCat")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="editUser">
              <r-list-item>{{
                $t("config.createEditForm.editUser")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="selectSup">
              <r-list-item>{{
                $t("config.createEditForm.selectSup")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="viewHidden">
              <r-list-item>{{
                $t("config.createEditForm.viewHidden")
              }}</r-list-item>
            </r-list-content>
          </r-list>
        </dl>
        <r-button
          buttonStyle="primary"
          full
          outline
          @click="inputCorrection"
          class="config-btn"
          >{{ $t("config.createEditForm.inputCorrection") }}</r-button
        >
        <r-button
          buttonStyle="primary"
          full
          @click="regist"
          class="config-btn"
          >{{ $t("config.createEditForm.regist") }}</r-button
        >
      </div>
    </r-modal-sub-header>

    <!-- 登録中のスピナー -->
    <div class="loading-bg" v-if="registStatus">
      <r-spinner></r-spinner>
    </div>
  </r-paper>
</template>
<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import userManager from "@/libs/User.js";

export default {
  components: {
    RTextbox,
    RButton,
    RList,
    RListContent,
    RListItem,
    RHeading,
    RPaper,
    RModalSubHeader,
    RSpinner,
  },
  data() {
    return {
      display: false,
      displayConfirm: false,
      confirm: false,
      userID: "",
      password: "",
      displayName: "",
      // headquarters: false,
      viewRp: true,
      viewOtherRp: false,
      createRp: false,
      viewCost: false,
      viewBook: false,
      createBook: false,
      viewInfo: false,
      editCat: false,
      editUser: false,
      selectSup: false,
      viewHidden: false,
      error: {
        userID: "",
        displayName: "",
        password: "",
      },
      registStatus: false,
    };
  },
  computed: {
    maskPassword() {
      return "*".repeat(this.password.length);
    },
  },
  methods: {
    generateUserID() {
      this.userID = this.generateRandomString(8);
    },
    generatePassword() {
      this.password = this.generateRandomString(10);
    },
    displayPassword() {
      this.display = true;
    },
    hiddenPassword() {
      this.display = false;
    },
    displayConfirmPassword() {
      this.displayConfirm = true;
    },
    hiddenConfirmPassword() {
      this.displayConfirm = false;
    },
    inputConfirm() {
      // 入力チェック
      this.executeValidation();
      if (
        this.error.userID !== "" ||
        this.error.displayName !== "" ||
        this.error.password !== ""
      ) {
        return;
      }
      this.confirm = true;
    },
    executeValidation() {
      // userIDのバリデーション
      if (this.userID === "") {
        this.$set(
          this.error,
          "userID",
          this.$i18n.t("config.edituserForm.inputValidationMsg"),
        );
      } else if (!this.userID.match(/^[A-Za-z0-9]*$/)) {
        this.$set(
          this.error,
          "userID",
          this.$i18n.t("config.edituserForm.halfSizeEngIntInputValidation"),
        );
      } else {
        this.$set(this.error, "userID", "");
      }

      // passwordのバリデーション
      if (this.password === "") {
        this.$set(
          this.error,
          "password",
          this.$i18n.t("config.edituserForm.inputValidationMsg"),
        );
      } else {
        this.$set(this.error, "password", "");
      }

      // nameのバリデーション
      if (this.displayName === "") {
        this.$set(
          this.error,
          "displayName",
          this.$i18n.t("config.edituserForm.inputValidationMsg"),
        );
      } else {
        this.$set(this.error, "displayName", "");
      }
    },
    inputCorrection() {
      this.confirm = false;
    },
    async regist() {
      this.registStatus = true;

      try {
        const userInfo = {
          userID: this.userID,
          password: this.password,
          displayName: this.displayName,
          customerID: this.customerID,
          storeID: this.storeID,
          viewRp: this.viewRp,
          viewOtherRp: this.viewOtherRp,
          createRp: this.createRp,
          viewCost: this.viewCost,
          viewBook: this.viewBook,
          createBook: this.createBook,
          viewInfo: this.viewInfo,
          editCat: this.editCat,
          editUser: this.editUser,
          selectSup: this.selectSup,
          viewHidden: this.viewHidden,
        };

        // ユーザ情報を取得
        await userManager.createUser(userInfo);
        this.registStatus = false;
        this.inputCorrection();

        //ユーザ登録メッセージをemit:「店舗名」に新規ユーザー「(displayName)」の登録を完了しました。
        const message =
          sessionStorage.storeName +
          this.$i18n.t("config.createEditForm.customerNewuser") +
          `「${this.displayName}」` +
          this.$i18n.t("config.createEditForm.registrationComplete");
        this.$emit("displayAlert", message);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
    },
    generateRandomString(limit) {
      return userManager.generateRandomString(limit);
    },
  },
  watch: {
    createRp: function () {
      if (this.createRp) {
        this.viewCost = true;
      }
    },
    createBook: function () {
      if (this.createBook) {
        this.viewCost = true;
        this.viewBook = true;
      }
    },
    viewInfo: function () {
      if (this.viewInfo) {
        this.viewCost = true;
      }
    },
    viewCost: function () {
      if (!this.viewCost) {
        this.createRp = false;
        this.createBook = false;
        this.viewInfo = false;
      }
    },
    viewBook: function () {
      if (!this.viewBook) {
        this.createBook = false;
      }
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
.config-createuser--main--content {
  margin-top: 20px;
}
.config-createuser--main--content--item {
  margin-top: 10px;
}
.config-createuser-dl {
  text-align: left;
}
.config-createuser--main--content--explain {
  text-align: left;
}

.error-message {
  height: 10px;
  text-align: left;
  color: #e85951;
  font-weight: bold;
}
.loading-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.config-createuser-id {
  font-weight: bold;
  margin-left: 20px;
}
.config-createuser--id-confirm {
  margin-bottom: 20px;
}
.config-displayName {
  margin-bottom: 20px;
}
.config-createuser--confirm-list {
  margin-top: 20px;
}
.give-user-authority {
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 20px;
}
.give-user-authority-item {
  margin-bottom: 20px;
}
.config-btn {
  margin-bottom: 20px;
}
.config-btn:nth-of-type(2n) {
  margin-bottom: unset;
}
/*iPad用css*/
@media screen and (min-width: 641px) {
  .config-createuser--main--content--item {
    margin-top: unset;
  }
  .config-createuser-id--registration--form {
    margin-top: 10px;
    display: grid;
    vertical-align: middle;
    grid-template-columns: 4fr 2fr;
    gap: 10px;
  }
  .config-userid-input-form {
    grid-template-columns: 4fr;
  }
  .config-createuser-pass--registration--form {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 4fr 2fr;
    gap: 10px;
    vertical-align: middle;
  }
  .config-userpass-input-form {
    grid-template-columns: 4fr;
  }
  .config--createuser-display-name--registration--form {
    margin-top: 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 4fr 2fr;
    gap: 10px;
  }
  .config-userdisplayname-input-form {
    grid-template-columns: 4fr;
  }
  .config-btn-wrapper {
    display: flex;
  }
  .config-create-user-btn {
    width: 50%;
  }
  .config-create-user-btn:nth-of-type(2n-1) {
    margin-right: 5px;
  }
  .give-user-authority-explain {
    margin: 0px 20px 0px;
  }
}
/*PC用css*/
@media screen and (min-width: 1024px) {
}
</style>

<template>
  <r-modal
    class="course-confirm-form"
    :title="$t('course.courseEdit.courseConfirmForm.courseConfirmTitle')"
    enter
    @enter="enter"
    @cancel="cancel"
  >
    <r-heading :level="2">{{ course.title }}</r-heading>

    <div class="main-wrapper">
      <!-- 画像ここから -->
      <div class="main--left">
        <div
          class="course-confirm--main--image course-confirm-form--main--content"
          :style="{ 'background-image': 'url(' + course.image + ')' }"
          v-if="course.image"
        ></div>

        <div
          class="course-confirm--main--image main--image--default course-confirm-form--main--content"
          v-else
        ></div>
        <!-- 画像ここまで -->

        <!-- カテゴリ -->
        <r-paper round class="course-confirm-form--main--content">
          <r-list-header>{{ $t("common.category") }}</r-list-header>
          <span
            class="course-confirm-form--main--categories--item"
            v-for="(category, index) in course.categories"
            :key="index"
            >{{ category }}</span
          >
        </r-paper>
      </div>

      <div class="main--right">
        <div class="main--right--inner-left">
          <!-- レシピ一覧 -->
          <r-paper class="course-confirm-form--main--content" round>
            <r-list>
              <r-list-header>{{ $t("common.recipe") }}</r-list-header>
              <r-list-content
                v-for="(factor, index) in course.factors"
                :key="index"
              >
                <r-list-item>{{ factor.name }}</r-list-item>
                <r-list-item>{{ factor.quantity }}</r-list-item>
              </r-list-content>
            </r-list>
          </r-paper>

          <!-- アレルギー -->
          <r-paper class="course-confirm-form--main--content" round>
            <r-list-header>{{ $t("common.allergy") }}</r-list-header>
            <div>
              <r-heading :level="4">{{
                $t("recipe.allergyItem.sevenItems")
              }}</r-heading>
              <template v-if="sevenItems.length > 0">
                <span
                  class="course-confirm-form--main--allergy--item"
                  v-for="(allergy, index) in sevenItems"
                  :key="`seven-${index}`"
                  >{{
                    $t(`recipe.allergyItem.${convertToAllergyCode(allergy)}`)
                  }}</span
                >
              </template>
              <span v-else>{{ $t("course.courseDetail.notAllergy") }}</span>
            </div>

            <div v-if="allergyTwenty">
              <r-heading :level="4">{{
                $t("recipe.allergyItem.twentyItems")
              }}</r-heading>
              <template v-if="twentyItems.length > 0">
                <span
                  class="course-confirm-form--main--allergy--item"
                  v-for="(allergy, index) in twentyItems"
                  :key="`twenty-${index}`"
                  >{{
                    $t(`recipe.allergyItem.${convertToAllergyCode(allergy)}`)
                  }}</span
                >
              </template>
              <span v-else>{{ $t("course.courseDetail.notAllergy") }}</span>
            </div>
          </r-paper>
        </div>

        <!-- 原価 -->
        <r-paper
          class="course-confirm-form--main--content course-confirm-form--main--cost"
          round
        >
          <r-list-header>{{ $t("common.cost") }}</r-list-header>
          <r-chart :rate="costRate" :label="$t('common.costRate')"></r-chart>
          <r-list>
            <r-list-content>
              <r-list-item>{{ totalCost | localNumber }}</r-list-item>
            </r-list-content>

            <r-list-content>
              <r-list-item>{{ course.price | localNumber }}</r-list-item>
            </r-list-content>

            <r-list-content>
              <r-list-item>{{ course.createUser }}</r-list-item>
            </r-list-content>
          </r-list>
        </r-paper>
      </div>
    </div>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RChart from "@/plugins/atoms/RChart.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListHeader from "@/plugins/atoms/RListHeader.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";

export default {
  components: {
    RModal,
    RPaper,
    RChart,
    RHeading,
    RList,
    RListHeader,
    RListContent,
    RListItem,
  },
  props: {
    course: {
      type: Object,
      default: () => ({}),
    },
    sevenItems: Array,
    twentyItems: Array,
    allergyTwenty: Boolean,
    totalCost: Number,
  },
  computed: {
    costRate() {
      return Number(((this.totalCost / this.course.price) * 100).toFixed(2));
    },
    convertToAllergyCode() {
      return (name) => {
        switch (name) {
          case "小麦":
            return "wheat";
          case "蕎麦":
            return "soba";
          case "乳製品":
            return "milk";
          case "卵":
            return "egg";
          case "落花生":
            return "peanuts";
          case "えび":
            return "shrimp";
          case "かに":
            return "crab";
          case "あわび":
            return "abalone";
          case "いか":
            return "squid";
          case "いくら":
            return "salmonRoe";
          case "オレンジ":
            return "orange";
          case "カシューナッツ":
            return "cashewNut";
          case "キウイフルーツ":
            return "kiwi";
          case "牛肉":
            return "beef";
          case "くるみ":
            return "walnut";
          case "ごま":
            return "sesame";
          case "さけ":
            return "salmon";
          case "さば":
            return "mackerel";
          case "大豆":
            return "soy";
          case "鶏肉":
            return "chicken";
          case "バナナ":
            return "banana";
          case "豚肉":
            return "pork";
          case "まつたけ":
            return "matsutake";
          case "もも":
            return "peaches";
          case "やまいも":
            return "yam";
          case "りんご":
            return "apple";
          case "ゼラチン":
            return "geratin";
          case "アーモンド":
            return "almond";
        }
      };
    },
  },
  methods: {
    enter() {
      this.$emit("enter");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.course-confirm-form {
  background-color: #f0f0f0;
}
.course-confirm-form--main--content {
  margin-top: 20px;
  text-align: left;
}
.course-confirm--main--image {
  min-height: 30vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #f8d5cb;
}
.main--image--default {
  background-image: url(~@/assets/images/logo4.png);
  background-size: auto 10vh;
}
.course-confirm-form--main--cost {
  text-align: center;
}
.course-confirm-form--main--allergy--item:not(:last-child):after {
  content: "・";
}

@media screen and (min-width: 641px) {
  .main-wrapper {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }
  .main--left {
    width: 30%;
    margin-right: 20px;
  }
  .main--right {
    width: 70%;
  }
}

@media screen and (min-width: 1024px) {
  .main-wrapper {
    display: flex;
  }
  .main--left {
    width: 30%;
  }
  .main--right {
    width: 70%;
    display: flex;
  }
  .main--right--inner-left {
    margin-right: 20px;
    width: 80%;
  }
}
</style>

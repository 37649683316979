import { db } from "@/libs";
import {
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  setDoc,
  serverTimestamp,
  collectionGroup,
} from "firebase/firestore";

export default {
  // カテゴリ検索用
  async getCategories(customerID, storeID) {
    let catRecords = { すべて: "" };
    try {
      // カテゴリを取得
      const categoryRef = doc(
        db,
        "customers",
        customerID,
        "stores",
        storeID,
        "categories",
        "category-id",
      );
      const documentSnapshot = await getDoc(categoryRef);
      if (documentSnapshot.exists()) {
        const record = documentSnapshot.data();
        const cateNames = record.name;
        for (let doc of cateNames) {
          catRecords[doc.name] = doc.name;
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return catRecords;
  },
  // レシピ一覧を取得
  async getRecipeList(customerID, storeID) {
    let recipeRecords = [];
    try {
      const recipeQuery = query(
        collection(db, "recipes"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
        where("costRate", ">", 0),
      );

      const recipeSnapshot = await getDocs(recipeQuery);
      recipeSnapshot.forEach((doc) => {
        const getData = doc.data();
        const data = {
          id: doc.id,
          ref: doc.ref,
          name: getData.name,
          cat1: getData.cat1,
          cat2: getData.cat2,
          cat3: getData.cat3,
          allergy: getData.allergy,
          serv: getData.serv,
          totalCost: getData.totalCost,
          price: getData.price,
          costRate: getData.costRate,
          updateAt: getData.updateAt,
        };
        recipeRecords.push(data);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return recipeRecords;
  },
  // レシピ、コース一覧を取得
  async getBooksList(customerID, storeID) {
    let result = [];
    try {
      // レシピの検索
      const recipeQuery = query(
        collection(db, "recipes"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
        where("totalCost", ">", 0),
      );

      const recipeSnapshot = await getDocs(recipeQuery);
      const recipeRecords = recipeSnapshot.docs.map((doc) => {
        const getData = doc.data();
        return {
          id: doc.id,
          recipeRef: doc.ref,
          courseRef: null,
          name: getData.name,
          cat1: getData.cat1,
          cat2: getData.cat2,
          cat3: getData.cat3,
          serv: getData.serv,
          totalCost: getData.totalCost,
          price: getData.price,
          costRate: getData.costRate,
          updateAt: getData.updateAt,
        };
      });

      // コースの検索
      const courseQuery = query(
        collection(db, "courses"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
        where("price", ">", 0),
      );

      const courseSnapshot = await getDocs(courseQuery);
      const courseRecords = await Promise.all(
        courseSnapshot.docs.map(async (doc) => {
          const getData = doc.data();
          // 該当のコースのトータルコストおよび原価率の計算をする
          const refFactor = await getDocs(
            collection(db, "courses", doc.id, "factors"),
          );

          // 登録したレシピのトータルコストを取得
          if (refFactor.empty) throw new Error("no factor data");

          // Map
          let totalCost = 0;
          for (const factorDoc of refFactor.docs) {
            const factor = factorDoc.data();
            const recipeRef = await getDoc(factor.recipeRef);
            if (!recipeRef.exists()) throw new Error("no recipe data");
            totalCost += Number(recipeRef.data().totalCost);
          }

          // 原価率の計算
          const costRate = (totalCost / getData.price) * 100;

          // カテゴリの分解
          const categories = getData.categories;

          // 一覧に表示するデータを作成
          return {
            id: doc.id,
            recipeRef: null,
            courseRef: doc.ref,
            name: getData.title,
            cat1: categories.length === 1 ? categories[0] : "",
            cat2: categories.length === 2 ? categories[1] : "",
            cat3: categories.length === 3 ? categories[2] : "",
            serv: getData.serv,
            totalCost: Number(totalCost).toFixed(2),
            price: getData.price,
            costRate: Number(costRate).toFixed(2),
            updateAt: getData.updateAt,
          };
        }),
      );

      // レシピとコースの結合
      result = [...recipeRecords, ...courseRecords];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return result;
  },
  // レシピリストから該当するカテゴリの一覧を取得
  searchRecipes(recipeList, category) {
    // eslint-disable-next-line no-console
    // console.log("recipeList", recipeList);

    let searchList = [];

    if (category) {
      for (let data of recipeList) {
        if (
          data.cat1 === category ||
          data.cat2 === category ||
          data.cat3 === category
        ) {
          searchList.push(data);
        }
      }
    } else {
      // 検索カテゴリが存在しない場合はそのまま返却
      searchList = recipeList;
    }

    return searchList;
  },
  // 店舗をまたがったカテゴリ一覧を取得
  async getCategoryArrayList(customerID) {
    try {
      const snapshot = await getDocs(
        query(
          collectionGroup(db, "categories"),
          where("customerID", "==", customerID),
        ),
      );

      let records = snapshot.docs.flatMap((doc) => {
        const data = doc.data();
        return data.name.map((e) => ({ name: e.name }));
      });

      records.unshift({ name: "すべて" });
      return records;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  // storeごとのカテゴリーを取得する
  async getCategoriesForList(customerID, storeID) {
    try {
      const categoryRef = doc(
        db,
        "customers",
        customerID,
        "stores",
        storeID,
        "categories",
        "category-id",
      );
      const snapshot = await getDoc(categoryRef);

      if (snapshot.exists()) {
        const data = snapshot.data();
        let records = data.name.reduce((acc, doc) => {
          const name = doc.name;
          acc.push({ name: name });
          return acc;
        }, []);

        records.unshift({ name: "すべて" });
        return records;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  // Category Form用
  async getCategoryFormList(customerID, storeID, existingCat) {
    let categoryArray = [];
    try {
      const categoryRef = doc(
        db,
        "customers",
        customerID,
        "stores",
        storeID,
        "categories",
        "category-id",
      );
      const documentSnapshot = await getDoc(categoryRef);
      if (documentSnapshot.exists()) {
        const record = documentSnapshot.data();
        categoryArray = record.name.map((category) => {
          let categoryData = [];
          let existData = false;
          if (existingCat.includes(category.name)) {
            existData = true;
          }
          categoryData = { name: category.name, bool: existData };
          return categoryData;
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return categoryArray;
  },
  async getCategoryConfigList(customerID, storeID) {
    let categories = [];

    try {
      const categoryRef = doc(
        db,
        "customers",
        customerID,
        "stores",
        storeID,
        "categories",
        "category-id",
      );
      const documentSnapshot = await getDoc(categoryRef);

      if (documentSnapshot.exists()) {
        const record = documentSnapshot.data();
        categories = record.name.map((category) => ({
          name: category.name,
          bool: false,
        }));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error("Forbidden");
    }

    return categories;
  },
  async setCategories(customerID, storeID, setCategories) {
    try {
      if (customerID && storeID && setCategories) {
        // 全て存在した場合登録可能
      } else {
        throw new Error("No Data");
      }

      const categoryRef = doc(
        db,
        "customers",
        customerID,
        "stores",
        storeID,
        "categories",
        "category-id",
      );
      await setDoc(categoryRef, {
        name: setCategories,
        customerID: customerID,
        storeID: storeID,
        updateAt: serverTimestamp(),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  // レシピ、コース一覧を取得:getBook
  async getRecipeCourseUsingCategories(customerID, storeID) {
    let result = [];
    try {
      // レシピの検索
      const recipeQuery = query(
        collection(db, "recipes"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
        where("costRate", ">", 0),
      );
      const recipeSnapshot = await getDocs(recipeQuery);
      const recipeRecords = recipeSnapshot.docs.map((doc) => {
        const getData = doc.data();
        return {
          name: getData.name,
          cat1: getData.cat1,
          cat2: getData.cat2,
          cat3: getData.cat3,
        };
      });

      // コースの検索
      const courseQuery = query(
        collection(db, "courses"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
        // .where("price", ">", 0);
      );
      const courseSnapshot = await getDocs(courseQuery);
      const courseRecords = await Promise.all(
        courseSnapshot.docs.map(async (doc) => {
          const getData = doc.data();
          // 該当のコースのトータルコストおよび原価率の計算をする
          const refFactor = await getDocs(
            collection(db, "courses", doc.id, "factors"),
          );

          // 登録したレシピのトータルコストを取得
          if (refFactor.empty) throw new Error("no factor data");
          // カテゴリの分解
          const categories = getData.categories;

          // 一覧に表示するデータを作成
          return {
            name: getData.title,
            cat1: categories[0] || "",
            cat2: categories[1] || "",
            cat3: categories[2] || "",
          };
        }),
      );
      // レシピとコースの結合
      result = [...recipeRecords, ...courseRecords];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return result;
  },
};

<template>
  <div class="r-textbox">
    <label for="$attrs.name" class="r-textbox--label">{{ label }}</label>
    <div class="r-textbox--inner-wrapper">
      <input
        v-bind="$attrs"
        :type="$attrs.type ? $attrs.type : 'text'"
        :name="$attrs.name"
        :disabled="disabled"
        class="r-textbox--input"
        :class="{
          disabled: disabled,
          error: error,
        }"
        @input="onInput"
        @keyup.enter="keyupEnter"
        @keypress="setCanSend"
      />
      <span class="r-textbox-unit">{{ unit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "r-textbox",
  props: {
    label: {
      type: String,
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
    disabled: Boolean,
    error: Boolean,
  },
  data() {
    return {
      canSend: false,
    };
  },
  methods: {
    onInput(e) {
      this.$emit("input", e.target.value);
    },
    keyupEnter(e) {
      if (this.canSend) {
        this.$emit("keyup", e.target.value);
        this.canSend = false;
      }
    },
    setCanSend() {
      this.canSend = true;
    },
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.r-textbox {
  --disable-color: #ebebe4;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.r-textbox--inner-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.r-textbox--label {
  font-weight: bold;
  text-align: left;
}
.r-textbox--input {
  border-radius: 4px;
  border: 2px solid #e6e6e6;
  padding: 8px;
  font-size: 1.2rem;
  width: 100%;
}
.r-textbox-unit {
  margin-left: 5px;
}
.disabled {
  background-color: var(--disable-color);
}
.error {
  border: 2px solid #e85951;
}

input[type="date"] {
  position: relative;
}
input[type="date"]::-webkit-datetime-edit-year-field,
input[type="date"]::-webkit-datetime-edit-month-field {
  color: black;
  letter-spacing: -1px;
}
input[type="date"]::-webkit-datetime-edit-day-field {
  letter-spacing: -1px;
}
input[type="date"]::-webkit-datetime-edit-text {
  letter-spacing: -1px;
}
input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
</style>

<template>
  <div class="not-found">
    <span class="not-found--code">ERROR 404</span>
    <span class="not-found--message">Page Not Found</span>
    <div class="recipe-detail--main--image--default"></div>
    <r-button
      buttonStyle="primary"
      class="not-found--button"
      @click="toTop"
      outline
      >トップへ戻る</r-button
    >
  </div>
</template>

<script>
import RButton from "@/plugins/atoms/RButton.vue";

export default {
  components: {
    RButton,
  },
  methods: {
    toTop() {
      this.$router.push({ name: "recipe" }, () => {});
    },
  },
};
</script>

<style scoped>
.not-found {
  min-width: 320px;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}
.not-found--code {
  font-family: "Candal";
  font-size: 2rem;
}
.not-found--message {
  font-family: "Candal";
  font-size: 1rem;
  color: #788185;
}
.recipe-detail--main--image--default {
  margin-top: 40px;
  background-image: url(~@/assets/images/logo4.png);
  background-size: auto 15vh;
  height: 15vh;
  width: 40%;
  min-width: 240px;
  background-repeat: no-repeat;
  background-position: center center;
}
.not-found--button {
  margin-top: 40px;
  background-color: #f0f0f0;
}

@media screen and (min-width: 641px) {
  .not-found--code {
    font-size: 3rem;
  }
  .not-found--message {
    font-size: 1.5rem;
  }
  .recipe-detail--main--image--default {
    background-size: auto 15vh;
    height: 15vh;
    width: 60%;
    min-width: 320px;
  }
}
</style>

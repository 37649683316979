import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./plugins/i18n/i18n.js";
import axios from "axios";
import { storage } from "@/libs";
import VueLazyload from "vue-lazyload";
import sanitizeHTML from "sanitize-html";
import { ref, listAll, getDownloadURL } from "firebase/storage";

// IE用の設定
if (!window.crypto) {
  window.crypto = window.msCrypto;
}

// fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faEllipsisV,
  faChevronDown,
  faChevronRight,
  faPlusCircle,
  faTimes,
  faEdit,
  faSortDown,
  faSortUp,
  faCalculator,
  faStar,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faBars,
  faEllipsisV,
  faChevronDown,
  faChevronRight,
  faPlusCircle,
  faTimes,
  faEdit,
  faSortDown,
  faSortUp,
  faCalculator,
  faStar,
  faSort,
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.prototype.$sanitize = sanitizeHTML;

Vue.use(VueLazyload, {
  preLoad: 1.0,
  lazyComponent: true,
});

// 3桁区切りのカンマを入れる
Vue.filter("localNumber", (num) => {
  if (num === undefined || null) {
    return;
  }
  return num.toLocaleString("ja-JP");
});

Vue.config.productionTip = false;

// Array.prototype.flatのpolyfill
if (!Array.prototype.flat) {
  Array.prototype.flat = function (depth) {
    var flattend = [];
    (function flat(array, depth) {
      for (let el of array) {
        if (Array.isArray(el) && depth > 0) {
          flat(el, depth - 1);
        } else {
          flattend.push(el);
        }
      }
    })(this, Math.floor(depth) || 1);
    return flattend;
  };
}

var store = {
  state: {
    customerID: "",
    customerName: "",
    allergyTwenty: false,
    level5: false,
    storeID: "",
    storeName: "",
    notifications: [],
    priceUpdate: {},
    itemList: [], // customerに紐づく食材リスト,
    displayName: "",
  },
  setCustomerInfoAction(id, name, level5, allergyTwenty) {
    this.state.customerID = id;
    this.state.customerName = name;
    this.state.level5 = level5;
    this.state.allergyTwenty = allergyTwenty;
  },
  setStoreInfoAction(id, name) {
    this.state.storeID = id;
    this.state.storeName = name;
  },
  setItemListAction(itemList) {
    this.state.itemList = itemList;
  },
  setNotificationsAction(notifications) {
    this.state.notifications = notifications;
  },
  setPriceUpdateAction(priceUpdate) {
    this.state.priceUpdate = priceUpdate;
  },
  clearCustomerInfoAction() {
    this.state.customerID = "";
    this.state.customerName = "";
    this.state.level5 = false;
    this.state.allergyTwenty = false;
  },
  clearStoreInfoAction() {
    this.state.storeID = "";
    this.state.storeName = "";
  },
  clearItemListAction() {
    this.state.itemList = [];
  },
  clearNotificationsAction() {
    this.state.notifications = [];
  },
  clearPriceUpdateAction() {
    this.state.priceUpdate = {};
  },
};

new Vue({
  data: {
    privateState: store.state,
  },
  methods: {
    setCustomerInfoAction: function (id, name, level5, allergyTwenty) {
      store.setCustomerInfoAction(id, name, level5, allergyTwenty);
    },
    setStoreInfoAction: function (id, name) {
      store.setStoreInfoAction(id, name);
    },
    // eslint-disable-next-line no-unused-vars
    setItemListAction: async function (customerID, storeID) {
      if (store.state.itemList.length > 0) {
        // すでに取得済みの場合はStoregeから取得しない
        return;
      }

      const storageRef = ref(storage, `master/${customerID}`);
      let getMasterData = [];
      // master file一覧を取得
      const refArray = await listAll(storageRef);

      // itemRefを取得しているのでこのままダウンロード
      if (refArray.items) {
        const itemsArray = refArray.items;
        await Promise.all(
          itemsArray.map(async (itemRef) => {
            const fullPath = itemRef.fullPath;

            if (fullPath.endsWith("master.json")) {
              // 末尾がmaster.jsonのファイルのみを取得
              const url = await getDownloadURL(itemRef);
              const response = await axios.get(url);
              // 配列を結合
              getMasterData = [...getMasterData, ...response.data];
            }
          }),
        );
      }

      // 重複を削除
      const getItemList = getMasterData.filter(function (value, index, self) {
        return (
          self.findIndex(function (item) {
            return value["［マイカタログID］"] === item["［マイカタログID］"];
          }) === index
        );
      });
      store.setItemListAction(getItemList);
    },
    setNotificationsAction: function (notifications) {
      store.setNotificationsAction(notifications);
    },
    setPriceUpdateAction: function (priceUpdate) {
      store.setPriceUpdateAction(priceUpdate);
    },
    clearCustomerInfoAction: function () {
      store.clearCustomerInfoAction();
    },
    clearStoreInfoAction: function () {
      store.clearStoreInfoAction();
    },
    clearItemListAction: function () {
      store.clearItemListAction();
    },
    clearNotificationsAction: function () {
      store.clearNotificationsAction();
    },
    clearPriceUpdateAction: function () {
      store.clearPriceUpdateAction();
    },
    clearAllAction: function () {
      store.clearCustomerInfoAction();
      store.clearStoreInfoAction();
      store.clearItemListAction();
      store.clearNotificationsAction();
      store.clearPriceUpdateAction();
      sessionStorage.clear();
    },
  },
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

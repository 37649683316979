<template>
  <div class="course-menu-form">
    <r-modal
      class="course-menu-form--modal--select-menu"
      :class="{ 'course-menu-form--modal--select-menu1': current === 0 }"
      :title="$t('course.courseRegistration.courseMenuForm.menuSelect')"
      :step="step ? '3' : ''"
      :cancelHidden="!windowSizePC"
      :enter="windowSizePC && selectedRecipes.length > 0"
      @enter="enter"
      @cancel="cancel"
    >
      <div
        class="course-menu-form--modal--select-menu--item course-menu-form--modal--select--wrapper"
      >
        <span v-if="windowSizeTab"
          >{{
            $t("course.courseRegistration.courseMenuForm.selectCourseCategory")
          }}&nbsp;:&nbsp;</span
        >
        <r-select :options="categories" @input="categorySelect" middle />
      </div>
      <div
        class="course-menu-form--modal--select-menu--wrapper"
        v-if="current === 0"
      >
        <div class="recipe-food-stuff-form--wrapper-for-web--left">
          <r-list
            class="course-menu-form--modal--menu-list course-menu-form--modal--select-menu--item"
          >
            <div v-for="(recipe, index) in recipes" :key="index">
              <r-list-content
                v-if="!selectedRecipes.map((e) => e.name).includes(recipe.name)"
                clickable
                @click="selectRecipe(index)"
                class="course-menu-form--modal--menu-list-item"
              >
                <r-list-item class="course-menu-form--modal--menu-list-item">{{
                  recipe.name
                }}</r-list-item>
              </r-list-content>
            </div>
          </r-list>
        </div>

        <!-- 選択した食材リスト(PC) -->
        <r-paper
          v-if="windowSizePC"
          round
          paperStyle="pink"
          class="recipe-food-stuff-form--wrapper-for-web--right"
        >
          <span class="recipe-food-stuff-form--wrapper-for-web--right--title">{{
            $t("recipe.recipeRegistration.recipeFoodstuffForm.selectedItem")
          }}</span>
          <div class="recipe-food-stuff--selected-item--wrapper-PC">
            <r-food-stuff-card
              class="recipe-food-stuff--selected-item"
              white
              close
              v-for="(selectedRecipe, index) in selectedRecipes"
              :key="index"
              :label="selectedRecipe.name"
              @close="deleteFromList(selectedRecipe.name)"
            />
          </div>
        </r-paper>
      </div>
    </r-modal>
    <!-- 選択した食材リスト(スマホ) -->
    <r-selected-items
      v-if="!windowSizePC"
      :title="$t('course.courseRegistration.courseMenuForm.selectedMenu')"
      :enter="selectedRecipes.length !== 0"
      @enter="enter"
      @cancel="cancel"
      class="course--selected-list"
    >
      <r-food-stuff-card
        class="course--selected-item"
        white
        close
        v-for="(selectedRecipe, index) in selectedRecipes"
        :key="index"
        :label="selectedRecipe.name"
        @close="deleteFromList(selectedRecipe.name)"
      />
    </r-selected-items>
  </div>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RFoodStuffCard from "@/plugins/molecules/RFoodStuffCard.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RSelectedItems from "@/plugins/molecules/RSelectedItems.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";

import auth from "@/libs/Auth.js";
import categoryManager from "@/libs/Category.js";

export default {
  components: {
    RModal,
    RFoodStuffCard,
    RSelectedItems,
    RSelect,
    RList,
    RListContent,
    RListItem,
    RPaper,
  },
  props: {
    quantityForm: Boolean,
    existingRecipes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: 0,
      recipes: [],
      selectedRecipes: [],
      categories: {},
      recipeList: [],
      error: "",
      width: 0,
      step: false,
    };
  },
  methods: {
    categorySelect(category) {
      // レシピ検索
      const searchList = categoryManager.searchRecipes(
        this.recipeList,
        category,
      );
      this.recipes = searchList;
    },
    selectRecipe(index) {
      this.selectedRecipes.push(this.recipes[index]);
      this.$set(
        this.selectedRecipes[this.selectedRecipes.length - 1],
        "quantity",
        1,
      );
    },
    deleteFromList(name) {
      const index = this.selectedRecipes.map((e) => e.name).indexOf(name);
      this.selectedRecipes.splice(index, 1);
    },
    enter() {
      this.$emit("enter", this.selectedRecipes);
    },
    cancel() {
      this.$emit("cancel");
    },
    handleResize() {
      this.width = window.innerWidth;
    },
  },
  computed: {
    windowSizePC() {
      return this.width >= 1024;
    },
    windowSizeTab() {
      return this.width >= 641;
    },
  },
  mounted() {
    if (this.quantityForm) {
      this.current = 1;
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$route.path === "/course-registration") {
      this.step = true;
    }
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  async created() {
    this.customerID = await auth.getCustomerID(this.$root);
    this.storeID = await auth.getStoreID(this.$root);
    if (!this.customerID || !this.storeID) {
      // customerID、StoreIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }

    try {
      // カテゴリを取得
      this.categories = await categoryManager.getCategories(
        this.customerID,
        this.storeID,
      );

      // レシピを取得する
      this.recipes = await categoryManager.getRecipeList(
        this.customerID,
        this.storeID,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error("Forbidden");
    }

    const clonedeep = require("lodash/cloneDeep");
    let arr = clonedeep(this.existingRecipes);
    this.recipeList = clonedeep(this.recipes);
    this.selectedRecipes = arr;
  },
};
</script>

<style scoped>
.course-menu-form {
  height: 80%;
  display: flex;
}
.course-menu-form--modal--quantity--list-item--left {
  width: 90%;
  display: flex;
  justify-content: center;
  height: 100%;
}
.course-menu-form--modal--quantity--list-item--right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.course-menu-form--modal--quantity--input {
  width: 30%;
}
.course-menu-form--modal--menu-list {
  max-height: 40vh;
  overflow: auto;
}
.course--selected-item {
  margin-bottom: 10px;
}

@media screen and (min-width: 641px) {
  .course--selected-item {
    width: 46%;
  }
  .course--selected-item:nth-child(odd) {
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .course-menu-form {
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .course-menu-form--modal--select-menu {
    max-height: 80%;
  }
  .course-menu-form--modal--select-menu--wrapper {
    height: 100%;
    display: flex;
  }
  .recipe-food-stuff-form--wrapper-for-web--left {
    height: 50vh;
    width: 70%;
    margin-right: 20px;
    overflow: auto;
  }
  .recipe-food-stuff-form--wrapper-for-web--right {
    width: 30%;
    height: 50vh;
    overflow: auto;
    position: relative;
    box-sizing: border-box;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
  .course-menu-form--modal--select-menu--item {
    height: 100%;
    margin-top: 20px;
  }
  .recipe-food-stuff-form--wrapper-for-web--right--title {
    background-color: #e87352;
    color: #ffffff;
    font-weight: bold;
    display: block;
    width: 100%;
    border-radius: 5px 5px 0 0;
    box-sizing: border-box;
    padding: 5px 0;
    margin-bottom: 10px;
  }
  .recipe-food-stuff--selected-item--wrapper-PC {
    max-height: 90%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 10px;
  }
  .course-menu-form--modal--select--wrapper {
    text-align: left;
  }
  .recipe-food-stuff--selected-item {
    margin-top: 10px;
  }
  .recipe-food-stuff--selected-item:nth-child(odd) {
    margin-right: 0px;
    margin-left: 0px;
  }
}
</style>

<template>
  <div id="app">
    <r-header-for-login v-if="isLoginPage"></r-header-for-login>
    <r-header
      v-else
      @open="openNotification"
      @loadingComplete="headerLoading = true"
    />
    <div class="main">
      <r-spinner
        v-if="!isLoginPage && !headerLoading && !footerLoading"
      ></r-spinner>
      <router-view v-else />
    </div>
    <r-login-footer v-if="isLoginPage" class="footer"></r-login-footer>
    <r-footer
      v-else
      class="footer"
      @loadingComplete="headerLoading = true"
    ></r-footer>

    <!-- モーダル -->
    <transition name="modal">
      <div
        class="modal-bg"
        v-if="isShowNotification"
        @click.self="closeShowNotificationModal()"
      >
        <notification-modal
          v-if="isShowNotification"
          @enter="openUpdateModal"
          @cancel="closeShowNotificationModal()"
          @batchUpdate="batchUpdate"
          class="notification-modal"
        />
        <div
          class="modal-bg"
          v-if="isShowUpdateCost"
          @click.self="closeShowUpdateCostModal()"
        >
          <cost-update-modal
            v-if="isShowUpdateCost"
            @enter="closeShowUpdateCostModal()"
            class="update-cost-modal"
            cancelHidden
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import RSpinner from "@/plugins/atoms/RSpinner.vue";
import RHeaderForLogin from "@/components/design/RHeaderForLogin.vue";
import RLoginFooter from "@/components/design/RLoginFooter.vue";
import NotificationModal from "@/components/common/NotificationModal.vue";
import CostUpdateModal from "@/components/common/CostUpdateModal.vue";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  components: {
    RSpinner,
    RHeader: () => import("@/components/design/RHeader.vue"),
    RHeaderForLogin,
    RLoginFooter,
    RFooter: () => import("@/components/design/RFooter.vue"),
    NotificationModal,
    CostUpdateModal,
  },
  data() {
    return {
      isShowNotification: false,
      isShowUpdateCost: false,
      headerLoading: false,
      footerLoading: false,
      batchUpdating: false,
    };
  },
  methods: {
    openNotification() {
      this.isShowNotification = true;
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".notification-modal");
      disableBodyScroll(modal);
    },
    openUpdateModal() {
      this.isShowUpdateCost = true;
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".update-cost-modal");
      disableBodyScroll(modal);
    },
    closeShowNotificationModal() {
      if (this.batchUpdating) {
        return;
      }
      this.isShowNotification = false;
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    closeShowUpdateCostModal() {
      this.isShowUpdateCost = false;
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".notification-modal");
      disableBodyScroll(modal);
    },
    batchUpdate() {
      this.batchUpdating = !this.batchUpdating;
    },
  },
  computed: {
    isLoginPage() {
      //ログイン時にheaderを隠す処理用
      if (
        this.$route.path === "/" ||
        this.$route.path === "/logout" ||
        this.$route.path === "/select-customer" ||
        this.$route.path.includes("error")
      ) {
        return true;
      }
      return false;
    },
  },
  errorCaptured(err, vm, info) {
    this.$router.replace(
      {
        name: "error",
        params: {
          error: err,
          vm: vm,
          info: info,
        },
      },
      () => {},
    );
    return false;
  },
};
</script>

<style>
#app {
  font-family: "Noto Sans JP", "メイリオ", "Meiryo", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", Helvetica,
    Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f0f0f0;
  position: relative;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.main {
  flex: 1 0;
  flex-basis: auto;
}
@media all and (-ms-high-contrast: none) {
  .main {
    flex-basis: 100vh;
  }
}
.footer {
  margin-top: 20px;
}
.modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 5%;
  box-sizing: border-box;
}

/**
  モーダル用のトランジション
  transitionタグのname属性に'modal'を付与する
 */
.modal-enter-active,
.modal-leave-active,
.error-enter-active,
.error-leave-active {
  transition: opacity 0.3s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}

@media screen and (min-width: 641px) {
  .modal-bg {
    padding: 0 10%;
  }
}
@media screen and (min-width: 1024px) {
  .modal-bg {
    padding: 0 25%;
  }
}
</style>

import { render, staticRenderFns } from "./ConfigEdituserForm.vue?vue&type=template&id=06535f22&scoped=true"
import script from "./ConfigEdituserForm.vue?vue&type=script&lang=js"
export * from "./ConfigEdituserForm.vue?vue&type=script&lang=js"
import style0 from "./ConfigEdituserForm.vue?vue&type=style&index=0&id=06535f22&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06535f22",
  null
  
)

export default component.exports
<!-- step1料理名入力 -->
<template>
  <r-modal
    :step="step ? '1' : ''"
    :title="$t('recipe.recipeRegistration.recipeNameForm.inputRecipeName')"
    @enter="enter"
    @cancel="cancel"
    enter
  >
    <!-- レシピ名入力 -->
    <div class="recipe-name-form--content">
      <r-textbox
        :placeholder="
          $t('recipe.recipeRegistration.recipeNameForm.requireRecipeName')
        "
        v-model="recipeName"
        name="recipe-name"
        :label="$t('recipe.recipeRegistration.recipeNameForm.recipeName')"
        :error="error.name !== ''"
      />
      <div class="error-message">{{ error.name }}</div>
    </div>

    <!-- ふりがな入力 -->
    <div class="recipe-name-form--content">
      <r-textbox
        class="recipe-form--kana"
        :placeholder="
          $t('recipe.recipeRegistration.recipeNameForm.inputRecipeNameKana')
        "
        v-model="kana"
        name="kana"
        :label="$t('recipe.recipeRegistration.recipeNameForm.recipeNameKana')"
        :error="error.kana !== ''"
      ></r-textbox>
      <div class="error-message">{{ error.kana }}</div>
    </div>
  </r-modal>
</template>

<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RModal from "@/plugins/molecules/RModal.vue";

export default {
  name: "recipe-name-form",
  props: {
    existingName: {
      type: String,
      default: "",
    },
    existingKana: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      recipeName: "",
      kana: "",
      errorForName: "",
      errorForKana: "",
      error: {
        name: "",
        kana: "",
      },
      step: false,
    };
  },
  components: {
    RTextbox,
    RModal,
  },
  methods: {
    enter() {
      this.executeValidation();
      if (this.error.name !== "" || this.error.kana !== "") {
        return;
      }
      this.$emit("enter", this.recipeName, this.kana);
    },
    cancel() {
      this.$emit("cancel");
    },
    executeValidation() {
      if (this.recipeName === "") {
        this.error.name = this.$t(
          "recipe.recipeRegistration.recipeNameForm.notInputedRecipeName",
        );
      } else if (this.recipeName.length >= 50) {
        this.error.name = this.$t(
          "recipe.recipeRegistration.recipeNameForm.inputRecipeNameLess50",
        );
      } else {
        this.error.name = "";
      }

      if (this.kana === "") {
        this.error.kana = this.$t(
          "recipe.recipeRegistration.recipeNameForm.inputRecipeNameKana",
        );
      } else if (
        !this.kana.match(
          /^[\u3040-\u309f|\x20|\u3000|\u30FC|\u2010-\u2015|\u2212|\uFF70]+$/,
        )
      ) {
        this.error.kana = this.$t(
          "recipe.recipeRegistration.recipeNameForm.dontInputOtherThanKana",
        );
      } else {
        this.error.kana = "";
      }
    },
  },
  watch: {
    existingName: {
      handler() {
        this.recipeName = this.existingName;
      },
      immediate: true,
    },
    existingKana: {
      handler() {
        this.kana = this.existingKana;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$route.path === "/recipe-registration") {
      this.step = true;
    }
  },
};
</script>

<style scoped>
.recipe-name-form--content {
  padding: 10px 0;
}
.recipe-form--kana {
  margin-top: 10px;
}
.error-message {
  height: 10px;
  text-align: left;
  color: #e85951;
  font-weight: bold;
}
</style>

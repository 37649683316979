var render = function render(){var _vm=this,_c=_vm._self._c;return _c('r-modal',{staticClass:"recipe-procedure-form",attrs:{"title":_vm.$t('recipe.recipeProcedure.step') +
    `${_vm.step}` +
    _vm.$t('recipe.recipeProcedure.register'),"enter":""},on:{"enter":_vm.enter,"cancel":_vm.cancel}},[_c('div',{staticClass:"recipe-procedure-form--main--wrapper"},[_c('div',{staticClass:"recipe-procedure-form--main--left"},[_c('r-modal-sub-header',{staticClass:"recipe-procedure-form--content recipe-procedure-form--image",attrs:{"title":_vm.$t('recipe.recipeProcedure.selectImage')}},[_c('r-fileupload',{ref:"fileupload",staticClass:"recipe-procedure-fileupload",attrs:{"image":_vm.image},on:{"change":_vm.changeImage}},[_vm._v(_vm._s(_vm.$t("recipe.recipeProcedure.selectFile")))])],1),_c('div',{staticClass:"recipe-procedure-form--edit--buttons"},[(_vm.image !== '')?_c('label',{staticClass:"r-fileupload--label r-fileupload--content",attrs:{"for":"file"}},[_vm._v(" "+_vm._s(_vm.$t("recipe.recipeProcedure.updateImage"))+" "),_c('input',{staticClass:"r-fileupload--input",attrs:{"id":"file","type":"file"},on:{"change":_vm.onFileChange}})]):_vm._e(),(_vm.image !== '')?_c('r-button',{staticClass:"recipe-procedure-form--edit--button",attrs:{"buttonStyle":"primary","outline":"","full":""},on:{"click":_vm.deleteImage}},[_vm._v(_vm._s(_vm.$t("recipe.recipeProcedure.deleteImage")))]):_vm._e()],1),_c('r-modal-sub-header',{staticClass:"recipe-procedure-form--content",attrs:{"title":_vm.$t('recipe.recipeProcedure.subTitle1') +
          `${_vm.step}` +
          _vm.$t('recipe.recipeProcedure.subTitle2')}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],staticClass:"recipe-procedure-form--content--textarea",class:{
            'recipe-procedure-form--content--textarea--error':
              _vm.error.text !== '',
          },attrs:{"name":"procedure","cols":"30","rows":"10"},domProps:{"value":(_vm.text)},on:{"input":function($event){if($event.target.composing)return;_vm.text=$event.target.value}}}),_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.error.text))])])],1),_c('r-paper',{staticClass:"recipe-procedure--form--main--right",attrs:{"round":"","paperStyle":"gray"}},[_c('r-list',_vm._l((_vm.items),function(item,index){return _c('r-list-content',{key:index},[_c('r-list-item',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
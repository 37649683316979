import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { db } from "@/libs";

export default {
  async getSuppliers(customerID, storeID) {
    let suppliers = [];
    try {
      const docRef = doc(
        db,
        "customers",
        customerID,
        "stores",
        storeID,
        "suppliers",
        "supplier-id",
      );
      const documentSnapshot = await getDoc(docRef);
      if (documentSnapshot.exists()) {
        const record = documentSnapshot.data();
        suppliers = record.name.map((supplier) => supplier.name);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    return suppliers;
  },
  async setSuppliers(customerID, storeID, setSuppliers) {
    try {
      const refSupplier = doc(
        db,
        "customers",
        customerID,
        "stores",
        storeID,
        "suppliers",
        "supplier-id",
      );
      await setDoc(refSupplier, {
        customerID: customerID,
        storeID: storeID,
        name: setSuppliers,
        updateAt: serverTimestamp(),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      alert(error);
    }
  },
};

<template>
  <r-modal
    class="recipe-sub-recipe-register-form"
    :title="
      $t('recipe.recipeRegistration.subRecipeRegisterForm.subRecipeFormTitle')
    "
    @cancel="close"
  >
    <div class="recipe-sub-recipe-register-form-modal-content">
      <div class="recipe-sub-recipe-left">
        <div
          class="recipe-sub-recipe-register-form--content recipe-sub-recipe-register-form--input"
        >
          <p>
            <span>{{
              $t(
                "recipe.recipeRegistration.subRecipeRegisterForm.inputQuantityUnit",
              )
            }}</span>
          </p>
          <div class="recipe-sub-recipe-register-form--input--wrapper">
            <r-textbox
              :placeholder="
                $t(
                  'recipe.recipeRegistration.subRecipeRegisterForm.inputTotalQuantity',
                )
              "
              type="number"
              v-model.number="subAmount"
              class="recipe-sub-recipe-r-textbox"
            />
            <r-select
              class="recipe-sub-recipe-r-selector"
              v-model="subUnit"
              :label="$t('common.selectUnit')"
              :options="options"
              @input="getSelectedUnit"
            ></r-select>
          </div>
        </div>
        <div
          class="recipe-sub-recipe-register-form--content"
          v-if="subAmount && isUnitSelected"
        >
          <r-paper
            paperStyle="gray"
            round
            class="recipe-sub-recipe-register-unitAmount"
          >
            <span
              >{{ subUnit
              }}{{
                $t("recipe.recipeRegistration.subRecipeRegisterForm.unitAmount")
              }}
              : {{ costPerSubUnit }}{{ $t("common.currencyYen") }}</span
            >
          </r-paper>
          <r-button buttonStyle="primary" full @click="registerAsSubRecipe">{{
            $t("common.registAbove")
          }}</r-button>
        </div>
      </div>
      <div class="recipe-sub-recipe--content-right">
        <r-list class="recipe-sub-recipe-register-form--content">
          <r-list-content v-for="(item, index) in items" :key="index">
            <r-list-item>{{ item.name }}</r-list-item>
            <r-list-item>{{ item.amount }}{{ item.unit }}</r-list-item>
          </r-list-content>
        </r-list>
      </div>
    </div>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RButton from "@/plugins/atoms/RButton.vue";

import auth from "@/libs/Auth.js";
import unitManager from "@/libs/Unit.js";
import recipeManager from "@/libs/Recipe.js";

export default {
  name: "RecipeSubRecipeRegisterForm",
  components: {
    RModal,
    RPaper,
    RTextbox,
    RSelect,
    RList,
    RListContent,
    RListItem,
    RButton,
  },
  props: {
    id: String,
    items: Array,
    totalCost: Number,
  },
  data() {
    return {
      subUnit: "",
      subAmount: 0,
      options: {},
      selectedUnit: "",
      subRecipeRegistration: false,
    };
  },
  computed: {
    costPerSubUnit() {
      return Number((this.totalCost / this.subAmount).toFixed(2));
    },
    isUnitSelected() {
      //単位の選択漏れをチェック
      if (this.selectedUnit === "") {
        return false;
      }
      return true;
    },
  },
  methods: {
    async registerAsSubRecipe() {
      //unitAmountの入力チェック
      if (this.subAmount < 0) {
        alert(this.$i18n.t("recipe.recipeCommon.validationSentenceSubAmount"));
      } else {
        try {
          await recipeManager.setSubRecipe(
            this.id,
            this.costPerSubUnit,
            this.selectedUnit,
            this.subAmount,
          );
          this.subRecipeRegistration = true;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log("ERROR", error);
          alert(error);
        }
        this.$emit("subRecipeRegistrationComplete", this.subRecipeRegistration);
      }
    },
    getSelectedUnit(unit) {
      /*r-selectで選択されたunit(単位)の判別*/
      this.selectedUnit = unit;
    },
    close() {
      this.$emit("close");
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        // customerID、StoreIDが存在しない場合はエラー ToDo: admin の場合を検討
        throw new Error("Forbidden");
      }
      // ユニット一覧を取得
      this.options = await unitManager.getUnits(this.customerID, this.storeID);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
.recipe-sub-recipe-register-form--content {
  margin-top: 20px;
}
.recipe-sub-recipe-register-form--input--wrapper {
  display: flex;
  vertical-align: middle;
}
@media screen and (min-width: 641px) {
  .recipe-sub-recipe-register-form-modal-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  .recipe-sub-recipe-left {
    min-height: 20vh;
    grid-column: 1/1;
    grid-row: 1/1;
    display: block;
    padding: 0px 16px 16px;
  }
  .recipe-sub-recipe-register-form--input--wrapper {
    padding-top: 16px;
  }
  .recipe-sub-recipe-register-unitAmount {
    margin-bottom: 16px;
  }
  .recipe-sub-recipe--content-right {
    min-height: 20vh;
    grid-column: 2/2;
    grid-row: 1/1;
    padding: 0px 16px 16px;
  }
  .recipe-sub-recipe-r-textbox {
    width: 160px;
  }
  .recipe-sub-recipe-r-selector {
    width: 160px;
    font-size: 1.2rem;
  }
}
@media (min-width: 1024px) {
  .recipe-sub-recipe-register-form {
    width: 1024px !important;
    box-sizing: border-box;
  }
  .recipe-sub-recipe-left {
    min-height: 20vh;
    grid-column: 1/1;
    grid-row: 1/1;
    display: block;
    text-align: center;
    padding: 0px 15px 15px;
  }
  .recipe-sub-recipe--content-right {
    min-height: 20vh;
    grid-column: 2/2;
    grid-row: 1/1;
    padding: 0px 15px 15px;
  }
}
</style>

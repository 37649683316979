<template>
  <r-card
    class="r-modal"
    :style="{
      width: width,
      height: height,
    }"
    round
  >
    <!-- 見出し -->
    <h3 class="r-modal--heading">
      <span v-if="step" class="r-modal--heading--step">STEP{{ step }}</span>
      <span class="r-modal--heading--label">{{ title }}</span>
    </h3>
    <r-hr v-if="title" hrStyle="primary" />
    <!-- コンテンツ -->
    <div class="r-modal--content">
      <slot />
    </div>

    <!-- アクションボタン -->
    <div class="r-modal--action">
      <r-button
        v-if="enter"
        class="r-modal--action--button"
        buttonStyle="primary"
        :full="windowSize === 'mobile'"
        :middle="windowSize === 'tab'"
        @click="clickHandler('enter')"
        >{{ enterLabel }}</r-button
      >
      <r-button
        v-if="skip"
        class="r-modal--action--button"
        buttonStyle="primary"
        outline
        :full="windowSize === 'mobile'"
        :middle="windowSize === 'tab'"
        @click="clickHandler('skip')"
        >{{ $t("common.skip") }}</r-button
      >
      <r-button
        class="r-modal--action--button"
        buttonStyle="primary"
        outline
        :full="windowSize === 'mobile'"
        :middle="windowSize === 'tab'"
        @click="clickHandler('cancel')"
        v-if="!cancelHidden"
        >{{ cancelLabel }}</r-button
      >
    </div>
  </r-card>
</template>

<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RCard from "@/plugins/atoms/RCard.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";

export default {
  name: "r-modal",
  components: {
    RButton,
    RCard,
    RHr,
  },
  data() {
    return {
      windowWidth: 0,
    };
  },
  props: {
    step: String,
    title: String,
    enter: Boolean,
    skip: Boolean,
    cancelHidden: Boolean,
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "",
    },
    enterLabel: {
      type: String,
      default: "決定",
    },
    cancelLabel: {
      type: String,
      default: "キャンセル",
    },
  },
  methods: {
    clickHandler(action) {
      this.$emit(action);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    windowSize() {
      if (this.windowWidth >= 641) {
        return "tab";
      } else if (this.windowWidth >= 1024) {
        return "pc";
      }
      return "mobile";
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.r-modal {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
.r-modal--heading {
  font-size: 1rem;
  text-align: left;
  font-weight: bold;
  padding-left: 20px;
  margin: 0;
}
.r-modal--heading--step {
  color: #e87352;
  margin-right: 10px;
}
.r-modal--content {
  margin-top: 20px;
}
.r-modal--action {
  margin-top: 20px;
  display: flex;
}
.r-modal--action--button {
  margin: 0 1%;
}
@media screen and (min-width: 641px) {
  .r-modal--heading {
    font-size: 1.6rem;
  }
  .r-modal--action {
    justify-content: center;
    padding: 0 50px;
  }
}

@media screen and (min-width: 1024px) {
  .r-modal {
    padding: 40px;
  }
  .r-modal--action {
    justify-content: center;
    padding: 0 100px;
  }
}
</style>

<!-- 食材追加用 -->
<template>
  <r-modal
    :title="$t('recipe.recipeCost.calcAdditionalFoodstuffCost')"
    :enter="inputCheck"
    @enter="enter"
    @cancel="cancel"
  >
    <r-spinner v-if="loading"></r-spinner>
    <div v-else>
      <div class="recipe-cost">
        <!-- 入力欄ここから -->
        <r-paper
          round
          class="recipe-cost--paper"
          v-for="(item, index) in tmpArray"
          :key="index"
        >
          <div class="recipe-cost--paper--title">
            <h2 class="recipe-cost--paper--title--recipe-name">
              {{ item.name }}
            </h2>
            <span class="recipe-cost--paper--title--caption">{{
              item.caption
            }}</span>
          </div>
          <r-hr hrStyle="primary" />

          <div class="recipe-cost--paper--main">
            <!-- サブレシピのとき  -->
            <div
              v-if="isSubRecipe(item)"
              class="recipe-cost--paper--main--left"
            >
              <div class="recipe-cost--paper--main--info">
                <div>
                  [{{ $t("common.totalQuantity") }}]{{ item.quantity
                  }}{{ item.quantityUnit }}
                </div>
                <div>
                  [{{ $t("recipe.recipeCost.price") }}] ¥{{ item.price }}
                </div>
              </div>
            </div>

            <!-- ユーザー登録食材のとき -->
            <div
              v-else-if="item.ingredientID === ''"
              class="recipe-cost--paper--main--left"
            >
              <div class="recipe-cost--paper--main--info">
                <div
                  class="recipe-cost--paper--main--info--input-price-wrapper"
                >
                  <span
                    >[{{ $t("recipe.recipeCost.price") }}]{{
                      $t("common.currency")
                    }}</span
                  >
                  <r-textbox
                    class="recipe-cost--paper--main--info--input-price"
                    type="number"
                    v-model.number="item.price"
                    :placeholder="$t('recipe.recipeCommon.inputFoodstuffPrice')"
                  ></r-textbox>
                </div>
              </div>

              <r-paper
                paperStyle="gray"
                round
                class="recipe-cost--paper--main--content"
              >
                <div class="input-box-wrapper">
                  <span>
                    {{
                      $t("recipe.recipeCost.totalAmountOf") +
                      `${item.unit}` +
                      $t("recipe.recipeCost.converseAmount")
                    }}
                  </span>
                  <r-textbox
                    type="number"
                    class="input-box"
                    v-model.number="item.unitAmount"
                    :unit="item.unit"
                  ></r-textbox>
                </div>
              </r-paper>
            </div>

            <!-- 通常食材かつ単価0 -->
            <div
              class="recipe-cost--paper--main--left"
              v-else-if="isPriceZero(item)"
            >
              <div class="recipe-cost--paper--main--info">
                <!-- 発注データ参照中のローディング -->
                <r-spinner v-if="orderDataLoading" harfSize></r-spinner>
                <div v-else>
                  <div>
                    [{{ $t("recipe.recipeCost.standard") }} ×︎
                    {{ $t("recipe.recipeCost.quantity") }}]
                    {{ item.standard }} ×︎ {{ item.quantity
                    }}{{ item.quantityUnit }}
                  </div>
                  <div>
                    [{{ $t("recipe.recipeCost.price") }}]
                    {{ $t("common.currency") }}{{ item.price }}
                  </div>
                  <div
                    class="recipe-cost--paper--main--info--input-price-wrapper"
                    v-if="isZeroPriceItem[index]"
                  >
                    <span
                      >[{{ $t("recipe.recipeCost.price") }}]{{
                        $t("common.currency")
                      }}</span
                    >
                    <r-textbox
                      class="recipe-cost--paper--main--info--input-price"
                      type="number"
                      v-model.number="item.price"
                      :placeholder="$t('recipe.recipeCost.inputPrice')"
                    ></r-textbox>
                    <!--発注データ参照ボタン-->
                    <r-button
                      class="recipe-cost--paper--main--info--order-data-button"
                      buttonStyle="primary"
                      v-if="item.price <= 0"
                      outline
                      @click="getOrderPrice(index, item.ingredientID)"
                    >
                      {{ $t("recipe.recipeCost.getOrderingData") }}
                    </r-button>
                  </div>
                </div>
              </div>

              <r-paper
                paperStyle="gray"
                round
                class="recipe-cost--paper--main--content"
              >
                <div class="input-box-wrapper">
                  <span
                    >{{ $t("recipe.recipeCost.totalAmountOf") }}
                    {{ item.unit }} {{ $t("recipe.recipeCost.converseAmount") }}
                  </span>
                  <r-textbox
                    type="number"
                    v-model.number="item.unitAmount"
                    :disabled="item.price <= 0"
                    :unit="item.unit"
                    class="input-box"
                  ></r-textbox>
                </div>
              </r-paper>
            </div>

            <!-- 通常の食材のとき -->
            <div v-else class="recipe-cost--paper--main--left">
              <div class="recipe-cost--paper--main--info">
                <div>
                  [{{ $t("recipe.recipeCost.standard") }}✖︎{{
                    $t("recipe.recipeCost.quantity")
                  }}] {{ item.standard }} ×︎ {{ item.quantity
                  }}{{ item.quantityUnit }}
                </div>
                <div>
                  [{{ $t("recipe.recipeCost.price") }}]
                  {{ $t("common.currency") }}{{ item.price }}
                </div>
              </div>

              <r-paper
                paperStyle="gray"
                round
                class="recipe-cost--paper--main--content"
              >
                <div class="input-box-wrapper">
                  <span>
                    {{
                      $t("recipe.recipeCost.totalAmountOf") +
                      `${item.unit}` +
                      $t("recipe.recipeCost.converseAmount")
                    }}
                  </span>
                  <!-- 総量入力 -->
                  <r-textbox
                    type="number"
                    v-model.number="item.unitAmount"
                    :unit="item.unit"
                    class="input-box"
                  ></r-textbox>
                </div>
              </r-paper>
            </div>

            <font-awesome-icon
              :icon="['fas', 'chevron-down']"
              recipe-cost--paper--main--content
              class="fa-chevron-down"
            ></font-awesome-icon>

            <!-- 原価表示ここから -->
            <div class="recipe-cost--paper--main--content cost--wrapper">
              <r-heading :level="4"
                >{{ item.amount }} {{ item.unit }}
                {{ $t("recipe.recipeCost.costOf") }}</r-heading
              >
              <div class="cost--inline-wrapper">
                <span class="cost--computed">{{ calcCost(index) }}</span>
                {{ $t("common.currencyYen") }}
              </div>
            </div>
          </div>
        </r-paper>
      </div>
    </div>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import recipeManager from "@/libs/Recipe.js";
import auth from "@/libs/Auth.js";

export default {
  components: {
    RModal,
    RPaper,
    RHr,
    RTextbox,
    RButton,
    RHeading,
    RSpinner,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      tmpArray: [],
      loading: false,
      orderDataLoading: false,
      isZeroPriceItem: [],
    };
  },
  methods: {
    enter() {
      this.$emit("enter", this.tmpArray);
    },
    cancel() {
      this.$emit("cancel");
    },
    async getOrderPrice(index, ingredientID) {
      this.orderDataLoading = true;

      const orderPrice = await recipeManager.getOrderPrice(
        this.customerID,
        this.storeID,
        ingredientID,
      );
      if (Number(orderPrice) > 0) {
        this.$set(this.items[index], "price", orderPrice);
      } else {
        alert(this.$i18n.t("recipe.recipeCost.noOrderingData"));
      }

      this.orderDataLoading = false;
    },
  },
  computed: {
    calcCost() {
      return (index) => {
        // コスト計算
        const costValue = recipeManager.calcItemCost(this.tmpArray[index]);
        if (costValue === Infinity) {
          return null;
        } else if (Number.isNaN(costValue)) {
          return 0;
        } else {
          this.$set(this.tmpArray[index], "cost", costValue);
          return costValue;
        }
      };
    },
    isFillAllForm() {
      const arr = this.items.map((e) => e.unitAmount);
      return !(arr.includes(0) || arr.includes(""));
    },
    isSubRecipe() {
      return (item) => {
        if (item.ref) {
          return true;
        }
        return false;
      };
    },
    isPriceZero() {
      return (item) => {
        if (item.ingredientID !== "") {
          return true;
        } else if (item.price !== 0 && item.unitAmount) {
          return true;
        } else {
          return false;
        }
      };
    },
    inputCheck() {
      let result = false;
      this.tmpArray.forEach((item) => {
        if (item.cost > 0 && item.unitAmount > 0) {
          result = true;
        }
      });
      return result;
    },
  },
  async created() {
    this.loading = true;
    try {
      const clonedeep = require("lodash/cloneDeep");
      this.tmpArray = clonedeep(this.items);

      //発注データ参照のため、customerID,storeIDを取得
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }
      // サブレシピの場合はunitAmountをセットする。
      this.tmpArray = await this.tmpArray.reduce(async (pacc, item) => {
        let acc = await pacc;
        if (item.ref !== null) {
          item.unitAmount = item.quantity;
        }
        acc.push(item);
        return acc;
      }, []);
      //単価0の有無をチェック
      this.isZeroPriceItem.length = this.tmpArray.length;
      this.isZeroPriceItem.fill(false);

      this.tmpArray.forEach((item, index) => {
        if (item.price == 0) {
          //単価0の食材の場合
          this.isZeroPriceItem[index] = true;
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.recipe-cost {
  padding: 0 20px;
}
.recipe-cost--buttons {
  display: flex;
  justify-content: space-between;
}
.recipe-cost--menu-button {
  display: block;
  text-align: left;
  margin-top: 20px;
}
.recipe-cost--title {
  margin-top: 20px;
}
.recipe-cost--paper {
  margin-top: 20px;
  width: 100%;
}
.recipe-cost--paper--title {
  text-align: left;
}
.recipe-cost--paper--title--recipe-name {
  margin: 0;
}
.recipe-cost--paper--main {
  margin-top: 10px;
}
/** 金額・規格・入り数など表示部分 */
.recipe-cost--paper--main--info {
  text-align: left;
}
.recipe-cost--paper--main--info--input-price-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.recipe-cost--paper--main--info--input-price {
  width: 30%;
}
.recipe-cost--paper--main--content {
  margin-top: 20px;
}

.input-box-wrapper {
  display: flex;
}
/** 原価関連 */
.cost--wrapper {
  text-align: left;
}
.cost--inline-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.cost--computed {
  border: 2px solid #e6e6e6;
  width: 90%;
  border-radius: 5px;
  font-size: 1.2rem;
  padding: 8px;
  box-sizing: border-box;
  display: inline-block;
  margin-right: 5px;
}
.fa-chevron-down {
  font-size: 2.4rem;
  color: #e87352;
}
.recipe-cost--register-button {
  margin-top: 20px;
}
.modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.modal-content {
  width: 90%;
  max-height: 90%;
  overflow: auto;
}

@media screen and (min-width: 641px) {
  .recipe-cost--paper--main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .fa-chevron-down {
    transform: rotate(-90deg);
  }
  .recipe-cost--paper--main--left {
    width: 70%;
  }
  .cost--wrapper {
    width: 20%;
  }
}

@media screen and (min-width: 1024px) {
  .recipe-cost {
    margin: auto;
  }
}
</style>

<template>
  <footer class="r-footer">
    <small class="r-footer--small">&copy;{{ year }} recipro</small>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: 2016,
    };
  },
};
</script>

<style scoped>
.r-footer {
  color: #ffffff;
  background-color: #e45a34;
  width: 100%;
  box-sizing: border-box;
  font-weight: bold;
}
.r-footer--nav-items {
  background-color: #e45a34;
  margin: 0;
  padding: 10px 0;
  font-size: 0.8rem;
}
.r-footer--nav-item {
  text-decoration: none;
  color: #ffffff;
}
/** 表示されていない部分も|がついてしまう */
.r-footer--nav-item:not(:first-child)::before {
  content: "|";
  margin-right: 20px;
  margin-left: 20px;
}
.r-footer--small {
  display: inline-block;
  background-color: #e24e26;
  width: 100%;
  margin: 0;
  padding: 10px 0;
}
</style>

<template>
  <r-modal
    :title="$t('recipe.recipeEdit.changeSellingPrice')"
    enter
    @enter="enter"
    @cancel="cancel"
  >
    <div class="user-food-price--main">
      <r-textbox
        class="user-food-price--main--input"
        type="tel"
        :error="error.updatePrice !== ''"
        v-model.number="updatePrice"
      ></r-textbox>
      <div>{{ $t("common.currencyYen") }}</div>
    </div>
    <div class="error-message">{{ error.updatePrice }}</div>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";

export default {
  components: {
    RModal,
    RTextbox,
  },
  props: {
    exPrice: Number,
    exIndex: Number,
  },
  data() {
    return {
      updatePrice: 0,
      error: {
        updatePrice: "",
      },
    };
  },
  created() {
    this.updatePrice = this.exPrice;
  },
  methods: {
    enter() {
      this.validate();
      if (this.error.updatePrice !== "") {
        return;
      }
      this.$emit("enter", this.updatePrice, this.exIndex);
    },
    validate() {
      if (this.updatePrice === 0 || this.updatePrice === "") {
        this.$set(
          this.error,
          "updatePrice",
          this.$i18n.t("recipe.recipeEdit.priceNotInput"),
        );
      } else if (
        typeof this.updatePrice !== "number" &&
        !this.updatePrice.match(/^[0-9]+$/)
      ) {
        this.$set(
          this.error,
          "updatePrice",
          this.$i18n.t("recipe.recipeEdit.inputHalfsizeNumber"),
        );
      } else {
        this.$set(this.error, "updatePrice", "");
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.user-food-price--main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-food-price--main--input {
  width: 30%;
}
.error-message {
  height: 10px;
  color: #e85951;
  font-weight: bold;
}
</style>

<template>
  <r-spinner v-if="loading"></r-spinner>
  <div v-else>
    <r-heading :level="1" :image="headingImage" class="title">
      {{ $t("recipe.recipeEdit.editRecipeTitleOrFoodstuff") }}
    </r-heading>
    <div class="recipe-edit">
      <!-- ページ遷移ボタンここから -->
      <div class="recipe-edit--buttons">
        <r-button
          buttonStyle="primary"
          outline
          class="recipe-edit--menu-button"
          @click="backDetail"
          >{{ $t("recipe.recipeEdit.backToRecipeDetail") }}</r-button
        >
      </div>
      <!-- ページ遷移ボタンここまで -->

      <!-- メインコンテンツここから -->
      <div class="recipe-edit--main">
        <!-- 料理名ここから -->
        <div class="recipe-edit--main--heading recipe-edit--main--content">
          <r-heading :level="2">
            <div class="recipe-edit--main--heading--title">
              <img :src="subHeadingImage" alt class="sub-heading-iamge" />
              {{ recipe.name }}
            </div>
          </r-heading>
          <r-button
            buttonStyle="secondary"
            @click="modalOpen(0)"
            class="recipe-edit--main--button recipe-edit--mail--button--edit-name"
            >{{ $t("recipe.recipeEdit.editRecipeName") }}</r-button
          >
        </div>
        <!-- 料理名ここまで -->

        <!-- 画像ここから -->
        <div>
          <div class="recipe-edit--main--content">
            <div
              class="recipe-edit--main--image"
              :style="{ 'background-image': 'url(' + recipe.image + ')' }"
              v-if="recipe.image"
            />

            <div
              class="recipe-edit--main--image recipe-edit--main--image--default"
              v-else
            />
            <r-button
              buttonStyle="secondary"
              full
              @click="modalOpen(1)"
              class="recipe-edit--main--button"
              >{{ $t("recipe.recipeEdit.editImage") }}</r-button
            >

            <r-button
              buttonStyle="secondary"
              full
              v-if="recipe.image"
              @click="$set(recipe, 'image', '')"
              class="recipe-edit--main--button"
              >{{ $t("recipe.recipeEdit.deleteImage") }}</r-button
            >
          </div>
          <!-- 画像ここまで -->

          <!-- カテゴリーここから -->
          <r-paper
            round
            class="recipe-edit--main--content recipe-edit--main--category"
          >
            <div class="recipe-edit--main--category--list">
              <span
                class="recipe-edit--main--category--item"
                v-text="recipe.cat1"
              />
              <span
                v-if="recipe.cat2 !== ''"
                class="recipe-edit--main--category--item"
                v-text="recipe.cat2"
              />
              <span
                v-if="recipe.cat3 !== ''"
                class="recipe-edit--main--category--item"
                v-text="recipe.cat3"
              />
            </div>
            <r-button
              class="recipe-edit--main--button"
              buttonStyle="secondary"
              full
              :existingCat="existingCat"
              @click="modalOpen(2)"
              >{{ $t("recipe.recipeEdit.editCategory") }}</r-button
            >
          </r-paper>
          <!-- カテゴリーここまで -->

          <!-- 公開設定ここから -->
          <r-paper
            round
            class="recipe-edit--main--content recipe-edit--main--private"
          >
            <r-list>
              <r-list-header>
                {{ $t("recipe.recipeEdit.recipePublic") }}
              </r-list-header>
              <label for="private">
                <input id="private" type="checkbox" v-model="recipe.hidden" />
                {{ $t("recipe.recipeEdit.recipePrivate") }}
              </label>
            </r-list>
          </r-paper>
          <!-- 公開設定ここまで -->
        </div>

        <!-- 食材編集ここから -->
        <div>
          <r-paper
            round
            class="recipe-edit--main--content recipe-edit--main--foodstuff"
          >
            <r-list>
              <r-list-header>
                {{ $t("common.foodstuff") }}({{ recipe.serv
                }}{{ $t("common.perPerson") }})
              </r-list-header>
              <r-list-content v-for="(item, index) in sortedItems" :key="index">
                <!-- 食材名 -->
                <r-list-item
                  class="recipe-edit--main--foodstuff--item recipe-edit--main--foodstuff--title"
                >
                  <!-- サブレシピのとき -->
                  <div v-if="item.subItems !== undefined">
                    <input
                      type="checkbox"
                      v-model="selectedFoodStuffs[index]"
                    />
                    <span class="recipe-edit--main--foodstuff--icon">[S]</span>
                    <span>{{ item.name }}</span>
                  </div>
                  <!-- ユーザ入力食材のとき -->
                  <div v-else-if="item.ingredientID === ''">
                    <div>
                      <input
                        type="checkbox"
                        v-model="selectedFoodStuffs[index]"
                      />
                      <span class="recipe-edit--main--foodstuff--icon"
                        >[U]</span
                      >
                      <span>{{ item.name }}</span>
                    </div>
                    <span
                      class="reciope-edit--main--foodstuff--update-price"
                      v-if="item.price > 0"
                      @click="openUpdateUserFoodPrice(item.price, index)"
                    >
                      [{{ $t("recipe.recipeEdit.changeSellingPrice") }}]</span
                    >
                  </div>
                  <!-- 通常食材のとき -->
                  <div v-else>
                    <input
                      type="checkbox"
                      v-model="selectedFoodStuffs[index]"
                    />
                    <span>{{ item.name }}</span>
                  </div>
                </r-list-item>
                <!-- 量・単位 -->
                <r-list-item
                  class="recipe-edit--main--foodstuff--item recipe-edit--main--foodstuff--amount"
                  >{{ item.amount | localNumber }}{{ item.unit }}</r-list-item
                >
                <!-- 金額 -->
                <r-list-item
                  class="recipe-edit--main--foodstuff--item recipe-edit--main--foodstuff--price"
                  >{{ $t("common.currency") }}{{ item.price | localNumber
                  }}{{ $t("common.currencyYen") }}</r-list-item
                >

                <!-- 並び順変更ボタン -->
                <r-list-item
                  class="recipe-edit--main--foodstuff--item recipe-edit--main--foodstuff--sort-button"
                >
                  <font-awesome-icon
                    :icon="['fas', 'sort-up']"
                    class="fas fa-sort-up recipe-edit--sortButton recipe-edit--sortButton-up"
                    :class="{
                      'recipe-edit--sortButton--disabled':
                        item.displayNum === minDisplayNum,
                    }"
                    @click="sortUp(index, item.displayNum)"
                  ></font-awesome-icon>
                  <font-awesome-icon
                    :icon="['fas', 'sort-down']"
                    class="fas fa-sort-down recipe-edit--sortButton recipe-edit--sortButton-down"
                    :class="{
                      'recipe-edit--sortButton--disabled':
                        item.displayNum === maxDisplayNum,
                    }"
                    @click="sortDown(index, item.displayNum)"
                  ></font-awesome-icon>
                </r-list-item>
              </r-list-content>
            </r-list>
            <div class="recipe-edit--main--foodstuff--edit">
              <div class="recipe-edit--main--foodstuff--edit--item">
                <span>{{ $t("recipe.recipeEdit.foodstuffChecked1") }}</span>
                <r-button
                  class="recipe-edit--main--button"
                  buttonStyle="secondary"
                  full
                  @click="openUpdateAmountUnit"
                  >{{ $t("recipe.recipeEdit.editAmountUnit") }}</r-button
                >
                <r-button
                  class="recipe-edit--main--button"
                  buttonStyle="secondary"
                  full
                  @click="openAlterFoodForm"
                  >{{ $t("recipe.recipeEdit.alternateFoodstuff") }}</r-button
                >
              </div>

              <div class="recipe-edit--main--foodstuff--edit--item">
                <span>{{ $t("recipe.recipeEdit.foodstuffChecked2") }}</span>
                <r-button
                  class="recipe-edit--main--button"
                  buttonStyle="secondary"
                  full
                  @click="deleteFoodstuff"
                  >{{ $t("recipe.recipeEdit.deleteFoodstuff") }}</r-button
                >
                <r-button
                  class="recipe-edit--main--button"
                  buttonStyle="secondary"
                  full
                  @click="modalOpen(5)"
                  >{{ $t("recipe.recipeEdit.addFoodstuff") }}</r-button
                >
              </div>
            </div>
          </r-paper>
          <!-- 画像編集ここまで -->

          <!-- アレルギーここから -->
          <r-paper
            round
            class="recipe-edit--main--content recipe-edit--main--allergies"
          >
            <r-list>
              <r-list-header>
                {{ $t("recipe.allergyItem.allergy") }}
              </r-list-header>
              <div class="recipe-registration--main--item--allelgy">
                <r-heading :level="4">
                  {{ $t("recipe.allergyItem.sevenItems") }}
                </r-heading>
                <label for="wheat">
                  <input
                    type="checkbox"
                    id="wheat"
                    v-model="tmpAllergy.sevenItems.wheat"
                  />
                  {{ $t("recipe.allergyItem.wheat") }}
                </label>
                <label for="soba">
                  <input
                    type="checkbox"
                    id="soba"
                    v-model="tmpAllergy.sevenItems.soba"
                  />
                  {{ $t("recipe.allergyItem.soba") }}
                </label>
                <label for="milk">
                  <input
                    type="checkbox"
                    id="milk"
                    v-model="tmpAllergy.sevenItems.milk"
                  />
                  {{ $t("recipe.allergyItem.milk") }}
                </label>
                <label for="egg">
                  <input
                    type="checkbox"
                    id="egg"
                    v-model="tmpAllergy.sevenItems.egg"
                  />
                  {{ $t("recipe.allergyItem.egg") }}
                </label>
                <label for="peanuts">
                  <input
                    type="checkbox"
                    id="peanuts"
                    v-model="tmpAllergy.sevenItems.peanuts"
                  />
                  {{ $t("recipe.allergyItem.peanuts") }}
                </label>
                <label for="shrimp">
                  <input
                    type="checkbox"
                    id="shrimp"
                    v-model="tmpAllergy.sevenItems.shrimp"
                  />
                  {{ $t("recipe.allergyItem.shrimp") }}
                </label>
                <label for="crab">
                  <input
                    type="checkbox"
                    id="crab"
                    v-model="tmpAllergy.sevenItems.crab"
                  />
                  {{ $t("recipe.allergyItem.crab") }}
                </label>
              </div>

              <div
                class="recipe-registration--main--item--allelgy"
                v-if="allergyTwenty"
              >
                <r-heading :level="4">
                  {{ $t("recipe.allergyItem.twentyItems") }}
                </r-heading>
                <label for="abalone">
                  <input
                    type="checkbox"
                    id="abalone"
                    v-model="tmpAllergy.twentyItems.abalone"
                  />
                  {{ $t("recipe.allergyItem.abalone") }}
                </label>
                <label for="squid">
                  <input
                    type="checkbox"
                    id="squid"
                    v-model="tmpAllergy.twentyItems.squid"
                  />
                  {{ $t("recipe.allergyItem.squid") }}
                </label>
                <label for="salmonRoe">
                  <input
                    type="checkbox"
                    id="salmonRoe"
                    v-model="tmpAllergy.twentyItems.salmonRoe"
                  />
                  {{ $t("recipe.allergyItem.salmonRoe") }}
                </label>
                <label for="orange">
                  <input
                    type="checkbox"
                    id="orange"
                    v-model="tmpAllergy.twentyItems.orange"
                  />
                  {{ $t("recipe.allergyItem.orange") }}
                </label>
                <label for="cashewNut">
                  <input
                    type="checkbox"
                    id="cashewNut"
                    v-model="tmpAllergy.twentyItems.cashewNut"
                  />
                  {{ $t("recipe.allergyItem.cashewNut") }}
                </label>
                <label for="kiwi">
                  <input
                    type="checkbox"
                    id="kiwi"
                    v-model="tmpAllergy.twentyItems.kiwi"
                  />
                  {{ $t("recipe.allergyItem.kiwi") }}
                </label>
                <label for="beef">
                  <input
                    type="checkbox"
                    id="beef"
                    v-model="tmpAllergy.twentyItems.beef"
                  />
                  {{ $t("recipe.allergyItem.beef") }}
                </label>
                <label for="walnut">
                  <input
                    type="checkbox"
                    id="walnut"
                    v-model="tmpAllergy.twentyItems.walnut"
                  />
                  {{ $t("recipe.allergyItem.walnut") }}
                </label>
                <label for="sesame">
                  <input
                    type="checkbox"
                    id="sesame"
                    v-model="tmpAllergy.twentyItems.sesame"
                  />
                  {{ $t("recipe.allergyItem.sesame") }}
                </label>
                <label for="salmon">
                  <input
                    type="checkbox"
                    id="salmon"
                    v-model="tmpAllergy.twentyItems.salmon"
                  />
                  {{ $t("recipe.allergyItem.salmon") }}
                </label>
                <label for="mackerel">
                  <input
                    type="checkbox"
                    id="mackerel"
                    v-model="tmpAllergy.twentyItems.mackerel"
                  />
                  {{ $t("recipe.allergyItem.mackerel") }}
                </label>
                <label for="soy">
                  <input
                    type="checkbox"
                    id="soy"
                    v-model="tmpAllergy.twentyItems.soy"
                  />
                  {{ $t("recipe.allergyItem.soy") }}
                </label>
                <label for="chicken">
                  <input
                    type="checkbox"
                    id="chicken"
                    v-model="tmpAllergy.twentyItems.chicken"
                  />
                  {{ $t("recipe.allergyItem.chicken") }}
                </label>
                <label for="banana">
                  <input
                    type="checkbox"
                    id="banana"
                    v-model="tmpAllergy.twentyItems.banana"
                  />
                  {{ $t("recipe.allergyItem.banana") }}
                </label>
                <label for="pork">
                  <input
                    type="checkbox"
                    id="pork"
                    v-model="tmpAllergy.twentyItems.pork"
                  />
                  {{ $t("recipe.allergyItem.pork") }}
                </label>
                <label for="matsutake">
                  <input
                    type="checkbox"
                    id="matsutake"
                    v-model="tmpAllergy.twentyItems.matsutake"
                  />
                  {{ $t("recipe.allergyItem.matsutake") }}
                </label>
                <label for="peaches">
                  <input
                    type="checkbox"
                    id="peaches"
                    v-model="tmpAllergy.twentyItems.peaches"
                  />
                  {{ $t("recipe.allergyItem.peaches") }}
                </label>
                <label for="yam">
                  <input
                    type="checkbox"
                    id="yam"
                    v-model="tmpAllergy.twentyItems.yam"
                  />
                  {{ $t("recipe.allergyItem.yam") }}
                </label>
                <label for="apple">
                  <input
                    type="checkbox"
                    id="apple"
                    v-model="tmpAllergy.twentyItems.apple"
                  />
                  {{ $t("recipe.allergyItem.apple") }}
                </label>
                <label for="geratin">
                  <input
                    type="checkbox"
                    id="geratin"
                    v-model="tmpAllergy.twentyItems.geratin"
                  />
                  {{ $t("recipe.allergyItem.geratin") }}
                </label>
                <label for="almond">
                  <input
                    type="checkbox"
                    id="almond"
                    v-model="tmpAllergy.twentyItems.almond"
                  />
                  {{ $t("recipe.allergyItem.almond") }}
                </label>
              </div>
            </r-list>
          </r-paper>
          <!-- アレルギーここまで -->
        </div>

        <!-- 原価ここから -->
        <r-paper
          round
          class="recipe-edit--main--content recipe-edit--main--cost"
        >
          <r-list>
            <r-list-header>{{ $t("common.costRate") }}</r-list-header>
            <r-chart
              v-if="isRegistCost"
              chartStyle="secondary"
              :label="$t('common.costRate')"
              :rate="recipe.costRate"
              class="recipe-update-confirm--main--cost-rate--chart recipe-edit--main--cost--items"
            />
            <div
              class="recipe-update-confirm--main--cost-rate--list-items recipe-edit--main--cost--items"
            >
              <r-list-content>
                <r-list-item>
                  {{ $t("common.cost") }} :
                  <span v-if="isRegistCost">
                    {{ recipe.costPerServ | localNumber }}
                    {{ $t("common.currencyYen") }}
                  </span>
                  <span v-else>
                    {{ $t("recipe.recipeDetail.notRegisterd") }}
                  </span>
                </r-list-item>
              </r-list-content>
              <r-list-content>
                <r-list-item>
                  {{ $t("common.price") }}:
                  <span v-if="isRegistCost">
                    {{ recipe.price | localNumber
                    }}{{ $t("common.currencyYen") }}
                  </span>
                  <span v-else>
                    {{ $t("recipe.recipeDetail.notRegisterd") }}
                  </span>
                </r-list-item>
              </r-list-content>
              <r-list-content>
                <r-list-item>
                  {{ $t("recipe.recipeEdit.targetRate") }}:
                  <span v-if="isRegistCost"
                    >{{ recipe.targetRate | localNumber }}%</span
                  >
                  <span v-else>
                    {{ $t("recipe.recipeDetail.notRegisterd") }}
                  </span>
                </r-list-item>
              </r-list-content>
              <r-list-content>
                <r-list-item>
                  {{ $t("recipe.recipeDetail.recipeCreateUser") }}:{{
                    recipe.createUser
                  }}
                </r-list-item>
              </r-list-content>
            </div>
          </r-list>

          <r-button
            v-if="isRegistCost"
            class="recipe-edit--main--button recipe-edit--main--cost--items"
            buttonStyle="secondary"
            full
            @click="modalOpen(7)"
            >{{ $t("recipe.recipeEdit.editPrice") }}</r-button
          >

          <r-button
            v-if="isRegistCost"
            class="recipe-edit--main--button recipe-edit--main--cost--items"
            buttonStyle="secondary"
            full
            @click="modalOpen(8)"
            >{{ $t("recipe.recipeEdit.editTargetRate") }}</r-button
          >
        </r-paper>
        <!-- 原価ここまで -->
      </div>
      <!-- メインコンテンツここまで -->

      <!-- 変更内容確認ボタン -->
      <r-button
        buttonStyle="primary"
        outline
        full
        class="recipe-edit--main--content confirm-button"
        @click="clickConfirmButton"
        >{{ $t("recipe.recipeEdit.confirmEditContent") }}</r-button
      >

      <!-- モーダルコンテンツここから -->
      <transition name="modal">
        <div
          class="recipe-registration--modal-bg"
          :class="{
            'recipe-registration--modal-bg--alter': modalFlag[4],
            'recipe-registration--modal-bg--step4':
              modalFlag[5] || modalFlag[6],
            'recipe-registration--modal-bg--step9': modalFlag[9],
          }"
          v-if="modalFlag.includes(true)"
        >
          <!-- 料理名入力 -->
          <recipe-name-form
            v-if="modalFlag[0]"
            @enter="clickFromRecipeName"
            @cancel="modalClose"
            class="recipe-registration--modal"
            :existingName="recipe.name"
            :existingKana="recipe.kana"
          />

          <!-- 画像選択 -->
          <recipe-image-form
            v-if="modalFlag[1]"
            @enter="clickFromImage"
            @skip="skipUploadImage"
            @cancel="modalClose"
            class="recipe-registration--modal"
          />

          <!-- 料理カテゴリー選択 -->
          <recipe-category-form
            v-if="modalFlag[2]"
            @enter="clickFromCategory"
            @cancel="modalClose"
            class="recipe-registration--modal"
            :existingCat="existingCat"
          />

          <!-- 量・単位を変更する -->
          <recipe-unit-form
            @enter="clickFromUnitFormUpdate"
            @cancel="modalClose('clear')"
            v-if="modalFlag[3]"
            :foodstuffs="tmpFoodStuffs"
            hasCostCalculated
            class="recipe-registration--modal"
            servingHidden
          />
          <!-- 代替食材 -->
          <recipe-alter-food-form
            v-if="modalFlag[4]"
            :existingFoodStuffs="tmpFoodStuffs"
            :costRate="recipe.costRate"
            @enter="updateWithAlterFood"
            @cancel="modalClose('clear')"
          />

          <!--食材追加ここから -->
          <recipe-foodstuff-form
            v-if="modalFlag[5]"
            @enter="clickFromFoodstuff"
            @cancel="modalClose"
            @closeModal="modalClose"
            editMode
            class="recipe-registration--modal recipe-registration--modal--food"
            :existingFoodstuffs="recipe.items"
            :recipeID="recipeID"
            :higherSubRecipeCount="higherSubRecipeCount"
          />

          <recipe-unit-form
            @enter="clickFromUnitFormAdd"
            @cancel="returnToFoodStuffForm"
            v-if="modalFlag[6]"
            :foodstuffs="tmpFoodStuffs"
            newRegistrationLimited
            servingHidden
            class="recipe-registration--modal recipe-registration--modal--food"
          />
          <!-- 食材追加ここまで -->

          <!-- 売値変更ここから -->
          <recipe-price-update-form
            v-if="modalFlag[7]"
            class="recipe-registration--modal"
            :price="recipe.price"
            @cancel="modalClose('clear')"
            @enter="enterFromPriceForm"
          />
          <!-- 売値変更ここまで -->

          <!-- 目標原価変更ここから -->
          <recipe-target-rate-update-form
            v-if="modalFlag[8]"
            class="recipe-registration--modal"
            :targetRate="recipe.targetRate"
            @enter="enterFromTargetRateForm"
            @cancel="modalClose"
          />
          <!-- 目標原価変更ここまで -->

          <!-- 確認画面ここから -->
          <recipe-update-confirm
            :recipe="recipe"
            :allergyTwenty="allergyTwenty"
            v-if="modalFlag[9]"
            @enter="updateRecipe"
            @cancel="modalClose"
            class="recipe-registration--modal recipe-edit--modal--confirm"
          />
          <!-- 確認画面ここまで -->

          <!-- ユーザー入力食材の金額変更モーダル -->
          <recipe-update-user-food-price
            v-if="modalFlag[10]"
            :exPrice="userFoodExPrice"
            :exIndex="userFoodExIndex"
            @enter="updatePriceForUserFood"
            @cancel="modalClose"
          ></recipe-update-user-food-price>

          <!-- ユーザ入力食材の金額登録モーダル -->
          <recipe-input-price-for-user-food
            v-if="modalFlag[11]"
            :exItems="tmpFoodStuffs"
            @enter="enterFromPriceForUserFood"
            @cancel="modalClose"
          ></recipe-input-price-for-user-food>

          <!-- 原価変更(食材関連) -->
          <recipe-cost-update-modal
            v-if="modalFlag[12]"
            :exCost="recipe.totalCost"
            :exCostRate="recipe.costRate"
            :cost="updateCost"
            :costRate="updateCostRate"
            :deletedItems="deletedItems"
            @enter="confirmUpdateCost('food')"
            @cancel="cancelUpdateCost(backModalIndex)"
          ></recipe-cost-update-modal>

          <!-- 原価変更 -->
          <recipe-cost-update-modal
            v-if="modalFlag[14]"
            :exCost="recipe.totalCost"
            :exCostRate="recipe.costRate"
            :cost="updateCost"
            :costRate="updateCostRate"
            price
            @enter="confirmUpdateCost('price')"
            @cancel="cancelUpdateCost(backModalIndex)"
          ></recipe-cost-update-modal>

          <!-- 追加食材の原価計算 -->
          <recipe-cost-modal
            v-if="modalFlag[13]"
            :items="tmpFoodStuffs"
            @enter="clickFromCostModal"
            @cancel="cancelFromCostModal"
          ></recipe-cost-modal>
        </div>
      </transition>
      <!-- モーダルコンテンツここまで -->
    </div>
  </div>
</template>

<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListHeader from "@/plugins/atoms/RListHeader.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RChart from "@/plugins/atoms/RChart.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import RecipeNameForm from "@/components/recipe/organisms/RecipeNameForm.vue";
import RecipeCategoryForm from "@/components/recipe/organisms/RecipeCategoryForm.vue";
import RecipeImageForm from "@/components/recipe/organisms/RecipeImageForm.vue";
import RecipeAlterFoodForm from "@/components/recipe/organisms/RecipeAlterFoodForm.vue";
import RecipeFoodstuffForm from "@/components/recipe/organisms/RecipeFoodstuffForm.vue";
import RecipeUnitForm from "@/components/recipe/organisms/RecipeUnitForm.vue";
import RecipePriceUpdateForm from "@/components/recipe/organisms/RecipePriceUpdateForm.vue";
import RecipeUpdateConfirm from "@/components/recipe/organisms/RecipeUpdateConfirm.vue";
import RecipeTargetRateUpdateForm from "@/components/recipe/organisms/RecipeTargetRateUpdateForm.vue";
import RecipeUpdateUserFoodPrice from "@/components/recipe/organisms/RecipeUpdateUserFoodPrice.vue";
import RecipeInputPriceForUserFood from "@/components/recipe/organisms/RecipeInputPriceForUserFood.vue";
import RecipeCostUpdateModal from "@/components/recipe/organisms/RecipeCostUpdateModal.vue";
import RecipeCostModal from "@/components/recipe/organisms/RecipeCostModal.vue";

import auth from "@/libs/Auth.js";
import recipeManager from "@/libs/Recipe.js";
import storeManager from "@/libs/Store.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  components: {
    RButton,
    RList,
    RListHeader,
    RListContent,
    RListItem,
    RHeading,
    RPaper,
    RChart,
    RSpinner,
    RecipeNameForm,
    RecipeCategoryForm,
    RecipeImageForm,
    RecipeAlterFoodForm,
    RecipeFoodstuffForm,
    RecipeUnitForm,
    RecipePriceUpdateForm,
    RecipeUpdateConfirm,
    RecipeTargetRateUpdateForm,
    RecipeUpdateUserFoodPrice,
    RecipeInputPriceForUserFood,
    RecipeCostUpdateModal,
    RecipeCostModal,
  },
  data() {
    return {
      recipeID: "",
      allergyTwenty: false,
      isShowEditMenu: false,
      isShowBalloonSubRecipeButton: false,
      subRecipeAlert: 0,

      // 原価更新キャンセル用のインデックス
      backModalIndex: null,
      modalFlag: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      // ラジオボタン制御用(TODO 名称変更)
      selectedFoodStuffs: [],
      tmpFoodStuffs: [],
      tmpFoodStuffs2: [],
      updatePrice: 0,
      deletedItems: [],
      // アレルギー一時保存用
      tmpAllergy: {
        sevenItems: {
          wheat: false,
          soba: false,
          milk: false,
          egg: false,
          peanuts: false,
          shrimp: false,
          crab: false,
        },
        twentyItems: {
          abalone: false,
          squid: false,
          salmonRoe: false,
          orange: false,
          cashewNut: false,
          kiwi: false,
          beef: false,
          walnut: false,
          sesame: false,
          salmon: false,
          mackerel: false,
          soy: false,
          chicken: false,
          banana: false,
          pork: false,
          matsutake: false,
          peaches: false,
          yam: false,
          apple: false,
          geratin: false,
          almond: false,
        },
      },
      recipe: {},
      orgRecipe: {},
      userFoodExPrice: 0,
      userFoodExIndex: 0,
      exCost: 0,
      exCostRate: 0,
      updateCost: 0,
      updateCostRate: 0,
      headingImage: require("../../../assets/images/editColor.png"),
      subHeadingImage: require("../../../assets/images/viewColor.png"),
      higherSubRecipeCount: 0,
      loading: false,
    };
  },
  methods: {
    /* データ登録 */
    async updateRecipe() {
      try {
        if (this.isUsingSmaregi) {
          if (
            !window.confirm(
              this.$i18n.t("recipe.recipeEdit.confirmUpdateRecipeSmaregi"),
            )
          ) {
            // 警告アラートにnoと選択した場合は保存を行わない
            return;
          }
        }
        const updateRecipeData = {
          customerID: this.customerID,
          storeID: this.storeID,
          recipeID: this.recipeID,
          recipe: this.recipe,
          tmpFoodStuffs: this.tmpFoodStuffs,
          orgRecipe: this.orgRecipe,
        };

        // 更新処理
        await recipeManager.updateRecipe(updateRecipeData);

        // サブレシピの場合上位のレシピの原価を更新
        // 新規にサブレシピを登録した場合は、上位にレシピが存在しない
        // 上位にレシピが存在する場合は解除できないためorgRecipeの
        // isSubRecipeで判断する
        if (this.orgRecipe.isSubRecipe) {
          await recipeManager.updateCostHigherRecipe(
            this.customerID,
            this.storeID,
            this.recipeID,
          );
        }

        // 編集中解除
        await auth.unLockEditStatus();

        // Detail画面に遷移
        this.$router.push(
          {
            name: "recipeDetail",
            params: { id: this.recipeID },
          },
          () => {},
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
        alert(error);
      }
      clearAllBodyScrollLocks();
    },
    /**表示切り替え */
    toggleIsShowEditMenu() {
      this.isShowEditMenu = !this.isShowEditMenu;
    },
    closeBalloon() {
      this.isShowBalloonSubRecipeButton = false;
    },
    async backDetail() {
      // 編集中解除
      await auth.unLockEditStatus();
      // Detail画面に遷移
      this.$router.push(
        {
          name: "recipeDetail",
          params: { id: this.recipeID },
        },
        () => {},
      );
    },
    /* 共通処理 */
    modalOpen(index) {
      this.$set(this.modalFlag, index, true);
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".recipe-registration--modal");
      disableBodyScroll(modal);
    },
    modalClose(clear) {
      const index = this.modalFlag.indexOf(true);
      this.$set(this.modalFlag, index, false);

      // 引数でtmpの削除するかどうかを制御する
      if (clear !== undefined) {
        this.tmpFoodStuffs = [];
        this.deletedItems = [];
        // 影響調査
        this.refreshSelectedFoodIndex();
      }
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    refreshSelectedFoodIndex() {
      this.selectedFoodIndex.forEach((e) =>
        this.$set(this.selectedFoodStuffs, e, false),
      );
    },

    /** create時に実行するメソッド */
    createTmpAllergy() {
      const sevenItemsCode = this.recipe.allergy.sevenItems.map((allergyName) =>
        this.convertToAllergyCode(allergyName),
      );
      sevenItemsCode.forEach((e) =>
        this.$set(this.tmpAllergy.sevenItems, e, true),
      );

      const twentyItemsCode = this.recipe.allergy.twentyItems.map(
        (allergyName) => this.convertToAllergyCode(allergyName),
      );
      twentyItemsCode.forEach((e) =>
        this.$set(this.tmpAllergy.twentyItems, e, true),
      );
    },
    convertToAllergyCode(allergy) {
      switch (allergy) {
        case "小麦":
          return "wheat";
        case "蕎麦":
          return "soba";
        case "乳製品":
          return "milk";
        case "卵":
          return "egg";
        case "落花生":
          return "peanuts";
        case "えび":
          return "shrimp";
        case "かに":
          return "crab";
        case "あわび":
          return "abalone";
        case "いか":
          return "squid";
        case "いくら":
          return "salmonRoe";
        case "オレンジ":
          return "orange";
        case "カシューナッツ":
          return "cashewNut";
        case "キウイフルーツ":
          return "kiwi";
        case "牛肉":
          return "beef";
        case "くるみ":
          return "walnut";
        case "ごま":
          return "sesame";
        case "さけ":
          return "salmon";
        case "さば":
          return "mackerel";
        case "大豆":
          return "soy";
        case "鶏肉":
          return "chicken";
        case "バナナ":
          return "banana";
        case "豚肉":
          return "pork";
        case "まつたけ":
          return "matsutake";
        case "もも":
          return "peaches";
        case "やまいも":
          return "yam";
        case "りんご":
          return "apple";
        case "ゼラチン":
          return "geratin";
        case "アーモンド":
          return "almond";
      }
    },
    convertToAllergyName(code) {
      switch (code) {
        case "wheat":
          return "小麦";
        case "soba":
          return "蕎麦";
        case "milk":
          return "乳製品";
        case "egg":
          return "卵";
        case "peanuts":
          return "落花生";
        case "shrimp":
          return "えび";
        case "crab":
          return "かに";
        case "abalone":
          return "あわび";
        case "squid":
          return "いか";
        case "salmonRoe":
          return "いくら";
        case "orange":
          return "オレンジ";
        case "cashewNut":
          return "カシューナッツ";
        case "kiwi":
          return "キウイフルーツ";
        case "beef":
          return "牛肉";
        case "walnut":
          return "くるみ";
        case "sesame":
          return "ごま";
        case "salmon":
          return "さけ";
        case "mackerel":
          return "さば";
        case "soy":
          return "大豆";
        case "chicken":
          return "鶏肉";
        case "banana":
          return "バナナ";
        case "pork":
          return "豚肉";
        case "matsutake":
          return "まつたけ";
        case "peaches":
          return "もも";
        case "yam":
          return "やまいも";
        case "apple":
          return "りんご";
        case "geratin":
          return "ゼラチン";
        case "almond":
          return "アーモンド";
      }
    },
    // 食材編集のチェックボックス用の配列を作成するメソッド
    createSelectedFoodStuffs() {
      this.selectedFoodStuffs.length = this.recipe.items.length;
      this.selectedFoodStuffs.fill(false);
    },

    /** 料理名変更 */
    clickFromRecipeName(name, kana) {
      this.$set(this.recipe, "name", name);
      this.$set(this.recipe, "kana", kana);
      this.modalClose();
    },
    /**画像の変更 */
    clickFromImage(value) {
      this.recipe.image = value;
      this.modalClose();
    },
    skipUploadImage() {
      this.recipe.image = "";
      this.modalClose();
    },
    /**カテゴリー編集 */
    clickFromCategory(value) {
      // 初期化
      this.$set(this.recipe, `cat1`, "");
      this.$set(this.recipe, `cat2`, "");
      this.$set(this.recipe, `cat3`, "");

      value.forEach((val, index) => {
        this.$set(this.recipe, `cat${index + 1}`, val.name);
      });

      this.modalClose();
    },

    /**************** 量・単位の変更ここから ****************************************/
    // モーダルを開くための事前処理
    openUpdateAmountUnit() {
      if (this.selectedFoodIndex.length === 0) {
        return;
      }
      // 初期化
      this.tmpFoodStuffs = [];
      const clonedeep = require("lodash/cloneDeep");
      const items = clonedeep(this.recipe.items);
      this.selectedFoodIndex.forEach((e) => this.tmpFoodStuffs.push(items[e]));

      this.modalOpen(3);
    },
    // 数量変更モーダルで決定押下したあとの処理
    clickFromUnitFormUpdate(value) {
      // 以前の現在の原価率を以前の原価率として設定
      this.recipe.pastCostRate = this.recipe.costRate;
      // 原価未計算の場合
      if (!this.isRegistCost) {
        this.selectedFoodIndex.forEach((e, index) => {
          this.$set(this.recipe.items, e, value[index]);

          this.modalClose("clear");
        });
      } else {
        const clonedeep = require("lodash/cloneDeep");
        const items = clonedeep(this.recipe.items);
        const newItems = items.filter(
          (item, index) => !this.selectedFoodIndex.includes(index),
        );
        value.forEach((e) => newItems.push(e));
        // 初期化
        this.tmpFoodStuffs2 = [];
        this.tmpFoodStuffs2 = newItems;

        // 各食材の原価を再計算
        this.tmpFoodStuffs2.forEach((item, index) => {
          this.$set(
            this.tmpFoodStuffs2[index],
            "cost",
            recipeManager.calcItemCost(this.tmpFoodStuffs2[index]),
          );
        });
        this.updateCost = recipeManager.calcTotalCost(this.tmpFoodStuffs2);
        this.updateCostRate = recipeManager.calcCostRate(
          this.updateCost,
          this.recipe.price,
        );

        // 戻るモーダルのインデックスを取得する
        this.backModalIndex = this.modalFlag.indexOf(true);
        this.modalClose();
        this.modalOpen(12);
      }
    },
    /************************************************************************* */

    /**************** 代替食材を探すここから ****************************************/
    // 代替食材モーダルを開く事前準備
    openAlterFoodForm() {
      // 食材インデックスが選ばれていない場合
      if (this.selectedFoodIndex.length === 0) {
        return;
      }

      // 食材リストを作成
      const clonedeep = require("lodash/cloneDeep");
      const items = clonedeep(this.recipe.items);
      this.selectedFoodIndex.forEach((e) => this.tmpFoodStuffs.push(items[e]));

      // サブレシピおよびユーザーが直接入力した食材の場合、アラート
      if (this.tmpFoodStuffs.filter((e) => e.ingredientID === "").length > 0) {
        alert(
          this.$i18n.t("recipe.recipeEdit.noAlternativeFoodstuffInSubRecipe"),
        );
        this.tmpFoodStuffs = [];
        return;
      }
      // 代替食材モーダルを開く
      this.modalOpen(4);
    },
    // 代替食材で変更する
    updateWithAlterFood(value) {
      // 以前の現在の原価率を以前の原価率として設定
      this.recipe.pastCostRate = this.recipe.costRate;
      // 原価未計算の場合
      if (!this.isRegistCost) {
        this.selectedFoodIndex.forEach((e, index) => {
          this.$set(this.recipe.items, e, value[index]);
        });
        this.modalClose("clear");
      } else {
        const clonedeep = require("lodash/cloneDeep");
        const items = clonedeep(this.recipe.items);
        const newItems = items.filter(
          (item, index) => !this.selectedFoodIndex.includes(index),
        );
        value.forEach((e) => newItems.push(e));
        // 初期化
        this.tmpFoodStuffs2 = [];
        this.tmpFoodStuffs2 = newItems;

        // 各食材の原価を再計算
        this.tmpFoodStuffs2.forEach((item, index) => {
          this.$set(
            this.tmpFoodStuffs2[index],
            "cost",
            recipeManager.calcItemCost(this.tmpFoodStuffs2[index]),
          );
        });
        this.updateCost = recipeManager.calcTotalCost(this.tmpFoodStuffs2);
        this.updateCostRate = recipeManager.calcCostRate(
          this.updateCost,
          this.recipe.price,
        );

        // 戻るモーダルのインデックスを取得する
        this.backModalIndex = this.modalFlag.indexOf(true);
        this.modalClose();
        this.modalOpen(12);
      }
    },
    /********************************************************************* */

    /**************** 食材の削除ここから ****************************************/
    /**食材の削除 */
    async deleteFoodstuff() {
      // チェックされていないときは何もしない
      if (this.selectedFoodIndex.length === 0) {
        return;
      }

      // 以前の現在の原価率を以前の原価率として設定
      this.recipe.pastCostRate = this.recipe.costRate;

      //全ての食材をチェックして削除を行う場合
      if (this.selectedFoodIndex.length === this.recipe.items.length) {
        alert(this.$i18n.t("recipe.recipeEdit.deleteAllFoodstuffNotAvailable"));
        return;
      }
      // 食材がひとつのときのアラート
      if (this.recipe.items.length === 1) {
        alert(this.$i18n.t("recipe.recipeEdit.deleteFoodstuffNotAvailable"));
        this.refreshSelectedFoodIndex();
        return;
      }

      // 原価未計算の場合
      if (!this.isRegistCost) {
        // 選ばれたインデックスと合致しないものだけ残してsetする
        const newItems = this.recipe.items.filter(
          (item, index) => !this.selectedFoodIndex.includes(index),
        );
        this.$set(this.recipe, "items", newItems);
        this.refreshSelectedFoodIndex();
      } else {
        // 原価計算済みの場合
        const clonedeep = require("lodash/cloneDeep");
        const items = clonedeep(this.recipe.items);
        const newItems = items.filter(
          (item, index) => !this.selectedFoodIndex.includes(index),
        );
        this.deletedItems = items.filter((item, index) =>
          this.selectedFoodIndex.includes(index),
        );

        // 初期化
        this.tmpFoodStuffs2 = [];
        this.tmpFoodStuffs2 = newItems;

        // 各食材の原価を再計算
        this.tmpFoodStuffs2.forEach((item, index) => {
          this.$set(
            this.tmpFoodStuffs2[index],
            "cost",
            recipeManager.calcItemCost(this.tmpFoodStuffs2[index]),
          );
        });
        this.updateCost = recipeManager.calcTotalCost(this.tmpFoodStuffs2);
        this.updateCostRate = recipeManager.calcCostRate(
          this.updateCost,
          this.recipe.price,
        );

        // 戻るモーダルのインデックスを取得する
        this.backModalIndex = this.modalFlag.indexOf(true);
        this.modalClose();
        this.modalOpen(12);
      }
      this.sortFoodstuffs();
    },
    /***************************************************************************** */

    /**************** 食材の追加ここから ****************************************/
    // 追加する食材を選択してtmpに格納
    clickFromFoodstuff(value) {
      // 以前の現在の原価率を以前の原価率として設定
      this.recipe.pastCostRate = this.recipe.costRate;

      this.tmpFoodStuffs = value.filter(
        (e) => (!e.id || typeof e.id === "undefined") && e.amount === 0,
      );
      this.$set(this.modalFlag, 5, false);
      this.modalOpen(6);
    },
    // 食材を追加した後、数量を入力する
    async clickFromUnitFormAdd(value) {
      this.tmpFoodStuffs = value;

      // 原価が未登録の時
      if (!this.isRegistCost) {
        this.tmpFoodStuffs.forEach((e) => this.recipe.items.push(e));
        this.tmpFoodStuffs = [];
        this.modalClose();
      } else {
        // 原価が計算済みの場合の処理

        //ユーザー入力食材があるかで分岐
        if (
          this.tmpFoodStuffs.some(
            (item) =>
              item.ref === null && item.ingredientID === 0 && item.price === 0,
          )
        ) {
          this.modalClose();
          // 金額入力モーダルをひらく
          this.modalOpen(11);
        } else {
          this.modalClose();
          // 追加食材の原価計算へ
          this.modalOpen(13);
        }
      }
    },
    // 数量入力から食材選択へ戻る処理
    returnToFoodStuffForm() {
      this.modalClose();
      this.modalOpen(5);
    },
    // 追加食材の原価を再計算した後の処理
    clickFromCostModal(value) {
      // ディープコピーを作成
      const clonedeep = require("lodash/cloneDeep");
      const items = clonedeep(this.recipe.items);

      // valueのインデックスは0のみ
      items.push(value[0]);

      // 初期化
      this.tmpFoodStuffs2 = [];
      this.tmpFoodStuffs2 = items;

      // 各食材の原価を再計算
      this.tmpFoodStuffs2.forEach((item, index) => {
        this.$set(
          this.tmpFoodStuffs2[index],
          "cost",
          recipeManager.calcItemCost(this.tmpFoodStuffs2[index]),
        );
      });
      this.updateCost = recipeManager.calcTotalCost(this.tmpFoodStuffs2);
      this.updateCostRate = recipeManager.calcCostRate(
        this.updateCost,
        this.recipe.price,
      );

      // 戻るモーダルのインデックスを取得する
      this.backModalIndex = this.modalFlag.indexOf(true);
      this.modalClose();
      this.modalOpen(12);
    },
    cancelFromCostModal() {
      this.modalClose();
      this.modalOpen(6);
    },
    // ユーザ入力食材の金額登録後の処理
    async enterFromPriceForUserFood(value) {
      // ディープコピーを作成
      const clonedeep = require("lodash/cloneDeep");
      const items = clonedeep(this.recipe.items);

      // valueのインデックスは0のみ
      items.push(value[0]);

      // 初期化
      this.tmpFoodStuffs2 = [];
      this.tmpFoodStuffs2 = items;

      // 各食材の原価を再計算
      this.tmpFoodStuffs2.forEach((item, index) => {
        this.$set(
          this.tmpFoodStuffs2[index],
          "cost",
          recipeManager.calcItemCost(this.tmpFoodStuffs2[index]),
        );
      });
      this.updateCost = recipeManager.calcTotalCost(this.tmpFoodStuffs2);
      this.updateCostRate = recipeManager.calcCostRate(
        this.updateCost,
        this.recipe.price,
      );

      // 戻るモーダルのインデックスを取得する
      this.backModalIndex = this.modalFlag.indexOf(true);
      this.modalClose();
      this.modalOpen(12);
    },

    /**************** 売値の変更ここから ****************************************/
    enterFromPriceForm(price) {
      this.updatePrice = price;

      this.recipe.pastCostRate = this.recipe.costRate;

      this.updateCost = this.recipe.totalCost;
      this.updateCostRate = recipeManager.calcCostRate(
        this.recipe.costPerServ,
        this.updatePrice,
      );

      this.backModalIndex = this.modalFlag.indexOf(true);
      this.modalClose();
      this.modalOpen(14);
    },

    /****************目標原価率の変更ここから****************************************/
    enterFromTargetRateForm(targetRate) {
      this.$set(this.recipe, "targetRate", targetRate);
      this.modalClose();
    },

    /****************変更確認画面ここから****************************************/
    clickConfirmButton() {
      // code -> name
      const sevenItems = Object.keys(this.tmpAllergy.sevenItems)
        .filter((e) => this.tmpAllergy.sevenItems[e])
        .map((code) => this.convertToAllergyName(code));
      const twentyItems = Object.keys(this.tmpAllergy.twentyItems)
        .filter((e) => this.tmpAllergy.twentyItems[e])
        .map((code) => this.convertToAllergyName(code));
      this.$set(this.recipe.allergy, "sevenItems", sevenItems);
      this.$set(this.recipe.allergy, "twentyItems", twentyItems);
      this.modalOpen(9);
    },

    /****************ユーザー入力食材の金額変更ここから***********************************/
    openUpdateUserFoodPrice(price, index) {
      this.userFoodExPrice = price;
      this.userFoodExIndex = index;
      this.modalOpen(10);
    },
    updatePriceForUserFood(price, index) {
      // 以前の現在の原価率を以前の原価率として設定
      this.recipe.pastCostRate = this.recipe.costRate;

      const clonedeep = require("lodash/cloneDeep");
      const items = clonedeep(this.recipe.items);
      items[index].price = price;

      this.tmpFoodStuffs2 = items;

      // 各食材の原価を再計算
      this.tmpFoodStuffs2.forEach((item, index) => {
        this.$set(
          this.tmpFoodStuffs2[index],
          "cost",
          recipeManager.calcItemCost(this.tmpFoodStuffs2[index]),
        );
      });
      this.updateCost = recipeManager.calcTotalCost(this.tmpFoodStuffs2);
      this.updateCostRate = recipeManager.calcCostRate(
        this.updateCost,
        this.recipe.price,
      );

      // 戻るモーダルのインデックスを取得する
      this.backModalIndex = this.modalFlag.indexOf(true);
      this.modalClose();
      this.modalOpen(12);
    },

    /**************** 原価再計算関連ここから ****************************************/
    // 原価再計算(通常食材・ユーザー入力食材)
    reCalcCost(item, index) {
      this.$set(
        this.recipe.items[index],
        "cost",
        recipeManager.calcItemCost(this.recipe.items[index]),
      );
    },
    reCalcTotalCost() {
      this.$set(
        this.recipe,
        "totalCost",
        recipeManager.calcTotalCost(this.recipe.items),
      );
    },
    reCalcCostRate() {
      this.$set(
        this.recipe,
        "costRate",
        recipeManager.calcCostRate(this.recipe.costPerServ, this.recipe.price),
      );
    },

    /**************** 表示順序関連ここから ****************************************/
    sortUp(index, displayNum) {
      if (displayNum == this.recipe.items[0].displayNum) {
        //最初の順番の食事
        return;
      }
      this.$set(this.recipe.items[index - 1], "displayNum", displayNum);
      this.$set(this.recipe.items[index], "displayNum", displayNum - 1);
    },
    sortDown(index, displayNum) {
      if (
        displayNum == this.recipe.items[this.recipe.items.length - 1].displayNum
      ) {
        //最後の順番の食材
        return;
      }
      this.$set(this.recipe.items[index + 1], "displayNum", displayNum);
      this.$set(this.recipe.items[index], "displayNum", displayNum + 1);
    },
    sortFoodstuffs() {
      this.recipe.items.sort(function (a, b) {
        return a.displayNum - b.displayNum;
      });
    },
    /**************** 原価更新確認ここから ****************************************/
    confirmUpdateCost(mode) {
      // 食材に関する更新の場合
      if (mode === "food") {
        this.$set(this.recipe, "items", this.tmpFoodStuffs2);
        this.$set(this.recipe, "totalCost", this.updateCost);
        this.$set(this.recipe, "costRate", this.updateCostRate);
        this.tmpFoodStuffs2 = [];
      } else if (mode === "price") {
        // 売値の変更の場合
        this.$set(this.recipe, "price", this.updatePrice);
        this.$set(this.recipe, "costRate", this.updateCostRate);
        this.updatePrice = 0;
      }
      this.modalClose("clear");
    },
    cancelUpdateCost(backModalIndex) {
      if (backModalIndex === -1) {
        this.modalClose();
      } else {
        this.modalClose();
        this.modalOpen(backModalIndex);
      }
    },
  },
  computed: {
    existingCat() {
      const arr = [];
      arr.push(this.recipe.cat1);
      arr.push(this.recipe.cat2);
      arr.push(this.recipe.cat3);
      return arr;
    },
    hasRecipeSub() {
      return Object.keys(this.recipe.subs).length !== 0;
    },
    selectedFoodIndex() {
      return this.selectedFoodStuffs.reduce((acc, val, index) => {
        if (val) {
          acc.push(index);
        }
        return acc;
      }, []);
    },
    isRegistCost() {
      return this.recipe.totalCost > 0;
    },
    sortedItems() {
      if (this.recipe.items.length === 0) {
        return;
      }
      this.sortFoodstuffs();
      return this.recipe.items;
    },
    maxDisplayNum() {
      //displayNumを初期化
      this.recipe.items.forEach((item, index) => {
        item.displayNum = index + 1;
      });
      const displayArr = this.recipe.items.map((item) => item.displayNum);
      return displayArr.reduce((a, b) => Math.max(a, b));
    },
    minDisplayNum() {
      //displayNumを初期化
      this.recipe.items.forEach((item, index) => {
        item.displayNum = index + 1;
      });
      const displayArr = this.recipe.items.map((item) => item.displayNum);
      return displayArr.reduce((a, b) => Math.min(a, b));
    },
    isUsingSmaregi() {
      return this.customer.smaregiStoreID ? true : false;
    },
  },
  async created() {
    this.loading = true;
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      if (this.$route.params.id) {
        this.recipeID = this.$route.params.id;
      } else if (sessionStorage.recipeID) {
        // 存在しない場合はlocal storageから取得
        this.recipeID = sessionStorage.recipeID;
      } else {
        // recipeIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      // 該当のレシピ情報を取得
      const recipeRes = await recipeManager.getRecipeWithImage(
        this.customerID,
        this.storeID,
        this.recipeID,
      );
      this.recipe = recipeRes;
      this.image = recipeRes.image;

      // 更新前のオブジェクトを保存
      const clonedeep = require("lodash/cloneDeep");
      this.orgRecipe = clonedeep(recipeRes);

      // アレルギーの品目数設定を取得
      this.allergyTwenty = this.$root.privateState.allergyTwenty;

      // 階層チェック
      // 上に階層がある場合にチェックが必要

      const customer = await storeManager.getStoreInfo(
        this.customerID,
        this.storeID,
      );
      this.customer = customer;

      // SessoonStrageに保存
      sessionStorage.recipeID = this.recipeID;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }

    this.createTmpAllergy();
    this.createSelectedFoodStuffs();

    // 変更前の原価を一時保存変数にいれる
    if (this.recipe.cost !== 0) {
      this.exCost = this.recipe.totalCost;
      this.exCostRate = this.recipe.costRate;
    }

    this.higherSubRecipeCount = await recipeManager.useSubRecipeCountHigher(
      this.customerID,
      this.storeID,
      this.recipe.id,
    );
    this.sortFoodstuffs();
    this.loading = false;
  },
};
</script>

<style scoped>
.recipe-edit {
  padding: 60px 20px 0;
}
.recipe-edit--main--heading--title {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.recipe-edit--mail--button--edit-name {
  width: 100%;
}
.recipe-edit--buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.sub-heading-iamge {
  width: 24px;
  height: auto;
  margin-right: 10px;
}
.recipe-edit--menu-button {
  display: block;
  text-align: left;
  margin-top: 20px;
}
/** メインコンテンツ */
.recipe-edit--main--content {
  margin-top: 20px;
}
.recipe-edit--main--button {
  margin-top: 10px;
}
.recipe-edit--main--image {
  min-height: 30vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #f8d5cb;
}
.recipe-edit--main--image--default {
  background-image: url(~@/assets/images/logo4.png);
  background-size: auto 15vh;
}
.recipe-edit--main--store-name {
  font-weight: bold;
  text-align: left;
  padding: 5px 0 5px 10px;
  border-top: 1px solid #e87352;
  border-bottom: 1px solid #e87352;
}
.recipe-edit--main--foodstuff--item {
  display: flex;
}
.recipe-edit--main--foodstuff--title {
  width: 50%;
}
.recipe-edit--main--foodstuff--amount,
.recipe-edit--main--foodstuff--price {
  width: 25%;
  text-align: right;
}
.recipe-edit--main--foodstuff--sort-button {
  text-align: center;
}

/** カテゴリー */
.recipe-edit--main--category,
.recipe-edit--main--allergies,
.recipe-edit--main--private {
  text-align: left;
}
.recipe-edit--main--category--item:not(:last-child):after {
  content: "・";
}

/** 食材リスト */
.recipe-edit--main--foodstuff--edit {
  text-align: left;
  margin-top: 20px;
}
.recipe-edit--main--foodstuff--edit--item {
  margin-top: 20px;
}
.reciope-edit--main--foodstuff--update-price,
.recipe-edit--main--foodstuff--icon {
  color: #517ce8;
  font-weight: bold;
}
.reciope-edit--main--foodstuff--update-price:hover {
  cursor: pointer;
}

/** アレルギー */
.recipe-registration--main--item--allelgy {
  margin-top: 10px;
}
.recipe-registration--modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 0 5%;
  margin: auto;
}
/** 原価 */
.recipe-edit--main--cost--items {
  margin-top: 20px;
}

.recipe-registration--modal-bg--step4 {
  align-items: flex-start;
}
.recipe-registration--modal--food {
  margin-top: 30px;
}
.confirm-button {
  padding: 10px;
}
.confirm-button:hover {
  background-color: #e87352;
  color: #fff;
  transition: 0.5s;
}

.recipe-edit--sortButton:hover {
  cursor: pointer;
}
.recipe-edit--sortButton--disabled {
  opacity: 0.7;
}
.recipe-edit--sortButton--disabled:hover {
  cursor: not-allowed;
}

@media screen and (min-width: 641px) {
  .recipe-edit {
    padding-top: 100px;
    margin: auto;
  }
  .recipe-edit--main {
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    grid-template-rows: 0.2fr 1fr;
    gap: 20px;
  }
  .recipe-edit--main--heading {
    grid-column: span 2;
    position: relative;
  }
  .recipe-edit--mail--button--edit-name {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 10em;
    margin-top: 0;
  }
  .recipe-edit--main--cost {
    grid-column: 2;
  }
  .recipe-registration--modal-bg {
    padding: 5%;
  }
  .recipe-registration--modal-bg--alter {
    padding: 0;
  }
}

@media screen and (min-width: 1024px) {
  .recipe-edit {
    width: 1024px;
    padding-top: 120px;
    margin: auto;
  }
  .recipe-edit--buttons {
    position: absolute;
    top: 70px;
  }
  .recipe-edit--main {
    display: grid;
    grid-template-columns: 0.8fr 1fr 0.5fr;
    grid-template-rows: 0.1fr 1fr;
    gap: 20px;
  }
  .recipe-edit--main--heading {
    grid-column: span 3;
  }
  .recipe-edit--main--cost {
    grid-column: 3;
  }
  .confirm-button {
    margin: 40px 0px 40px;
  }
  .recipe-registration--modal {
    min-width: 880px;
  }
  .recipe-registration--modal-bg {
    padding: 0 25%;
  }
  .recipe-registration--modal-bg--alter {
    padding: 0;
  }
  .recipe-registration--modal-bg--step4,
  .recipe-registration--modal-bg--step9 {
    align-items: center;
    padding: 0 15%;
  }
  .recipe-registration--modal--food {
    width: 1024px;
    height: 90%;
  }
}
</style>

<template>
  <r-paper round class="login-list">
    <r-modal-sub-header :title="$t('config.loginList.usersLastLoginTime')">
      <r-spinner v-if="loading"></r-spinner>
      <r-list v-else>
        <r-list-content v-for="(item, index) in userList" :key="index">
          <r-list-item>{{ item.name }}</r-list-item>
          <r-list-item v-if="item.lastLoginDate === ''">{{
            $t("config.loginList.noHistory")
          }}</r-list-item>

          <r-list-item v-else> {{ item.lastLoginDate }}</r-list-item>
        </r-list-content>
      </r-list>
    </r-modal-sub-header>
  </r-paper>
</template>

<script>
import RPaper from "@/plugins/atoms/RPaper.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";

import auth from "@/libs/Auth.js";
import userManager from "@/libs/User.js";

export default {
  components: {
    RPaper,
    RList,
    RListContent,
    RListItem,
    RSpinner,
    RModalSubHeader,
  },
  data() {
    return {
      userList: [],
      customerID: "",
      storeID: "",
      loading: false,
    };
  },
  async created() {
    this.loading = true;

    this.customerID = await auth.getCustomerID(this.$root);
    this.storeID = await auth.getStoreID(this.$root);
    if (!this.customerID || !this.storeID) {
      // customerID、StoreIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }

    try {
      this.userList = await userManager.getUserListWithLastLoginDate(
        this.customerID,
        this.storeID,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.loading = false;
  },
};
</script>

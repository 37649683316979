<template>
  <div class="logout--wrapper">
    <div class="logout">
      <!-- スマホ用 -->
      <span class="logout--header">{{ $t("auth.logout") }}</span>
      <div class="logout--main">
        <div class="logout--main--content">{{ $t("auth.loggedout") }}</div>
        <r-button
          class="logout--main--content"
          buttonStyle="primary"
          @click="toLoginPage"
          >{{ $t("auth.toLoginPage") }}</r-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import RButton from "@/plugins/atoms/RButton.vue";

export default {
  components: {
    RButton,
  },
  methods: {
    toLoginPage() {
      this.$router.push({ name: "login" }, () => {});
    },
  },
};
</script>

<style scoped>
.logout--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}
.logout {
  background-color: #ffffff;
  width: 250px;
  height: 150px;
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  margin: auto;
  padding: 10px;
  position: relative;
}
.logout--header {
  background-color: #e87352;
  color: #ffffff;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-radius: 5px 5px 0 0;
  font-weight: bold;
  padding: 5px 0;
}
.logout--main {
  position: relative;
  top: 20px;
  height: 100%;
  box-sizing: border-box;
}
.logout--main--content {
  margin-top: 20px;
}

@media screen and (min-width: 641px) {
  .logout {
    width: 40%;
  }
}

@media screen and (min-width: 1024px) {
  .logout {
    width: 30%;
  }
}
</style>

<template>
  <div
    v-if="!image"
    class="r-fileupload"
    :class="[{ 'r-fileupload--drag': isDrag === 'new' }]"
    @dragover.prevent="checkDrag($event, 'new', true)"
    @dragleave.prevent="checkDrag($event, 'new', false)"
    @drop.prevent="onFileChange"
  >
    <div class="r-fileupload--wrapper">
      <span v-if="error" class="error-message">{{ error }}</span>
      <b
        v-if="isVisibleMessage"
        class="r-fileupload--message r-fileupload--content"
        >{{ $t("common.uploadImageDragAndDrop") }}</b
      >
      <span
        v-if="isVisibleMessage"
        class="r-fileupload--message r-fileupload--content"
        >{{ $t("common.or") }}</span
      >
      <label class="r-fileupload--label r-fileupload--content" for="file">
        <i :class="['fa', 'fa-' + icon]" v-if="icon"></i>
        <slot></slot>
        <input
          id="file"
          class="r-fileupload--input"
          type="file"
          @change="onFileChange"
        />
      </label>
    </div>
  </div>
  <img class="preview-item-file" v-else :src="image" alt />
</template>

<script>
export default {
  name: "r-fileupload",
  props: {
    icon: {
      type: String,
      default: "camera",
    },
    image: String,
  },
  data() {
    return {
      uploadedImage: "",
      isDrag: null,
      error: "",
      isVisibleMessage: false,
    };
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      const fileTypes = files[0].name.split(".");
      const len = fileTypes.length;
      if (len === 0) {
        return;
      }

      // ファイルサイズチェック
      const fileSize = files[0].size;
      if (fileSize > 4e6) {
        this.error = this.$i18n.t("common.fileSizeIsBig");
        return;
      }

      // 拡張子チェック
      const extension = fileTypes[len - 1];

      const lowerExtension = extension.toLowerCase();
      if (
        lowerExtension === "jpg" ||
        lowerExtension === "jpeg" ||
        lowerExtension === "png" ||
        lowerExtension === "gif"
      ) {
        this.createImage(files[0]);
      } else {
        this.error = this.$i18n.t("common.notImageFile");
      }
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedImage = e.target.result;
        this.$emit("change", this.uploadedImage);
      };
      reader.readAsDataURL(file);
    },
    checkDrag(event, key, status) {
      if (status && event.dataTransfer.types === "text/plain") {
        return false;
      }
      this.isDrag = status ? key : null;
    },
    handleResize() {
      if (window.innerWidth <= 1024) {
        this.isVisibleMessage = false;
      } else {
        this.isVisibleMessage = true;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.r-fileupload {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #f8d5cb;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.r-fileupload--drag {
  border: 1px solid #e87352;
  background-color: rgba(0, 0, 0, 0.3);
}
.r-fileupload--label {
  background-color: #e87352;
  color: #ffffff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  padding: 5px 15px;
}
.r-fileupload--label:hover {
  cursor: pointer;
  background-color: #e24f25;
  transition: 0.5s;
}
.r-fileupload--input {
  display: none;
}
.preview-item-file {
  width: 100%;
}
.r-fileupload--content {
  margin-top: 10px;
}
.error-message {
  color: #e85951;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .preview-item-file {
    width: 60%;
  }
}
@media screen and (min-width: 1024px) {
  .preview-item-file {
    width: 40%;
  }
  .r-fileupload--wrapper {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: auto;
  }
}
</style>

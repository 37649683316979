<template>
  <r-modal
    class="status-message"
    :title="$t('reciproCommon.statusMsgDetail.aboutEditStatusLock')"
    @cancel="cancel"
    :cancelLabel="$t('reciproCommon.statusMsgDetail.cancelLabel')"
  >
    <div class="status-message--content">
      <h4>● {{ $t("reciproCommon.statusMsgDetail.accountStatusOneUser") }}</h4>
      <p>
        {{ $t("reciproCommon.statusMsgDetail.reciproLogoutSecurityRule") }}
        <br />
        {{ $t("reciproCommon.statusMsgDetail.reciproLogoutPossibility") }}
        <br />
        <span class="unlock-button" @click="unLockRecipe">
          [{{ $t("reciproCommon.statusMsgDetail.unlockEditStatus") }}]
        </span>
        {{ $t("reciproCommon.statusMsgDetail.performUnlock") }}
      </p>
    </div>
    <hr />
    <div class="status-message--content">
      <h4>
        ● {{ $t("reciproCommon.statusMsgDetail.accountStatusMultiUser") }}
      </h4>
      <p>
        {{ $t("reciproCommon.statusMsgDetail.accountUsingOtherUser") }}
        <br />
        {{ $t("reciproCommon.statusMsgDetail.confirmEntireUser") }}
        <br />
        <span @click="unLockRecipe" class="unlock-button"
          >[{{ $t("reciproCommon.statusMsgDetail.unlockEditStatus") }}]</span
        >
        {{ $t("reciproCommon.statusMsgDetail.performUnlock") }}
      </p>
    </div>
  </r-modal>
</template>

<script>
import auth from "@/libs/Auth.js";

import RModal from "@/plugins/molecules/RModal.vue";

export default {
  components: {
    RModal,
  },
  methods: {
    async unLockRecipe() {
      // 編集を解除する
      await auth.unLockEditStatus();
      this.editRecipeID = "";
      alert("ロックを解除しました");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.status-message--content {
  text-align: left;
}
.unlock-button {
  color: #517ce8;
}
.unlock-button:hover {
  cursor: pointer;
}
</style>

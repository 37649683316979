<template>
  <!--セクションの名前入力-->
  <r-modal
    class="modal-content"
    :title="!changeSectionName ? title1 : title2"
    enter
    @enter="inputSectionName"
    @cancel="closeSectionName"
  >
    <div class="error-message">
      {{ error.sectionName }}
    </div>
    <r-textbox
      :placeholder="$t('menu.menuRegist.sectionCreateForm.snPlaceholder')"
      v-model="sectionName"
      :error="error.sectionName !== ''"
    />
  </r-modal>
</template>
<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";

export default {
  components: {
    RModal,
    RTextbox,
  },
  props: {
    changeSectionName: Boolean,
    sectionNameForChange: String,
  },
  data() {
    return {
      title1: this.$i18n.t("menu.menuRegist.sectionCreateForm.sectionName"),
      title2: this.$i18n.t(
        "menu.menuRegist.sectionCreateForm.sectionNameChange",
      ),
      sectionName: "",
      isSectionNameNotEmpty: false,
      error: {
        sectionName: "",
      },
    };
  },
  methods: {
    inputSectionName() {
      // 入力チェックの実行
      this.executeValidation();
      if (this.error.sectionName !== "") {
        return;
      }

      if (this.changeSectionName === true) {
        //セクション名の変更の場合
        this.$emit("sectionNameChanged", this.sectionName);
      } else {
        //セクションの生成
        this.$emit("sectionCreated", this.sectionName);
      }
    },
    closeSectionName() {
      this.$emit("cancel");
    },
    executeValidation() {
      if (this.sectionName === "") {
        this.$set(
          this.error,
          "sectionName",
          this.$i18n.t("menu.menuRegist.sectionCreateForm.sectionNameValidate"),
        );
      } else if (this.sectionName.length > 50) {
        this.$set(
          this.error,
          "sectionName",
          this.$i18n.t(
            "menu.menuRegist.sectionCreateForm.sectionNameValidation",
          ),
        );
      } else {
        this.$set(this.error, "sectionName", "");
      }
    },
  },
  created() {
    if (this.changeSectionName) {
      this.sectionName = this.sectionNameForChange;
    }
  },
};
</script>
<style scoped>
.input-name {
  border: 2px solid #eaeaea;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px;
  width: 95%;
  align-content: center;
}
/*モーダル関連*/
.error-message {
  text-align: left;
  color: #e85951;
  font-weight: bold;
}
.modal-content {
  position: relative;
  vertical-align: middle;
  width: 92%;
}
/*iPad用css*/
@media (min-width: 641px) {
  .modal-content {
    padding: 24px 35px 32px;
    width: 92%;
  }
  .input-name {
    font-size: 1.2rem;
    border: 2px solid #cdcdcd;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    line-height: 52px;
    height: 56px;
    margin: 16px 0px 16px 0px;
  }
}
/*PC用css*/
@media (min-width: 1024px) {
  .modal-content {
    padding: 24px 40px 32px;
    width: 80%;
  }
  .input-name {
    font-size: 1.2rem;
    border: 2px solid #cdcdcd;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    line-height: 52px;
    height: 56px;
    margin: 16px 0px 16px 0px;
  }
}
</style>

<template>
  <r-modal class="recipe-select-store" @cancel="close">
    <r-select :options="stores" @update="emitSelectedStore"> </r-select>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";

import auth from "@/libs/Auth.js";

export default {
  components: {
    RModal,
    RSelect,
  },
  data() {
    return {
      customerID: "",
      stores: {},
    };
  },
  methods: {
    async setStore() {
      if (this.customerID) {
        this.stores = await auth.getStoreList(this.customerID);
      }
    },
    emitSelectedStore(id) {
      this.$emit("selectStore", id);
    },
    close() {
      this.$emit("close");
    },
  },
  async created() {
    this.customerID = await auth.getCustomerID(this.$root);

    this.setStore();
  },
};
</script>

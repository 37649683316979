<template>
  <div
    class="r-paper"
    :class="[
      paperStyle ? 'r-paper--' + paperStyle : '',
      {
        'r-paper--round': round,
      },
    ]"
    @click="clickHandler"
  >
    <slot />
  </div>
</template>

<script>
export default {
  naem: "r-paper",
  props: {
    paperStyle: String,
    round: Boolean,
  },
  methods: {
    clickHandler(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style scoped>
.r-paper {
  padding: 15px;
  background-color: #ffffff;
  box-sizing: border-box;
}
.r-paper--pink {
  background-color: #f8d5cb;
}
.r-paper--yellow {
  background-color: #faf6ea;
}
.r-paper--gray {
  background-color: #f0f0f0;
}
.r-paper--round {
  border-radius: 10px;
}
</style>

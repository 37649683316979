<template>
  <r-card class="recipe-list-card" @click="clickHandler" tabindex="0">
    <!-- 画像あり -->
    <div
      class="recipe-list-card--image"
      :style="{ 'background-image': 'url(' + image + ')' }"
      v-if="image"
    >
      <div v-if="isCostOver" class="recipe-list-card--image--over">
        <p class="recipe-list-card--image--over--alert">
          !{{ $t("recipe.recipeList.targetCostRateIs") }}
          <br />
          {{ $t("recipe.recipeList.costOverNow") }}
        </p>
      </div>
    </div>

    <!-- 画像なし -->
    <div
      class="recipe-list-card--image recipe-list-card--image--default"
      v-else
    >
      <div v-if="isCostOver" class="recipe-list-card--image--over">
        <p class="recipe-list-card--image--over--alert">
          !{{ $t("recipe.recipeList.targetCostRateIs") }}
          <br />
          {{ $t("recipe.recipeList.costOverNow") }}
        </p>
      </div>
    </div>

    <span class="recipe-list-card--sub-recipe-label" v-if="isSubRecipe">
      {{ $t("common.subRecipe") }}
    </span>
    <div v-if="hidden" class="recipe-list-card--hidden">
      <img
        class="recipe-list-card--hidden--img"
        :src="hiddenRecipeImage"
        :alt="$t('recipe.recipeEdit.recipeStatusHidden')"
      />
    </div>

    <div class="recipe-list-card--content">
      <r-badge
        class="recipe-list-card--content--status"
        :class="{ 'content-status-isHeadquarter': isHeadquarter }"
        badgeStyle="secondary"
        badgeSize="large"
        shadow
        v-if="badgeIcon"
      >
        <font-awesome-icon :icon="['fas', `${badgeIcon}`]"></font-awesome-icon>
      </r-badge>
      <div class="recipe-list-card--content--title">{{ title }}</div>
      <r-tag class="recipe-list-card--content--category" :label="label" />
      <div v-if="isHeadquarter" class="store-id">{{ storeID }}</div>
    </div>
  </r-card>
</template>

<script>
import RCard from "@/plugins/atoms/RCard.vue";
import RTag from "@/plugins/atoms/RTag.vue";
import RBadge from "@/plugins/atoms/RBadge.vue";

export default {
  components: {
    RCard,
    RTag,
    RBadge,
  },
  props: {
    title: String,
    label: String,
    image: String,
    isSubRecipe: Boolean,
    // 原価率超えてるか(trueでcss変更)
    isCostOver: Boolean,
    hidden: Boolean,
    inputStatus: String,
    storeID: String,
    isHeadquarter: Boolean,
  },
  methods: {
    clickHandler(e) {
      this.$emit("click", e);
    },
  },
  computed: {
    badgeIcon() {
      if (this.inputStatus === "finish") {
        return "star";
      } else if (this.inputStatus === "cost") {
        return "calculator";
      } else if (this.inputStatus === "edit") {
        return "edit";
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      hiddenRecipeImage: require("../../../assets/images/icon2_lock.png"),
    };
  },
};
</script>

<style scoped>
.recipe-list-card {
  width: 100%;
  position: relative;
}
.recipe-list-card:hover {
  cursor: pointer;
}
.recipe-list-card--image {
  min-height: 30vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #f8d5cb;
}
.recipe-list-card--image--default {
  background-image: url(~@/assets/images/logo4.png);
  background-size: auto 15vh;
}
.recipe-list-card--image--over {
  height: 30vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.recipe-list-card--image--over--alert {
  font-weight: bold;
  color: #ffffff;
}
.recipe-list-card--content {
  text-align: left;
  padding: 20px 10px;
}
.recipe-list-card--content--status {
  position: absolute;
  right: 16px;
  bottom: 80px;
}
.recipe-list-card--content--title {
  font-weight: bold;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.recipe-list-card--content--category {
  display: inline-block;
  margin-top: 10px;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.recipe-list-card--sub-recipe-label {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  padding: 2px 15px 2px 10px;
  border-radius: 0 45px 45px 0;
  border: 1px solid #ffffff;
  border-left: none;
  font-weight: bold;
  text-align: left;
  background-color: #517ce8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  top: 15px;
  left: 0;
}
.recipe-list-card--hidden {
  position: absolute;
  background-color: #e85951;
  border-radius: 5px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px;
  top: 15px;
  right: 10px;
}
.recipe-list-card--hidden--img {
  width: 36px;
  height: auto;
}
.store-id {
  margin-top: 10px;
  color: #517ce8;
  font-weight: bold;
}
.content-status-isHeadquarter {
  bottom: 110px;
}
@media screen and (min-width: 641px) {
  .recipe-list-card:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 0.5s;
  }
  .recipe-list-card--image {
    min-height: 147px;
  }
  .recipe-list-card--image--over {
    height: 147px;
  }
  .recipe-list-card--image--default {
    background-size: auto 10vh;
  }
  .recipe-list-card--content {
    text-align: left;
    padding: 32px 16px 20px;
  }
  .recipe-list-card--content--status {
    bottom: 95px;
  }
  .content-status-isHeadquarter {
    top: 120px;
  }
}

@media (min-width: 1024px) {
  .recipe-list-card:hover {
    cursor: pointer;
    opacity: 0.8;
    transition: 0.5s;
  }
  .recipe-list-card--image {
    min-height: 147px;
  }
  .recipe-list-card--image--over {
    min-height: 147px;
  }
  .recipe-list-card--image--default {
    background-size: auto 10vh;
  }
  .recipe-list-card--content {
    text-align: left;
    padding: 32px 16px 20px;
  }
}
</style>

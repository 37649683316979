<template>
  <div
    class="r-balloon"
    :class="[balloonType ? 'r-balloon--' + balloonType : '']"
  >
    <button class="r-balloon--close-button" @click="clickHandler" v-if="close">
      <font-awesome-icon
        :icon="['fas', 'times']"
        class="fas fa-times"
      ></font-awesome-icon>
    </button>

    <div class="r-balloon--main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "r-balloon",
  methods: {
    clickHandler(e) {
      this.$emit("click", e);
    },
  },
  props: {
    balloonType: String,
    close: Boolean,
  },
};
</script>

<style scoped>
.r-balloon {
  position: relative;
  display: inline-block;
  padding: 16px;
  min-width: 120px;
  background-color: #ffffff;
  border: 2px solid #e87352;
  z-index: 3;
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.r-balloon:before {
  content: "";
  position: absolute;
  transform: rotate(-45deg);
  height: 22px;
  width: 22px;
  background: #ffffff;
  z-index: 3;
}
.r-balloon--top:before {
  border-right: 2px solid #e87352;
  border-top: 2px solid #e87352;
  top: -13px;
  right: 56px;
}
.r-balloon--bottom:before {
  border-left: 2px solid #e87352;
  border-bottom: 2px solid #e87352;
  bottom: -13px;
  right: 56px;
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.3);
}
.r-balloon--close-button {
  background-color: #ffffff;
  border: 2px solid #a8a8a8;
  display: inline-block;
  border-radius: 50%;
  font-size: 0.6rem;
  height: 24px;
  width: 24px;
  text-align: center;
  line-height: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.r-balloon--main {
  text-align: left;
}
</style>

<template>
  <r-spinner v-if="loading" />
  <div class="recipe-top" v-else>
    <!--body: レシピを作る-->
    <!-- 編集中の他ユーザーがいる場合のメッセージ -->
    <r-paper round class="recipe-edit-status" v-if="this.editRecipeID !== ''">
      {{ $t("recipe.recipeTop.someoneUseThisAccount") }}
      <div>
        <span class="recipe-edit-status--button" @click="openEditStatusDetail"
          >[{{ $t("common.details") }}]</span
        >
        <span @click="unLockRecipe" class="recipe-edit-status--button"
          >[{{ $t("recipe.recipeTop.unregistEditStatus") }}]</span
        >
      </div>
    </r-paper>

    <!--メインのボタン-->
    <div class="recipe-btn--wrapper">
      <!--レシピを作る-->
      <r-button
        v-if="userAuthority.createRp"
        class="recipe-btn"
        buttonStyle="primary"
        full
        @click="toRecipeRegistration"
      >
        <div class="button--inner">
          <img src="../../../assets/images/entry.png" alt="" />
          <div class="recipe-btn--label">
            <div class="recipe-btn--label-eng">
              {{ $t("recipe.recipeTop.createRecipe") }}
            </div>
            <div class="recipe-btn--label-kana">
              {{ $t("recipe.recipeTop.toRecipeRegistration") }}
            </div>
          </div>
        </div>
      </r-button>
      <!--レシピを見る-->
      <r-button
        class="recipe-btn"
        buttonStyle="primary"
        full
        @click="toRecipeList"
      >
        <div class="button--inner">
          <img src="../../../assets/images/view.png" alt="" />
          <div class="recipe-btn--label">
            <div class="recipe-btn--label-eng">
              {{ $t("recipe.recipeTop.viewRecipe") }}
            </div>
            <div class="recipe-btn--label-kana">
              {{ $t("recipe.recipeTop.toRecipeList") }}
            </div>
          </div>
        </div>
      </r-button>
    </div>

    <!-- 他のユーザがレシピを編集しているときについての説明モーダル -->
    <div v-if="isVisible" class="modal-bg" @click="closeEditStatusDetail">
      <status-message-detail class="modal" @cancel="closeEditStatusDetail" />
    </div>
  </div>
</template>
<script>
import RPaper from "@/plugins/atoms/RPaper.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import StatusMessageDetail from "@/components/common/StatusMessageDetail.vue";
import auth from "@/libs/Auth.js";

export default {
  components: {
    RPaper,
    RButton,
    RSpinner,
    StatusMessageDetail,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      editRecipeID: "",
      isVisible: false,
      userAuthority: {
        createRp: false,
      },
      loading: false,
    };
  },
  methods: {
    toRecipeRegistration() {
      this.$router.push({ name: "recipeRegistration" });
    },
    toRecipeList() {
      this.$router.push({ name: "recipeList" }, () => {});
    },
    async unLockRecipe() {
      // 編集を解除する
      await auth.unLockEditStatus();
      this.editRecipeID = "";
    },
    openEditStatusDetail() {
      this.isVisible = true;
    },
    closeEditStatusDetail() {
      this.isVisible = false;
    },
  },
  async created() {
    this.loading = true;

    try {
      this.customerID = await auth.getCustomerID(this.$root);
      if (!this.customerID) {
        throw new Error("Forbidden");
      }

      // 自分自身のアカウントで編集しているか
      this.editRecipeID = await auth.getEditingMyRecipe();

      const isStore = await auth.isStore();
      if (isStore) {
        // 店舗IDを取得
        this.storeID = await auth.getStoreID(this.$root);
        if (!this.storeID) {
          throw new Error("Forbidden");
        }
      }
      // レシピ作成の権限チェック
      const createRp = await auth.getCreateRp();
      this.$set(this.userAuthority, "createRp", createRp);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.recipe-top {
  font-size: 1rem;
  padding: 0 20px;
}
.recipe-edit-status {
  margin-top: 20px;
  clear: both;
  font-size: 0.9rem;
}
.recipe-edit-status--button {
  color: #517ce8;
}
.recipe-edit-status--button:hover {
  cursor: pointer;
}
.select-store-wrapper {
  margin-bottom: 16px;
  background-color: #fff;
  border-radius: 5px;
  display: block;
}
.select-store {
  padding: 10px;
  text-align: left;
}
.button--inner {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.recipe-btn--icon {
  line-height: 2;
  font-size: 2rem;
  margin-right: 5px;
}
.recipe-btn--label {
  text-align: left;
}
.recipe-btn--label-eng {
  font-family: "Candal";
  text-align: center;
}
.recipe-btn {
  margin-top: 20px;
  border-radius: 10px;
  font-weight: bold;
  padding: 20px 0;
}
.recipe-btn:hover {
  cursor: pointer;
  background-color: #ea5446;
  transition: all 0.5s ease;
}
.modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.modal {
  width: 90%;
}
@media screen and (min-width: 641px) {
  .recipe-top {
    padding-top: 40px;
  }
  .recipe-edit-status {
    font-size: 1rem;
  }
  .recipe-btn--label {
    margin-left: 20px;
  }
  .recipe-btn--label-eng {
    font-size: 24px;
  }
  .recipe-btn--label-kana {
    font-size: 24px;
  }
}
@media (min-width: 1024px) {
  .recipe-top {
    min-height: 100%;
    margin: auto;
    width: 1024px;
    padding-top: 40px;
  }
  .recipe-btn--wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .recipe-btn {
    border-radius: 50%;
    height: 350px;
    width: 350px;
    margin-right: 80px;
    font-size: 1.4rem;
  }
  .recipe-btn:nth-of-type(2n) {
    margin-right: unset;
  }
  .recipe-btn--label {
    margin-left: unset;
    margin-bottom: 20px;
  }
  .recipe-btn--label-kana {
    font-size: 31px;
  }
  .button--inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    box-sizing: border-box;
  }
}
</style>

<template>
  <!--book名前入力モーダル-->
  <r-modal
    :title="modalTitle"
    RHr="primary"
    class="modal-content"
    enter
    @enter="changeBookName"
    @cancel="closeModal"
  >
    <!--メニューブック名前入力-->
    <div class="error-message">{{ error.bookName }}</div>
    <!--メニューブック名前入力-->
    <r-textbox v-model="menuBook" :error="error.bookName !== ''" />
  </r-modal>
</template>
<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";

export default {
  components: {
    RModal,
    RTextbox,
  },
  props: {
    bookName: String,
  },
  data() {
    return {
      modalTitle: this.$i18n.t("menu.menuRegist.bookNameForm.modalTitle"),
      menuBook: "",
      disable: true,
      isBookNameNotInputed: false,
      error: {
        bookName: "",
      },
    };
  },
  methods: {
    changeBookName() {
      this.executeValidation();
      if (this.error.bookName !== "") {
        return;
      } else {
        this.$emit("bookNameChanged", this.menuBook);
      }
    },
    closeModal() {
      // 条件見直し
      if (!this.isInputForm) {
        this.$emit("cancel");
      }
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    executeValidation() {
      if (this.menuBook === "") {
        this.$set(
          this.error,
          "bookName",
          this.$i18n.t("menu.menuRegist.bookNameForm.bookNameValidate"),
        );
      } else if (this.menuBook.length > 50) {
        this.$set(
          this.error,
          "bookName",
          this.$i18n.t("menu.menuTop.bookSelectForm.bookNameLengthValidation"),
        );
      } else {
        this.$set(this.error, "bookName", "");
      }
    },
  },
  created() {
    this.menuBook = this.bookName;
  },
  //PCサイズ対応
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style scoped>
.modal-content {
  width: 92%;
}
.book-selector {
  border: 2px solid #e87352;
  border-radius: 4px;
  margin-bottom: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px;
}
.error-message {
  text-align: left;
  color: #e85951;
  font-weight: bold;
}

/*iPad用css*/
@media (min-width: 641px) {
  .modal-content {
    padding: 24px 35px 32px;
    width: 92%;
  }
  .input-name {
    font-size: 1.2rem;
    border: 2px solid #cdcdcd;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    line-height: 52px;
    height: 56px;
    margin: 16px 0px 16px 0px;
  }
}
/*PC用css*/
@media (min-width: 1024px) {
  .modal-content {
    width: 80%;
  }
  .input-name {
    font-size: 1.2rem;
    border: 2px solid #cdcdcd;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    line-height: 52px;
    height: 56px;
    margin: 16px 0px 16px 0px;
  }
}
</style>

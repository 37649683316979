<template>
  <r-modal
    :title="$t('recipe.recipeCost.costRateChangeBelow')"
    enter
    enterLabel="OK"
    @enter="closeModal"
    :cancelHidden="cancel"
    @cancel="cancelHandler"
  >
    <div class="cost-update--main--content" v-if="!price">
      <span class="main--content--main--before"
        >{{ $t("recipe.recipeCost.costCurrentlyRegistered") }} :
        {{ exCost | ZndDecimalPlace }}{{ $t("common.currencyYen") }}</span
      >
      <font-awesome-icon
        :icon="['fas', 'chevron-down']"
        class="recipe-cost--paper--main--content"
      ></font-awesome-icon>
      <span class="main--content--alter">
        <span class="main--content--alter--label">
          {{ $t("common.afterChange") }}&nbsp;:&nbsp;
        </span>
        <span class="main--content--alter--value">
          {{ cost | ZndDecimalPlace }}{{ $t("common.currencyYen") }}
        </span>
      </span>
    </div>

    <div class="cost-update--main--content">
      <span class="main--content--main--before">
        {{ $t("common.costRateCurrentlyRegistered") }} :
        {{ exCostRate | ZndDecimalPlace }}%
      </span>

      <font-awesome-icon
        :icon="['fas', 'chevron-down']"
        class="recipe-cost--paper--main--content fa-chevron-down"
      ></font-awesome-icon>
      <span class="main--content--alter">
        <span class="main--content--alter--label">
          {{ $t("common.afterChange") }}&nbsp;:&nbsp;
        </span>
        <span class="main--content--alter--value">
          {{ costRate | ZndDecimalPlace }}%
        </span>
      </span>
    </div>

    <div v-if="isDisplayDeletedItems" class="deleted-items">
      <span class="deleted-items--title">{{
        $t("recipe.recipeCost.deleteFoodstuff")
      }}</span>
      <div
        class="deleted-items--item"
        v-for="(item, index) in deletedItems"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";

export default {
  components: {
    RModal,
  },
  props: {
    cancel: Boolean,
    exCost: Number,
    exCostRate: Number,
    cost: Number,
    costRate: Number,
    deletedItems: Array,
    price: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit("enter");
    },
    cancelHandler() {
      this.$emit("cancel");
    },
  },
  computed: {
    isDisplayDeletedItems() {
      if (this.deletedItems) {
        return this.deletedItems.length > 0;
      }
      return false;
    },
  },
  filters: {
    ZndDecimalPlace(val) {
      return val.toFixed(2);
    },
  },
};
</script>

<style scoped>
.cost-update--main--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.fa-chevron-down {
  margin-right: 10px;
  font-size: 2.4rem;
  color: #e87352;
}
.main--content--alter--label {
  font-size: 18px;
  font-weight: bold;
}
.main--content--alter--value {
  font-size: 24px;
  font-weight: bold;
  color: #517ce8;
}
.main--content--main--before {
  margin-right: 10px;
}
.main--content--alter {
  display: flex;
  align-items: center;
}

.deleted-items {
  text-align: left;
}
.deleted-items--title {
  color: #e87352;
  font-weight: bold;
}
@media screen and (min-width: 641px) {
  .cost-update--main--content {
    flex-direction: inherit;
    justify-content: center;
    align-items: center;
  }
  .fa-chevron-down {
    transform: rotate(-90deg);
  }
  .main--content--alter--label {
    font-size: 20px;
  }
  .main--content--alter--value {
    font-size: 36px;
  }
}

@media screen and (min-width: 1024px) {
  .main--content--alter--label {
    font-size: 24px;
  }
  .main--content--alter--value {
    font-size: 48px;
  }
}
</style>

<template>
  <div class="food-stuff-search-list">
    <!-- 商品名と一致 -->
    <r-heading :level="4"
      >{{ $t("recipe.recipeFoodstuffSearchForm.matchingWithItemName") }}({{
        numberOfItems
      }}{{ $t("common.case") }})</r-heading
    >
    <div class="food-stuff-search-list--wrapper">
      <div
        class="food-stuff-search-list--item"
        v-for="(item, index) in filteredSearchedItems"
        :key="`item-${index}`"
      >
        <r-food-stuff
          :label="item.name"
          :tag="item.ss"
          :caption="item.supplier"
          @click="select(item.name)"
        />
      </div>
    </div>

    <!-- 小分類名と一致 -->
    <r-heading :level="4"
      >{{
        $t("recipe.recipeFoodstuffSearchForm.matchingWithSmallClassification")
      }}({{ numberOfSsItems }}{{ $t("common.case") }})</r-heading
    >
    <span @click="isShowSsItems = true">{{
      $t("recipe.recipeFoodstuffSearchForm.showEveryFiftyCase")
    }}</span>
    <div class="food-stuff-search-list--wrapper">
      <div
        class="food-stuff-search-list--item"
        v-for="(item, index) in filteredSearchedSsItems"
        :key="`ssItem-${index}`"
      >
        <r-food-stuff
          :label="item.name"
          :tag="item.ss"
          :caption="item.supplier"
          @click="select(item.name)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RFoodStuff from "@/plugins/molecules/RFoodStuffCard.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";

export default {
  components: {
    RFoodStuff,
    RHeading,
  },
  props: {
    searchedItems: {
      type: Array,
      default: () => [],
    },
    searchedSsItems: {
      type: Array,
      default: () => [],
    },
    selectedFoodstuffs: Array,
  },
  data() {
    return {
      isShowSsItems: false,
    };
  },
  computed: {
    numberOfItems() {
      return this.filteredSearchedItems.length;
    },
    numberOfSsItems() {
      return this.filteredSearchedSsItems.length;
    },
    filteredSearchedItems() {
      return this.searchedItems.reduce((acc, item) => {
        if (!this.selectedFoodstuffs.map((e) => e.name).includes(item.name)) {
          acc.push(item);
        }
        return acc;
      }, []);
    },
    filteredSearchedSsItems() {
      return this.searchedSsItems.reduce((acc, item) => {
        if (!this.selectedFoodstuffs.map((e) => e.name).includes(item.name)) {
          acc.push(item);
        }
        return acc;
      }, []);
    },
  },
  methods: {
    select(name) {
      this.$emit("select", name);
    },
  },
};
</script>

<style scoped>
.food-stuff-search-list {
  height: 300px;
  overflow: auto;
  text-align: left;
}
.food-stuff-search-list--wrapper {
  display: flex;
  flex-wrap: wrap;
}
.food-stuff-search-list--item {
  margin-top: 20px;
  width: 100%;
}

@media screen and (min-width: 641px) {
  .food-stuff-search-list--item {
    width: 45%;
    box-sizing: border-box;
    margin-right: 20px;
    margin-top: 20px;
  }
  .food-stuff-search-list--item:nth-child(2n) {
    margin-right: 0;
  }
}
</style>

<template>
  <div
    class="r-badge"
    :class="[
      badgeSize ? 'r-badge-' + badgeSize : '',
      badgeStyle ? 'r-badge-' + badgeStyle : '',
      {
        'r-badge-shadow': shadow,
        'r-badge-outline': outline,
      },
    ]"
    :style="{
      'background-color': customBackground,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "r-badge",
  props: {
    badgeSize: String,
    badgeStyle: String,
    customBackground: String,
    shadow: Boolean,
    outline: Boolean,
  },
};
</script>

<style scoped>
.r-badge {
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
}
.r-badge-primary {
  background-color: #ea5446;
}
.r-badge-secondary {
  background-color: #517ce8;
}
.r-badge-small {
  font-size: 10px;
  line-height: 20px;
  width: 20px;
  height: 20px;
}
.r-badge-middle {
  height: 36px;
  width: 36px;
  line-height: 36px;
}
.r-badge-large {
  height: 48px;
  width: 48px;
  line-height: 48px;
  font-weight: bold;
}
.r-badge-shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.r-badge-outline {
  border: 2px solid #fff;
}
</style>

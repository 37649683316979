<!-- 作り方 -->
<template>
  <r-modal
    :title="
      $t('recipe.recipeProcedure.step') +
      `${step}` +
      $t('recipe.recipeProcedure.register')
    "
    class="recipe-procedure-form"
    enter
    @enter="enter"
    @cancel="cancel"
  >
    <div class="recipe-procedure-form--main--wrapper">
      <div class="recipe-procedure-form--main--left">
        <r-modal-sub-header
          :title="$t('recipe.recipeProcedure.selectImage')"
          class="recipe-procedure-form--content recipe-procedure-form--image"
        >
          <r-fileupload
            @change="changeImage"
            :image="image"
            ref="fileupload"
            class="recipe-procedure-fileupload"
            >{{ $t("recipe.recipeProcedure.selectFile") }}</r-fileupload
          >
        </r-modal-sub-header>
        <!--編集ボタン-->
        <div class="recipe-procedure-form--edit--buttons">
          <label
            class="r-fileupload--label r-fileupload--content"
            for="file"
            v-if="image !== ''"
          >
            {{ $t("recipe.recipeProcedure.updateImage") }}
            <input
              id="file"
              class="r-fileupload--input"
              type="file"
              @change="onFileChange"
            />
          </label>
          <r-button
            class="recipe-procedure-form--edit--button"
            buttonStyle="primary"
            outline
            full
            @click="deleteImage"
            v-if="image !== ''"
            >{{ $t("recipe.recipeProcedure.deleteImage") }}</r-button
          >
        </div>
        <r-modal-sub-header
          :title="
            $t('recipe.recipeProcedure.subTitle1') +
            `${step}` +
            $t('recipe.recipeProcedure.subTitle2')
          "
          class="recipe-procedure-form--content"
        >
          <textarea
            name="procedure"
            cols="30"
            rows="10"
            class="recipe-procedure-form--content--textarea"
            :class="{
              'recipe-procedure-form--content--textarea--error':
                error.text !== '',
            }"
            v-model="text"
          ></textarea>

          <div class="error-message">{{ error.text }}</div>
        </r-modal-sub-header>
      </div>
      <r-paper
        class="recipe-procedure--form--main--right"
        round
        paperStyle="gray"
      >
        <r-list>
          <r-list-content v-for="(item, index) in items" :key="index">
            <r-list-item>{{ item.name }}</r-list-item>
          </r-list-content>
        </r-list>
      </r-paper>
    </div>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RFileupload from "@/plugins/atoms/RFileupload.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RButton from "@/plugins/atoms/RButton.vue";

export default {
  data() {
    return {
      text: "",
      image: "",
      error: {
        text: "",
      },
    };
  },
  props: {
    step: Number,
    items: {
      type: Array,
      default: () => [],
    },
    tmpText: {
      type: String,
      default: "",
    },
    tmpImg: {
      type: String,
      default: "",
    },
  },
  components: {
    RModal,
    RModalSubHeader,
    RFileupload,
    RPaper,
    RList,
    RListContent,
    RListItem,
    RButton,
  },
  methods: {
    enter() {
      if (this.text === "") {
        this.$set(
          this.error,
          "text",
          this.$i18n.t("recipe.recipeProcedure.recipeProcedureIsNotInputed"),
        );
        return;
      }

      this.$emit("enter", this.step - 1, this.text, this.image);
    },
    cancel() {
      this.$emit("cancel");
    },
    changeImage(value) {
      this.image = value;
    },
    deleteImage() {
      this.image = "";
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      const fileTypes = files[0].name.split(".");
      const len = fileTypes.length;
      if (len === 0) {
        return;
      }

      // ファイルサイズチェック
      const fileSize = files[0].size;
      if (fileSize > 4e6) {
        this.error = this.$i18n.t("recipe.recipeProcedure.fileSizeBig");
        return;
      }

      // 拡張子チェック
      const extension = fileTypes[len - 1];

      const lowerExtension = extension.toLowerCase();
      if (
        lowerExtension === "jpg" ||
        lowerExtension === "jpeg" ||
        lowerExtension === "png" ||
        lowerExtension === "gif"
      ) {
        this.createImage(files[0]);
      } else {
        this.error = this.$i18n.t("recipe.recipeProcedure.notImageFileAlert");
      }
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.uploadedImage = e.target.result;
        this.changeImage(this.uploadedImage);
      };
      reader.readAsDataURL(file);
    },
  },
  created() {
    this.text = this.tmpText;
    this.image = this.tmpImg;
  },
};
</script>

<style scoped>
.recipe-procedure-form {
  width: 80%;
  overflow-y: scroll;
}
.recipe-procedure-form--content--textarea {
  border: 2px solid #ebebe4;
  width: 100%;
  font-size: 16px;
  color: #2c3e50;
}
.recipe-procedure-form--content--textarea--error {
  border: 2px solid #e85951;
}
.recipe-procedure-form--edit--buttons {
  display: flex;
  margin-top: 20px;
  box-sizing: border-box;
}
.recipe-procedure-form--edit--button {
  width: 50%;
}
.recipe-procedure-form--edit--button:last-child {
  margin-right: unset;
}
.recipe-procedure-fileupload {
  height: auto;
}
.recipe-procedure-fileupload >>> .error-message {
  display: block;
  margin-bottom: 20px;
}
.r-fileupload--label {
  background-color: #ffffff;
  color: #e87352;
  border: 2px solid #e87352;
  border-radius: 5px;
  width: 50%;
  font-size: 1rem;
  font-weight: bold;
  padding: 5px;
  margin-right: 20px;
  box-sizing: border-box;
}
.r-fileupload--label:hover {
  cursor: pointer;
  background-color: #e87352;
  color: #ffffff;
  transition: 0.5s;
}
.r-fileupload--input {
  display: none;
}
.error-message {
  color: #e85951;
  font-weight: bold;
}

@media screen and (min-width: 641px) {
  .recipe-procedure-form {
    width: 80%;
  }
  .recipe-procedure-form--main--wrapper {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
  }
  .recipe-procedure-form--main--left {
    width: 60%;
    margin-right: 20px;
    box-sizing: border-box;
  }
  .recipe-procedure--form--main--right {
    width: 40%;
  }
  .recipe-procedure-fileupload >>> .error-message {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .recipe-procedure-form {
    width: 60%;
  }
  .recipe-procedure-form--main--wrapper {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    overflow: auto;
    height: 100%;
  }
  .recipe-procedure-fileupload {
    min-height: 20vh;
    height: auto;
  }
  .recipe-procedure-fileupload >>> .r-fileupload--wrapper {
    width: 80%;
    display: flex;
  }
  .recipe-procedure-fileupload >>> .r-fileupload--label {
    width: 40%;
    margin: auto;
  }
  .recipe-procedure-form--main--left {
    width: 60%;
    margin-right: 20px;
  }
  .recipe-procedure--form--main--right {
    width: 40%;
  }
  .recipe-procedure-form--image >>> .r-fileupload {
    height: 200px;
  }
}
</style>

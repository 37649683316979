<template>
  <div>
    <r-heading :level="1" :image="headingImage">
      <!-- 大見出し -->
      <div class="heading-1">
        <span v-text="recipe.name"></span>
        <span class="heading-2" v-if="recipe.isSubRecipe"
          >【{{ $t("common.subRecipe") }}】</span
        >
      </div>
    </r-heading>
    <div class="recipe-detail">
      <!-- 一覧へ戻ると編集ボタン -->
      <div class="recipe-detail--buttons">
        <router-link to="/recipe-list">
          <r-button buttonStyle="primary" outline>
            {{ $t("recipe.recipeDetail.backToRecipeList") }}
          </r-button>
        </router-link>
        <r-button
          buttonStyle="primary"
          outline
          @click="toggleIsShowEditMenu"
          :disabled="
            editingRecipeID !== '' || priceUpdateStatus || editingSelfCheck
          "
          v-if="userAuthority.createRp"
          >{{ $t("recipe.recipeDetail.editRecipe") }}</r-button
        >
      </div>

      <r-paper round v-if="editingRecipeID !== ''" class="lock-edit-msg">
        {{ $t("recipe.recipeDetail.lockEditMessage1") }}
        {{ editingName }}
        {{ $t("recipe.recipeDetail.lockEditMessage2") }}
      </r-paper>

      <!-- 編集メニュー -->
      <transition name="modal">
        <r-balloon
          balloonType="top"
          class="recipe-detail--edit-menu"
          v-if="isShowEditMenu"
        >
          <r-list>
            <r-list-content
              clickable
              hoverEffectOff
              @click="editRecipe"
              class="recipe-detail--edit-btn"
            >
              <r-list-item class="recipe-detail--edit-btn-item-list">
                <img class="edit-menu--icon" :src="editImage" alt />
                <span class="edit-menu-name"
                  >&nbsp;{{ $t("recipe.recipeDetail.editRecipeName") }}</span
                >
              </r-list-item>
            </r-list-content>
            <r-list-content
              clickable
              hoverEffectOff
              @click="calcCosts"
              class="recipe-detail--edit-btn"
            >
              <r-list-item class="recipe-detail--edit-btn-item-list">
                <img class="edit-menu--icon" :src="costImage" alt />
                <span class="edit-menu-name" v-if="!hasRecipeCost"
                  >&nbsp;{{ $t("recipe.recipeDetail.calculateCostRate") }}</span
                >
                <span class="edit-menu-name" v-else
                  >&nbsp;{{
                    $t("recipe.recipeDetail.reCalculateCostRate")
                  }}</span
                >
              </r-list-item>
            </r-list-content>
            <r-list-content
              clickable
              hoverEffectOff
              @click="createTutorials"
              class="recipe-detail--edit-btn"
            >
              <r-list-item class="recipe-detail--edit-btn-item-list">
                <img class="edit-menu--icon" :src="recipeImage" alt />
                <span class="edit-menu-name" v-if="!hasRecipeTutorials"
                  >&nbsp;{{ $t("recipe.recipeDetail.toRecipeProcedure") }}</span
                >
                <span class="edit-menu-name" v-else
                  >&nbsp;{{
                    $t("recipe.recipeDetail.toRecipeProcedureEdit")
                  }}</span
                >
              </r-list-item>
            </r-list-content>
            <r-list-content
              clickable
              hoverEffectOff
              @click="clickCopyRecipe"
              class="recipe-detail--edit-btn"
            >
              <r-list-item class="recipe-detail--edit-btn-item-list">
                <img class="edit-menu--icon" :src="copyImage" alt />
                <span class="edit-menu-name"
                  >&nbsp;{{ $t("recipe.recipeDetail.copyRecipe") }}</span
                >
              </r-list-item>
            </r-list-content>
            <r-list-content
              hoverEffectOff
              clickable
              @click="deleteRecipe"
              class="recipe-detail--edit-btn"
            >
              <r-list-item class="recipe-detail--edit-btn-item-list">
                <img class="edit-menu--icon" :src="trashImage" alt />
                <span class="edit-menu-name"
                  >&nbsp;{{ $t("recipe.recipeDetail.deleteRecipe") }}</span
                >
              </r-list-item>
            </r-list-content>
          </r-list>
        </r-balloon>
      </transition>
      <div
        class="recipe-detail--edit-menu--bg"
        v-if="isShowEditMenu"
        @click="isShowEditMenu = false"
      ></div>

      <!-- アラート -->
      <r-alert v-if="alertMessage">{{ $t(alertMessage) }}</r-alert>

      <!-- 原価計算と作り方 -->
      <r-paper
        round
        class="recipe-detail--alert"
        v-if="(!hasRecipeCost || !hasRecipeTutorials) && userAuthority.createRp"
      >
        <ul class="recipe-detail--alert--item recipe-detail--alert--text">
          <li v-if="!hasRecipeCost">
            {{ $t("recipe.recipeDetail.costRateNotCalculated") }}
          </li>
          <li v-if="!hasRecipeTutorials">
            {{ $t("recipe.recipeDetail.noRecipeProcedure") }}
          </li>
        </ul>
        <r-button
          v-if="!hasRecipeCost"
          buttonStyle="primary"
          outline
          full
          :disabled="
            editingRecipeID !== '' || priceUpdateStatus || editingSelfCheck
          "
          class="recipe-detail--alert--item recipe-detail--alert--button recipe-detail--alert--button--left"
          @click="calcCosts"
          >{{ $t("recipe.recipeDetail.registRecipeCost") }}</r-button
        >
        <r-button
          v-if="!hasRecipeTutorials"
          buttonStyle="primary"
          outline
          full
          :disabled="
            editingRecipeID !== '' || priceUpdateStatus || editingSelfCheck
          "
          class="recipe-detail--alert--item recipe-detail--alert--button"
          @click="createTutorials"
          >{{ $t("recipe.recipeDetail.registRecipeProcedure") }}</r-button
        >
      </r-paper>
      <div class="recipe-detail--main">
        <!-- 3カラム左 -->
        <div class="recipe-detail--main--left">
          <img
            class="recipe-detail--main--image recipe-detail--main--content"
            :src="image"
            v-if="image"
          />
          <div
            class="recipe-detail--main--image--default recipe-detail--main--content"
            v-else
          ></div>

          <div
            class="recipe-detail--main--content recipe-detail--main--store-name"
          >
            <span>{{ storeName }}</span>
          </div>

          <r-paper
            round
            class="recipe-detail--main--content recipe-detail--main--category"
          >
            <span
              class="recipe-detail--main--category--item"
              v-text="recipe.cat1"
            />
            <span
              class="recipe-detail--main--category--item"
              v-text="recipe.cat2"
              v-if="recipe.cat2"
            />
            <span
              class="recipe-detail--main--category--item"
              v-text="recipe.cat3"
              v-if="recipe.cat3"
            />
          </r-paper>
          <div class="recipe-detail--main--sub-recipe-button--wrapper">
            <r-balloon
              class="recipe-detail--main--sub-recipe-button--wrapper--balloon"
              v-if="isShowBalloonSubRecipeButton"
              @click="closeBalloon"
              balloonType="bottom"
              close
            >
              <p v-if="subRecipeAlert === 0">
                <span>
                  {{ $t("recipe.recipeDetail.subRecipeAlert1") }}
                  <br />
                  {{ $t("recipe.recipeDetail.subRecipeAlert2") }}
                </span>
              </p>
              <div v-else-if="subRecipeAlert === 1">
                <p>
                  !{{ $t("recipe.recipeDetail.recipeCostAlert1") }}
                  <br />
                  {{ $t("recipe.recipeDetail.recipeCostAlert2") }}
                </p>
                <r-button buttonStyle="primary" @click="calcCosts">
                  {{ $t("recipe.recipeDetail.calculateRecipeCost") }}
                </r-button>
              </div>
            </r-balloon>
            <!-- サブレシピ登録 -->
            <r-button
              class="recipe-detail--main--content recipe-detail--main--sub-recipe-button"
              buttonStyle="primary"
              outline
              full
              round
              :disabled="
                editingRecipeID !== '' || priceUpdateStatus || editingSelfCheck
              "
              @click="registerAsSubRecipe"
              v-if="!recipe.isSubRecipe && userAuthority.createRp"
              >{{ $t("recipe.recipeDetail.registerAsSubRecipe") }}</r-button
            >
            <!-- サブレシピ解除 -->
            <r-button
              class="recipe-detail--main--content recipe-detail--main--sub-recipe-button"
              buttonStyle="primary"
              outline
              full
              round
              v-else-if="recipe.isSubRecipe && userAuthority.createRp"
              :disabled="
                editingRecipeID !== '' || priceUpdateStatus || editingSelfCheck
              "
              @click="unregisterSubRecipe"
              >{{ $t("recipe.recipeDetail.unregisterSubRecipe") }}</r-button
            >
            <!--////////////////-->
          </div>
        </div>

        <!-- 食材リスト -->
        <!-- 3カラム 真ん中 -->
        <div
          class="recipe-detail--main--content recipe-detail--main--foodstuff--allergy"
        >
          <r-paper
            round
            class="recipe-detail--main--content recipe-detail--main--foodstuff"
          >
            <r-list>
              <r-list-header>
                {{ $t("common.foodstuff") }}({{ recipe.serv
                }}{{ $t("common.perPerson") }})
              </r-list-header>
              <!-- サブレシピ以外用のリスト -->
              <r-list-content
                class="recipe-detail--main--content--food-list"
                v-for="(item, index) in sortedRecipeItems"
                :key="index"
                :sub="item.ref !== null && (!item.hidden || viewHidden)"
                clickable
                hoverEffectOff
                @click="toggleRecipeBalloon(index)"
              >
                <r-list-item>
                  <span
                    class="recipe-detail--main--content recipe-detail--main--foodstuff--user"
                    v-if="item.ingredientID === '' && item.ref === null"
                    >【U】</span
                  >
                  <img
                    v-else-if="item.ref !== null && item.hidden && !viewHidden"
                    :src="lockImage"
                    alt
                    class="lock-icon"
                  />
                  {{ item.name }}
                </r-list-item>
                <r-list-item>{{ item.amount }}{{ item.unit }}</r-list-item>
                <div
                  slot="sub"
                  class="recipe-detail--main--foodstuff--sub"
                  v-if="item.ref !== null && (!item.hidden || viewHidden)"
                >
                  <router-link
                    :to="{
                      path: `/recipe-detail/${item.subItems?.[0]?.recipeID}`,
                      query: {
                        storeID: storeID,
                      },
                    }"
                    target="_blank"
                  >
                    <span class="recipe-detail--main--foodstuff--sub--left"
                      >【{{ $t("common.details") }}】</span
                    >
                  </router-link>
                  <r-list class="recipe-detail--main--foodstuff--sub--right">
                    <r-list-content
                      v-for="(subItem, index) in item.subItems"
                      :key="`subItem-${index}`"
                    >
                      <r-list-item class="list-sub1">
                        {{ subItem.name }}
                      </r-list-item>
                      <r-list-item class="list-sub2"
                        >{{ subItem.amount }}{{ subItem.unit }}</r-list-item
                      >
                    </r-list-content>
                  </r-list>
                </div>
                <!-- 食材詳細バルーン -->
                <r-balloon
                  balloonType="top"
                  class="recipe-detail--main--content--food-list--balloon"
                  v-if="recipeBalloonArray?.[index] && item.ref === null"
                >
                  <!-- バルーン内(通常の食材) -->
                  <div v-if="item.ingredientID !== ''">
                    <div
                      class="redipe-detail--main--content--food-list--balloon--ss"
                    >
                      {{ item.ss }}
                    </div>
                    <div>
                      [{{ $t("config.supplier") }}]{{
                        supplierName(item.ingredientID)
                      }}
                    </div>
                    <div>
                      [{{ $t("recipe.recipeDetail.standard") }} ×
                      {{ $t("recipe.recipeDetail.quantity") }}]{{
                        item.standard
                      }}
                      × {{ item.quantity }}
                    </div>
                    <div>
                      [{{ $t("recipe.recipeCost.price") }}]{{
                        $t("common.currency")
                      }}{{ item.price }}
                    </div>
                  </div>
                  <!-- バルーン内(ユーザー入力食材用) -->
                  <div v-else>
                    <div>{{ $t("recipe.recipeDetail.ownCompanyIng") }}</div>
                    <div>
                      [{{ $t("recipe.recipeCost.price") }}]{{
                        $t("common.currency")
                      }}{{ item.price }}
                    </div>
                  </div>
                </r-balloon>
              </r-list-content>
            </r-list>
          </r-paper>

          <r-paper
            round
            class="recipe-detail--main--content recipe-detail--main--allergies"
          >
            <r-list>
              <r-list-header>
                {{ $t("recipe.allergyItem.allergy") }}
              </r-list-header>
              <!-- 7品目 -->
              <div class="recipe-detail--main--allergies--items--wrapper">
                <r-heading :level="4">{{
                  $t("recipe.allergyItem.sevenItems")
                }}</r-heading>
                <template v-if="hasAllergySeven">
                  <span
                    v-for="(allergy, index) in recipe.allergy.sevenItems"
                    :key="`7-${index}`"
                    class="recipe-detail--main--allergies--items"
                  >
                    {{
                      $t("recipe.allergyItem." + convertToAllergyCode(allergy))
                    }}
                  </span>
                </template>
                <span v-else>{{
                  $t("recipe.recipeDetail.allergyNotRegistered")
                }}</span>
              </div>
              <!-- 20品目 -->
              <div
                v-if="allergyTwenty"
                class="recipe-detail--main--allergies--items--wrapper"
              >
                <r-heading :level="4">{{
                  $t("recipe.allergyItem.twentyItems")
                }}</r-heading>
                <template v-if="hasAllergyTwenty">
                  <span
                    v-for="(allergy, index) in recipe.allergy.twentyItems"
                    :key="`20-${index}`"
                    class="recipe-detail--main--allergies--items"
                  >
                    {{
                      $t("recipe.allergyItem." + convertToAllergyCode(allergy))
                    }}
                  </span>
                </template>
                <span v-else>{{
                  $t("recipe.recipeDetail.allergyNotRegistered")
                }}</span>
              </div>
            </r-list>
          </r-paper>
        </div>

        <!-- 3カラム 右 -->
        <r-paper
          round
          class="recipe-detail--main--content recipe-detail--main--cost-rate"
        >
          <r-list>
            <r-list-header v-if="userAuthority.viewCost">
              {{ $t("common.costRate") }}
            </r-list-header>
            <r-chart
              chartStyle="secondary"
              :label="$t('common.costRate')"
              :rate="recipe.costRate"
              :pastCostRate="recipe.pastCostRate"
              class="recipe-detail--main--cost-rate--chart"
              v-if="hasRecipeCost && userAuthority.viewCost"
            />
            <div class="recipe-detail--main--cost-rate--list-items">
              <r-list-content v-if="userAuthority.viewCost">
                <r-list-item>
                  {{ $t("common.cost") }} :
                  <span v-if="hasRecipeCost">
                    {{ recipe.costPerServ | localNumber
                    }}{{ $t("common.currencyYen") }}
                  </span>
                  <span v-else>{{
                    $t("recipe.recipeDetail.notRegisterd")
                  }}</span>
                </r-list-item>
              </r-list-content>
              <r-list-content v-if="userAuthority.viewCost">
                <r-list-item>
                  {{ $t("common.price") }} :
                  <span v-if="hasRecipeCost">
                    {{ recipe.price | localNumber
                    }}{{ $t("common.currencyYen") }}
                  </span>
                  <span v-else>{{
                    $t("recipe.recipeDetail.notRegisterd")
                  }}</span>
                </r-list-item>
              </r-list-content>
              <r-list-content v-if="userAuthority.viewCost">
                <r-list-item>
                  {{ $t("recipe.recipeDetail.targetRate") }} :
                  <span v-if="hasRecipeCost"
                    >{{ recipe.targetRate | localNumber }}%</span
                  >
                  <span v-else>{{
                    $t("recipe.recipeDetail.notRegisterd")
                  }}</span>
                </r-list-item>
              </r-list-content>
              <r-list-content>
                <r-list-item>
                  {{ $t("recipe.recipeDetail.recipeCreateUser") }} :
                  {{ recipe.createUser }}
                </r-list-item>
              </r-list-content>
            </div>
          </r-list>
        </r-paper>
      </div>

      <!-- 3カラムの下 -->
      <r-paper
        round
        class="recipe-detail--main--content recipe-detail--main--how-to"
        v-if="hasRecipeTutorials"
      >
        <r-list>
          <r-list-header>
            {{ $t("recipe.recipeDetail.recipeProcedure") }}
          </r-list-header>
          <div class="recipe-detail--main--how-to--item--wrapper">
            <div
              class="recipe-detail--main--how-to--item"
              v-for="(tutorial, index) in sortedTutorials"
              :key="index"
            >
              <span class="recipe-detail--main--how-to--step"
                >STEP{{ tutorial.step }}</span
              >
              <div
                class="recipe-detail--main--how-to--image"
                :style="{ 'background-image': 'url(' + tutorial.image + ')' }"
                v-if="tutorial.image != ''"
              />
              <div v-else class="recipe-detial--main--how-to--image-nofile" />
              <p
                class="recipe-detail--main--how-to--text"
                v-html="replaceNtoBr(tutorial.text)"
              ></p>
            </div>
          </div>
        </r-list>
      </r-paper>

      <!-- モーダル -->
      <transition name="modal">
        <div
          class="modal-bg"
          v-if="isShowSubRecipeModal"
          @mousedown.self="modalClose()"
        >
          <recipe-sub-recipe-register-form
            class="modal"
            :id="recipeID"
            :items="recipe.items"
            :totalCost="recipe.totalCost"
            v-if="isShowSubRecipeModal"
            @close="modalClose()"
            @subRecipeRegistrationComplete="subRecipeRegistrationConfirm"
          />
        </div>
      </transition>

      <transition name="modal">
        <div
          class="modal-bg"
          v-if="isShowCopyModal"
          @mousedown.self="isShowCopyModal = false"
        >
          <recipe-select-store-for-copy
            class="modal"
            v-if="isShowCopyModal"
            @selectStore="selectCopyStore"
            @close="modalClose()"
          />
        </div>
      </transition>

      <div class="modal-bg loading-bg" v-if="loading">
        <r-spinner></r-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import RAlert from "@/plugins/atoms/RAlert.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListHeader from "@/plugins/atoms/RListHeader.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RChart from "@/plugins/atoms/RChart.vue";
import RBalloon from "@/plugins/atoms/RBalloon.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";
import RecipeSubRecipeRegisterForm from "@/components/recipe/organisms/RecipeSubRecipeRegisterForm.vue";
import RecipeSelectStoreForCopy from "@/components/recipe/organisms/RecipeSelectStoreForCopy.vue";

import auth from "@/libs/Auth.js";
import recipeManager from "@/libs/Recipe.js";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  components: {
    RAlert,
    RButton,
    RList,
    RListHeader,
    RListContent,
    RListItem,
    RPaper,
    RChart,
    RBalloon,
    RHeading,
    RSpinner,
    RecipeSubRecipeRegisterForm,
    RecipeSelectStoreForCopy,
  },
  data() {
    return {
      storeName: "",
      customerID: "",
      viewHidden: "",
      userAuthority: {
        createRp: false,
      },
      isHeadquarters: false,
      isMyStore: false,
      storeID: "",
      recipeID: "",
      category: "",
      allergyTwenty: false,
      image: "",
      isShowEditMenu: false,
      isShowBalloonSubRecipeButton: false,
      isShowSubRecipeModal: false,
      isShowCopyModal: false,
      alertMessage: "",
      subRecipeAlert: 0,
      recipe: {
        storeID: "",
        cat1: "",
        cat2: "",
        cat3: "",
        name: "",
        kana: "",
        image: "",
        isSubRecipe: false,
        costPerServ: 0,
        costPerSubUnit: "",
        costRate: 0,
        pastCostRate: 0,
        totalCost: 0,
        targetRate: 0,
        allergy: {},
        price: 0,
        createAt: "",
        hidden: false,
        updateAt: "",
        tutorials: [],
        items: [],
      },
      editingRecipeID: "",
      editingName: "",
      // 取引先データ
      storeSuppliers: [],
      supplierArray: [],
      // 食材の詳細情報を表示するバルーンの管理用フラグ
      recipeBalloonArray: [],
      // ローディング画面管理用フラグ
      loading: false,
      // コピー用のストアID
      storeIDForCopy: "",

      // ヘッダーやメニューの画像データ
      headingImage: require("../../../assets/images/viewColor.png"),
      editImage: require("../../../assets/images/editColor.png"),
      costImage: require("../../../assets/images/costColor.png"),
      recipeImage: require("../../../assets/images/recipeColor.png"),
      copyImage: require("../../../assets/images/copyColor.png"),
      trashImage: require("../../../assets/images/trashColor.png"),
      lockImage: require("../../../assets/images/lockColor.png"),
      subRecipeRegistFlag: false,
      subRecipeUnregistFlag: false,
      priceUpdateStatus: false,
      editingSelfCheck: false,
      isOtherStoreView: false,
      isStore: true,
    };
  },
  methods: {
    toggleIsShowEditMenu() {
      this.isShowEditMenu = !this.isShowEditMenu;
    },
    //サブレシピ登録モーダルを開くやつ
    async registerAsSubRecipe() {
      //階層チェック
      const count = await recipeManager.useSubRecipeCount(this.recipe.items);
      if (this.$root.privateState.level5 && count >= 5) {
        //食材に5levelのサブレシピがある場合、該当レシピをsubrecipeとして登録不可
        alert(this.$i18n.t("recipe.recipeDetail.noMoreRegistFifthSubRecipe"));
        return;
      } else if (!this.$root.privateState.level5 && count >= 3) {
        // eslint-disable-next-line
        //5段階使用未チェック顧客と食材に3levelのサブレシピ使用
        alert(this.$i18n.t("recipe.recipeDetail.noMoreRegistThirdSubRecipe"));
        return;
      }

      if (this.hasRecipeSub) {
        this.subRecipeAlert = 0;
        this.isShowBalloonSubRecipeButton = true;
      } else if (!this.hasRecipeCost) {
        this.subRecipeAlert = 1;
        this.isShowBalloonSubRecipeButton = true;
      } else {
        this.isShowSubRecipeModal = true;
        //モーダルコンポーネント指定、モーダル背景固定処理
        const modal = document.querySelector(".modal");
        disableBodyScroll(modal);
      }
    },
    //subRecipe登録完了後
    subRecipeRegistrationConfirm() {
      //モーダル閉じる
      this.modalClose();
      this.$set(this.recipe, "isSubRecipe", true);
      this.makeAlertMessage("registSubRecipe");
    },
    //subRecipe解除
    async unregisterSubRecipe() {
      //メッセージ：[他のメニューで食材として使用されているので削除できません。]
      if (this.recipeID) {
        const doesExist = await recipeManager.doesExistHigherRecipe(
          this.customerID,
          this.storeID,
          this.recipeID,
        );
        if (doesExist) {
          alert(this.$i18n.t("recipe.recipeDetail.recipeUsedInOtherRecipe"));
        } else {
          await recipeManager.unsetSubRecipe(this.recipeID);
          this.$set(this.recipe, "isSubRecipe", false);
          this.makeAlertMessage("unregistSubRecipe");
        }
      }
    },
    // 画面上部のアラート(r-alert)のメッセージを管理
    makeAlertMessage(flag) {
      switch (flag) {
        case "registRecipe":
          this.alertMessage = "recipe.recipeDetail.registRecipe";
          break;
        case "registSubRecipe":
          this.alertMessage = "recipe.recipeDetail.registSubRecipe";
          break;
        case "unregistSubRecipe":
          this.alertMessage = "recipe.recipeDetail.unregistSubRecipe";
          break;
        case "registCost":
          this.alertMessage = "recipe.recipeDetail.registCost";
          break;
      }
    },
    closeBalloon() {
      this.isShowBalloonSubRecipeButton = false;
    },
    modalClose() {
      this.isShowSubRecipeModal = false;
      this.isShowCopyModal = false;
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    async toggleRecipeBalloon(index) {
      // 他に開いているバルーンがあった場合閉じる
      const exIndex = this.recipeBalloonArray.indexOf(true);
      if (exIndex !== -1) {
        this.$set(this.recipeBalloonArray, exIndex, false);
      }

      if (index === exIndex) {
        this.$set(this.recipeBalloonArray, index, false);
      } else {
        this.$set(this.recipeBalloonArray, index, true);
      }

      // 取引先情報がデータベースに存在しない場合
      if (this.supplierArray.length === 0) {
        if (this.$root.privateState.itemList.length < 1) {
          await this.$root.setItemListAction(this.customerID, this.storeID);
        }
        // マスターの取引会社リストを取得
        const itemList = this.$root.privateState.itemList;

        const suppliersArray = itemList.map((line) => {
          return {
            ingredientID: line["［マイカタログID］"],
            supplier: line["［取引先名］"],
          };
        });
        const ingredientIDArray = this.recipe.items.map(
          (item) => item.ingredientID,
        );
        this.supplierArray = ingredientIDArray.map((id) => {
          const index = suppliersArray.findIndex(
            (item) => item.ingredientID === id,
          );
          if (index === -1) {
            return {
              ingredientID: "",
              supplier: "",
            };
          }
          return suppliersArray?.[index];
        });
      }
    },
    async editRecipe() {
      try {
        // 編集中の登録
        await auth.lockEditStatus(this.recipeID);
        this.$router.push(
          {
            name: "recipeEdit",
            params: {
              id: this.recipeID,
            },
          },
          () => {},
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("ERROR", error);
        throw new Error(error);
      }
    },
    clickCopyRecipe() {
      if (this.isHeadquarters) {
        // モーダルを開く
        this.isShowCopyModal = true;
      } else {
        this.storeIDForCopy = this.storeID;
        this.copyRecipe();
      }
    },
    selectCopyStore(id) {
      this.isShowCopyModal = false;
      this.storeIDForCopy = id;
      this.copyRecipe();
    },
    async copyRecipe() {
      const result = confirm(
        this.$i18n.t("recipe.recipeDetail.confirmCopyRecipe"),
      );
      if (!result) {
        this.storeIDForCopy = "";
        return;
      }

      this.loading = true;

      //await recipeManager.copyRecipe(this.recipe, this.storeID);
      const createUser = auth.getDisplayName();
      await recipeManager.copyRecipeData(
        this.recipeID,
        this.storeIDForCopy,
        createUser,
      );
      setTimeout(() => {
        alert(this.$i18n.t("recipe.recipeDetail.copyRecipeSuccess"));
        this.$router.push({ name: "recipeList" }, () => {});
      }, 6000);
    },
    async deleteRecipe() {
      // 他のレシピで食材として使用されていた場合の処理
      const doesExist = await recipeManager.doesExistHigherRecipe(
        this.customerID,
        this.storeID,
        this.recipeID,
      );
      if (doesExist) {
        alert(this.$i18n.t("recipe.recipeDetail.foodstuffRecipeCannotDelete"));
        return;
      }
      const isUseCourse = await recipeManager.isRecipeRegistCourse(
        this.customerID,
        this.storeID,
        this.recipeID,
      );
      if (!isUseCourse) {
        alert(this.$i18n.t("recipe.recipeDetail.courseRecipeCannotDelete"));
        return;
      }
      const result = confirm(
        this.$i18n.t("recipe.recipeDetail.confirmDeleteMenu"),
      );
      if (result) {
        this.loading = true;
        try {
          // データの削除-サブコレクションはFunctionでコマンドで削除
          await recipeManager.deleteRecipe(this.recipeID);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          throw new Error(error);
        }
        // 2秒間を置かないと完全に消えない
        setTimeout(() => {
          this.$router.push(
            {
              name: "recipeList",
              params: {
                deleteFlag: true,
                deleteRecipe: this.recipeID,
              },
            },
            () => {},
          );
        }, 2000);
      }
    },
    async createTutorials() {
      try {
        // 編集中の登録
        await auth.lockEditStatus(this.recipeID);
        // 作り方の登録画面に遷移
        this.$router.push(
          {
            name: "recipeProcedure",
            params: {
              id: this.recipeID,
              tutorials: this.recipe.tutorials,
            },
          },
          () => {},
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("ERROR", error);
        throw new Error(error);
      }
    },
    async calcCosts() {
      try {
        // 編集中の登録
        await auth.lockEditStatus(this.recipeID);
        // 原価率の登録に背に
        this.$router.push(
          {
            name: "recipeCost",
            params: {
              id: this.recipeID,
            },
          },
          () => {},
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("ERROR", error);
        throw new Error(error);
      }
    },
    async getSubRecipeHiddenStatus(id) {
      let result;
      try {
        const recipeRes = await recipeManager.getRecipe(id);
        result = recipeRes.hidden;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
        throw new Error(error);
      }
      return result;
    },
  },
  computed: {
    hasRecipeSub() {
      // サブレシピの存在確認
      return this.recipe.isSubRecipe;
    },
    hasRecipeTutorials() {
      return this.recipe.tutorials.length !== 0;
    },
    hasRecipeCost() {
      return this.recipe.totalCost > 0;
    },
    // 取引先リストと合致するかどうか判断する
    isMatchWithSupplierList() {
      return (supplier) => {
        if (!this.storeSuppliers.includes(supplier)) {
          false;
        }
        return true;
      };
    },
    hasAllergySeven() {
      if (this.recipe.allergy.sevenItems) {
        return this.recipe.allergy.sevenItems.length > 0;
      }
      return false;
    },
    hasAllergyTwenty() {
      if (this.recipe.allergy.twentyItems) {
        return this.recipe.allergy.twentyItems.length > 0;
      }
      return false;
    },
    // 食材リスト
    recipeItems() {
      const recipeWithoutSubRecipe = this.recipe.items.filter(
        (e) => e.ref === null,
      );
      return recipeWithoutSubRecipe.sort((a, b) => {
        if (a.displayNum < b.displayNum) return -1;
        if (a.displayNum > b.displayNum) return 1;
        return 0;
      });
    },
    sortedRecipeItems() {
      return Object.assign([], this.recipe.items).sort((a, b) => {
        if (a.displayNum < b.displayNum) return -1;
        if (a.displayNum > b.displayNum) return 1;
        return 0;
      });
    },
    // ソートした作りかたのリスト
    sortedTutorials() {
      if (!this.hasRecipeTutorials) {
        return;
      }
      return Object.assign([], this.recipe.tutorials).sort((a, b) => {
        if (a.step < b.step) return -1;
        if (a.step > b.step) return 1;
        return 0;
      });
    },
    replaceNtoBr() {
      return (text) => {
        return this.$sanitize(text.replace(/\r?\n/g, "<br>"));
      };
    },
    convertToAllergyCode() {
      return (allergy) => {
        // スイッチでコードに変換する
        switch (allergy) {
          case "小麦":
            return "wheat";
          case "蕎麦":
            return "soba";
          case "乳製品":
            return "milk";
          case "卵":
            return "egg";
          case "落花生":
            return "peanuts";
          case "えび":
            return "shrimp";
          case "かに":
            return "crab";
          case "あわび":
            return "abalone";
          case "いか":
            return "squid";
          case "いくら":
            return "salmonRoe";
          case "オレンジ":
            return "orange";
          case "カシューナッツ":
            return "cashewNut";
          case "キウイフルーツ":
            return "kiwi";
          case "牛肉":
            return "beef";
          case "くるみ":
            return "walnut";
          case "ごま":
            return "sesame";
          case "さけ":
            return "salmon";
          case "さば":
            return "mackerel";
          case "大豆":
            return "soy";
          case "鶏肉":
            return "chicken";
          case "バナナ":
            return "banana";
          case "豚肉":
            return "pork";
          case "まつたけ":
            return "matsutake";
          case "もも":
            return "peaches";
          case "やまいも":
            return "yam";
          case "りんご":
            return "apple";
          case "ゼラチン":
            return "geratin";
          case "アーモンド":
            return "almond";
        }
      };
    },
    supplierName() {
      return function (id) {
        if (this.supplierArray.length === 0) {
          return "";
        }
        const index = this.supplierArray.findIndex(
          (item) => item.ingredientID === id,
        );
        if (index === -1) {
          return "";
        }
        return this.supplierArray?.[index]?.supplier;
      };
    },
  },
  async created() {
    this.loading = true;
    this.isHeadquarters = await auth.isHeadquarters();
    this.customerID = await auth.getCustomerID(this.$root);
    this.isViewOtherRp = await auth.getViewOtherRp();
    this.storeID = await auth.getStoreID(this.$root);
    this.isStore = await auth.isStore();

    if (!this.storeID) {
      this.storeID = this.$route.query.storeID;
    }

    // 店舗ユーザーで他店舗のユーザーを見る場合の対応 (detailのみの処理)
    if (this.isViewOtherRp && this.isStore) {
      let recipeStoreID;
      if (sessionStorage.storeID) {
        recipeStoreID = sessionStorage.storeID;
      } else {
        if (this.$route.query.storeID) {
          recipeStoreID = this.$route.query.storeID;
        } else {
          recipeStoreID = this.$root.privateState.storeID || "";
        }
      }

      if (recipeStoreID !== this.storeID) {
        this.storeID = recipeStoreID;
        this.isOtherStoreView = true;
      } else {
        this.isOtherStoreView = false;
      }
    } else {
      this.isOtherStoreView = false;
    }

    if (!this.customerID || !this.storeID) {
      // customerID、StoreIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }

    if (this.$route.params.id) {
      this.recipeID = this.$route.params.id;
    } else if (sessionStorage.recipeID) {
      // 存在しない場合はlocal storageから取得
      this.recipeID = sessionStorage.recipeID;
    } else {
      // recipeIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }

    // 編集中のRecipeIDを取得
    this.editingName = await auth.getEditingRecipe(
      this.customerID,
      this.recipeID,
    );
    if (this.editingName) {
      this.editingRecipeID = this.recipeID;
    }

    // 該当のレシピ情報を取得
    try {
      const recipeRes = await recipeManager.getRecipeWithImage(
        this.customerID,
        this.storeID,
        this.recipeID,
      );
      this.recipe = recipeRes;
      this.image = recipeRes.image;

      // 店舗名称を取得
      this.storeName = await auth.getStoreName(this.customerID, this.storeID);

      // アレルギーの品目数設定を取得
      this.allergyTwenty = this.$root.privateState.allergyTwenty;

      // SessoonStrageに保存
      sessionStorage.recipeID = this.recipeID;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }

    //食材の詳細情報を表示するバルーンを管理するフラグの配列を用意して、falseで初期化
    this.recipeBalloonArray.length = this.recipeItems.length;
    this.recipeBalloonArray.fill(false);

    if (this.$route.params.alertMessage) {
      this.makeAlertMessage(this.$route.params.alertMessage);
    }

    // サブレシピの公開設定を取得する
    this.recipe.items.map(async (item, index) => {
      if (item.subItems !== undefined) {
        const hidden = await this.getSubRecipeHiddenStatus(item.ref.id);
        this.$set(this.recipe.items[index], "hidden", hidden);
      }
    });

    // ユーザの非公開レシピ閲覧権限の有無を取得
    this.viewHidden = await auth.getViewHidden();
    const createRp = await auth.getCreateRp();
    this.$set(this.userAuthority, "createRp", createRp);
    const viewCost = await auth.getViewCost();
    this.$set(this.userAuthority, "viewCost", viewCost);

    if (this.isOtherStoreView) {
      // 他店舗のユーザーでかつレシピ閲覧権限の場合は編集権限は強制的にOFF
      this.$set(this.userAuthority, "createRp", false);
      this.$set(this.userAuthority, "viewCost", false);
    }

    // 使用している食材の原価に変更があった場合の処理
    this.priceUpdateStatus = this.$root.privateState.notifications
      .map((item) => item.recipeID)
      .includes(this.recipe.recipeID);
    this.loading = false;
    if (this.userAuthority.viewCost && this.priceUpdateStatus) {
      if (this.hasRecipeCost) {
        alert(this.$i18n.t("recipe.recipeDetail.recalcCostRate"));
      } else {
        alert(this.$i18n.t("recipe.recipeDetail.updateFoodstuff"));
      }
    }

    // 自分自身のアカウントで編集しているか
    const editRecipeID = await auth.getEditingMyRecipe();
    if (editRecipeID !== "") {
      this.editingSelfCheck = true;
      alert(this.$i18n.t("recipe.recipeDetail.editingStatusInSameAccount"));
    }
  },
};
</script>

<style scoped>
.recipe-detail {
  padding: 60px 20px 0px;
  position: relative;
}
.heading-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-2 {
  color: #517ce8;
}
.recipe-detail--buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
}
.recipe-detail--edit-menu {
  box-sizing: border-box;
  width: 60%;
  position: absolute;
  right: 0px;
  top: 130px;
  padding: 5px 10px;
}
.recipe-detail--edit-menu--bg {
  width: 100vw;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.recipe-detail--edit-btn:hover {
  color: #e87352;
  transition: color 0.3s;
}
.recipe-detail--edit-btn-item-list >>> .r-list-item--content {
  display: flex;
  align-items: center;
}
.recipe-detail--subrecipe-alert {
  margin-top: 20px;
}
.recipe-detail--alert {
  margin-top: 20px;
}
.recipe-detail--alert--text {
  margin: 0;
  text-align: left;
}
.recipe-detail--alert--button {
  margin-top: 10px;
}
/** メインコンテンツ */
.recipe-detail--main--content {
  margin-top: 20px;
}
.recipe-detail--main--image {
  width: 100%;
  height: auto;
}
.recipe-detail--main--image--default {
  background-image: url(~@/assets/images/logo4.png);
  background-size: auto 15vh;
  width: 100%;
  height: 25vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #f8d5cb;
}
.recipe-detail--main--store-name {
  font-weight: bold;
  text-align: left;
  padding: 5px 0 5px 10px;
  border-top: 1px solid #e87352;
  border-bottom: 1px solid #e87352;
}
.recipe-detail--main--category {
  text-align: left;
}
.recipe-detail--main--category--item:not(:last-child):after {
  content: "・";
}
/** 食材リスト */
.recipe-detail--main--foodstuff--user {
  color: #517ce8;
}
.recipe-detail--main--content--food-list {
  position: relative;
}
.recipe-detail--main--content--food-list--balloon {
  position: absolute;
  top: 50px;
  left: 0;
}
.redipe-detail--main--content--food-list--balloon--ss {
  color: #517ce8;
  font-weight: bold;
}
.recipe-detail--main--foodstuff--sub {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recipe-detail--main--foodstuff--sub--left {
  width: 20%;
}
.recipe-detail--main--foodstuff--sub--right {
  width: 80%;
}
/** アレルギー */
.recipe-detail--main--allergies {
  text-align: left;
}
/** 原価 */
.recipe-detail--main--cost-rate--chart {
  margin-top: 20px;
}
.recipe-detail--main--cost-rate--list-items {
  margin-top: 20px;
}
.recipe-detail--main--sub-recipe-button--wrapper {
  position: relative;
}
.recipe-detail--main--sub-recipe-button--wrapper--balloon {
  position: absolute;
  bottom: 50px;
}

.list-sub1 {
  min-width: 80%;
}
.list-sub2 {
  min-width: 20%;
  text-align: right;
}

.recipe-detail--main--allergies--items:not(:last-child):after {
  content: "・";
}
.recipe-detail--main--allergies--items--wrapper {
  margin-top: 10px;
}

.recipe-detail--main--how-to--item {
  margin-top: 10px;
  text-align: left;
}
.recipe-detail--main--how-to--step {
  color: #e87352;
  font-weight: bold;
  font-size: 1.2rem;
}
.recipe-detail--main--how-to--image {
  margin-top: 20px;
  width: 100%;
  height: 30vh;
  background-size: contain;
  background-repeat: no-repeat;
}
.recipe-detial--main--how-to--image-nofile {
  margin-top: 20px;
  width: 100%;
  height: 30vh;
  background-image: url(~@/assets/images/logo4.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 5vh;
}
.recipe-detail--main--how-to--text {
  border-left: 2px solid #e87352;
  padding: 0 5px;
  margin-top: 20px;
}

.modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.loading-bg {
  background: rgba(255, 255, 255, 0.5);
}

.modal {
  width: 90%;
}
.edit-menu--icon {
  width: 20px;
  height: 20px;
}
.edit-menu-name {
  margin-left: 5px;
}
.lock-edit-msg {
  margin-top: 20px;
}
.lock-icon {
  width: 18px;
  height: auto;
}
@media screen and (min-width: 641px) {
  .recipe-detail {
    padding-top: 100px;
  }
  .recipe-detail--edit-menu {
    width: 300px;
    top: 75px;
  }
  .recipe-detail--buttons {
    position: absolute;
    top: 0px;
    width: 95vw;
  }
  .recipe-detail--main {
    display: grid;
    /*iPad画面で左右1:1*/
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  .recipe-detail--main--left {
    grid-row: 1/4;
    grid-template-columns: 1fr;
    grid-column: 1/2;
  }
  .recipe-detail--main--foodstuff--allergy {
    margin-top: 0px;
    grid-row: 1/2;
    grid-template-columns: 1fr;
    grid-column: 2/2;
  }
  .recipe-detail--main--cost-rate {
    grid-row: 2/4;
    grid-template-columns: 1fr;
    grid-column: 2/2;
  }
  .recipe-detail--main--how-to--item--wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .recipe-detail--main--how-to--item {
    margin-right: 20px;
    width: calc((100% - 40px) / 3);
  }
  .recipe-detail--main--how-to--item:nth-of-type(3n) {
    margin-right: unset;
  }
  .recipe-detail--alert {
    display: flex;
    align-items: center;
  }
  .recipe-detail--alert--item {
    width: 30%;
  }
  .recipe-detail--alert--button {
    margin-top: 0;
  }
  .recipe-detail--alert--button--left {
    margin-right: 20px;
  }
}

@media (min-width: 1024px) {
  .recipe-detail {
    width: 1024px;
    margin: auto;
    padding-top: 120px;
  }
  .recipe-detail--edit-menu {
    width: 300px;
    top: 90px;
  }
  .recipe-detail--buttons {
    position: absolute;
    top: 15px;
    width: 1024px;
  }
  .recipe-detail--main {
    display: grid;
    grid-template-columns: 0.8fr 1fr 0.5fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
  }
  .recipe-detail--main--left {
    grid-row: 1/1;
    grid-template-columns: 1fr;
    grid-column: 1/2;
  }
  .recipe-detail--main--foodstuff--allergy {
    margin-top: 0px;
    grid-row: 1/1;
    grid-template-columns: 1fr;
    grid-column: 2/3;
  }
  .recipe-detail--main--cost-rate {
    grid-row: 1/1;
    grid-template-columns: 1fr;
    grid-column: 3/4;
  }
  .recipe-detail--main--how-to--item--wrapper {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .recipe-detail--main--how-to--item {
    margin-right: 20px;
    width: calc((100% - 40px) / 3);
  }
  .recipe-detail--main--how-to--item:nth-of-type(3n) {
    margin-right: unset;
  }
}
</style>

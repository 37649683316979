<template>
  <div class="r-radio">
    <button
      v-for="(option, index) in options"
      :key="index"
      class="r-radio--button"
      :class="{ 'r-radio--button__current': current === index }"
      @click="toggleCurrent(index)"
    >
      {{ option.name }}
    </button>
  </div>
</template>

<script>
export default {
  name: "r-radio",
  data() {
    return {
      current: 0,
    };
  },
  props: {
    options: Array,
  },
  methods: {
    clickHandler(e, index) {
      this.$emit("click", e, index);
    },
    toggleCurrent(index) {
      this.current = index;
      this.clickHandler(index);
    },
  },
};
</script>

<style scoped>
.r-radio {
  display: flex;
  flex-wrap: wrap;
}
.r-radio--button {
  background-color: #ffffff;
  border: 2px solid #e87352;
  border-radius: 16px;
  background-color: #ffffff;
  font-size: 14px;
  padding: 5px 14px;
  margin: 0.5em 0.5em;
}
.r-radio--button:hover {
  background-color: #e87352;
  color: #ffffff;
  transition: 0.5s;
  cursor: pointer;
}
.r-radio--button__current {
  background-color: #e87352;
  color: #ffffff;
}
</style>

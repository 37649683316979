<template>
  <r-paper class="config-category" round>
    <r-modal-sub-header :title="$t('config.selectuserForm.editDeleteUser')" />
    <div class="config-user-select--form">
      <div class="config-category--main config-user-list--form">
        <span>{{ $t("config.edituserForm.editDeleteUserExplan") }}</span>
        <r-checkbox
          :items="users"
          @toggle="toggle"
          class="config-category-select-user-list"
        />
      </div>
      <div class="config-btn-wrapper">
        <r-button
          class="config-category--button"
          buttonStyle="secondary"
          full
          @click="selectUser"
          >{{ $t("config.edit") }}</r-button
        >
        <r-button
          class="config-category--button"
          buttonStyle="secondary"
          full
          @click="deleteUsers"
          >{{ $t("config.delete") }}</r-button
        >
      </div>
    </div>
    <div class="loading-bg" v-if="deleteStatus">
      <r-spinner></r-spinner>
    </div>
  </r-paper>
</template>
<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RCheckbox from "@/plugins/atoms/RCheckbox.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import userManager from "@/libs/User.js";

export default {
  components: {
    RButton,
    RCheckbox,
    RPaper,
    RModalSubHeader,
    RSpinner,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      users: [],
      inputName: "",
      deleteStatus: false,
    };
  },
  computed: {},
  methods: {
    toggle(index) {
      this.$set(this.users[index], "bool", !this.users[index].bool);
    },
    selectUser() {
      // ユーザリストのboolのみの配列
      const selectedUserList = this.users.filter((e) => e.bool);

      // 選択されていなかった場合、処理しない
      if (selectedUserList.length === 0) {
        return;
      }
      // 複数選択されていた場合、メッセージを表示する
      if (selectedUserList.length >= 2) {
        alert(this.$t("config.selectuserForm.selectOneUserInCaseOfEdit"));
        return;
      }
      const index = this.users.map((e) => e.bool).indexOf(true);
      this.$emit("edit", this.users[index].id);
    },
    async deleteUsers() {
      const selectedUserList = this.users.filter((e) => e.bool);

      // 選択されていなかった場合、処理しない
      if (selectedUserList.length === 0) {
        return;
      }

      // 削除してもいいかダイアログを表示
      const deleteUserConfirm = window.confirm(
        this.$i18n.t("config.selectuserForm.selectUserDeleteConfirmMsg"),
      );
      if (!deleteUserConfirm) {
        return;
      }

      this.deleteStatus = true;
      try {
        await Promise.all(
          this.users.map(async (user) => {
            if (user.bool) {
              const userInfo = {
                customerID: this.customerID,
                storeID: this.storeID,
                id: user.id,
              };
              await userManager.deleteUser(userInfo);
            }
          }),
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw new Error(error);
      }

      const userList = await userManager.getUserList(
        this.customerID,
        this.storeID,
      );
      this.users = userList.map((user) => {
        const recoed = { id: user.id, name: user.displayName, bool: false };
        return recoed;
      });
      this.deleteStatus = false;
    },
  },
  async created() {
    this.customerID = await auth.getCustomerID(this.$root);
    this.storeID = await auth.getStoreID(this.$root);
    if (!this.customerID || !this.storeID) {
      // customerID、StoreIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }

    // ユーザ一覧を取得
    const userList = await userManager.getUserList(
      this.customerID,
      this.storeID,
    );
    this.users = userList.map((user) => {
      const recoed = { id: user.id, name: user.displayName, bool: false };
      return recoed;
    });
  },
};
</script>

<style scoped>
.config-category--main {
  text-align: left;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  padding: 10px;
}
.config-category--button {
  margin-top: 10px;
}

.loading-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.config-category-select-user-list >>> .checkbox--button {
  min-height: 32px;
}
/*iPad用css*/
@media screen and (min-width: 641px) {
  .config-category--button {
    margin-top: unset;
    height: 40px;
  }
  .config-user-select--form {
    margin-top: 10px;
    display: grid;
    vertical-align: middle;
    grid-template-columns: 5fr 1fr;
    gap: 10px;
    min-height: 300px;
  }
  .config-user-list--form {
    grid-template-columns: 5fr;
  }
  .config-btn-wrapper {
    grid-template-columns: 1fr;
    margin: auto;
    display: block;
  }
  .config-category--button:nth-of-type(2n-1) {
    margin-bottom: 10px;
  }
}
/*PC用css*/
@media screen and (min-width: 1024px) {
}
</style>

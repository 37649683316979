<template>
  <div>
    <r-heading :level="1" :image="headingImage">{{ course.title }}</r-heading>
    <div class="course-detail">
      <!-- メニューボタンここから -->
      <div class="course-detail--content course-detail--header-buttons">
        <r-button buttonStyle="primary" outline @click="toCourseList">{{
          $t("course.courseDetail.backToCourseList")
        }}</r-button>
        <r-button
          buttonStyle="primary"
          outline
          @click="isShowEditMenu = true"
          v-if="userAuthority.createRp"
          >{{ $t("course.courseDetail.editCourse") }}</r-button
        >
      </div>
      <!-- メニューボタンここまで -->

      <!-- 編集メニューここから -->
      <transition name="modal">
        <r-balloon
          class="course-detaill--edit-menu"
          balloonType="top"
          v-if="isShowEditMenu"
        >
          <r-list>
            <r-list-content
              clickable
              hoverEffectOff
              @click="toCourseEdit"
              class="course-detail--edit-btn"
            >
              <r-list-item class="course-detail--edit-btn-item-list">
                <img class="edit-menu--icon" :src="editImage" alt />
                <span
                  >&nbsp;{{
                    $t("course.courseDetail.editCourseNameOrMenu")
                  }}</span
                >
              </r-list-item>
            </r-list-content>

            <r-list-content
              clickable
              hoverEffectOff
              @click="deleteCourse"
              class="course-detail--edit-btn"
            >
              <r-list-item class="course-detail--edit-btn-item-list">
                <img class="edit-menu--icon" :src="trashImage" alt />
                <span>&nbsp;{{ $t("course.courseDetail.deleteCourse") }}</span>
              </r-list-item>
            </r-list-content>
          </r-list>
        </r-balloon>
      </transition>
      <!-- 編集メニューここまで -->

      <div
        class="menu-bg"
        @click.self="isShowEditMenu = false"
        v-if="isShowEditMenu"
      />

      <!-- 画像ここから -->
      <div class="course-detail--main">
        <div class="course-detail--content--left">
          <!--<div
            :style="{ 'background-image': 'url(' + course.image + ')' }"
            v-if="course.image"
          ></div>-->
          <img
            class="course-detail--image course-detail--content"
            :src="course.image"
            v-if="course.image"
          />

          <div
            class="course-detail--image course-detail--image--default course-detail--content"
            v-else
          ></div>
          <!-- 画像ここまで -->

          <!-- 店舗名称ここから -->
          <div class="course-detail--content course-detail--store-name">
            {{ storeName }}
          </div>
          <!-- 店舗名称ここまで -->

          <!-- カテゴリー -->
          <r-paper class="course-detail--content" round>
            <span
              class="course-detail--content--category"
              v-for="(category, index) in course.categories"
              :key="index"
              >{{ category }}</span
            >
          </r-paper>
          <!-- カテゴリーここまで -->
        </div>

        <div class="course-detail--content--allergy">
          <!-- 料理ここから -->
          <r-paper class="course-detail--content" round>
            <r-list>
              <r-list-header>{{ $t("common.recipe") }}</r-list-header>
              <r-list-content
                v-for="(recipe, index) in course.factors"
                :key="index"
              >
                <r-list-item>{{ recipe.name }}</r-list-item>
                <r-list-item
                  >{{ recipe.serv }}{{ $t("common.perPerson") }}</r-list-item
                >
              </r-list-content>
            </r-list>
          </r-paper>
          <!-- 料理ここまで -->

          <!-- アレルギーここから -->
          <r-paper class="course-detail--content" round>
            <r-list-header>{{ $t("common.allergy") }}</r-list-header>
            <div>
              <r-heading :level="4">
                {{ $t("recipe.allergyItem.sevenItems") }}
              </r-heading>
              <template v-if="allergiesSevenItems.length !== 0">
                <span
                  v-for="(allergy, index) in allergiesSevenItems"
                  :key="`seven-${index}`"
                  class="course-detail--content--allergy--item"
                  >{{ $t(`recipe.allergyItem.${allergy}`) }}</span
                >
              </template>
              <span v-else>{{ $t("course.courseDetail.notAllergy") }}</span>
            </div>

            <div v-if="allergyTwenty">
              <r-heading :level="4">
                {{ $t("recipe.allergyItem.twentyItems") }}
              </r-heading>
              <template v-if="allergiesTwentyItems.length !== 0">
                <span
                  v-for="(allergy, index) in allergiesTwentyItems"
                  :key="`twenty-${index}`"
                  class="course-detail--content--allergy--item"
                  >{{ $t(`recipe.allergyItem.${allergy}`) }}</span
                >
              </template>
              <span v-else>{{ $t("course.courseDetail.notAllergy") }}</span>
            </div>
          </r-paper>
          <!-- アレルギーここまで -->
        </div>

        <!-- 原価表示ここから -->
        <r-paper class="course-detail--content course-detail--cost" round>
          <r-list-header v-if="userAuthority.viewCost">{{
            $t("common.cost")
          }}</r-list-header>
          <r-chart
            :rate="costRate"
            class="course-detail--content--chart"
            chartStyle="secondary"
            :label="$t('common.costRate')"
            v-if="userAuthority.viewCost"
          />

          <r-list>
            <r-list-content v-if="userAuthority.viewCost">
              <r-list-item
                >{{ $t("common.cost") }} : {{ totalCost | localNumber
                }}{{ $t("common.currencyYen") }}</r-list-item
              >
            </r-list-content>

            <r-list-content v-if="userAuthority.viewCost">
              <r-list-item
                >{{ $t("common.price") }} : {{ course.price | localNumber
                }}{{ $t("common.currencyYen") }}</r-list-item
              >
            </r-list-content>

            <r-list-content>
              <r-list-item>
                {{ $t("common.author") }} :
                {{ course.createUser }}
              </r-list-item>
            </r-list-content>
          </r-list>
        </r-paper>
        <!-- 原価表示ここまで -->
      </div>
    </div>
  </div>
</template>

<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RChart from "@/plugins/atoms/RChart.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListHeader from "@/plugins/atoms/RListHeader.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RBalloon from "@/plugins/atoms/RBalloon.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";

import auth from "@/libs/Auth.js";
import courseManager from "@/libs/Course.js";

export default {
  components: {
    RButton,
    RPaper,
    RChart,
    RList,
    RListContent,
    RListHeader,
    RListItem,
    RBalloon,
    RHeading,
  },
  data() {
    return {
      storeName: "",
      allergyTwenty: false,
      isShowEditMenu: false,
      userAuthority: {
        createRp: false,
      },
      courseID: "",
      customerID: "",
      storeID: "",
      course: {
        title: "",
        categories: [],
        recipes: [],
        price: 0,
        image: "",
        hidden: false,
      },
      headingImage: require("../../../assets/images/courseColor.svg"),
      editImage: require("../../../assets/images/editColor.png"),
      trashImage: require("../../../assets/images/trashColor.png"),
    };
  },
  computed: {
    allergiesSevenItems() {
      if (this.course.factors) {
        const allergy = this.course.factors.reduce((acc, recipe) => {
          return [...acc, ...recipe.allergy.sevenItems];
        }, []);
        return [...new Set(allergy)].map((name) =>
          this.convertToAllergyCode(name),
        );
      } else {
        return [];
      }
    },
    allergiesTwentyItems() {
      if (this.course.factors) {
        const allergy = this.course.factors.reduce((acc, recipe) => {
          return [...acc, ...recipe.allergy.twentyItems];
        }, []);
        return [...new Set(allergy)].map((name) =>
          this.convertToAllergyCode(name),
        );
      } else {
        return [];
      }
    },
    totalCost() {
      if (this.course.factors) {
        return this.course.factors.reduce((acc, factor) => {
          acc += factor.totalCost * factor.serv;
          return Number(acc.toFixed(2));
        }, 0);
      } else {
        return 0;
      }
    },
    costRate() {
      return Number(((this.totalCost / this.course.price) * 100).toFixed(2));
    },
  },
  methods: {
    toCourseList() {
      this.$router.push("/course-list", () => {});
    },
    toCourseEdit() {
      this.$router.push(
        {
          name: "courseEdit",
          params: {
            id: this.courseID,
          },
        },
        () => {},
      );
    },
    convertToAllergyCode(name) {
      switch (name) {
        case "小麦":
          return "wheat";
        case "蕎麦":
          return "soba";
        case "乳製品":
          return "milk";
        case "卵":
          return "egg";
        case "落花生":
          return "peanuts";
        case "えび":
          return "shrimp";
        case "かに":
          return "crab";
        case "あわび":
          return "abalone";
        case "いか":
          return "squid";
        case "いくら":
          return "salmonRoe";
        case "オレンジ":
          return "orange";
        case "カシューナッツ":
          return "cashewNut";
        case "キウイフルーツ":
          return "kiwi";
        case "牛肉":
          return "beef";
        case "くるみ":
          return "walnut";
        case "ごま":
          return "sesame";
        case "さけ":
          return "salmon";
        case "さば":
          return "mackerel";
        case "大豆":
          return "soy";
        case "鶏肉":
          return "chicken";
        case "バナナ":
          return "banana";
        case "豚肉":
          return "pork";
        case "まつたけ":
          return "matsutake";
        case "もも":
          return "peaches";
        case "やまいも":
          return "yam";
        case "りんご":
          return "apple";
        case "ゼラチン":
          return "geratin";
        case "アーモンド":
          return "almond";
      }
    },
    async deleteCourse() {
      // 削除
      const result = confirm(
        this.$i18n.t("course.courseDetail.courseDeleteConfirmMsg"),
      );
      if (!result) {
        return;
      }
      await courseManager.deleteCourse(this.courseID);
      this.$router.push(
        {
          name: "courseList",
          params: {
            deletedCourse: this.course.title,
          },
        },
        () => {},
      );
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        //customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      if (this.$route.params.id) {
        this.courseID = this.$route.params.id;
      } else if (sessionStorage.courseID) {
        // 存在しない場合はlocal storageから取得
        this.courseID = sessionStorage.courseID;
      } else {
        // recipeIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      //該当IDのコースを登録
      this.course = await courseManager.getCourseDetailWithImage(
        this.customerID,
        this.storeID,
        this.courseID,
      );

      //店舗名称を取得
      this.storeName = await auth.getStoreName(this.customerID, this.storeID);

      //アレルギーの品目数設定を取得
      this.allergyTwenty = this.$root.privateState.allergyTwenty;

      //Session Strageに保存
      sessionStorage.courseID = this.courseID;

      //権限取得
      const createRp = await auth.getCreateRp();
      this.$set(this.userAuthority, "createRp", createRp);
      const viewCost = await auth.getViewCost();
      this.$set(this.userAuthority, "viewCost", viewCost);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
.course-detail {
  padding: 60px 20px 0;
  position: relative;
}
.course-detail--header-buttons {
  display: flex;
  justify-content: space-between;
}
.course-detaill--edit-menu {
  position: absolute;
  right: 5px;
  top: 130px;
  z-index: 10;
}
.course-detail--edit-btn:hover {
  color: #e87352;
  transition: color 0.3s;
}
.course-detail--edit-btn-item-list >>> .r-list-item--content {
  display: flex;
  align-items: center;
}
.menu-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.course-detail--content {
  margin-top: 20px;
  text-align: left;
}
.course-detail--image {
  height: auto;
  width: 100%;
}
.course-detail--store-name {
  border-top: 1px solid #e87352;
  border-bottom: 1px solid #e87352;
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: bold;
}
.course-detail--content--category:not(:last-child):after,
.course-detail--content--allergy--item:not(:last-child):after {
  content: "・";
}
.course-detail--image--default {
  background-image: url(~@/assets/images/logo4.png);
  background-size: auto 15vh;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 25vh;
  background-color: #f8d5cb;
}
.course-detail--content--chart {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.edit-menu--icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

@media screen and (min-width: 641px) {
  .course-detail {
    padding-top: 100px;
  }
  .course-detail--header-buttons {
    position: absolute;
    top: 0px;
    width: 95vw;
  }
  .course-detail--main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .course-detail--content--left {
    grid-row: 1/4;
    grid-template-columns: 1fr;
    grid-column: 1;
  }
  .course-detail--content--allergy {
    margin-top: 0px;
    grid-row: 1/2;
    grid-template-columns: 1fr;
    grid-column: 2;
  }
  .course-detail--cost {
    grid-row: 2/4;
    grid-template-columns: 1fr;
    grid-column: 2;
  }
  .course-detaill--edit-menu {
    top: 90px;
  }
}

@media screen and (min-width: 1024px) {
  .course-detail {
    padding-top: 120px;
    width: 1024px;
    margin: auto;
  }
  .course-detail--header-buttons {
    top: 10px;
    width: 1024px;
  }
  .course-detail--main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 20px;
  }
  .course-detail--content--left {
    grid-row: 1/1;
    grid-template-columns: 1fr;
    grid-column: 1/2;
  }
  .course-detail--content--allergy {
    margin-top: 0px;
    grid-row: 1/1;
    grid-template-columns: 1fr;
    grid-column: 2/3;
  }
  .course-detail--cost {
    grid-row: 1/1;
    grid-template-columns: 1fr;
    grid-column: 3/4;
  }
  .course-detaill--edit-menu {
    top: 90px;
  }
}
</style>

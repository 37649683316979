<template>
  <div class="login-main">
    <r-alert class="login--ie" v-if="browserType === 'IE'">
      <div>
        お使いのブラウザは推奨環境ではないようです。
        <a
          href="https://www.google.co.jp/chrome/?brand=CHBD&gclid=EAIaIQobChMIuNub-JHG5wIVCKqWCh2bZgZ1EAAYASAAEgKnw_D_BwE&gclsrc=aw.ds"
          target="_blank"
          >Google Chrome</a
        >での閲覧をお勧めしています。
      </div>
    </r-alert>
    <r-paper class="login-main--paper" round>
      <div class="login-main--title">
        <span>{{ $t("auth.login") }}</span>
      </div>
      <span class="login-main--content login-main--caption">{{
        $t("auth.inputIdandPassowrd")
      }}</span>
      <div class="login-main--content">
        <form action>
          <r-textbox
            :label="$t('auth.userID')"
            type="string"
            v-model="username"
            name="username"
            autocomplete="on"
            class="login-main--content--textbox"
            :error="error.username !== ''"
          />
          <div class="error-message">
            {{ error.username }}
          </div>
          <r-textbox
            :label="$t('auth.password')"
            type="password"
            v-model="password"
            name="current-password"
            autocomplete="on"
            class="login-main--content--textbox login-main--content--password"
            :error="error.password !== ''"
          />
          <div class="error-message">
            {{ error.password }}
          </div>
        </form>
      </div>
      <div class="login-main--content--wrapper">
        <r-button
          class="login-main--content"
          buttonStyle="primary"
          @click="login"
          full
          >{{ $t("auth.login") }}</r-button
        >
      </div>
    </r-paper>
  </div>
</template>

<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RAlert from "@/plugins/atoms/RAlert.vue";

import auth from "@/libs/Auth.js";
import { UAParser } from "ua-parser-js";

export default {
  name: "login",
  components: {
    RTextbox,
    RPaper,
    RButton,
    RAlert,
  },
  data() {
    return {
      username: "",
      password: "",
      isLoginPage: true, //login状態をemitするためのBoolean変数,
      error: {
        username: "",
        password: "",
      },
      browserType: "",
    };
  },
  methods: {
    async login() {
      // 入力チェック
      this.executeValidation();
      if (this.error.username !== "" || this.error.password !== "") {
        return;
      }
      try {
        // usernameを加工
        const username = this.username.trim().toLowerCase();
        const role = await auth.signInCheck(username, this.password.trim());
        if (role === "admin") {
          this.$router.push({ name: "selectCustomer" }, () => {});
        } else {
          this.$router.push({ name: "recipe" }, () => {});
        }
      } catch (error) {
        this.catchErrorCode(error.message);
      }
    },
    executeValidation() {
      if (this.username === "") {
        this.$set(this.error, this.$i18n.t("auth.usernameInputValidation"));
      } else {
        this.$set(this.error, "username", "");
      }
      if (this.password === "") {
        this.$set(
          this.error,
          "password",
          this.$i18n.t("auth.passwordInputValidation"),
        );
      } else {
        this.$set(this.error, "password", "");
      }
    },
    catchErrorCode(error) {
      switch (error) {
        case "auth/user-not-found":
          this.$set(
            this.error,
            "username",
            this.$i18n.t("auth.usernameNotFound"),
          );
          break;
        case "auth/invalid-email":
          this.$set(
            this.error,
            "username",
            this.$i18n.t("auth.invalidUsername"),
          );
          break;
        case "auth/wrong-password":
          this.$set(this.error, "password", this.$i18n.t("auth.wrongPassword"));
          break;
        case "auth/too-many-requests":
          alert(this.$i18n.t("auth.tooManyRequest"));
          break;
      }
    },
  },
  async created() {
    await this.$root.clearAllAction();
    const parser = new UAParser();
    this.browserType = parser.getBrowser().name;
  },
};
</script>

<style scoped>
.login-main {
  width: 100%;
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
}
.login--ie {
  margin-bottom: 20px;
}
.login-main--paper {
  padding: 20px 20px;
  width: 92%;
}
.login-main--title {
  text-align: left;
  padding: 10px;
  font-weight: bold;
  border-bottom: 2px solid #e87352;
}
.login-main--content {
  margin-top: 20px;
}
.login-main--caption {
  display: block;
  color: #686868;
  font-size: 0.8rem;
  text-align: left;
}
.header-blocker {
  height: 14vh;
}
.login-main--content--wrapper {
  padding: 0 80px;
}
.login-main--content--textbox {
  margin: 5px;
}
.login-main--content--password {
  margin-top: 20px;
}
.error-message {
  height: 10px;
  text-align: left;
  color: #e85951;
  font-weight: bold;
}
/*iPad用css*/
@media (min-width: 641px) {
  .login-main--paper {
    width: 480px;
  }
}
@media (min-width: 1024px) {
  .login-main {
    width: 60%;
    margin: auto;
  }
}
</style>

<template>
  <div>
    <h2 class="r-list-header">
      <slot></slot>
    </h2>
    <r-hr hrStyle="primary" />
  </div>
</template>

<script>
import RHr from "@/plugins/atoms/RHorizontalRule.vue";
export default {
  name: "r-list-header",
  components: {
    RHr,
  },
};
</script>

<style scoped>
.r-list-header {
  border-left: 5px solid #e87352;
  text-align: left;
  padding-left: 10px;
  margin: 0 0 10px 0;
  font-size: 1rem;
}
</style>

import { render, staticRenderFns } from "./RModal.vue?vue&type=template&id=32ef06c0&scoped=true"
import script from "./RModal.vue?vue&type=script&lang=js"
export * from "./RModal.vue?vue&type=script&lang=js"
import style0 from "./RModal.vue?vue&type=style&index=0&id=32ef06c0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ef06c0",
  null
  
)

export default component.exports
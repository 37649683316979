<template>
  <!--遅延ローディングを入れる -->
  <div>
    <r-heading :level="1" :image="headingImage">
      {{ $t("recipe.recipeList.viewRecipe") }}</r-heading
    >
    <div class="recipe-list">
      <r-spinner v-show="isShowSpinner || isLoadingMore" />
      <div v-show="!(isShowSpinner || isLoadingMore)">
        <r-alert v-if="deleteFlag" class="recipe-list-delete-msg">
          <span>{{ $t("recipe.recipeList.deleteOneRecipe") }}</span>
        </r-alert>
        <div class="recipe-list-category-search-filtering-form">
          <!--ボタン-->
          <div class="recipe-list-button-wrapper">
            <!--店舗の選択-->
            <r-button
              class="recipe-list--category-button"
              buttonStyle="primary"
              outline
              @click="toggleStore"
              v-show="isShowSelectStore"
              >{{ $t("recipe.recipeTop.selectStore") }}</r-button
            >
            <!--キーワード検索ボタン-->
            <r-button
              class="recipe-list--category-button"
              buttonStyle="primary"
              outline
              @click="toggleSearch"
              >{{ $t("common.keywordSearch") }}</r-button
            >
            <!--カテゴリから選択ボタン-->
            <r-button
              class="recipe-list--category-button"
              buttonStyle="primary"
              outline
              @click="toggleCategory"
              >{{ $t("recipe.recipeList.selectCategory") }}</r-button
            >
          </div>
          <div
            class="recipe-list-filtering-wrapper"
            :class="[
              !(isShowStore && isShowSearch && isShowCategory)
                ? 'recipe-list-filtering-wrapper store-list'
                : '',
              isShowCategory ? ' margin-top' : '',
              isShowSearch ? ' margin-top' : '',
              isShowStore ? ' margin-top' : '',
            ]"
          >
            <!--店舗リスト-->
            <transition
              name="category"
              @before-enter="beforeEnter"
              @enter="enter"
              @before-leave="beforeLeave"
              @leave="leave"
            >
              <r-paper
                round
                v-show="isShowStore"
                v-if="isShowSelectStore"
                class="recipe-list--store-select"
              >
                <r-radio :options="stores" @click="filteringRecipeByStore" />
              </r-paper>
            </transition>
            <!--キーワード検索入力欄-->
            <transition
              name="category"
              @before-enter="beforeEnter"
              @enter="enter"
              @before-leave="beforeLeave"
              @leave="leave"
            >
              <r-paper
                round
                v-if="isShowSearch"
                class="recipe-list--keyword-search"
              >
                <r-search-form @search="searchRecipe" />
              </r-paper>
            </transition>
            <!--category-->
            <transition
              name="category"
              @before-enter="beforeEnter"
              @enter="enter"
              @before-leave="beforeLeave"
              @leave="leave"
            >
              <r-paper
                round
                class="recipe-list-category-list"
                v-show="isShowCategory"
              >
                <r-radio
                  :options="categories"
                  @click="filteringRecipeByCategory"
                  class="recipe-list-category-list-items"
                />
              </r-paper>
            </transition>
          </div>
        </div>
        <r-spinner v-if="isShowListSpinner" />
        <div v-else>
          <!-- カードここから -->
          <div class="recipe-list--card-wrapper" v-if="hasRecipes">
            <recipe-list-card
              v-for="(recipe, index) in recipes"
              :key="index"
              class="recipe-list--card"
              :title="recipe?.name"
              :label="recipe.cat"
              :image="recipe.image"
              :storeID="convertFromStoreIDToName(recipe.storeID)"
              :isSubRecipe="recipe.isSubRecipe"
              :isCostOver="isCostOver(recipe.costRate, recipe.targetRate)"
              :inputStatus="inputStatus(index)"
              :isHeadquarter="isHeadquarter"
              @click="toRecipeDetail(recipe.id, recipe.storeID)"
            />
          </div>
          <div v-else class="recipe-searching-result-none">
            <p>{{ $t("recipe.recipeList.noRegisteredRecipe") }}</p>
          </div>
          <r-button
            v-if="hasRecipes && isShowMoreButton"
            buttonStyle="primary"
            outline
            full
            class="recipe-list--see-more-button"
            @click="loadMoreRecipes"
            >{{ $t("common.showMore") }}</r-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RRadio from "@/plugins/atoms/RRadio.vue";
import RecipeListCard from "@/components/recipe/organisms/RecipeListCard.vue";
import RSearchForm from "@/plugins/molecules/RSearchForm.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import categoryManager from "@/libs/Category.js";
import recipeManager from "@/libs/Recipe.js";
import userManager from "@/libs/User.js";

import RAlert from "@/plugins/atoms/RAlert.vue";

export default {
  name: "recipeList",
  components: {
    RecipeListCard,
    RButton,
    RPaper,
    RRadio,
    RSearchForm,
    RHeading,
    RSpinner,
    RAlert,
  },
  methods: {
    async filteringRecipeByCategory(index) {
      if (index > 0) {
        this.currentCategory = this.categories[index]?.name;
      } else {
        this.currentCategory = "";
      }
      this.page = 1;
      await this.searchRecipe("", 1, this.limit);
    },
    async filteringRecipeByStore(index) {
      if (index > 0) {
        this.currentStore = this.stores[index].id;

        this.categories = await categoryManager.getCategoriesForList(
          this.customerID,
          this.currentStore,
        );
      } else {
        this.currentStore = "";
        this.categories = await categoryManager.getCategoryArrayList(
          this.customerID,
        );
      }
      this.page = 1;
      await this.searchRecipe("", 1, this.limit);
    },
    toggleCategory() {
      // カテゴリ
      this.isShowCategory = !this.isShowCategory;
      this.recipeIndex = 8;
    },
    toggleStore() {
      this.isShowStore = !this.isShowStore;
    },
    toggleSearch() {
      this.isShowSearch = !this.isShowSearch;
    },
    toRecipeDetail(selected, storeID) {
      if (selected && storeID) {
        if (this.stores.length > 0) {
          // 店舗選択する場合はstoreIDを設定していないためここでセット
          this.storeID = storeID;
          const store = this.stores.filter((e) => e.id === storeID);
          let storeName = "";
          if (store.length > 0) {
            storeName = store[0]?.name;
          }
          this.$root.setStoreInfoAction(this.storeID, storeName);
          sessionStorage.storeID = this.storeID;
          sessionStorage.storeName = storeName;
        }
        this.$router.push(
          { name: "recipeDetail", params: { id: selected } },
          () => {},
        );
      }
    },
    async setStore() {
      if (this.customerID) {
        this.stores = await auth.getStoreArrayList(this.customerID);
      }
    },
    async searchRecipe(searchWord = "", page = 1, limit = 8) {
      this.isShowListSpinner = page === 1;
      this.isLoadingMore = page > 1;
      this.searchWord = searchWord;

      let searchStore = this.isShowSelectStore
        ? this.currentStore
        : this.storeID;

      try {
        const response = await recipeManager.searchRecipe(
          searchWord,
          this.currentCategory,
          this.customerID,
          searchStore,
          page,
          limit,
        );

        if (page === 1) {
          this.recipes = response.recipes;
        } else {
          this.recipes.push(...response.recipes);
        }

        this.isHasMore = response.isHasMore;

        if (response.recipes.length > 0) {
          this.currentPage = (response.page ?? 1) + 1;
        }

        this.isShowSpinner = false;
        this.isLoadingMore = false;
        this.isShowListSpinner = false;
      } catch (error) {
        console.error("Error searching recipes:", error);
        this.isShowSpinner = false;
        this.isLoadingMore = false;
        this.isShowListSpinner = false;
      }
    },
    beforeEnter(el) {
      el.style["height"] = 0;
    },
    enter(el) {
      el.style["height"] = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style["height"] = el.scrollHeight + "px";
    },
    leave(el) {
      el.style["height"] = 0;
    },
    addRecipeIndex() {
      this.recipeIndex += 8;
    },
    async loadMoreRecipes() {
      if (this.isLoadingMore || !this.isHasMore) return;
      const scrollTopBeforeLoad = window.scrollY;
      await this.searchRecipe(this.searchWord, this.currentPage, this.limit);
      this.$nextTick(() => {
        window.scrollTo({
          top: scrollTopBeforeLoad,
          behavior: "instant",
        });
      });
    },
  },
  computed: {
    inputStatus() {
      const _this = this;
      return function (index) {
        if (_this.recipes[index].costRate && _this.recipes[index].tutorials) {
          return "finish";
        } else if (_this.recipes[index].costRate) {
          return "cost";
        } else if (_this.recipes[index].tutorials) {
          return "edit";
        } else {
          return "";
        }
      };
    },
    isCostOver() {
      return (costRate, targetRate) => {
        if (costRate === "" || targetRate === "") {
          return false;
        }

        if (costRate > targetRate) {
          return true;
        }
        return false;
      };
    },
    hasRecipes() {
      if (this.recipes.length > 0) {
        return true;
      }
      return false;
    },
    filteredRecipes() {
      return this.recipes.filter(
        (recipe, index) => index + 1 <= this.recipeIndex,
      );
    },
    isShowMoreButton() {
      return this.isHasMore;
    },
    convertFromStoreIDToName() {
      return (storeID) => {
        if (
          (this.isHeadquarter || this.isShowSelectStore) &&
          this.storeList.length
        ) {
          const index = this.storeList.map((e) => e.id).indexOf(storeID);
          return this.storeList[index]?.name;
        } else {
          return "";
        }
      };
    },
  },
  data() {
    return {
      recipes: [],
      // 検索用変数
      categories: [],
      currentCategory: "",
      searchWord: "",
      customerID: "",
      isShowCategory: false,
      stores: [],
      currentStore: "",
      isShowStore: false,
      storeID: "",
      isHeadquarter: false,
      isShowSearch: false,
      isShowSpinner: true,
      isShowListSpinner: false,
      isViewOtherRp: false,
      isShowSelectStore: false,
      headingImage: require("../../../assets/images/viewColor.png"),
      deleteFlag: false,
      recipeIndex: 10,
      storeList: [],
      deleteRecipe: "",
      currentPage: 1,
      isHasMore: true,
      isLoadingMore: false,
      limit: 8,
    };
  },
  async created() {
    try {
      const isStore = await auth.isStore();
      this.isHeadquarter = await auth.isHeadquarters();
      const uid = await auth.getUid();
      const userInfo = await userManager.getUserInfo(uid);
      this.isViewOtherRp = userInfo.viewOtherRp;

      // 他店舗の表示チェック
      if (isStore) {
        if (this.isViewOtherRp) {
          this.isShowSelectStore = true;
        } else {
          this.isShowSelectStore = false;
        }
      } else {
        this.isShowSelectStore = true;
      }

      if (!this.isShowSelectStore) {
        this.customerID = await auth.getCustomerID(this.$root);
        this.storeID = await auth.getStoreID(this.$root);
        if (!this.customerID || !this.storeID) {
          // customerID、StoreIDが存在しない場合はエラー
          throw new Error("Forbidden");
        }
        // カテゴリ一覧を取得
        this.categories = await categoryManager.getCategoriesForList(
          this.customerID,
          this.storeID,
        );
      } else {
        this.customerID = await auth.getCustomerID(this.$root);
        if (!this.customerID) {
          // 顧客IDが存在しない場合はエラー
          throw new Error("Forbidden");
        }

        this.storeID = "";
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";
        // 店舗一覧を表示
        await this.setStore();
        // 本部の場合は全てのカテゴリを表示
        this.categories = await categoryManager.getCategoryArrayList(
          this.customerID,
        );
      }
      //削除時の遷移
      if (this.$route.params.deleteRecipe) {
        this.deleteRecipe = this.$route.params.deleteRecipe;
      }
      // 全てにチェック
      this.filteringRecipeByCategory(0);

      // 本部ユーザのときは、各レシピの店舗名を表示する
      if (this.isHeadquarter || this.isShowSelectStore) {
        this.isHeadquarter = true;
        const storeArrayList = await auth.getStoreArrayList(this.customerID);
        storeArrayList.shift();
        this.storeList = storeArrayList;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    if (this.$route.params.deleteFlag) {
      this.deleteFlag = true;
    }
  },
};
</script>

<style scoped>
/*------------
 スマホ
--------------*/
.recipe-list {
  padding: 60px 10px 0;
  box-sizing: border-box;
}
.recipe-list-delete-msg {
  margin-bottom: 20px;
}
.recipe-list-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.recipe-list--category-button {
  padding: 5px 0.5em;
  font-size: 0.8rem;
  margin-right: 5px;
}
.recipe-list--category-button:last-child {
  margin-right: unset;
}
.recipe-list--store-select {
  margin-bottom: 20px;
  display: flex;
  height: auto !important;
  transition: height 0.2s linear;
  margin-top: 20px;
  overflow: auto;
}
.store-list {
  margin-top: 0px !important;
}
.recipe-list-filtering-wrapper {
  margin-top: 20px;
  display: block;
}
.recipe-list--card-wrapper {
  display: block;
}
.recipe-list--card {
  clear: both;
  margin-top: 24px;
}
.recipe-list--see-more-button {
  margin-top: 20px;
}
.recipe-searching-result-none {
  padding-top: 20px;
  margin-top: 20px;
  display: inline-flex;
  width: 100%;
}
.btn-margin-top {
  margin-top: 20px;
}
.store-list-btn-margin {
  margin-bottom: 20px;
}

.recipe-list-category-list {
  transition: height 0.2s linear;
  margin-top: 20px;
  /* display: flex; */
  overflow: auto;
}
.recipe-list--keyword-search {
  transition: height 0.2s linear;
  margin-top: 20px;
  overflow: auto;
}
.recipe-list-category-list-items {
  padding-bottom: unset;
}
/**
  カテゴリー用のトランジション
  transitionタグのname属性に'category'を付与する
 */
.category-enter-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: category-opened;
}
.category-leave-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: category-closed;
}

@keyframes category-opened {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes category-closed {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*------------
 タブレット・PC
--------------*/

@media screen and (min-width: 641px) {
  .recipe-list {
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 100px;
    box-sizing: border-box;
    width: 95vw;
  }
  .recipe-list-category-search-filtering-form {
    display: grid;
  }
  .recipe-list-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px;
  }
  .margin-top {
    margin-top: 20px !important;
  }
  .recipe-list--category-button {
    font-size: 1rem;
    margin-right: 20px;
  }
  .store-list-wrapper {
    margin-top: 20px;
    display: block;
  }
  .recipe-list--card-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 95vw;
  }
  .recipe-list--card {
    box-sizing: border-box;
    width: 32%;
    margin-right: 2%;
  }
  .recipe-list--card:nth-child(3n) {
    margin-right: 0;
  }
  .recipe-searching-result-none {
    width: 95vw;
  }
}

/*------------
 PC
--------------*/

@media screen and (min-width: 1024px) {
  .recipe-list {
    width: 1024px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 120px 0 0 0;
  }
  .recipe-list--card-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 1024px;
  }
  .recipe-list--card {
    width: 238px;
    box-sizing: border-box;
  }
  .recipe-list--card:nth-child(3n) {
    margin-right: 24px;
  }
  .recipe-list--card:nth-child(4n) {
    margin-right: 0;
  }
  .recipe-list-category-list-items {
    padding-bottom: unset;
  }
  .recipe-searching-result-none {
    width: 1024px;
  }
}
</style>

<template>
  <div>
    <r-heading :level="1" :image="headingImage">
      {{ $t("recipe.recipeProcedure.recipeProcedureTitle") }}
    </r-heading>
    <div class="recipe-procedure">
      <!-- ページ遷移ボタンここから -->
      <div class="recipe-edit--buttons back-btn">
        <r-button
          buttonStyle="primary"
          outline
          class="recipe-procedure--menu-button"
          @click="backDetail"
          >{{ $t("recipe.recipeProcedure.backToRecipeDetail") }}</r-button
        >
      </div>
      <!-- ページ遷移ボタンここまで -->
      <!--レシピタイトル-->
      <r-heading class="recipe-procedure--title" :level="2">
        {{ name }}
      </r-heading>

      <!--作り方リスト-->
      <div class="recipe-procedure--paper--wrapper">
        <r-paper
          round
          class="recipe-procedure--paper"
          :class="{
            'recipe-procedure--paper--normal':
              tutorials[tutorial.step - 1].text === '' &&
              tutorials[tutorial.step - 1].image === '',
            'recipe-procedure--paper--current': current === index,
          }"
          v-for="(tutorial, index) in tutorials"
          :key="index"
          @click.stop="openModal(index)"
        >
          <!-- カード内部未入力状態の表示 -->
          <div
            class="recipe-procedure--paper--content"
            v-if="
              tutorials[tutorial.step - 1].text === '' &&
              tutorials[tutorial.step - 1].image === ''
            "
          >
            <i
              class="fas fa-edit recipe-procedure--paper--content--icon"
              v-if="index === current"
            ></i>
            <span class="recipe-procedure--paper--content--text">
              {{ $t("recipe.recipeProcedure.step") }}{{ tutorial.step
              }}{{ $t("recipe.recipeProcedure.register") }}
            </span>
          </div>

          <!-- カード内部入力状態の表示 -->
          <div class="recipe-procedure--paper--content-finish" v-else>
            <h2 class="recipe-procedure--paper--content-finish--title">
              step{{ tutorial.step }}
            </h2>
            <div
              v-if="tutorial.image"
              class="recipe-procedure--paper--content-finish--image"
              :style="{
                'background-image': 'url(' + tutorial.image + ')',
              }"
            />
            <div class="recipe-procedure--img--default" v-else></div>
            <p
              class="recipe-procedure--paper--content-finish--text"
              v-html="replaceNtoBr(tutorial.text)"
            ></p>

            <div class="recipe-procedure--paper--content-finish--buttons">
              <r-button
                class="recipe-procedure--paper--content-finish--button recipe-procedure--paper--content-finish--button--left"
                buttonStyle="primary"
                outline
                full
                @click.stop="editTutorial(index)"
              >
                {{ $t("recipe.recipeProcedure.editRecipeProcedure") }}
              </r-button>
              <r-button
                class="recipe-procedure--paper--content-finish--button"
                @click.stop="deleteTutorial(index)"
                buttonStyle="primary"
                outline
                full
              >
                {{ $t("recipe.recipeProcedure.deleteRecipeProcedure") }}
              </r-button>
            </div>
          </div>
        </r-paper>
        <!-- 「＋」ボタン-->
        <r-paper
          round
          class="recipe-procedure--paper recipe-procedure--paper--normal recipe-procedure--paper--add-button"
          :class="[
            tutorials.map((e) => e.text).includes('')
              ? 'recipe-procedure--paper--add-button--disable'
              : 'recipe-procedure--paper--add-button--able',
          ]"
          @click="addStep(tutorials.length)"
        >
          <font-awesome-icon
            :icon="['fas', 'plus-circle']"
            class="fas fa-plus-circle"
          ></font-awesome-icon>
        </r-paper>
      </div>

      <!-- モーダル -->
      <transition name="modal">
        <div
          class="recipe-procedure--modal-bg"
          v-if="modalFlag.includes(true)"
          @mousedown.self="closeModal(modalFlag.indexOf(true))"
        >
          <recipe-procedure-form
            v-if="modalFlag.includes(true)"
            :step="modalFlag.indexOf(true) + 1"
            @enter="enterFromModal"
            @cancel="closeModal(modalFlag.indexOf(true))"
            :items="items"
            :tmpImg="tmpImg"
            :tmpText="tmpText"
            class="recipe-procedure--modal"
          ></recipe-procedure-form>
        </div>
      </transition>

      <r-button
        class="recipe-procedure--enter-button"
        buttonStyle="primary"
        full
        v-if="tutorials[0].text !== ''"
        @click="registerWithFirestore"
        >{{ $t("recipe.recipeProcedure.registerRecipeProcedure") }}</r-button
      >
    </div>
  </div>
</template>

<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";

import RecipeProcedureForm from "@/components/recipe/organisms/RecipeProcedureForm.vue";

import auth from "@/libs/Auth.js";
import recipeManager from "@/libs/Recipe.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  components: {
    RButton,
    RPaper,
    RHeading,
    RecipeProcedureForm,
  },
  data() {
    return {
      tutorials: [
        {
          step: 1,
          id: "",
          text: "",
          image: "",
        },
        {
          step: 2,
          id: "",
          text: "",
          image: "",
        },
        {
          step: 3,
          id: "",
          text: "",
          image: "",
        },
      ],
      // 削除用の配列
      deleteID: [],
      recipeID: "",
      modalFlag: [false, false, false],
      name: "",
      current: 0,
      items: [],
      tmpImg: "",
      tmpText: "",
      headingImage: require("../../../assets/images/recipeColor.png"),
      isSubmitted: false,
    };
  },
  computed: {
    replaceNtoBr() {
      return (text) => {
        return this.$sanitize(text.replace(/\r?\n/g, "<br>"));
      };
    },
  },
  methods: {
    async backDetail() {
      // 編集中解除
      await auth.unLockEditStatus();
      // Detail画面に遷移
      this.$router.push(
        {
          name: "recipeDetail",
          params: { id: this.recipeID },
        },
        () => {},
      );
    },
    openModal(index) {
      if (this.current === index) {
        this.$set(this.modalFlag, index, true);
        //モーダルコンポーネント指定、モーダル背景固定処理
        const modal = document.querySelector(".recipe-procedure--modal");
        disableBodyScroll(modal);
      }
    },
    closeModal(index) {
      this.tmpImg = "";
      this.tmpText = "";
      this.$set(this.modalFlag, index, false);
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    addStep(index) {
      if (!this.tutorials.map((e) => e.text).includes("")) {
        this.tutorials.push({
          id: "",
          step: index + 1,
          text: "",
          image: "",
        });
        this.modalFlag.push(false);
        /*currentを未作成のtutorialsのindexに設定*/
        this.current = this.tutorials.map((e) => e.text).indexOf("");
      }
    },
    enterFromModal(index, text, image) {
      this.$set(this.tutorials[index], "text", text);
      this.$set(this.tutorials[index], "image", image);
      //this.current++;
      this.current = this.tutorials.map((e) => e.text).indexOf("");
      this.tmpImg = "";
      this.tmpText = "";

      this.closeModal(index);
    },
    editTutorial(index) {
      this.tmpImg = this.tutorials[index].image;
      this.tmpText = this.tutorials[index].text;
      this.$set(this.modalFlag, index, true);
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".recipe-procedure--modal");
      disableBodyScroll(modal);
    },
    deleteTutorial(index) {
      if (this.tutorials[index].id !== "") {
        this.deleteID.push(this.tutorials[index].id);
      }
      // 押されたインデックスの要素を排除
      let arr = this.tutorials.filter((e) => e.step !== index + 1);
      // 押されたインデックスの要素からIDを取り出し追加(ない場合はなにもしない)

      arr.forEach((e, index) => {
        if (e.step > index + 1) {
          e.step = index + 1;
        }
      });
      // 配列の要素数が3より小さくなった場合、最後に空のオブジェクトを追加する
      if (arr.length < 3) {
        arr.push({
          step: 3,
          id: "",
          text: "",
          image: "",
        });
      } else {
        arr.push({
          step: arr.length + 1,
          id: "",
          text: "",
          image: "",
        });

        // 削除した際に白いカードが残ってしまうため削除処理
        if (arr.length > this.current) {
          arr.pop();
          this.modalFlag.pop();
        }
      }
      this.current = arr.map((e) => e.text).indexOf("");
      this.tutorials = arr;
    },
    async registerWithFirestore() {
      if (!this.isSubmitted) {
        this.isSubmitted = true;
        try {
          await recipeManager.registTutorial(
            this.customerID,
            this.storeID,
            this.recipeID,
            this.tutorials,
            this.deleteID,
          );

          // 編集中解除
          await auth.unLockEditStatus();

          // 全画面に戻る
          this.$router.push(
            {
              name: "recipeDetail",
              params: { id: this.recipeID },
            },
            () => {},
          );

          this.isSubmitted = false;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log("ERROR", error);
          throw new Error(error);
        }
      }
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      if (this.$route.params.id) {
        this.recipeID = this.$route.params.id;
      } else {
        // recipeIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      if (this.$route.params.tutorials) {
        const tutorialsParams = this.$route.params.tutorials;

        tutorialsParams.forEach((tutorial) => {
          const indexStep = Number(tutorial.step) - 1;
          if (indexStep < 3) {
            this.$set(this.tutorials, indexStep, tutorial);
          } else {
            this.tutorials.push(tutorial);
          }
        });
      } else {
        // チュートリアルが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      // モーダルで表示する食材一覧を取得する
      if (this.recipeID) {
        // レシピ名称と食材を取得
        this.name = await recipeManager.getRecipeName(this.recipeID);
        this.items = await recipeManager.getItems(this.recipeID);
      } else {
        // recipeIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    if (this.tutorials.map((e) => e.text).indexOf("") === -1) {
      this.tutorials.push({
        step: this.tutorials.length + 1,
        id: "",
        text: "",
        image: "",
      });
    }
    this.current = this.tutorials.map((e) => e.text).indexOf("");
  },
};
</script>

<style scoped>
.recipe-procedure {
  box-sizing: border-box;
  padding: 60px 20px 0;
  width: 100%;
  height: 100%;
  min-height: 700px;
}
.back-btn {
  text-decoration: none;
}
.recipe-procedure--menu-button {
  display: block;
  text-align: left;
  margin-top: 20px;
}
.recipe-procedure--menu-button:hover {
  color: #ffffff;
  background-color: #e87352;
  transition: 0.5s;
}
.recipe-procedure--title {
  margin-top: 20px;
}
.recipe-procedure--paper {
  min-height: 24vh;
  width: 100%;
  margin-top: 20px;
  padding: 10px 20px 10px 20px;
  box-sizing: border-box;
}
.recipe-procedure--paper--normal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.recipe-procedure--paper--current {
  border: 2px solid #e87352;
}
.recipe-procedure--paper--current:hover {
  cursor: pointer;
  opacity: 0.7;
}
.recipe-procedure--paper--content {
  display: flex;
  flex-direction: column;
}

.recipe-procedure--paper--content--icon {
  font-size: 3rem;
  color: #e87352;
}
.recipe-procedure--paper--content--text {
  margin-top: 10px;
}
.recipe-procedure--paper--content-finish {
  width: 100%;
  text-align: left;
}
.recipe-procedure--paper--content-finish--title {
  color: #e87352;
  margin: 10px 0 0 0;
}
.recipe-procedure--paper--content-finish--image {
  min-height: 30vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 10px;
}
.recipe-procedure--img--default {
  min-height: 20vh;
  max-height: 25vh;
  background-image: url(~@/assets/images/logo4.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 5vh;
}
.recipe-procedure--paper--content-finish--text {
  width: 100%;
  word-wrap: break-word;
  box-sizing: border-box;
  border-left: 5px solid #e87352;
  margin: 0;
  margin-top: 10px;
  padding-left: 10px;
}
.recipe-procedure--paper--content-finish--buttons {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 10px 0px 10px;
}
.recipe-procedure--paper--content-finish--button--left {
  margin-right: 10px;
  margin-bottom: 10px;
}
.recipe-procedure--modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  margin: auto;
  padding: 5%;
  overflow: auto;
}
.recipe-procedure-edit-btn {
  margin-top: 10px;
  margin-bottom: 10px;
}
.recipe-procedure-edit-btn:last-child {
  margin-top: unset;
  margin-bottom: unset;
}
.recipe-procedure--paper--add-button {
  min-height: 24vh;
  background-color: #e87352;
  color: #ffffff;
  font-size: 8rem;
}
.recipe-procedure--paper--add-button--able:hover {
  cursor: pointer;
}
.recipe-procedure--paper--add-button--disable {
  background-color: #a8a8a8;
}
.recipe-procedure--enter-button {
  margin-top: 20px;
}

@media screen and (min-width: 641px) {
  .recipe-procedure {
    margin: 0 auto;
    padding: 20px;
    padding-top: 100px;
  }
  .recipe-procedure--paper--wrapper {
    margin-top: 20px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    line-height: 1;
    clear: both;
  }
  .recipe-edit--buttons {
    position: absolute;
    top: 105px;
  }
  .recipe-procedure--paper {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    float: left;
    margin-top: 0px;
    margin-right: 2%;
    margin-bottom: 24px;
    padding: 16px 16px 16px;
    width: calc(94% / 4);
    min-height: 26vh;
    max-height: auto;
  }
  .recipe-procedure--paper:nth-of-type(4n) {
    margin-right: 0px;
  }
  .recipe-procedure--paper:nth-of-type(4n + 1) {
    /*改行最初のSTEPに該当*/
    clear: both;
  }
  .recipe-procedure--paper:last-child {
    margin-bottom: 0px;
  }

  /*未登録作り方*/
  .recipe-procedure--paper--content {
    display: flex;
    flex-direction: column;
  }

  .recipe-procedure--paper--content--icon {
    font-size: 3rem;
    color: #e87352;
  }
  .recipe-procedure--paper--content--text {
    margin-top: 10px;
  }
  /*登録済み*/
  .recipe-procedure--paper--content-finish:nth-of-type(4n) {
    margin-right: 0px;
  }
  .recipe-procedure--paper--content-finish--image {
    min-height: 15vh;
    max-height: 20vh;
  }
  .recipe-procedure--img--default {
    min-height: 15vh;
    max-height: 20vh;
  }
  .recipe-procedure--paper--content-finish--text {
    margin-top: 20px;
  }
  .recipe-procedure--paper--content-finish--buttons {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    bottom: 20px;
    margin-top: 20px;
  }
  .recipe-procedure--paper--content-finish--button--left {
    margin-bottom: unset;
  }
  .recipe-procedure-edit-btn {
    padding: 0px 1px 0px;
    font-size: 0.7rem;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 3px;
  }
  .recipe-procedure-edit-btn:last-child {
    margin-right: unset;
  }
  .recipe-procedure--paper--add-button {
    background-color: #e87352;
    color: #ffffff;
    font-size: 8rem;
  }
  .recipe-procedure--enter-button {
    margin-top: 20px;
  }
  .recipe-procedure--modal-bg {
    padding: 10%;
  }
}

@media screen and (min-width: 1024px) {
  .recipe-procedure {
    width: 1024px;
    margin: auto;
    padding: 0px;
    padding-top: 120px;
  }
  /*作り方リスト*/
  .recipe-procedure--paper--wrapper {
    display: block;
    width: 100%;
    box-sizing: border-box;
    line-height: 1.5;
    clear: both;
  }
  .recipe-edit--buttons {
    position: absolute;
    top: 95px;
  }
  .recipe-procedure--paper {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    float: left;
    margin-right: 20px;
    margin-bottom: 24px;
    padding: 16px 16px 16px;
    width: calc(25% - 16px);
    min-height: 32vh;
    max-height: auto;
  }
  /*未登録作り方*/
  .recipe-procedure--paper--content {
    display: flex;
    flex-direction: column;
  }
  .recipe-procedure--paper--content--icon {
    font-size: 3rem;
    color: #e87352;
  }
  .recipe-procedure--paper--content--text {
    margin-top: 10px;
  }
  /*登録済み*/
  .recipe-procedure--paper--content-finish:nth-of-type(4n) {
    margin-right: 0px;
  }
  .recipe-procedure--paper--content-finish--image {
    min-height: 20vh;
    max-height: 25vh;
    /* width: 100%;
    vertical-align: top; */
  }
  .recipe-procedure--img--default {
    min-height: 20vh;
    max-height: 25vh;
  }
  .recipe-procedure--paper--content-finish--text {
    margin-top: 20px;
  }
  .recipe-procedure--paper--content-finish--buttons {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    bottom: 20px;
    margin-top: 20px;
  }
  .recipe-procedure-edit-btn {
    font-size: 1rem;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 20px;
  }
  .recipe-procedure-edit-btn:last-child {
    margin-right: unset;
  }
  .recipe-procedure--paper--add-button {
    background-color: #e87352;
    color: #ffffff;
    font-size: 8rem;
  }
  .recipe-procedure--paper--add-button--able:hover {
    background-color: #ea5446;
    transition: 0.5s;
    cursor: pointer;
  }
  .recipe-procedure--paper--add-button--disable {
    background-color: #a8a8a8;
  }
  .recipe-procedure--enter-button {
    margin-top: 20px;
  }
  .recipe-procedure--modal-bg {
    padding-top: 20%;
    padding-right: 15%;
    padding-left: 15%;
  }
}
</style>

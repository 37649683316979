<template>
  <r-modal
    class="course-selling-price"
    :step="step ? '4' : ''"
    :title="$t('course.courseRegistration.coursePriceForm.inputPrice')"
    enter
    @enter="enter"
    @cancel="cancel"
  >
    <div class="course-selling-price--content">
      <span>
        {{ $t("common.cost") }}
        <strong>{{ totalCost }}</strong>
        {{ $t("common.currencyYen") }}/1{{ $t("common.perPerson") }}
      </span>
    </div>
    <r-hr hrStyle="sub-primary" class="course-selling-price--content" />

    <div
      class="course-selling-price--content course-selling-price--input-wrapper"
    >
      <div
        class="course-selling-price-error-sentence"
        v-show="price === 0 || price === ''"
      >
        {{ error }}
      </div>
      <div class="course-selling-price-input-wrapper">
        <r-textbox
          class="course-selling-price--input"
          type="number"
          v-model.number="price"
          :error="error !== ''"
        />{{ $t("common.currencyYen") }}
      </div>
    </div>

    <r-chart
      class="course-selling-price--content"
      :rate="costRate"
      :label="$t('common.costRate')"
    />
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";
import RChart from "@/plugins/atoms/RChart.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";

export default {
  components: {
    RModal,
    RHr,
    RChart,
    RTextbox,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    existingPrice: {
      type: Number,
      default: 0,
    },
    totalCost: Number,
  },
  data() {
    return {
      price: 0,
      error: "",
      step: false,
    };
  },
  computed: {
    costRate() {
      return Number(((this.totalCost / this.price) * 100).toFixed(2));
    },
  },
  methods: {
    enter() {
      if (this.price === 0 || this.price === "") {
        this.error = this.$i18n.t(
          "course.courseRegistration.coursePriceForm.invalidValueOrNotInput",
        );
        return;
      }
      this.$emit("enter", this.price, this.costRate);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  mounted() {
    this.price = this.existingPrice;
    if (this.$route.path === "/course-registration") {
      this.step = true;
    }
  },
};
</script>

<style scoped>
.course-selling-price--content {
  margin-top: 20px;
}
.course-selling-price--input-wrapper {
  display: grid;
  align-items: center;
  justify-content: center;
}
.course-selling-price-error-sentence {
  grid-row: 1/1;
  transition: 1s;
  margin-bottom: 10px;
}
.course-selling-price-input-wrapper {
  grid-row: 2/3;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<template>
  <div class="r-selected-items">
    <div class="r-selected-items--main">
      <span class="r-selected-items--main--title">{{ title }}</span>
      <div class="r-selected-items--main--items">
        <slot></slot>
      </div>
    </div>
    <div class="selected-food-stuff--action">
      <r-button
        v-if="enter"
        class="selected-food-stuff--action--button"
        buttonStyle="primary"
        :middle="isWindowTab"
        :class="{ 'button-mobile': !isWindowTab }"
        @click="clickHandler('enter')"
        >{{ $t("common.decide") }}</r-button
      >
      <r-button
        buttonStyle="primary"
        :middle="isWindowTab"
        :class="{ 'button-mobile': !isWindowTab }"
        outline
        @click="clickHandler('cancel')"
        >{{ $t("common.cancel") }}</r-button
      >
    </div>
  </div>
</template>

<script>
import RButton from "@/plugins/atoms/RButton.vue";

export default {
  name: "r-selected-item",
  components: {
    RButton,
  },
  data() {
    return {
      width: 0,
    };
  },
  methods: {
    clickHandler(action) {
      this.$emit(action);
    },
    handleResize() {
      this.width = window.innerWidth;
    },
  },
  computed: {
    isWindowTab() {
      return this.width >= 641;
    },
  },
  props: {
    title: String,
    enter: Boolean,
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.r-selected-items {
  box-sizing: border-box;
  position: absolute;
  width: 100vw;
  height: 30vh;
  background-color: #f8d5cb;
  right: 0;
  left: 0;
  bottom: 0;
}
.r-selected-items--main--title {
  background-color: #e87352;
  color: #ffffff;
  font-weight: bold;
  display: block;
  width: 100%;
}
.r-selected-items--main--items {
  box-sizing: border-box;
  height: 15vh;
  padding: 10px 20px;
  overflow: auto;
}
.selected-food-stuff--action {
  box-sizing: border-box;
  display: flex;
  padding: 20px 10px;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 2px solid #2c3e50;
  justify-content: center;
}
.selected-food-stuff--action--button {
  margin-right: 10px;
}
.button-mobile {
  width: 320px;
}

@media screen and (min-width: 641px) {
  .r-selected-items--main--items {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
}
</style>

import { render, staticRenderFns } from "./RFoodStuffCard.vue?vue&type=template&id=806b4730&scoped=true"
import script from "./RFoodStuffCard.vue?vue&type=script&lang=js"
export * from "./RFoodStuffCard.vue?vue&type=script&lang=js"
import style0 from "./RFoodStuffCard.vue?vue&type=style&index=0&id=806b4730&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "806b4730",
  null
  
)

export default component.exports
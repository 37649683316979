// 分析
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "@/libs";

export default {
  async getMinMaxDate(customerID, storeID) {
    let minMaxDate = {};
    try {
      const documentRef = doc(db, "customers", customerID, "stores", storeID);
      const documentSnapshot = await getDoc(documentRef);

      if (documentSnapshot.exists()) {
        const record = documentSnapshot.data();
        let convMinDate = null;
        let convMaxDate = null;
        if ("regiMinDate" in record) {
          convMinDate = record.regiMinDate.toDate();
        } else {
          convMinDate = new Date("2015/01/01 00:00:00");
        }

        if ("regiMaxDate" in record) {
          convMaxDate = record.regiMaxDate.toDate();
        } else {
          convMaxDate = new Date();
        }

        convMaxDate.setDate(convMaxDate.getDate() - 1);

        minMaxDate.min = `${convMinDate.getFullYear()}/${
          convMinDate.getMonth() + 1
        }/${convMinDate.getDate()}`;

        minMaxDate.max = `${convMaxDate.getFullYear()}/${
          convMaxDate.getMonth() + 1
        }/${convMaxDate.getDate()}`;
      }
      return minMaxDate;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getRegiData(customerID, storeID, minDate, maxDate, searchCat) {
    //  該当期間のレジデータを取得
    const regiData = await _getRegiData(
      customerID,
      storeID,
      minDate,
      maxDate,
      searchCat,
    );
    // 期間中の総売上を計算
    const totalSales = regiData.reduce((acc, doc) => {
      acc += Number(doc.price) * Number(doc.salesVol);
      return acc;
    }, 0);

    // 一覧を取得
    const contents = _createContents(regiData, totalSales);

    return contents;
  },
  // 総数を計算
  calcTotalValue(contents) {
    // 出数・売上高・総コスト計算
    const objTotal = _calcTotalVol(contents);

    // 売上総利益合計
    objTotal.totalGain = objTotal.totalSalesPrice - objTotal.totalCost;

    // 原価率計算
    const totalCostRate = (objTotal.totalCost / objTotal.totalSalesPrice) * 100;
    // 小数点第二位で四捨五入
    objTotal.totalCostRate = Math.round(totalCostRate * 100) / 100;

    return objTotal;
  },
};

async function _getRegiData(customerID, storeID, minDate, maxDate, searchCat) {
  let regiData = null;
  try {
    if (minDate) {
      minDate = minDate.replace(/-/g, "/");
    }
    if (maxDate) {
      maxDate = maxDate.replace(/-/g, "/");
    }

    const searchMaxDate = new Date(maxDate + " 00:00:00");
    searchMaxDate.setDate(searchMaxDate.getDate() + 1);

    const regiQuery = query(
      collection(db, "regiData"),
      where("customerID", "==", customerID),
      where("storeID", "==", storeID),
      where("bDay", ">=", new Date(minDate + " 00:00:00")),
      where("bDay", "<", searchMaxDate),
    );

    const querySnapshot = await getDocs(regiQuery);

    if (querySnapshot.empty) {
      return [];
    }
    // カテゴリでフィルタ
    regiData = querySnapshot.docs.reduce((acc, doc) => {
      const record = doc.data();
      record.id = doc.id;
      if (searchCat.length > 0) {
        const menuCat = record.cat;
        const matchArray = menuCat.filter((o) => searchCat.indexOf(o) != -1);
        if (matchArray.length > 0) {
          acc.push(record);
        }
      } else {
        // 全選択
        acc.push(record);
      }
      return acc;
    }, []);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error(error);
  }
  return regiData;
}

// 一覧を作成(PHPの処理を踏襲)
function _createContents(regiData, totalSales) {
  let contents = [];
  const _totalSales = Number(totalSales);

  // 出数の集計(muilt==false)の場合は１メニュー１データ
  const objSumVol = regiData.reduce((acc, regi) => {
    // ハッシュキーの作成(以下のまとめたキーでuniqe)
    const streHash =
      String(regi.regiID) +
      String(regi.price) +
      String(regi.cost) +
      String(regi.menuID);

    // 初期化
    if (!acc[streHash]) {
      const record = {
        menuID: regi.menuID, // メニューID
        title: regi.title, // メニュー名称
        salesVol: 0, // 出数
        sellPrice: Number(regi.price), // 販売価格
        cost: Number(regi.cost), // コスト
        multi: regi.multi, // DB上のmultiは未使用
      };
      acc[streHash] = record;
    }

    // 出数
    acc[streHash].salesVol += Number(regi.salesVol);
    return acc;
  }, {});

  if (Object.keys(objSumVol).length > 0) {
    // Objectから配列
    const arraySumVol = Object.keys(objSumVol).map(function (key) {
      return objSumVol[key];
    });

    // リストからmenuIDを抽出
    const menuIDList = arraySumVol.map((doc) => {
      return doc.menuID;
    });

    // 重複抽出
    const multiList = menuIDList.filter(function (x, i, self) {
      return self.indexOf(x) === i && i !== self.lastIndexOf(x);
    });

    // その他数値の計算
    let objCalc = arraySumVol.reduce((acc, doc) => {
      // 初期化
      if (!acc.multi) {
        acc.multi = {};
      }
      // 初期化
      if (!acc.single) {
        acc.single = [];
      }

      doc.salesPrice = doc.salesVol * doc.sellPrice;
      doc.salesRatio = _totalSales === 0 ? 0 : doc.salesPrice / _totalSales;
      doc.costRate = _calcCostRate(doc.cost, doc.sellPrice);
      doc.gain = _calcGain(doc.cost, doc.sellPrice, doc.salesVol);

      if (multiList.indexOf(doc.menuID) >= 0) {
        // multi
        doc.multi = true;
        if (Array.isArray(acc.multi[doc.menuID])) {
          acc.multi[doc.menuID].push(doc);
        } else {
          const recode = new Array();
          recode.push(doc);
          acc.multi[doc.menuID] = recode;
        }
      } else {
        // single
        doc.multi = false;
        acc.single.push(doc);
      }

      return acc;
    }, {});

    // single計算データ
    const singleCalcArray = objCalc.single;
    // multi データの計算
    let multiCalcArray = [];
    if (Object.keys(objCalc.multi).length > 0) {
      const obj = objCalc.multi;
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          // 同一メニューIDで抽出
          let title = "";
          let salesVol = 0;
          let salesPrice = 0;
          let sellPrice = 0;
          let cost = 0;
          const multiArray = obj[key];
          for (let i = 0; i < multiArray.length; i++) {
            title = multiArray[i].title;
            salesVol += multiArray[i].salesVol;
            salesPrice += multiArray[i].salesPrice;
            sellPrice += multiArray[i].sellPrice * multiArray[i].salesVol;
            cost += multiArray[i].cost * multiArray[i].salesVol;
          }

          const menuData = {
            menuID: key,
            title: title,
            salesVol: salesVol, // 出数
            sellPrice: salesVol !== 0 ? sellPrice / salesVol : 0, // 販売価格
            cost: salesVol !== 0 ? _calcCostAve(cost, salesVol) : 0, // コスト
            salesPrice: salesPrice, // 売り上げ合計
            salesRatio: salesPrice !== 0 ? salesPrice / _totalSales : 0,
            costRate: salesPrice !== 0 ? _calcCostRate(cost, salesPrice) : 0,
            gain: sellPrice - cost,
            multi: true,
          };
          multiCalcArray.push(menuData);
        }
      }
    }

    const calcArray = [...singleCalcArray, ...multiCalcArray];

    // 売り上げ構成比でソート
    calcArray.sort(function (a, b) {
      if (a.salesRatio > b.salesRatio) return -1;
      if (a.salesRatio < b.salesRatio) return 1;
      return 0;
    });

    // ABC分析
    const rankA = 0.7;
    const rankB = 0.9;

    let totalRatio = 0;
    contents = calcArray.map((content) => {
      totalRatio += content.salesRatio;
      content.totalRatio = totalRatio;
      if (content.totalRatio <= rankA) {
        content.rank = "A";
      } else if (content.totalRatio <= rankB && content.totalRatio > rankA) {
        content.rank = "B";
      } else {
        content.rank = "C";
      }
      return content;
    });
  }

  return contents;
}

function _calcCostAve(totalCost, salesVol) {
  if (!totalCost || !salesVol) {
    return 0;
  }

  let costAve = totalCost / salesVol;
  // 小数点第二位で四捨五入
  costAve = Math.round(costAve * 100) / 100;

  return costAve;
}

// 原価率
function _calcCostRate(cost, price) {
  if (cost === 0 || price === 0) {
    return 0;
  }
  let costRate = (Number(cost) / Number(price)) * 100;
  // 小数点第二位で四捨五入
  costRate = Math.round(costRate * 100) / 100;

  return costRate;
}

// 粗利
function _calcGain(cost, price, vol) {
  const gain = (Number(price) - Number(cost)) * Number(vol);
  return gain;
}

function _calcTotalVol(contents) {
  const totalVol = contents.reduce((acc, doc) => {
    if (Object.keys(acc).length == 0) {
      acc.totalSalesVol = 0;
      acc.totalSalesPrice = 0;
      acc.totalCost = 0;
      acc.totalGain = 0;
    }
    // 出数合計
    acc.totalSalesVol += Number(doc.salesVol);
    // 売上高合計
    acc.totalSalesPrice += Number(doc.salesPrice);
    // コスト合計
    acc.totalCost += Number(doc.cost) * Number(doc.salesVol);
    return acc;
  }, {});

  return totalVol;
}

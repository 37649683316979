<template>
  <div class="r-tab-for-pc">
    <div
      class="r-tab-for-pc--label"
      :class="{ 'r-tab-for-pc__active': isShow }"
      @click="clickHandler"
    >
      {{ label }}
    </div>
    <transition
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div class="r-tab-for-pc--content" v-if="isShow">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    clickHandler() {
      this.isShow = !this.isShow;
    },
    // アコーディオン制御用
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = 0;
    },
  },
  props: {
    label: String,
  },
};
</script>

<style scoped>
.r-tab-for-pc {
  text-align: left;
  width: 100%;
}

.r-tab-for-pc--label {
  background-color: #ffffff;
  box-sizing: border-box;
  border-left: 5px solid #e87352;
  padding: 10px;
  font-weight: bold;
  border-radius: 0 5px 5px 0;
}
.r-tab-for-pc--label:hover {
  cursor: pointer;
}
.r-tab-for-pc__active {
  background-color: #e87352;
  color: #ffffff;
  border-radius: 0 5px 0 0;
}

.r-tab-for-pc--content {
  transition: height 0.3s ease-in-out;
}
/**
  カテゴリー用のトランジション
  transitionタグのname属性に'category'を付与する
 */
.accordion-enter-active {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: accordion-opened;
}
.accordion-leave-active {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-name: accordion-closed;
}

@keyframes accordion-opened {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes accordion-closed {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

<template>
  <r-modal
    class="course-image-form"
    :title="$t('course.courseRegistration.courseImageForm.imageSelect')"
    :step="step ? '2' : ''"
    enter
    :skip="step && regist"
    @enter="enter"
    @skip="skip"
    @cancel="cancel"
  >
    <div class="error-message">
      {{ error.image }}
    </div>
    <r-fileuploader @change="changeImage" :image="image">{{
      $t("course.courseRegistration.courseImageForm.fileSelect")
    }}</r-fileuploader>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RFileuploader from "@/plugins/atoms/RFileupload.vue";

export default {
  components: {
    RModal,
    RFileuploader,
  },
  props: {
    regist: Boolean,
  },
  data() {
    return {
      image: "",
      error: {
        image: "",
      },
      step: false,
    };
  },
  methods: {
    enter() {
      this.executeValidation();
      if (this.error.image !== "") {
        return;
      }
      this.$emit("enter", this.image);
    },
    skip() {
      this.$emit("skip");
    },
    cancel() {
      this.$emit("cancel");
    },
    changeImage(value) {
      this.image = value;
    },
    executeValidation() {
      if (this.image === "") {
        this.$set(
          this.error,
          "image",
          this.$i18n.t(
            "course.courseRegistration.courseImageForm.courseImageNotUploaded",
          ),
        );
      }
    },
  },
  mounted() {
    if (this.$route.path === "/course-registration") {
      this.step = true;
    }
  },
};
</script>

<style scoped>
.error-message {
  color: #e85951;
  font-weight: bold;
}
</style>

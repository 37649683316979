<template>
  <div>
    <r-spinner v-if="loading"></r-spinner>
    <div v-else>
      <r-heading :level="1" :image="headingImage">
        <span v-if="targetRate === 0">{{
          $t("recipe.recipeCost.calculateRecipeCost")
        }}</span>
        <span v-else>{{ $t("recipe.recipeCost.recalculateRecipeCost") }}</span>
      </r-heading>

      <div class="recipe-cost">
        <div class="recipe-cost--buttons">
          <r-button
            buttonStyle="primary"
            outline
            class="recipe-cost--menu-button"
            @click="backDetail"
            >{{ $t("recipe.recipeCost.backToRecipeDetail") }}</r-button
          >
        </div>

        <r-heading class="recipe-cost--title" :level="2">{{ name }}</r-heading>

        <!-- 入力欄ここから -->
        <r-paper
          round
          class="recipe-cost--paper"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="recipe-cost--paper--title">
            <h2 class="recipe-cost--paper--title--recipe-name">
              {{ item.name }}
            </h2>
            <span class="recipe-cost--paper--title--caption">{{
              item.caption
            }}</span>
          </div>
          <r-hr hrStyle="primary" />

          <div class="recipe-cost--paper--main">
            <!-- サブレシピのとき  -->
            <div
              v-if="isSubRecipe(item)"
              class="recipe-cost--paper--main--left"
            >
              <div class="recipe-cost--paper--main--info">
                <div>
                  [{{ $t("recipe.recipeCost.unitAmount") }}]&nbsp;{{
                    item.unitAmount
                  }}{{ item.unit }}
                </div>
                <div>
                  [{{ $t("recipe.recipeCost.price") }}]&nbsp;{{
                    $t("common.currency")
                  }}&nbsp;{{ item.price }}
                </div>
              </div>
            </div>

            <!-- ユーザー登録食材のとき -->
            <div
              v-else-if="item.ingredientID === ''"
              class="recipe-cost--paper--main--left"
            >
              <div class="recipe-cost--paper--main--info">
                <div
                  class="recipe-cost--paper--main--info--input-price-wrapper"
                >
                  <span
                    >[{{ $t("recipe.recipeCost.price") }}]&nbsp;{{
                      $t("common.currency")
                    }}&nbsp;</span
                  >
                  <r-textbox
                    v-if="item.costCalcZero"
                    class="recipe-cost--paper--main--info--input-price"
                    type="number"
                    v-model.number="item.price"
                    :placeholder="$t('recipe.recipeCost.inputPricePlaceholder')"
                  ></r-textbox>
                  <span v-else>{{ item.price }}</span>
                </div>
              </div>
              <r-paper
                paperStyle="gray"
                round
                class="recipe-cost--paper--main--content"
              >
                <div class="input-box-wrapper">
                  <span>{{
                    $t("recipe.recipeCost.totalAmountOf") +
                    "”" +
                    `${item.unit}` +
                    "”" +
                    $t("recipe.recipeCost.converseAmount")
                  }}</span>
                  <r-textbox
                    type="number"
                    class="input-box"
                    v-model.number="item.unitAmount"
                    :unit="item.unit"
                  ></r-textbox>
                </div>
              </r-paper>
            </div>

            <!-- 通常食材かつ単価0 -->
            <div
              class="recipe-cost--paper--main--left"
              v-else-if="isPriceZero(item)"
            >
              <div class="recipe-cost--paper--main--info">
                <!-- 発注データ参照中のローディング -->
                <r-spinner v-if="orderDataLoading[index]" harfSize></r-spinner>
                <div v-else>
                  <div>
                    [{{ $t("recipe.recipeCost.standard") }}×︎{{
                      $t("recipe.recipeCost.quantity")
                    }}] {{ item.standard }} ×︎ {{ item.quantity
                    }}{{ item.quantityUnit }}
                  </div>
                  <div>
                    [{{ $t("recipe.recipeCost.price") }}]&nbsp;
                    {{ $t("common.currency") }}&nbsp;{{ item.price }}
                  </div>
                  <div
                    class="recipe-cost--paper--main--info--input-price-wrapper"
                    v-if="isZeroPriceItem[index]"
                  >
                    <span>[{{ $t("recipe.recipeCost.price") }}]</span>&nbsp;
                    <r-textbox
                      class="recipe-cost--paper--main--info--input-price"
                      type="number"
                      :placeholder="
                        $t('recipe.recipeCost.inputPricePlaceholder')
                      "
                      v-model.number="item.price"
                    />
                    <!--発注データ参照ボタン-->
                    <r-button
                      class="recipe-cost--paper--main--info--order-data-button"
                      v-if="item.price <= 0"
                      outline
                      buttonStyle="primary"
                      @click="getOrderPrice(index, item.ingredientID)"
                      >{{ $t("recipe.recipeCost.getOrderingData") }}</r-button
                    >
                  </div>
                </div>
              </div>

              <r-paper
                paperStyle="gray"
                round
                class="recipe-cost--paper--main--content"
              >
                <div class="input-box-wrapper">
                  <span
                    >{{ $t("recipe.recipeCost.totalAmountOf") }}
                    {{ item.unit }} {{ $t("recipe.recipeCost.converseAmount") }}
                  </span>
                  <r-textbox
                    type="number"
                    class="input-box"
                    :disabled="item.price <= 0"
                    v-model.number="item.unitAmount"
                    :unit="item.unit"
                  ></r-textbox>
                </div>
              </r-paper>
            </div>

            <!-- 通常の食材のとき -->
            <div v-else class="recipe-cost--paper--main--left">
              <div class="recipe-cost--paper--main--info">
                <div>
                  [{{ $t("recipe.recipeCost.standard") }}✖︎{{
                    $t("recipe.recipeCost.quantity")
                  }}] {{ item.standard }} ×︎ {{ item.quantity
                  }}{{ item.quantityUnit }}
                </div>
                <div>
                  [{{ $t("recipe.recipeCost.price") }}]&nbsp;
                  {{ $t("common.currency") }}&nbsp;{{ item.price }}
                </div>
              </div>

              <r-paper
                paperStyle="gray"
                round
                class="recipe-cost--paper--main--content"
              >
                <div class="input-box-wrapper">
                  <!-- 総量入力 -->
                  <span
                    >{{ $t("recipe.recipeCost.totalAmountOf") }}
                    {{ item.unit }}
                    {{ $t("recipe.recipeCost.converseAmount") }}</span
                  >
                  <r-textbox
                    class="input-box"
                    type="number"
                    :disabled="item.price <= 0"
                    v-model.number="item.unitAmount"
                    :unit="item.unit"
                  ></r-textbox>
                </div>
              </r-paper>
            </div>

            <font-awesome-icon
              class="fas fa-chevron-down recipe-cost--paper--main--content"
              :icon="['fas', 'chevron-down']"
            ></font-awesome-icon>

            <!-- 原価表示ここから -->
            <div class="recipe-cost--paper--main--content cost--wrapper">
              <r-heading :level="4"
                >{{ item.amount }} {{ item.unit }}
                {{ $t("recipe.recipeCost.costOf") }}</r-heading
              >
              <div class="cost--inline-wrapper">
                <span class="cost--computed">{{ calcCost(index) }}</span>
                {{ $t("common.currencyYen") }}
              </div>
            </div>
          </div>
        </r-paper>
        <r-button
          v-if="isFillAllForm"
          buttonStyle="primary"
          outline
          full
          class="recipe-cost--register-button"
          @click="clickCalcCost"
          >{{ $t("recipe.recipeCost.recipeCostRegister") }}</r-button
        >

        <!-- モーダル -->
        <transition name="modal">
          <div
            class="modal-bg"
            v-if="isShowModal === true"
            @mousedown.self="cancel()"
          >
            <recipe-cost-registration
              :id="recipeID"
              :price="price"
              :serv="serv"
              :pastCostRate="pastCostRate"
              :targetRate="targetRate"
              :items="items"
              v-if="isShowModal"
              class="modal-content"
              @cancel="cancel"
            />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import RPaper from "@/plugins/atoms/RPaper.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";
import RecipeCostRegistration from "@/components/recipe/organisms/RecipeCostRegistration.vue";

import auth from "@/libs/Auth.js";
import recipeManager from "@/libs/Recipe.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  components: {
    RPaper,
    RHr,
    RTextbox,
    RButton,
    RHeading,
    RSpinner,
    RecipeCostRegistration,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      isShowModal: false,
      recipeID: "",
      name: "",
      exPrice: 0, // 直接入力の価格
      serv: 0, // 人前
      price: 0, // レシピの価格
      pastCostRate: 0, // 前回の原価率
      targetRate: 0, // 目標原価
      items: [],
      loading: false,
      orderDataLoading: [],
      headingImage: require("../../../assets/images/costColor.png"),
      isZeroPriceItem: [],
    };
  },
  methods: {
    clickCalcCost() {
      this.isShowModal = true;
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".modal-content");
      disableBodyScroll(modal);
    },
    cancel() {
      this.isShowModal = false;
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    async backDetail() {
      // 編集中解除
      await auth.unLockEditStatus();
      // Detail画面に遷移
      this.$router.push(
        {
          name: "recipeDetail",
          params: { id: this.recipeID },
        },
        () => {},
      );
    },
    async getOrderPrice(index, ingredientID) {
      this.$set(this.orderDataLoading, index, true);
      const orderPrice = await recipeManager.getOrderPrice(
        this.customerID,
        this.storeID,
        ingredientID,
      );
      if (Number(orderPrice) > 0) {
        this.$set(this.items[index], "price", orderPrice);
      } else {
        alert(this.$i18n.t("recipe.recipeCost.noOrderingData"));
      }
      this.$set(this.orderDataLoading, index, false);
    },
  },
  computed: {
    calcCost() {
      return (index) => {
        // コスト計算
        const costValue = recipeManager.calcItemCost(this.items[index]);
        this.$set(this.items[index], "cost", costValue);
        //return costValue;
        if (costValue === Infinity || Number.isNaN(costValue)) {
          return 0;
        }
        return costValue;
      };
    },
    isFillAllForm() {
      const arr = this.items.map((e) => e.unitAmount);
      return !(arr.includes(0) || arr.includes(""));
    },
    isSubRecipe() {
      return (item) => {
        if (item.ref) {
          return true;
        }
        return false;
      };
    },
    isPriceZero() {
      return (item) => {
        if (item.ingredientID !== "") {
          return true;
        } else if (item.price !== 0 && item.unitAmount) {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  async created() {
    this.loading = true;
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      if (this.$route.params.id) {
        this.recipeID = this.$route.params.id;
      } else if (sessionStorage.recipeID) {
        // 存在しない場合はlocal storageから取得
        this.recipeID = sessionStorage.recipeID;
      } else {
        // recipeIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      // 該当のレシピの targetRate と costを取得する
      const recipeRes = await recipeManager.getRecipe(this.recipeID);

      //現在のレシピの名前取得
      this.name = recipeRes.name;
      this.serv = recipeRes.serv || "";
      (this.pastCostRate = recipeRes.costRate || 0), // 変更前の原価率
        (this.price = recipeRes.price || 0);
      this.targetRate = recipeRes.targetRate || 0;
      const getItems = recipeRes.items;

      // サブレシピの場合はunitAmountをセットする。
      this.items = await getItems.reduce(async (pacc, item) => {
        let acc = await pacc;
        if (item.ref !== null) {
          item.unitAmount = item.quantity;
        }
        if (item.ingredientID === "" && item.price === 0) {
          //直接入力食材が金額が0の状態で原価計算を行う場合
          item.costCalcZero = true;
        }
        acc.push(item);
        return acc;
      }, []);

      // 原価計算前の金額表示(recipe-cost-registrationモーダル表示用)
      this.exPrice = this.price;

      //単価0の有無をチェック
      this.isZeroPriceItem.length = this.items.length;
      this.isZeroPriceItem.fill(false);

      this.items.forEach((item, index) => {
        if (item.price == 0) {
          //単価0の食材の場合
          this.isZeroPriceItem[index] = true;
        }
      });

      //r-spinner表示用配列
      this.orderDataLoading.length = this.items.length;
      this.orderDataLoading.fill(false);

      this.loading = false;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
.recipe-cost {
  padding: 60px 20px 0;
}
.recipe-cost--buttons {
  display: flex;
  justify-content: space-between;
}
.recipe-cost--menu-button {
  display: block;
  text-align: left;
  margin-top: 20px;
}
.recipe-cost--title {
  margin-top: 20px;
}
.recipe-cost--paper {
  margin-top: 20px;
}
.recipe-cost--paper--title {
  text-align: left;
}
.recipe-cost--paper--title--recipe-name {
  margin: 0;
}
.recipe-cost--paper--main {
  margin-top: 10px;
}
/** 金額・規格・入り数など表示部分 */
.recipe-cost--paper--main--info {
  text-align: left;
}
.recipe-cost--paper--main--info--input-price-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.recipe-cost--paper--main--info--input-price {
  width: 30%;
}
.recipe-cost--paper--main--content {
  margin-top: 20px;
}

.input-box-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
/** 原価関連 */
.cost--wrapper {
  text-align: left;
}
.cost--inline-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.cost--computed {
  border: 2px solid #e6e6e6;
  width: 90%;
  border-radius: 5px;
  font-size: 1.2rem;
  padding: 8px;
  box-sizing: border-box;
  display: inline-block;
  margin-right: 5px;
}
.fa-chevron-down {
  font-size: 2.4rem;
  color: #e87352;
}
.recipe-cost--register-button {
  margin-top: 20px;
}
.modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.modal-content {
  width: 90%;
  max-height: 90%;
  overflow: auto;
}

@media screen and (min-width: 641px) {
  .recipe-cost {
    padding-top: 100px;
  }
  .recipe-cost--paper--main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .recipe-cost--buttons {
    position: absolute;
    top: 105px;
  }
  .fa-chevron-down {
    transform: rotate(-90deg);
  }
  .recipe-cost--paper--main--left {
    width: 70%;
  }
  .cost--wrapper {
    width: 20%;
  }
  .input-box {
    width: 30%;
  }
  .input-box-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
}

@media screen and (min-width: 1024px) {
  .recipe-cost {
    width: 1024px;
    margin: auto;
    padding-top: 120px;
  }
  .recipe-cost--buttons {
    position: absolute;
    top: 90px;
  }
}
</style>

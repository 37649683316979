<!--
props: なし
@searchで入力内容を親側で受け取れる
-->
<template>
  <div class="r-search-form">
    <div class="form--wrapper">
      <r-textbox
        class="r-search-form--textbox"
        :placeholder="$t('common.searchTextBox')"
        v-model="searchWord"
        @keyup="tellSearchWord"
      />
      <div class="icon-button" v-if="searchWord !== ''">
        <font-awesome-icon
          :icon="['fas', 'times']"
          class="fas fa-times"
          @click="clearSerchWord"
        ></font-awesome-icon>
      </div>
    </div>

    <r-button
      class="r-search-form--button"
      buttonStyle="secondary"
      small
      @click="tellSearchWord"
    >
      {{ $t("common.search") }}
    </r-button>
  </div>
</template>

<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";

export default {
  name: "rSearchForm",
  components: {
    RTextbox,
    RButton,
  },
  data() {
    return {
      searchWord: "",
    };
  },
  props: {
    clearOnly: Boolean,
  },
  methods: {
    tellSearchWord() {
      this.$emit("search", this.searchWord);
    },
    clearSerchWord() {
      this.searchWord = "";
      if (!this.clearOnly) {
        this.tellSearchWord();
      }
    },
  },
};
</script>

<style scoped>
.r-search-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.form--wrapper {
  display: flex;
  align-items: center;
  width: 80%;
  border-radius: 4px;
  border: 2px solid #e6e6e6;
  margin-right: 10px;
}
.r-search-form--textbox {
  width: 90%;
  margin-right: 10px;
}
.r-search-form--textbox >>> .r-textbox--input {
  border: none;
}
.r-search-form--textbox >>> .r-textbox--input:focus {
  outline: 0;
}
.icon-button {
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
.icon-button:hover {
  opacity: 0.8;
  cursor: pointer;
}
.fa-times {
  font-size: 24px;
  color: #c9c8c8;
}
.r-search-form--button {
  height: inherit;
}
.r-search-form--button:hover {
  background-color: #0048ff;
  transition: 0.5s;
}
</style>

<template>
  <!--セクションのメニュー選択モーダル-->
  <div class="select-menu-wrapper">
    <!--カテゴリ-->
    <div class="select-category" v-if="windowSizePC">
      <span class="label"
        >{{ $t("menu.menuRegist.sectionMenuForm.category") }}:</span
      >
      <r-select
        :label="selectLabel"
        :options="categories"
        @input="categorySelect"
      />
    </div>
    <!--セクションのメニュー選択-->
    <r-modal
      :title="
        '「' +
        `${sectionNameForHeading}` +
        '」' +
        $t('menu.menuRegist.sectionMenuForm.menuSelecting')
      "
      :cancelHidden="!windowSizePC && !tabletSizePC"
      @cancel="closeSectionMenuSelect"
      :enter="windowSizePC || tabletSizePC"
      @enter="menuListEmit()"
      class="select-menu-modal"
    >
      <div class="select-category" v-if="!windowSizePC">
        <span class="label"
          >{{ $t("menu.menuRegist.sectionMenuForm.category") }} :</span
        >
        <r-select
          class="selector"
          :label="selectLabel"
          :options="categories"
          @input="categorySelect"
        />
      </div>
      <div class="modal-content">
        <!--モバイル-->
        <div class="select-menu-list" v-if="!windowSizePC && !tabletSizePC">
          <div v-for="(item, index) in menus" :key="index">
            <r-button
              buttonStyle="primary"
              outline
              full
              class="menu-item-for-mobile"
              @click="selectMenu(index)"
              v-if="!selectingMenus.map((e) => e.name).includes(item.name)"
            >
              <div class="menu-name-for-mobile">{{ item.name }}</div>
              <r-hr hrStyle="gray" />
              <div class="menu-price-for-mobile">
                {{ $t("common.price") }}：{{ item.price }} &nbsp;{{
                  $t("common.costRate")
                }}：{{ item.costRate }}
              </div>
            </r-button>
          </div>
        </div>
        <!--PC及びiPad用メニューリスト-->
        <div v-if="windowSizePC || tabletSizePC" class="select-menu-list">
          <div class="menu-items-header">
            <span class="menu-name-title">{{ $t("menu.menuTop.menu") }}</span>
            <div class="menu-price-title">
              <span class="m-price-title">{{ $t("common.price") }}</span>
              <span class="m-rate-title">{{ $t("common.costRate") }}</span>
            </div>
          </div>
          <div
            class="menu-items"
            v-for="(menu, menuIdx) in menus"
            :key="menuIdx"
            @click="selectMenu(menuIdx)"
            v-show="!selectingMenus.map((e) => e.name).includes(menu.name)"
          >
            <div class="menu-name">{{ menu.name }}</div>
            <div class="menu-price">
              <div class="m-price">
                {{ $t("common.currency") }} {{ menu.price }}
              </div>
              <div class="m-rate">{{ menu.costRate }} %</div>
            </div>
          </div>
        </div>
        <div class="selected-menu-list">
          <r-selected-items
            :title="
              $t('menu.menuRegist.sectionMenuForm.selectedMenus') +
              '（' +
              $t('common.costRate') +
              '：' +
              `${totalCostRate}` +
              '%)'
            "
            @cancel="closeSectionMenuSelect"
            enter
            @enter="menuListEmit()"
            class="selected-item-list"
            v-if="!windowSizePC && !tabletSizePC"
          >
            <!--選択されたメニュー-->
            <r-food-stuff-card
              v-for="(item, index) in selectingMenus"
              :key="index"
              :label="item.name"
              white
              close
              @close="deleteMenu(index)"
              class="selecting-menu"
            ></r-food-stuff-card>
          </r-selected-items>
          <!--PCサイズ対応selected-items-->
          <r-paper
            v-if="windowSizePC || tabletSizePC"
            round
            paperStyle="pink"
            class="recipe-food-stuff-form--wrapper-for-web--right"
          >
            <span class="recipe-food-stuff-form--wrapper-for-web--right--title">
              {{ $t("menu.menuRegist.sectionMenuForm.selectedMenus") }} ({{
                $t("common.costRate")
              }}
              {{ totalCostRate }} %)
            </span>
            <r-food-stuff-card
              class="recipe-food-stuff--selected-item"
              white
              close
              v-for="(menu, index) in selectingMenus"
              :key="index"
              :label="menu.name"
              @close="deleteMenu(index)"
            />
          </r-paper>
        </div>
      </div>
    </r-modal>
  </div>
</template>
<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RSelectedItems from "@/plugins/molecules/RSelectedItems.vue";
import RFoodStuffCard from "@/plugins/molecules/RFoodStuffCard.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import auth from "@/libs/Auth.js";
import categoryManager from "@/libs/Category.js";

export default {
  components: {
    RModal,
    RButton,
    RHr,
    RSelect,
    RSelectedItems,
    RFoodStuffCard,
    RPaper,
  },
  props: {
    sectionName: String,
    selectedMenus: Array,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      //カテゴリ>> recipeから取得
      categories: {},
      selectedCategory: "",
      selectLabel: this.$i18n.t("menu.menuRegist.sectionMenuForm.selectLabel"),
      // selectingMenus:{}
      selectingMenus: [], //メニューのオブジェクト{}を格納する配列
      //メニューのデータを読み込む>>recipeおよびコースから取得
      menus: [],
      recipeList: [],
      width: window.innerWidth,
    };
  },
  methods: {
    closeSectionMenuSelect() {
      //モーダル終了
      this.$emit("cancel");
    },
    categorySelect(category) {
      // レシピ検索
      const searchList = categoryManager.searchRecipes(
        this.recipeList,
        category,
      );
      this.menus = searchList;

      this.selectedCategory = "";
      if (category) {
        this.selectedCategory = category;
      }
    },
    selectMenu(index) {
      //メニュー選択
      this.selectingMenus.push(this.menus[index]);
    },
    deleteMenu(index) {
      //選択してメニューの削除
      this.selectingMenus.splice(index, 1);
    },
    menuListEmit() {
      //メニューリストをemitする前に選択されたメニューを確認
      if (this.selectingMenus.length == 0) {
        alert(
          this.$i18n.t("menu.menuRegist.sectionMenuForm.menuSelectValidation"),
        );
        return;
      } else {
        //選択メニューの
        this.$emit("menuList", this.selectingMenus);
      }
    },
    handleResize() {
      this.width = window.innerWidth;
    },
  },
  computed: {
    sectionNameForHeading() {
      if (this.sectionName.length < 9) {
        return this.sectionName;
      } else {
        const shortSectionName = this.sectionName.slice(0, 8);
        return shortSectionName + "...";
      }
    },
    totalCostRate() {
      //選択したメニューの原価率総合
      const totalCostRate = this.selectingMenus.reduce((acc, menu) => {
        acc += menu.costRate / this.selectingMenus.length;
        return acc;
      }, 0);
      return parseFloat(totalCostRate.toFixed(2));
    },
    windowSizePC() {
      //PCサイズ対応
      return this.width >= 1024;
    },
    tabletSizePC() {
      return this.width >= 641;
    },
  },
  async created() {
    this.customerID = await auth.getCustomerID(this.$root);
    this.storeID = await auth.getStoreID(this.$root);
    if (!this.customerID || !this.storeID) {
      // customerID、StoreIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }
    try {
      // カテゴリを取得
      this.categories = await categoryManager.getCategories(
        this.customerID,
        this.storeID,
      );

      this.selectingMenus = Object.keys(this.selectedMenus).map(
        (e) => this.selectedMenus[e],
      );

      // レシピを取得する
      this.recipeList = await categoryManager.getBooksList(
        this.customerID,
        this.storeID,
      );
      this.menus = this.recipeList;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error("Forbidden");
    }
  },
  //PCサイズ対応
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style scoped>
.select-menu-wrapper {
  font-size: 1rem;
  box-sizing: border-box;
}
.select-menu-modal {
  height: 80vh;
  width: 92vw;
  padding: 20px;
  overflow-y: auto;
  margin: 0 auto;
  padding-bottom: 20vh;
}
.select-category {
  margin-bottom: 5px;
  text-align: left;
}
.menu-item-for-mobile {
  padding: 5px;
  margin-bottom: 10px;
}
.menu-name-for-mobile {
  color: black;
  float: left;
}
.menu-price-for-mobile {
  color: black;
  float: left;
}
.selected-item-list {
  text-align: center;
}
.selecting-menu {
  margin-bottom: 10px;
  height: unset;
}
/*iPad用css*/
@media (min-width: 641px) {
  .select-menu-wrapper {
    width: 90%;
    display: block;
    justify-content: center;
    height: 92vh;
  }
  .select-menu-modal {
    width: 90%;
    padding: 30px;
    margin: 0 auto;
  }
  .select-category {
    height: 30px;
    margin-bottom: 10px;
    display: inline-flex;
    width: 100%;
    justify-content: left;
  }
  .label {
    display: grid;
    width: 100px;
    align-content: center;
  }
  .selector {
    display: inline-block;
    width: 300px;
    width: auto;
  }
  /*メニューリスト*/
  .menu-items-header {
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    padding: 5px;
    background-color: #e87352;
    color: #ffffff;
    font-weight: bold;
  }
  /*メニュータイトル*/
  .menu-name-title {
    flex-grow: 1;
    width: 100%;
    font-weight: bold;
    text-align: left;
    /* width: 60%; */
  }
  .menu-price-title {
    flex-grow: 1;
    display: flex;
    width: 100%;
  }
  .m-price-title {
    text-align: right;
    width: 100%;
    flex-grow: 1;
    font-weight: bold;
  }
  .m-rate-title {
    text-align: right;
    flex-grow: 1;
    width: 100%;
    font-weight: bold;
  }
  .menu-items {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    padding: 5px;
  }
  .menu-items:hover {
    cursor: pointer;
    background-color: #f8d6cd;
  }
  .menu-name {
    text-align: left;
    /* width: 60%; */
    flex-grow: 1;
    padding: 5px;
    width: 100%;
    text-align: left;
  }
  .menu-price {
    flex-grow: 1;
    display: flex;
    width: 100%;
  }
  .m-price {
    text-align: right;
    width: 100%;
    flex-grow: 1;
  }
  .m-rate {
    text-align: right;
    flex-grow: 1;
    width: 100%;
  }
  .section-cost-rate {
    padding: 5px;
    border-bottom: 2px solid #517ce8;
  }
  .rate-number {
    color: #517ce8;
  }
  .section-name {
    float: left;
    padding: 10px;
  }
  .selecting-menu {
    min-height: 240px;
    width: 320px;
  }
  .recipe-food-stuff-form--wrapper-for-web--right {
    position: relative;
    padding-top: 40px;
    min-height: 230px;
  }
  .recipe-food-stuff-form--wrapper-for-web--right--title {
    background-color: #e87352;
    color: #ffffff;
    font-weight: bold;
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 5px 5px 0 0;
    box-sizing: border-box;
    padding: 5px 0;
    margin-bottom: 10px;
  }
  .recipe-food-stuff--selected-item {
    margin-top: 10px;
  }
  /* .selected-item-list {
  } */
  .select-menu-list {
    width: 100%;
    height: 360px;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  .selected-menu-list {
    width: 100%;
    height: 180px;
    overflow-y: auto;
  }
}
/*PC用css */
@media (min-width: 1024px) {
  .select-menu-wrapper {
    width: 1105px;
    height: 763px;
    position: relative;
    display: flex;
    justify-content: center;
  }
  .select-category {
    position: absolute;
    display: flex;
    right: 20px;
    top: 20px;
    /* width: auto;
    height: auto; */
    width: 30%;
  }
  .label {
    display: grid;
    width: 140px;
    align-content: center;
  }
  /*メニューリスト*/
  .menu-items-header {
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    padding: 5px;
    background-color: #e87352;
    color: #ffffff;
    font-weight: bold;
  }
  /*メニュータイトル*/
  .menu-name-title {
    flex-grow: 1;
    width: 100%;
    font-weight: bold;
    text-align: left;
    /* display: inline-block;
    text-align: center; */
  }
  .menu-price-title {
    flex-grow: 1;
    display: flex;
    width: 100%;
  }
  .m-price-title {
    text-align: right;
    width: 100%;
    flex-grow: 1;
    font-weight: bold;
  }
  .m-rate-title {
    text-align: right;
    flex-grow: 1;
    width: 100%;
    font-weight: bold;
  }
  .menu-items {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    padding: 5px;
  }
  .menu-name {
    text-align: left;
    flex-grow: 1;
    padding: 5px;
    width: 100%;
    text-align: left;
  }
  .menu-price {
    flex-grow: 1;
    display: flex;
    width: 100%;
  }
  .m-price {
    text-align: right;
    width: 100%;
    flex-grow: 1;
  }
  .m-rate {
    text-align: right;
    width: 100%;
    flex-grow: 1;
  }
  .section-cost-rate {
    padding: 5px;
    border-bottom: 2px solid #517ce8;
  }
  .rate-number {
    color: #517ce8;
  }
  .section-name {
    float: left;
    padding: 10px;
  }
  .select-menu-modal {
    height: 783px;
    width: 92%;
    padding: 20px;
    overflow-y: auto;
    margin: 0 auto;
  }
  .selecting-menu {
    width: 640px;
    padding: 40px;
  }
  .recipe-food-stuff-form--wrapper-for-web--right {
    position: relative;
    padding-top: 40px;
    min-height: 550px;
  }
  .recipe-food-stuff-form--wrapper-for-web--right--title {
    background-color: #e87352;
    color: #ffffff;
    font-weight: bold;
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 5px 5px 0 0;
    box-sizing: border-box;
    padding: 5px 0;
    margin-bottom: 10px;
  }
  .recipe-food-stuff--selected-item {
    margin-top: 10px;
  }
  /* .selected-item-list {
  } */
  .modal-content {
    padding: 20px;
    width: 100%;
    height: 600px;
    display: flex;
    box-sizing: border-box;
    margin: 0 auto;
  }
  .select-menu-list {
    width: 65%;
    height: 550px;
    margin-right: 20px;
    display: block;
    overflow-y: auto;
  }
  .selected-menu-list {
    width: 35%;
    height: 550px;
    min-height: 30vh;
    overflow-y: auto;
  }
}
</style>

<template>
  <div>
    <r-heading :level="1" :image="headingImage">{{
      $t("recipe.recipeRegistration.createRecipe")
    }}</r-heading>
    <div
      class="recipe-registration"
      :class="{ 'recipe-registration--fix': modalFlag.includes(true) }"
    >
      <r-paper round class="recipe-registration--store-select" v-if="!isStore">
        <span>{{ $t("recipe.recipeTop.selectStore") }}</span>
        <r-select
          v-if="existingValue !== ''"
          :options="stores"
          @input="storeSelect"
          :existingValue="existingValue"
        />
      </r-paper>

      <div class="main-conntent--wrapper">
        <div class="recipe-registration--step">
          <r-step-nav
            class="step--content"
            v-for="(stepNavLabel, index) in stepNavLabels"
            :key="index"
            :index="index"
            :step="currentStep"
            @click="modalOpen(index)"
            >{{ stepNavLabel }}</r-step-nav
          >
        </div>
        <!-- メインコンテンツ -->
        <r-paper
          class="recipe-registration--main"
          :class="[
            currentStep === 0
              ? 'recipe-registration--main--image-center'
              : 'recipe-registration--main--image-right',
            { 'recipe-registration--main--image-none': currentStep > 3 },
          ]"
          paperStyle="pink"
          round
        >
          <!-- 料理名 -->
          <div class="recipe-registration--main--item" v-if="currentStep > 0">
            <r-paper round class="recipe-registration--main--item--left">
              <div class="recipe-name">{{ recipeName }}</div>
              <div>{{ kana }}</div>
            </r-paper>
            <span @click="modalOpen(0)" class="change-btn"
              >【{{ $t("recipe.recipeRegistration.changeRecipeName") }}】</span
            >
          </div>
          <!-- カテゴリー -->
          <div class="recipe-registration--main--item" v-if="currentStep > 1">
            <r-paper
              class="recipe-registration--main--item--categories recipe-registration--main--item--left"
              round
            >
              <span
                class="recipe-registration--main--item--category"
                v-for="(category, index) in categories"
                :key="index"
                >{{ category.name }}</span
              >
            </r-paper>
            <span @click="modalOpen(1)" class="change-btn"
              >【{{ $t("recipe.recipeRegistration.changeCategory") }}】</span
            >
          </div>
          <!-- 写真 -->
          <div class="recipe-registration--main--item" v-if="currentStep > 2">
            <r-paper class="recipe-registration--main--item--image">
              <span v-if="image">
                <img class="upload-image" :src="image" alt />
              </span>
              <div
                class="recipe-registration--main--item--img--default"
                v-else
              ></div>
            </r-paper>
            <span @click="modalOpen(2)" class="change-btn"
              >【{{ $t("recipe.recipeRegistration.changePicture") }}】</span
            >
            <span @click="deleteImage" class="change-btn" v-if="image"
              >【{{ $t("recipe.recipeRegistration.deletePicture") }}】</span
            >
          </div>

          <div class="recipe-registration--main--item" v-if="currentStep > 3">
            <r-paper class="recipe-registration--main--item--left" round>
              <r-list>
                <r-list-content
                  v-for="(foodstuff, index) in foodstuffs"
                  :key="index"
                >
                  <r-list-item>{{ foodstuff?.name }}</r-list-item>
                  <r-list-item
                    >{{ foodstuff?.amount }}{{ foodstuff?.unit }}</r-list-item
                  >
                </r-list-content>
              </r-list>
            </r-paper>
            <span @click="modalOpen(3)" class="change-btn"
              >【{{ $t("recipe.recipeRegistration.changeFoodstuff") }}】</span
            >
          </div>

          <div
            class="recipe-registration--main--item recipe-registration--main--item--2"
            v-if="currentStep > 3"
          >
            <r-paper round>
              <span class="recipe-registration--title">{{
                $t("recipe.allergyItem.allergy")
              }}</span>
              <div>{{ $t("recipe.recipeRegistration.allergyCheck") }}</div>
              <r-hr hrStyle="sub-primary" />
              <!-- アレルギー(7品目) -->
              <div class="recipe-registration--main--item--allergy">
                <r-heading :level="4">{{
                  $t("recipe.allergyItem.sevenItems")
                }}</r-heading>
                <div>
                  <label for="wheat">
                    <input
                      v-model="allergy.sevenItems.wheat"
                      type="checkbox"
                      id="wheat"
                    />
                    {{ $t("recipe.allergyItem.wheat") }}
                  </label>
                  <label for="soba">
                    <input
                      v-model="allergy.sevenItems.soba"
                      type="checkbox"
                      id="soba"
                    />
                    {{ $t("recipe.allergyItem.soba") }}
                  </label>
                  <label for="milk">
                    <input
                      v-model="allergy.sevenItems.milk"
                      type="checkbox"
                      id="milk"
                    />
                    {{ $t("recipe.allergyItem.milk") }}
                  </label>
                  <label for="egg">
                    <input
                      v-model="allergy.sevenItems.egg"
                      type="checkbox"
                      id="egg"
                    />
                    {{ $t("recipe.allergyItem.egg") }}
                  </label>
                  <label for="peanuts">
                    <input
                      v-model="allergy.sevenItems.peanuts"
                      type="checkbox"
                      id="peanuts"
                    />
                    {{ $t("recipe.allergyItem.peanuts") }}
                  </label>
                  <label for="shrimp">
                    <input
                      v-model="allergy.sevenItems.shrimp"
                      type="checkbox"
                      id="shrimp"
                    />
                    {{ $t("recipe.allergyItem.shrimp") }}
                  </label>
                  <label for="crab">
                    <input
                      v-model="allergy.sevenItems.crab"
                      type="checkbox"
                      id="crab"
                    />
                    {{ $t("recipe.allergyItem.crab") }}
                  </label>
                </div>
                <!-- アレルギー(20品目) -->
                <div
                  class="recipe-registration--main--item--allergy"
                  v-if="allergyTwenty"
                >
                  <r-heading :level="4">{{
                    $t("recipe.allergyItem.twentyItems")
                  }}</r-heading>
                  <div>
                    <label for="abalone">
                      <input
                        type="checkbox"
                        id="abalone"
                        v-model="allergy.twentyItems.abalone"
                      />
                      {{ $t("recipe.allergyItem.abalone") }}
                    </label>
                    <label for="squid">
                      <input
                        type="checkbox"
                        id="squid"
                        v-model="allergy.twentyItems.squid"
                      />
                      {{ $t("recipe.allergyItem.squid") }}
                    </label>
                    <label for="salmonRoe">
                      <input
                        type="checkbox"
                        id="salmonRoe"
                        v-model="allergy.twentyItems.salmonRoe"
                      />
                      {{ $t("recipe.allergyItem.salmonRoe") }}
                    </label>
                    <label for="orange">
                      <input
                        type="checkbox"
                        id="orange"
                        v-model="allergy.twentyItems.orange"
                      />
                      {{ $t("recipe.allergyItem.orange") }}
                    </label>
                    <label for="cashewNut">
                      <input
                        type="checkbox"
                        id="cashewNut"
                        v-model="allergy.twentyItems.cashewNut"
                      />
                      {{ $t("recipe.allergyItem.cashewNut") }}
                    </label>
                    <label for="kiwi">
                      <input
                        type="checkbox"
                        id="kiwi"
                        v-model="allergy.twentyItems.kiwi"
                      />
                      {{ $t("recipe.allergyItem.kiwi") }}
                    </label>
                    <label for="beef">
                      <input
                        type="checkbox"
                        id="beef"
                        v-model="allergy.twentyItems.beef"
                      />
                      {{ $t("recipe.allergyItem.beef") }}
                    </label>
                    <label for="walnut">
                      <input
                        type="checkbox"
                        id="walnut"
                        v-model="allergy.twentyItems.walnut"
                      />
                      {{ $t("recipe.allergyItem.walnut") }}
                    </label>
                    <label for="sesame">
                      <input
                        type="checkbox"
                        id="sesame"
                        v-model="allergy.twentyItems.sesame"
                      />
                      {{ $t("recipe.allergyItem.sesame") }}
                    </label>
                    <label for="salmon">
                      <input
                        type="checkbox"
                        id="salmon"
                        v-model="allergy.twentyItems.salmon"
                      />
                      {{ $t("recipe.allergyItem.salmon") }}
                    </label>
                    <label for="mackerel">
                      <input
                        type="checkbox"
                        id="mackerel"
                        v-model="allergy.twentyItems.mackerel"
                      />
                      {{ $t("recipe.allergyItem.mackerel") }}
                    </label>
                    <label for="soy">
                      <input
                        type="checkbox"
                        id="soy"
                        v-model="allergy.twentyItems.soy"
                      />
                      {{ $t("recipe.allergyItem.soy") }}
                    </label>
                    <label for="chicken">
                      <input
                        type="checkbox"
                        id="chicken"
                        v-model="allergy.twentyItems.chicken"
                      />
                      {{ $t("recipe.allergyItem.chicken") }}
                    </label>
                    <label for="banana">
                      <input
                        type="checkbox"
                        id="banana"
                        v-model="allergy.twentyItems.banana"
                      />
                      {{ $t("recipe.allergyItem.banana") }}
                    </label>
                    <label for="pork">
                      <input
                        type="checkbox"
                        id="pork"
                        v-model="allergy.twentyItems.pork"
                      />
                      {{ $t("recipe.allergyItem.pork") }}
                    </label>
                    <label for="matsutake">
                      <input
                        type="checkbox"
                        id="matsutake"
                        v-model="allergy.twentyItems.matsutake"
                      />
                      {{ $t("recipe.allergyItem.matsutake") }}
                    </label>
                    <label for="peaches">
                      <input
                        type="checkbox"
                        id="peaches"
                        v-model="allergy.twentyItems.peaches"
                      />
                      {{ $t("recipe.allergyItem.peaches") }}
                    </label>
                    <label for="yam">
                      <input
                        type="checkbox"
                        id="yam"
                        v-model="allergy.twentyItems.yam"
                      />
                      {{ $t("recipe.allergyItem.yam") }}
                    </label>
                    <label for="apple">
                      <input
                        type="checkbox"
                        id="apple"
                        v-model="allergy.twentyItems.apple"
                      />
                      {{ $t("recipe.allergyItem.apple") }}
                    </label>
                    <label for="geratin">
                      <input
                        type="checkbox"
                        id="geratin"
                        v-model="allergy.twentyItems.geratin"
                      />
                      {{ $t("recipe.allergyItem.geratin") }}
                    </label>
                    <label for="almond">
                      <input
                        type="checkbox"
                        id="almond"
                        v-model="allergy.twentyItems.almond"
                      />
                      {{ $t("recipe.allergyItem.almond") }}
                    </label>
                  </div>
                </div>
              </div>
            </r-paper>
          </div>

          <div
            class="recipe-registration--main--item recipe-registration--main--item--2"
            v-if="currentStep > 3"
          >
            <r-paper round>
              <span class="recipe-registration--title">{{
                $t("recipe.recipeRegistration.hideRecipeConfig")
              }}</span>
              <div>
                ❇︎{{
                  $t(
                    "recipe.recipeRegistration.authorizedUserCanReadHiddenRecipe",
                  )
                }}
              </div>
              <r-hr hrStyle="sub-primary" />
              <div>
                <label for="private">
                  <input v-model="isPrivate" type="checkbox" id="private" />
                  {{ $t("recipe.recipeRegistration.hideRecipe") }}
                </label>
              </div>
            </r-paper>

            <!-- 登録ボタン -->
            <r-button
              class="recipe-registration--enter-button"
              buttonStyle="primary"
              outline
              full
              @click="registRecipe"
              >{{ $t("recipe.recipeRegistration.recipeRegist") }}</r-button
            >
          </div>
        </r-paper>
      </div>
      <!-- modal content -->
      <transition name="modal">
        <div
          class="recipe-registration--modal-bg"
          :class="{ 'recipe-registration--modal-bg--step4': modalFlag[3] }"
          v-if="modalFlag.includes(true)"
          @mousedown.self="modalClose(modalFlag.indexOf(true))"
        >
          <!-- 料理名入力 -->
          <recipe-name-form
            v-show="modalFlag[0]"
            @enter="clickFromRecipeName"
            @cancel="modalClose(0)"
            :existingName="recipeName"
            :existingKana="kana"
            class="recipe-registration--modal"
          />
          <!-- 料理カテゴリー選択 -->
          <recipe-category-form
            v-if="modalFlag[1]"
            @enter="clickFromCategory"
            @cancel="modalClose(1)"
            :existingCat="existingCat"
            class="recipe-registration--modal"
          />
          <!-- 画像選択 -->
          <recipe-image-form
            v-if="modalFlag[2]"
            @enter="clickFromImage"
            @skip="skipUploadImage"
            @cancel="modalClose(2)"
            :existingImage="image"
            :regist="currentStep <= 2"
            class="recipe-registration--modal"
          />
          <!-- 食材選択 -->
          <recipe-foodstuff-form
            v-if="modalFlag[3]"
            :existingFoodstuffs="foodstuffs"
            @enter="clickFromFoodstuff"
            @cancel="modalClose(3)"
            @closeModal="modalClose(3)"
            newRegistrationLimited
            class="recipe-registration--modal recipe-registration--modal--food"
          />
          <recipe-unit-form
            class="recipe-registration--modal"
            :foodstuffs="tmpFoodstuffs"
            @enter="clickFromUnitForm"
            @cancel="cencelFromUnitForm"
            :cancelLabel="$t('common.back')"
            newRegistrationLimited
            v-if="modalFlag[4]"
          />
        </div>
      </transition>

      <!-- loading -->
      <div class="loading" v-if="loading">
        <r-spinner></r-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import RStepNav from "@/plugins/atoms/RStepNav.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import RecipeNameForm from "@/components/recipe/organisms/RecipeNameForm.vue";
import RecipeCategoryForm from "@/components/recipe/organisms/RecipeCategoryForm.vue";
import RecipeImageForm from "@/components/recipe/organisms/RecipeImageForm.vue";
import RecipeFoodstuffForm from "@/components/recipe/organisms/RecipeFoodstuffForm.vue";
import RecipeUnitForm from "@/components/recipe/organisms/RecipeUnitForm.vue";

import auth from "@/libs/Auth.js";
import recipeManager from "@/libs/Recipe.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  components: {
    RecipeNameForm,
    RecipeCategoryForm,
    RecipeImageForm,
    RecipeFoodstuffForm,
    RecipeUnitForm,
    RStepNav,
    RPaper,
    RHr,
    RButton,
    RSelect,
    RList,
    RListContent,
    RListItem,
    RHeading,
    RSpinner,
  },
  data() {
    return {
      stepNavLabels: [
        this.$i18n.t("recipe.recipeRegistration.inputRecipeName"),
        this.$i18n.t("recipe.recipeRegistration.selectCategory"),
        this.$i18n.t("recipe.recipeRegistration.selectRecipePicture"),
        this.$i18n.t("recipe.recipeRegistration.selectFoodstuff"),
      ],
      currentStep: 0,
      modalFlag: [false, false, false, false],
      customers: {},
      stores: {},
      customerID: "",
      storeID: "",
      allergyTwenty: false,
      loading: false,
      loadingMessage: "",

      allergy: {
        sevenItems: {
          wheat: false,
          soba: false,
          milk: false,
          egg: false,
          peanuts: false,
          shrimp: false,
          crab: false,
        },
        twentyItems: {
          abalone: false,
          squid: false,
          salmonRoe: false,
          orange: false,
          cashewNut: false,
          kiwi: false,
          beef: false,
          walnut: false,
          sesame: false,
          salmon: false,
          mackerel: false,
          soy: false,
          chicken: false,
          banana: false,
          pork: false,
          matsutake: false,
          peaches: false,
          yam: false,
          apple: false,
          geratin: false,
          almond: false,
        },
      },
      // 入力内容
      recipeName: "",
      kana: "",
      categories: [],
      image: "",
      foodstuffs: [], // 食材一覧
      tmpFoodstuffs: [],
      serv: 0,
      arrAllergy: {
        sevenItems: [],
        twentyItems: [],
      },
      isStore: false,
      isPrivate: false,
      isHeadquarter: false,
      headingImage: require("../../../assets/images/entryColor.png"),
      existingValue: "",
    };
  },
  computed: {
    selectedCategories() {
      return 0;
    },
    existingCat() {
      return this.categories.map((e) => e.name);
    },
  },
  methods: {
    storeSelect(id) {
      if (id) {
        this.storeID = id;
        const storeName = Object.keys(this.stores).find(
          (key) => this.stores[key] === id,
        );
        this.$root.setStoreInfoAction(this.storeID, storeName);
        sessionStorage.storeID = this.storeID;
      } else {
        this.storeID = "";
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";
      }

      this.clearAllInputData();
    },
    clearAllInputData() {
      // 入力内容の初期化
      this.recipeName = "";
      this.kana = "";
      this.categories = [];
      this.image = "";
      this.foodstuffs = []; // 食材一覧
      this.tmpFoodstuffs = [];
      this.serv = 0;
      this.$set(this.arrAllergy, "sevenItems", []);
      this.$set(this.arrAllergy, "twentyItems", []);

      this.currentStep = 0;
    },
    deleteImage() {
      this.image = "";
    },
    // モーダル制御関連
    modalOpen(index) {
      this.$set(this.modalFlag, index, true);
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".recipe-registration--modal");
      if (modal) {
        disableBodyScroll(modal);
      } else {
        console.error("Modal element not found");
      }
    },
    modalClose(index) {
      this.$set(this.modalFlag, index, false);
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    incrementCurrent(index) {
      if (this.currentStep === index) {
        this.currentStep++;
      }
    },
    // step1
    clickFromRecipeName(name, kana) {
      this.recipeName = name;
      this.kana = kana;
      this.incrementCurrent(0);
      this.modalClose(0);
    },
    //step2
    clickFromCategory(value) {
      this.categories = value;
      this.incrementCurrent(1);
      this.modalClose(1);
    },
    //step3
    clickFromImage(value) {
      this.image = value;
      this.incrementCurrent(2);
      this.modalClose(2);
    },
    skipUploadImage() {
      this.incrementCurrent(2);
      this.modalClose(2);
    },
    //step4
    clickFromFoodstuff(foodstuffs) {
      this.tmpFoodstuffs = foodstuffs;
      this.incrementCurrent(3);
      this.modalClose(3);
      this.modalOpen(4);
    },
    clickFromUnitForm(foodstuffs, serv) {
      this.foodstuffs = foodstuffs;
      this.serv = serv;
      this.modalClose(4);
    },
    cencelFromUnitForm(value) {
      this.modalClose(4);
      this.tmpFoodstuffs = value;
      this.modalOpen(3);
    },
    createAllergyString() {
      // 7品目
      /*this.arrAllergy.sevenItems = Object.keys(this.allergy.sevenItems).filter(
        e => this.allergy.sevenItems[e]
      ).forEach(allergyCode => this.convertToAllergyName(allergyCode));*/
      this.arrAllergy.sevenItems = Object.keys(this.allergy.sevenItems)
        .filter((e) => this.allergy.sevenItems[e])
        .map((code) => this.convertToAllergyName(code));

      // 20品目
      this.arrAllergy.twentyItems = Object.keys(this.allergy.twentyItems)
        .filter((e) => this.allergy.twentyItems[e])
        .map((allergyCode) => this.convertToAllergyName(allergyCode));
    },
    convertToAllergyName(allergyCode) {
      switch (allergyCode) {
        case "wheat":
          return "小麦";
        case "soba":
          return "蕎麦";
        case "milk":
          return "乳製品";
        case "egg":
          return "卵";
        case "peanuts":
          return "落花生";
        case "shrimp":
          return "えび";
        case "crab":
          return "かに";
        case "abalone":
          return "あわび";
        case "squid":
          return "いか";
        case "salmonRoe":
          return "いくら";
        case "orange":
          return "オレンジ";
        case "cashewNut":
          return "カシューナッツ";
        case "kiwi":
          return "キウイフルーツ";
        case "beef":
          return "牛肉";
        case "walnut":
          return "くるみ";
        case "sesame":
          return "ごま";
        case "salmon":
          return "さけ";
        case "mackerel":
          return "さば";
        case "soy":
          return "大豆";
        case "chicken":
          return "鶏肉";
        case "banana":
          return "バナナ";
        case "pork":
          return "豚肉";
        case "matsutake":
          return "まつたけ";
        case "peaches":
          return "もも";
        case "yam":
          return "やまいも";
        case "apple":
          return "りんご";
        case "geratin":
          return "ゼラチン";
        case "almond":
          return "アーモンド";
      }
    },
    async setStore() {
      if (this.customerID) {
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";
        this.stores = await auth.getStoreList(this.customerID);

        const storesKeys = Object.keys(this.stores).map(
          (key) => this.stores[key],
        );
        storesKeys.shift();

        this.existingValue = storesKeys[0];
      }
    },
    async registRecipe() {
      this.loading = true;

      const createUser = auth.getDisplayName();
      this.createAllergyString();

      try {
        const createRecipeData = {
          customerID: this.customerID,
          storeID: this.storeID,
          categories: this.categories,
          name: this.recipeName,
          kana: this.kana,
          image: this.image,
          serv: this.serv,
          allergy: this.arrAllergy,
          isPrivate: this.isPrivate,
          foodstuffs: this.foodstuffs,
          createUser: createUser,
        };
        // 新規作成
        const recipeID = await recipeManager.createRecipe(createRecipeData);

        this.loading = false;

        // Detail画面に遷移
        this.$router.push(
          {
            name: "recipeDetail",
            params: { id: recipeID, alertMessage: "registRecipe" },
          },
          () => {},
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
        throw new Error(error);
      }
    },
  },
  async created() {
    try {
      const isStoreUser = await auth.isStore();
      this.isHeadquarter = await auth.isHeadquarters();
      if (isStoreUser) {
        this.isStore = true;
        this.customerID = await auth.getCustomerID(this.$root);
        this.storeID = await auth.getStoreID(this.$root);
        if (!this.customerID || !this.storeID) {
          // customerID、StoreIDが存在しない場合はエラー
          throw new Error("Forbidden");
        }
      } else {
        this.customerID = await auth.getCustomerID(this.$root);
        if (!this.customerID) {
          // 顧客IDが存在しない場合はエラー
          throw new Error("Forbidden");
        }

        this.storeID = "";
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";

        // 店舗一覧を表示
        await this.setStore();
      }
      // アレルギーの品目数設定を取得
      this.allergyTwenty = this.$root.privateState.allergyTwenty;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
.recipe-registration {
  padding: 60px 20px 0px;
  box-sizing: border-box;
}
.recipe-registration--store-select {
  align-content: center;
  text-align: left;
}
.recipe-registration--step {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.step--content {
  width: 48%;
  margin: 0.2rem 0.2rem;
  box-sizing: border-box;
}
.recipe-registration--main {
  min-height: 296px;
  background-image: url(~@/assets/images/logo4.png);
  background-repeat: no-repeat;
  margin-top: 20px;
  box-sizing: border-box;
}
.recipe-registration--main--image-center {
  background-position: center center;
  background-size: auto 100px;
}
.recipe-registration--main--image-right {
  background-position: bottom 10px right 10px;
  background-size: auto 10vh;
}
.recipe-registration--main--image-none {
  background-image: none;
}
.recipe-registration--main--item {
  text-align: left;
  margin-top: 20px;
}
.recipe-name {
  font-weight: bold;
}
.change-btn {
  color: #e87352;
  font-weight: bold;
}
.change-btn:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #ea5446;
}
.recipe-registration--main--item--category:not(:last-child)::after {
  content: "・";
}
.recipe-registration--enter-button {
  margin-top: 20px;
}
.recipe-registration--enter-button:hover {
  color: #fff;
  transition: 0.5s;
  background-color: #e87352;
}
.recipe-registration--main--item--img--default {
  padding: 0;
  height: 160px;
  width: 213px;
  background-image: url(~@/assets/images/logo4.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 8vh;
}
.recipe-registration--main--item--image {
  height: 160px;
  width: 213px;
  padding: 0;
}
.upload-image {
  height: 160px;
  width: 213px;
}
.recipe-registration--modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  margin: auto;
  padding: 0 5%;
}
.recipe-registration--modal-bg--step4 {
  align-items: flex-start;
}
.recipe-registration--modal--food {
  width: 100%;
}
.recipe-registration--main--item--allergy {
  margin-top: 10px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}
.recipe-registration--title {
  font-weight: bold;
}

@media screen and (min-width: 641px) {
  .recipe-registration {
    padding-top: 100px;
  }
  .recipe-registration--step {
    margin-top: 20px;
  }
  .step--content {
    width: calc(50% - 0.2rem);
    margin: 0rem 0.2rem 0.4rem 0.2rem;
  }
  .step--content:nth-of-type(2n-1) {
    margin-left: 0px;
  }
  .step--content:nth-of-type(2n) {
    margin-right: 0px;
  }
  .recipe-registration--enter-button {
    margin-top: 60px;
    padding: 10px;
  }
  /** 変更ボタンを右側に移動する */
  .recipe-registration--main--item {
    display: flex;
    align-items: center;
  }
  .recipe-registration--main--item--2 {
    flex-direction: column;
    align-items: inherit;
  }
  .recipe-registration--main--item--left {
    width: 80%;
  }
  .recipe-registration--modal-bg {
    padding: 5%;
  }
}

@media (min-width: 1024px) {
  .recipe-registration {
    padding-top: 140px;
  }
  .recipe-registration--store-select {
    width: 1000px;
    margin: auto;
    margin-bottom: 20px;
  }
  .main-conntent--wrapper {
    width: 1024px;
    display: flex;
    margin: auto;
  }
  .recipe-registration--step {
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    max-height: 294px;
    padding: 0 20px 0 0;
    margin-top: 0px;
  }
  .recipe-registration--main {
    margin-top: 0px;
    width: 75%;
    padding: 0 30px 20px;
  }
  .step--content {
    width: 100%;
    margin: 0;
  }
  .recipe-registration--modal-bg {
    padding: 0 25%;
  }
  .recipe-registration--modal {
    min-width: 880px;
  }
  .recipe-registration--modal-bg--step4 {
    padding: 0 15%;
  }
  .recipe-registration--modal--food {
    width: 1024px;
    height: 90%;
  }
}
</style>

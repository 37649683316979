<template>
  <div>
    <r-modal
      class="recipe-unit-form"
      enter
      @enter="enter"
      @cancel="cancel"
      :title="$t('recipe.recipeEdit.unitFormTitle')"
      v-if="status === ''"
    >
      <!-- 数量入力 -->
      <div class="recipe-unit-form--list">
        <span class="errorFlag">
          {{ errorMessage.amount }}
          {{ errorMessage.unit }}
        </span>
        <!--食材入力見出し(PC・タブレットのみ表示)-->
        <r-list-content heading v-if="isWindowTablet">
          <r-list-item
            class="recipe-unit-form--list-heading recipe-unit-form--list-heading--left"
            >{{ $t("recipe.recipeCommon.foodstuff") }}</r-list-item
          >
          <r-list-item
            class="recipe-unit-form--list-heading recipe-unit-form--list-heading--right"
          >
            <span class="recipe-unit-form--list-heading--right--item">{{
              $t("recipe.recipeCommon.amount")
            }}</span>
            <span class="recipe-unit-form--list-heading--right--item">{{
              $t("recipe.recipeCommon.unit")
            }}</span>
          </r-list-item>
        </r-list-content>
        <!-- 入力欄 PC・タブレット -->
        <template v-if="isWindowTablet">
          <div v-for="(foodStuff, index) in inputArray" :key="`pc-${index}`">
            <!-- 新規登録ここから -->
            <template v-if="newRegistrationLimited">
              <template v-if="!foodStuff?.id">
                <r-list-content
                  class="recipe-foodstuff-form--unit-form"
                  v-if="!foodStuff?.id"
                >
                  <r-list-item
                    class="recipe-foodstuff-form--unit-form--food-name"
                    >{{ foodStuff?.name }}</r-list-item
                  >
                  <div class="recipe-foodstuff-form--unit-form--wrapper">
                    <r-textbox
                      class="recipe-foodstuff-form--unit-form--textbox"
                      type="number"
                      v-model.number="foodStuff.amount"
                      :placeholder="$t('recipe.recipeEdit.foodstuffAmount')"
                      :error="inputCheckArray[index]?.amountError"
                    />
                    <r-select
                      v-if="!foodStuff?.isSubRecipe"
                      class="recipe-foodstuff-form--unit-form--select"
                      v-model="foodStuff.unit"
                      :label="$t('recipe.recipeEdit.foodstuffUnit')"
                      :options="units"
                      :existingValue="foodStuff?.unit"
                      :error="inputCheckArray[index]?.unitError"
                    />
                    <div
                      v-if="foodStuff?.isSubRecipe"
                      class="recipe-foodstuff-form--unit-form--subRecipe-unit"
                    >
                      {{ foodStuff?.unit }}
                    </div>
                  </div>
                </r-list-content>
              </template>
            </template>
            <!-- 新規登録ここまで -->

            <!-- 編集のときここから -->
            <template v-else>
              <r-list-content>
                <span class="recipe-foodstuff-form--unit-form--food-name">{{
                  foodStuff?.name
                }}</span>
                <div class="recipe-foodstuff-form--unit-form--wrapper">
                  <r-textbox
                    class="recipe-foodstuff-form--unit-form--textbox"
                    type="number"
                    v-model.number="foodStuff.amount"
                    :placeholder="$t('recipe.recipeEdit.foodstuffAmount')"
                    :error="inputCheckArray[index]?.amountError"
                  />
                  <r-select
                    v-if="!foodStuff?.isSubRecipe"
                    class="recipe-foodstuff-form--unit-form--select"
                    v-model="tmpUnit"
                    @update="changeUnit(index)"
                    :label="$t('recipe.recipeEdit.foodstuffUnit')"
                    :options="units"
                    :existingValue="foodStuff?.unit"
                    :error="inputCheckArray[index]?.unitError"
                  />
                  <div
                    v-if="foodStuff?.isSubRecipe"
                    class="recipe-foodstuff-form--unit-form--subRecipe-unit"
                  >
                    {{ foodStuff?.unit }}
                  </div>
                </div>
              </r-list-content>
            </template>
            <!-- 編集のときここまで -->
          </div>
        </template>
        <!-- pc用ここまで -->

        <!-- 入力欄 スマホ -->
        <template v-else>
          <div
            class="recipe-foodstuff-form--unit-form"
            v-for="(foodStuff, index) in inputArray"
            :key="`mobile-${index}`"
          >
            <!-- 新規登録 -->
            <template v-if="newRegistrationLimited">
              <template v-if="!foodStuff?.id">
                <span class="recipe-foodstuff-form--unit-form--food-name">{{
                  foodStuff?.name
                }}</span>
                <div class="recipe-foodstuff-form--unit-form--wrapper">
                  <r-textbox
                    class="recipe-foodstuff-form--unit-form--textbox"
                    type="number"
                    v-model.number="foodStuff.amount"
                    :placeholder="$t('recipe.recipeEdit.foodstuffAmount')"
                    :error="inputCheckArray[index]?.amountError"
                  />
                  <r-select
                    v-if="!foodStuff?.isSubRecipe"
                    class="recipe-foodstuff-form--unit-form--select"
                    v-model="foodStuff.unit"
                    :label="$t('recipe.recipeEdit.foodstuffUnit')"
                    :options="units"
                    :existingValue="foodStuff?.unit"
                    :error="inputCheckArray[index]?.unitError"
                  />
                  <div v-if="foodStuff?.isSubRecipe">{{ foodStuff?.unit }}</div>
                </div>
                <r-hr class="recipe-unit-form--content" hrStyle="gray" />
              </template>
            </template>

            <template v-else>
              <span class="recipe-foodstuff-form--unit-form--food-name">{{
                foodStuff?.name
              }}</span>
              <div class="recipe-foodstuff-form--unit-form--wrapper">
                <r-textbox
                  class="recipe-foodstuff-form--unit-form--textbox"
                  type="number"
                  v-model.number="foodStuff.amount"
                  :placeholder="$t('recipe.recipeEdit.foodstuffAmount')"
                  :error="inputCheckArray[index]?.amountError"
                />
                <r-select
                  v-if="!foodStuff?.isSubRecipe"
                  class="recipe-foodstuff-form--unit-form--select"
                  v-model="tmpUnit"
                  @update="changeUnit(index)"
                  :label="$t('recipe.recipeEdit.foodstuffUnit')"
                  :options="units"
                  :existingValue="foodStuff?.unit"
                  :error="inputCheckArray[index]?.unitError"
                />
                <div v-if="foodStuff?.isSubRecipe">{{ foodStuff?.unit }}</div>
              </div>
              <r-hr class="recipe-unit-form--content" hrStyle="gray" />
            </template>
          </div>
        </template>
      </div>

      <!-- 人前の入力ここから -->
      <div v-if="!servingHidden">
        <div class="amount-form--serving">
          <r-textbox
            type="tel"
            class="amount-form--serving--textbox"
            v-model.number="serv"
            :error="servError"
          />
          <span class="amount-form--serving--unit">{{
            $t("common.perPerson")
          }}</span>
        </div>
        <div class="errorFlag">
          {{ errorMessage.serv }}
        </div>
      </div>
      <!-- 人前の入力ここまで -->
    </r-modal>

    <recipe-cost-modal
      :items="changeItem"
      @enter="changeCostUnitChangedItem"
      @cancel="closeReciepCostModal"
      v-if="status === 'unitChange'"
    ></recipe-cost-modal>
  </div>
</template>

<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";
import RModal from "@/plugins/molecules/RModal.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";

import RecipeCostModal from "@/components/recipe/organisms/RecipeCostModal.vue";

import auth from "@/libs/Auth.js";
import unitManager from "@/libs/Unit.js";

export default {
  components: {
    RTextbox,
    RSelect,
    RHr,
    RModal,
    RListContent,
    RListItem,
    RecipeCostModal,
  },
  props: {
    foodstuffs: {
      type: Array,
      default: () => [],
    },
    existingServ: {
      type: Number,
      default: 1,
    },
    servingHidden: Boolean,
    newRegistrationLimited: Boolean,
    hasCostCalculated: Boolean,
  },
  data() {
    return {
      inputArray: [],
      serv: 1,
      units: {},
      errorFlag: false,
      inputCheckArray: [],
      servError: false,
      width: 0,
      modalStatus: "",
      changeItem: [],
      status: "",
      changeUnitStatus: false,
      tmpUnit: "",
      errorMessage: {
        amount: "",
        unit: "",
        serv: "",
      },
    };
  },
  computed: {
    isWindowTablet() {
      return this.width > 641;
    },
  },
  methods: {
    changeUnit(index) {
      if (this.hasCostCalculated) {
        const item = this.inputArray[index];
        this.changeItem.push({
          id: item.id,
          ingredientID: item.ingredientID,
          name: item.name,
          amount: item.amount,
          unit: this.tmpUnit,
          cost: item.cost,
          quantity: item.quantity,
          unitAmount: item.unitAmount,
          price: item.price,
          quantityUnit: item.quantityUnit,
          isSubRecipe: item.ref ? true : false,
        });
        // modalを切り替え
        this.status = "unitChange";
      } else {
        this.$set(this.inputArray, index, this.tmpUnit);
      }
    },
    changeCostUnitChangedItem(tmpArray) {
      const changeIndex = this.inputArray
        .map((e) => e.name)
        .indexOf(tmpArray[0].name);
      // 単位を反映
      this.$set(this.inputArray, changeIndex, tmpArray[0]);
      this.closeReciepCostModal();
    },
    closeReciepCostModal() {
      this.changeItem = [];
      this.changeUnitStatus = false;
      this.status = "";
    },
    enter() {
      this.makeInputCheckArray();
      // 入力漏れチェック
      if (
        this.inputCheckArray.map((e) => e?.amountError)?.includes(true) ||
        this.inputCheckArray.map((e) => e?.unitError)?.includes(true) ||
        this.errorMessage.serv !== ""
      ) {
        this.errorFlag = true;
        return;
      }
      // 入力した内容を foodstuffsに反映
      this.inputArray.forEach((e) => {
        let index = this.foodstuffs.findIndex(
          (item) =>
            item.ingredientID === e.ingredientID && item.name === e.name,
        );
        // eslint-disable-next-line vue/no-mutating-props
        this.foodstuffs[index].amount = e.amount;
        // eslint-disable-next-line vue/no-mutating-props
        this.foodstuffs[index].unit = e.unit;
        // eslint-disable-next-line vue/no-mutating-props
        this.foodstuffs[index].cost = e.cost;
        // eslint-disable-next-line vue/no-mutating-props
        this.foodstuffs[index].unitAmount = e.unitAmount;
        // eslint-disable-next-line vue/no-mutating-props
        this.foodstuffs[index].displayNum = e.displayNum;
      });
      this.$emit("enter", this.foodstuffs, this.serv);
    },
    cancel() {
      this.$emit("cancel", this.foodstuffs);
    },
    makeInputCheckArray() {
      let errorCountAmount = 0;
      let errorCountUnit = 0;

      this.inputArray.forEach((val, index) => {
        // 挿入用オブジェクト
        if (val.amount <= 0 || val.amount === "") {
          this.$set(this.inputCheckArray[index], "amountError", true);
          this.$set(
            this.errorMessage,
            "amount",
            this.$t("recipe.recipeEdit.unitFormError"),
          );
          errorCountAmount++;
        } else if (
          typeof val.amount !== "number" &&
          !val.amount.match(/^[0-9]+$/)
        ) {
          this.$set(this.inputCheckArray[index], "amountError", true);
          this.$set(
            this.errorMessage,
            "amount",
            "半角で整数値を入力してください。",
          );
          errorCountAmount++;
        }
        // else if (!Number.isInteger(val.amount)) {
        //   this.$set(this.inputCheckArray[index], "amountError", true);
        //   this.$set(
        //     this.errorMessage,
        //     "amount",
        //     "半角で整数値を入力してください。"
        //   );
        //   errorCountAmount++;
        //}
        else if (errorCountAmount === 0) {
          this.$set(this.inputCheckArray[index], "amountError", false);
          this.$set(this.errorMessage, "amount", "");
        }
        if (val.unit === "") {
          this.$set(this.inputCheckArray[index], "unitError", true);
          this.$set(
            this.errorMessage,
            "unit",
            this.$t("recipe.recipeEdit.unitFormError"),
          );
          errorCountUnit++;
        } else if (errorCountUnit === 0) {
          this.$set(this.inputCheckArray[index], "unitError", false);
          this.$set(this.errorMessage, "unit", "");
        }
      });

      if (this.serv <= 0 || this.serv === "") {
        this.$set(this.errorMessage, "serv", "人前が入力されていません");
      } else if (
        typeof this.serv !== "number" &&
        !this.serv.match(/^[0-9]+$/)
      ) {
        this.$set(this.errorMessage, "serv", "半角数字で入力してください。");
      } else {
        this.$set(this.errorMessage, "serv", "");
      }
    },
    makeInputCheckArrayDefault() {
      this.inputArray.forEach((val) => {
        this.inputCheckArray.push({
          name: val.name,
          amountError: false,
          unitError: false,
        });
      });
    },
    handleResize() {
      this.width = window.innerWidth;
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      // 単位一覧を取得
      this.units = await unitManager.getUnits(this.customerID, this.storeID);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error("Forbidden");
    }
    // props から表示データ作成
    this.inputArray = this.foodstuffs.map((item) => {
      let inputData = {
        id: item.id,
        ingredientID: item.ingredientID,
        name: item.name,
        amount: item.amount,
        unit: item.unit,
        cost: item.cost,
        quantity: item.quantity,
        unitAmount: item.unitAmount,
        price: item.price,
        quantityUnit: item.quantityUnit,
        isSubRecipe: item.ref ? true : false,
        displayNum: item.displayNum,
      };
      return inputData;
    });
    this.serv = this.existingServ;
    this.makeInputCheckArrayDefault();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.recipe-unit-form--list {
  margin-top: 10px;
  height: 50vh;
  overflow: auto;
}

/** リストコンテンツ */
.recipe-foodstuff-form--unit-form {
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  margin-top: 20px;
  align-items: center;
}

.recipe-foodstuff-form--unit-form--wrapper {
  width: 350px;
  display: flex;
  justify-content: flex-end;
}
.recipe-foodstuff-form--unit-form--textbox {
  width: 100%;
  margin-right: 10px;
}
.recipe-foodstuff-form--unit-form--select {
  width: 100%;
}

/** 人前入力 */
.amount-form--serving {
  box-sizing: border-box;
  width: 50%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.amount-form--serving--textbox {
  width: 140px;
  margin-right: 10px;
}

.errorFlag {
  transition: 3s;
  color: red;
}
.recipe-unit-form--content {
  margin-top: 10px;
}
.recipe-foodstuff-form--unit-form--subRecipe-unit {
  display: flex;
  justify-content: center;
  width: 120px;
}

@media screen and (min-width: 641px) {
  .recipe-unit-form--list {
    margin-top: 20px;
  }
  /** リストの見出し */
  .recipe-unit-form--list-heading {
    font-weight: bold;
    text-align: center;
  }
  .recipe-unit-form--list-heading--left {
    width: 60%;
  }
  .recipe-unit-form--list-heading--right {
    width: 40%;
    display: flex;
  }
  .recipe-foodstuff-form--unit-form--food-name {
    width: 60%;
    font-weight: bold;
  }
  .recipe-unit-form--list-heading--right--item {
    display: inline-block;
    width: 100px;
    margin-left: 10px;
  }
  .recipe-foodstuff-form--unit-form--textbox {
    width: 100px;
    margin-right: 10px;
  }
  .recipe-foodstuff-form--unit-form--select {
    width: 100px;
  }
}

@media screen and (min-width: 1024px) {
  .recipe-unit-form--list {
    margin-top: 20px;
  }
  .recipe-foodstuff-form--unit-form--textbox {
    width: 120px;
    margin-right: 10px;
  }
  .recipe-foodstuff-form--unit-form--select {
    width: 120px;
  }
  .recipe-unit-form--list-heading--right--item {
    width: 120px;
  }
}
</style>

<template>
  <r-modal
    class="recipe-price-update-form"
    :title="$t('recipe.recipeEdit.editPrice')"
    enter
    @enter="enter"
    @cancel="cancel"
  >
    <div class="error-message">{{ error.price }}</div>
    <div class="recipe-price-update-form--input">
      <r-textbox
        type="tel"
        v-model.number="tmpPrice"
        class="textbox"
        :error="error.price !== ''"
      ></r-textbox>
      {{ $t("common.currencyYen") }}
    </div>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";

export default {
  props: {
    price: Number,
  },
  components: {
    RModal,
    RTextbox,
  },
  methods: {
    enter() {
      this.executeValidation();
      if (this.error.price !== "") {
        return;
      }
      this.$emit("enter", this.tmpPrice);
    },
    cancel() {
      this.$emit("cancel");
    },
    executeValidation() {
      if (this.tmpPrice < 0 || this.tmpPrice === "") {
        this.$set(
          this.error,
          "price",
          this.$i18n.t("recipe.recipeEdit.priceNotInputed"),
        );
      } else if (
        typeof this.tmpPrice !== "number" &&
        !this.tmpPrice.match(/^[0-9]+$/)
      ) {
        this.$set(
          this.error,
          "price",
          this.$i18n.t("recipe.recipeDetail.validationHalfsizeInteger"),
        );
      } else if (!Number.isInteger(this.tmpPrice)) {
        this.$set(
          this.error,
          "price",
          this.$i18n.t("recipe.recipeDetail.validationHalfsizeInteger"),
        );
      } else {
        this.$set(this.error, "price", "");
      }
    },
  },
  data() {
    return {
      tmpPrice: 0,
      error: {
        price: "",
      },
    };
  },
  watch: {
    price: {
      handler() {
        this.tmpPrice = this.price;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.recipe-price-update-form--input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-message {
  color: #e85951;
  font-weight: bold;
}
.textbox {
  width: 100px;
}
</style>

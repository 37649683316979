export default {
  getSimilarItemNameList(masterItems, suppliers, selectItem) {
    let similarNameArray = [];

    // 現在選択している食材の小分類が「その他」「全般」は食材名が類似した食材一覧の表示は行わない
    if (selectItem.ss === "その他" || selectItem.ss === "全般") {
      return similarNameArray;
    }

    // 取引先が存在している場合は、取引先のみの食材で抽出
    const getItems = masterItems.reduce((accumulator, line) => {
      const supplierName = line["［取引先名］"];
      let addArray = false;
      if (suppliers.length > 0) {
        // 取引会社が登録されている場合
        if (suppliers.indexOf(supplierName) >= 0) {
          addArray = true;
        }
      } else {
        addArray = true;
      }

      let ssName = line["［食品小分類名］"];
      if (addArray) {
        // 小分類名に商品名が入っていないと候補外
        if (selectItem.name.indexOf(ssName) < 0) {
          addArray = false;
        }
      }

      if (addArray) {
        // 小分類名に「その他」または、「全般」が存在する場合は、小分類に「中分類名(小分類名)」を表示する
        if (ssName === "その他" || ssName === "全般") {
          ssName = `${line["［食品中分類名］"]}(${ssName})`;
        }

        accumulator.push({
          id: line["［マイカタログID］"],
          ingredientID: line["[マイカタログID]"],
          supplier: line["［取引先名］"],
          ls: line["［食品大分類名］"],
          ms: line["［食品中分類名］"],
          ss: ssName,
          name: line["［商品名］"],
          standard: line["［規格］"],
          quantity: Number(line["［入数］"]),
          quantityUnit: line["［入数単位］"],
          price: Number(line["［単価］"]),
          kana: line["［商品名カナ］"],
        });
      }
      return accumulator;
    }, []);
    // 重複削除
    similarNameArray = [...new Set(getItems)];

    return similarNameArray;
  },
  getGroupItemNameList(masterItems, suppliers, selectItem) {
    let groupNameArray = [];

    // 現在選択している食材の小分類が「その他」「全般」は食材名が類似した食材一覧の表示は行わない
    if (selectItem.ss === "その他" || selectItem.ss === "全般") {
      return groupNameArray;
    }

    // 取引先が存在している場合は、取引先のみの食材で抽出
    const getItems = masterItems.reduce((accumulator, line) => {
      const supplierName = line["［取引先名］"];
      let addArray = false;
      if (suppliers.length > 0) {
        // 取引会社が登録されている場合
        if (suppliers.indexOf(supplierName) >= 0) {
          addArray = true;
        }
      } else {
        addArray = true;
      }

      let ssName = line["［食品小分類名］"];
      const mmName = line["［食品中分類名］"];
      if (addArray) {
        // 中分離名が一致しないと候補から除外
        if (mmName !== selectItem.ms) {
          addArray = false;
        }
      }

      if (addArray) {
        // 小分類名に「その他」または、「全般」が存在する場合は、小分類に「中分類名(小分類名)」を表示する
        if (ssName === "その他" || ssName === "全般") {
          ssName = `${line["［食品中分類名］"]}(${ssName})`;
        }

        accumulator.push({
          id: line["［マイカタログID］"],
          ingredientID: line["［マイカタログID］"],
          supplier: line["［取引先名］"],
          ls: line["［食品大分類名］"],
          ms: line["［食品中分類名］"],
          ss: ssName,
          name: line["［商品名］"],
          standard: line["［規格］"],
          quantity: Number(line["［入数］"]),
          quantityUnit: line["［入数単位］"],
          cost: 0,
          price: Number(line["［単価］"]),
          kana: line["［商品名カナ］"],
        });
      }
      return accumulator;
    }, []);

    // 重複削除
    groupNameArray = [...new Set(getItems)];

    return groupNameArray;
  },
};

<template>
  <r-modal
    :title="$t('reciproCommon.costUpdateModal.costUpdateConfirmModalTitle')"
    enter
    enterLabel="OK"
    @enter="closeModal"
    :cancelHidden="cancelHidden"
    class="modal-wrapper"
  >
    <div class="cost-update--main--content-wrapper">
      <div class="cost-update--main--content bottom-line">
        <div class="cost-sentence">
          {{ $t("reciproCommon.costUpdateModal.pastTotalCost") }} :
          <span
            >{{ priceUpdate.pastTotalCost | localNumber
            }}{{ $t("common.currencyYen") }}</span
          >
        </div>
        <img
          class="right-arrow cost-sentence margin-config"
          src="@/assets/images/icon2_right_color.png"
        />
        <div class="cost-sentence after-update-wrapper">
          <span class="cost-sentence numeric-value"
            >{{ $t("reciproCommon.costUpdateModal.afterUpdateCost") }} :</span
          >
          <span class="numeric-value after-update"
            >{{ priceUpdate.totalCost | localNumber
            }}{{ $t("common.currencyYen") }}</span
          >
        </div>
      </div>
      <div class="cost-update--main--content second-content">
        <div class="cost-sentence">
          {{ $t("reciproCommon.costUpdateModal.pastTotalCostRate") }} :
          <span>{{ priceUpdate.pastCostRate }}%</span>
        </div>
        <img
          class="right-arrow cost-sentence margin-config"
          src="@/assets/images/icon2_right_color.png"
        />
        <div class="cost-sentence after-update-wrapper">
          <span class="cost-sentence numeric-value"
            >{{
              $t("reciproCommon.costUpdateModal.afterUpdateCostRate")
            }}
            :</span
          >
          <span class="numeric-value after-update">
            {{ priceUpdate.costRate }}%</span
          >
        </div>
      </div>
    </div>
  </r-modal>
</template>
<script>
import RModal from "@/plugins/molecules/RModal.vue";
import auth from "@/libs/Auth.js";
import priceUpdateManager from "@/libs/PriceUpdate.js";

export default {
  components: {
    RModal,
  },
  props: {
    cancelHidden: Boolean,
  },
  data() {
    return {
      priceUpdate: {
        price: 0,
        totalCost: 0,
        pastTotalCost: 0,
        costRate: 0,
        pastCostRate: 0,
      },
      isHeadquarters: false,
      customerID: "",
      storeID: "",
    };
  },
  methods: {
    async closeModal() {
      // 更新チェック
      await priceUpdateManager.updateCheck(this.priceUpdate.priceUpdateID);
      // 表示用のデータを削除
      this.$root.clearPriceUpdateAction();
      this.$emit("enter");
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      this.isHeadquarters = await auth.isHeadquarters();

      if (!this.customerID || (!this.storeID && !this.isHeadquarters)) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      if (this.$root.privateState.priceUpdate) {
        this.priceUpdate = this.$root.privateState.priceUpdate;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
.modal-wrapper {
  width: 90%;
}
.cost-update--main--content-wrapper {
  display: grid;
  vertical-align: middle;
  padding: 0px 20px 0px;
}
.cost-update--main--content {
  display: grid;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: unset;
}
.bottom-line {
  border-bottom: 2px solid #444;
}
.right-arrow {
  width: 18px;
  height: 28px;
  transform: rotate(90deg);
}
.margin-config {
  margin: auto !important;
}
.cost-sentence {
  display: block;
  margin: auto 0px auto 0px;
}
.second-content {
  margin-top: 20px;
}
.numeric-value {
  font-weight: bold;
  font-size: 20px;
}
.after-update-wrapper {
  display: flex;
}
.after-update {
  font-weight: bold;
  font-size: 40px;
  color: #517ce8;
  text-align: left;
  float: left;
}
@media screen and (min-width: 641px) {
  .modal-wrapper {
    width: 95vw;
  }
  .cost-update--main--content-wrapper {
    display: grid;
    vertical-align: middle;
    padding: 20px 20px 20px;
  }
  .cost-update--main--content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: 20px 0px 20px 0px;
  }
  .bottom-line {
    padding-bottom: 20px;
    border-bottom: unset;
  }
  .second-content {
    margin-top: unset;
  }
  .right-arrow {
    transform: unset;
  }
  .margin-config {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .numeric-value {
    font-size: 20px;
  }
  .after-update {
    font-size: 32px;
  }
}
@media (min-width: 1024px) {
  .modal-wrapper {
    width: 90vw !important;
  }
  .cost-update--main--content-wrapper {
    padding: unset;
  }
  .cost-update--main--content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    padding: 20px;
  }
  .numeric-value {
    font-size: 24px;
  }
  .after-update {
    font-size: 48px;
  }
}
</style>

<template>
  <div>
    <!--タイトル：メニュー一覧-->
    <r-heading :level="1" class="menu-list-heading" :image="headingImage">{{
      $t("menu.menuList.menuListTitle")
    }}</r-heading>
    <div class="menu-list-wrapper">
      <!--「戻る」ボタン-->
      <div class="back-btn">
        <r-button
          buttonStyle="primary"
          :outline="true"
          @click="toMenuTop"
          class="back-btn-r"
        >
          <span>{{ $t("common.back") }}</span>
        </r-button>
      </div>
      <!--メニューブック選択(4つ)-->
      <div class="book-title-wrapper">
        <r-button
          v-for="(value, index) in books"
          :key="index"
          @click="toggleCurrent(index)"
          class="book-title"
          :class="
            value.name === '' ? ' book-title-not' : ' book-title-registered'
          "
          :style="
            index === current ? 'background-color:#e87352; color:#fff' : ''
          "
          :disabled="value.name === ''"
          buttonStyle="primary"
          outline
          full
        >
          <span v-if="value.name !== ''">{{ value.name }}</span>
          <span v-else>{{ $t("menu.menuList.notRegisterd") }}</span>
        </r-button>
      </div>
      <!--メニューブックセクション内容-->
      <r-spinner v-if="isShowSpinner" />
      <div v-else>
        <div class="book-contents">
          <div class="book-name-price">
            <div class="book-name-cost-wrapper">
              <span class="book-name">{{ currentBookName }}</span>
              <span class="book-cost-rate">
                【{{ $t("common.costRate") }}：{{
                  calcTotalCostRate
                }}
                ％】</span
              >
            </div>
            <div v-if="windowSizePC" class="edit-btn-wrapper">
              <r-button
                buttonStyle="primary"
                outline
                full
                @click="editBook"
                class="edit-btn"
              >
                {{ $t("menu.menuList.editBook") }}</r-button
              >
              <r-button
                buttonStyle="primary"
                outline
                full
                @click="deleteBook"
                class="edit-btn"
              >
                {{ $t("menu.menuList.deleteBook") }}</r-button
              >
            </div>
          </div>
          <r-paper paperStyle="yellow" class="yellow-paper">
            <!--編集・削除ボタン-->
            <div v-if="!windowSizePC">
              <r-button
                buttonStyle="primary"
                outline
                full
                @click="editBook"
                class="edit-btn"
              >
                {{ $t("menu.menuList.editBook") }}</r-button
              >
              <r-button
                buttonStyle="primary"
                outline
                full
                @click="deleteBook"
                class="edit-btn"
              >
                {{ $t("menu.menuList.deleteBook") }}</r-button
              >
            </div>
            <div class="link-note-text">
              <!-- <span
                >※レシピ画面を別タブで開きたい場合はctrlまたは⌘＋クリック<br />
                コースは別タブで開くことはできません。</span
              > -->
            </div>

            <!--ブック内容表示:セクションリスト-->
            <div
              class="section-list"
              v-for="(item, index) in sectionList"
              :key="index"
            >
              <div class="section-title">
                <div class="section-title-name">{{ item.name }}</div>
                <div v-if="windowSizePC">
                  {{ $t("common.costRate") }} :
                  <span class="rate-number">{{ item.costRate }}</span> %
                </div>
              </div>
              <div class="section-cost-rate" v-if="!windowSizePC">
                {{ $t("common.costRate") }}:
                <span class="rate-number">{{ item.costRate }}</span> %
              </div>
              <!--メニュー１個の名前、売値、原価率-->
              <div class="menu-items" v-if="windowSizePC">
                <span class="menu-name-title">{{
                  $t("menu.menuTop.menu")
                }}</span>
                <div class="menu-price-title">
                  <span class="m-price-title">{{ $t("common.price") }}</span>
                  <span class="m-rate-title">{{ $t("common.costRate") }}</span>
                </div>
              </div>
              <div
                class="menu-items"
                v-for="(menu, menuIdx) in sectionList[index].menus"
                :key="menuIdx"
              >
                <!-- メニュー名 -->
                <div class="menu-name">
                  <!-- レシピがメニューの場合 -->
                  <!-- <div
                    v-if="menu.recipeRef"
                    @click="toRecipeDetail(menu.recipeRef.id)"
                    class="link-text"
                  >
                    {{ menu.name }}
                  </div> -->

                  <div v-if="menu.recipeRef">
                    <router-link
                      :to="{
                        path: `/recipe-detail/${menu.recipeRef.id}`,
                      }"
                      >{{ menu.name }}</router-link
                    >
                  </div>

                  <!-- コースがメニューの場合 -->
                  <div
                    v-if="menu.courseRef"
                    @click="toCourseDetail(menu.courseRef.id)"
                    class="link-text"
                  >
                    {{ menu.name }}
                  </div>
                </div>
                <div class="menu-price">
                  <div class="m-price">
                    <span v-if="!windowSizePC">
                      {{ $t("menu.menuRegist.price") }} :
                    </span>
                    {{ $t("common.currency") }} {{ menu.price }}
                  </div>
                  <div class="m-rate">
                    <span v-if="!windowSizePC"
                      >&nbsp;&nbsp;&nbsp;{{
                        $t("menu.menuRegist.price")
                      }}
                      :</span
                    >
                    {{ menu.costRate }} %
                  </div>
                </div>
              </div>
            </div>
            <!--合計原価率グルプ-->
            <div class="total-chart">
              <h3>{{ $t("common.totalCostRate") }}</h3>
              <r-chart
                chartStyle="secondary"
                :rate="calcTotalCostRate"
                :label="$t('common.costRate')"
              ></r-chart>
            </div>
          </r-paper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RChart from "@/plugins/atoms/RChart.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import menuManager from "@/libs/Menu.js";

export default {
  components: {
    RButton,
    RPaper,
    RChart,
    RHeading,
    RSpinner,
  },
  data() {
    return {
      customerID: "",
      storeID: "", //モーダル操作
      isComplete: false, //登録完了メッセージ表示
      current: 0, //現在のブックのインデックス
      currentBookName: "",
      selectedBook: {},
      sectionList: [], //セクションの表示用リスト
      totalCostRate: 0,
      books: [], // ブック
      width: window.innerWidth,
      headingImage: require("../../../assets/images/bookColor.png"),
      isShowSpinner: false,
    };
  },
  methods: {
    toMenuTop() {
      //戻るボタン
      this.$router.push({ name: "menu" }, () => {});
    },
    toRecipeDetail(recipeID) {
      // レシピ名のリンク

      // 別のタブを開いてコースの詳細画面に遷移する
      let resolvedRoute = this.$router.resolve({
        name: "recipeDetail",
        params: {
          id: recipeID,
        },
      });
      window.open(resolvedRoute.href, "_blank");
    },
    toCourseDetail(courseID) {
      // コース名のリンク

      this.$router.push(
        {
          name: "courseDetail",
          params: {
            id: courseID,
          },
        },
        () => {},
      );

      // // 別のタブを開いてコースの詳細画面に遷移する
      // let resolvedRoute = this.$router.resolve({
      //   name: "courseDetail",
      //   params: {
      //     id: courseID
      //   }
      // });
      // window.open(resolvedRoute.href, "_blank");
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    async toggleCurrent(index) {
      this.isShowSpinner = true;
      this.current = index;
      //選択して表示用のデータ生成
      this.currentBookName = this.books[index].name;
      await this.createSectionList(this.books[index].id);
      this.isShowSpinner = false;
    },
    editBook() {
      //sessionStorageにデータ登録(bookID, bookTitle, bookNoのみ)
      (sessionStorage.bookNo = Number(this.current) + 1),
        (sessionStorage.bookID = this.books[this.current].id);
      if (this.books[this.current].name === null) {
        //nullの場合チェック
        sessionStorage.bookTitle = "";
      }

      this.$router.push(
        {
          name: "menuEdit",
          params: {
            bookID: this.books[this.current].id,
            bookTitle: this.books[this.current].name,
            bookNo: Number(this.current) + 1,
            sectionList: this.sectionList,
          },
        },
        () => {},
      );
    },
    async deleteBook() {
      //選択したメニューブックを削除
      //メニューブックのセクションの削除
      if (
        window.confirm(
          this.$i18n.t("menu.menuList.thisMenuBook") +
            this.books[this.current].name +
            this.$i18n.t("menu.menuList.confirmDeleteBook"),
        )
      ) {
        try {
          // データの削除-サブコレクションはFunctionでコマンドで削除
          await menuManager.deleteBook(this.books[this.current].id);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          throw new Error(error);
        }
        this.$router.push(
          {
            name: "menu",
            params: {
              //アラート表示
              isDeleteComplete: true,
              deleteBook: this.books[this.current].name,
            },
          },
          () => {},
        );
      }
    },
    async createSectionList(bookID) {
      this.sectionList = await menuManager.getSectionList(bookID);
    },
  },
  async created() {
    this.isShowSpinner = true;
    this.customerID = await auth.getCustomerID(this.$root);
    this.storeID = await auth.getStoreID(this.$root);
    if (!this.customerID || !this.storeID) {
      // customerID、StoreIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }
    // ブックの情報を取得
    try {
      // メニュー情報を取得する
      this.books = await menuManager.getMenuList(this.customerID, this.storeID);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      alert(error);
    }
    await this.toggleCurrent(this.books.findIndex((e) => e.name !== ""));
    this.isShowSpinner = false;
  },
  computed: {
    calcTotalCostRate() {
      //合計原価率計算
      const tmpArr = this.sectionList.map((e) => e.menus).flat();
      const totalCostRate = tmpArr.reduce((acc, menu) => {
        acc += menu.costRate / tmpArr.length;
        return acc;
      }, 0);
      return parseFloat(totalCostRate.toFixed(2));
    },
    windowSizePC() {
      //PCサイズ対応
      return this.width >= 1024 || this.width >= 641;
    },
  },
  //PCサイズ対応
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style scoped>
.menu-list-wrapper {
  font-size: 1rem;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 60px;
}
.back-btn {
  display: flex;
  justify-content: left;
  position: relative;
  height: auto;
  top: -2vh;
}
.back-btn-r {
  color: #333;
  margin-left: 4vw;
  margin-top: 15px;
}
.back-btn-r:hover {
  background-color: #e87352;
  color: #fff;
  transition: 0.1s;
}
.book-title-wrapper {
  display: block;
  box-sizing: border-box;
  padding: 4vw;
  padding-top: 0;
}
.book-title {
  width: calc(50% - 5px);
  height: 4.5vh;
  margin-right: 5px;
  margin-bottom: 5px;
}
.book-title:nth-of-type(2n) {
  margin-right: unset;
}
.book-title-registered {
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.book-title-registered:hover {
  background-color: #e87352;
  color: #fff;
  transition: 0.5s;
}
.book-title-not {
  border: 2px solid #f8d5cb;
  color: #aaa;
}
.book-contents {
  display: block;
  min-height: 30vh;
  background-color: #4d261b;
  border-radius: 5px;
}
.book-name-price {
  padding-left: 2vw;
}
.book-name {
  color: #fff;
  text-align: left;
  font-weight: bold;
  display: block;
  padding-top: 4vw;
  padding-left: 4vw;
  width: 85%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.book-cost-rate {
  color: #fff;
  text-align: left;
  font-weight: bold;
  display: block;
  padding-left: 4vw;
}
.yellow-paper {
  min-height: 30vh;
  border: 4vw solid #4d261b;
  padding: 4vw #4d261b;
  border-radius: 0 0 5px 5px;
}
.edit-btn {
  width: 45%;
  height: 4.5vh;
  margin: 5px;
  color: #333;
}
.edit-btn:hover {
  color: #4d261b;
  background-color: #fff;
  border: 2px solid #4d261b;
  transition: 0.5s;
}
.section-list {
  padding: 10px;
}
.section-title {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
  border-top: 2px solid #e87352;
  border-bottom: 2px solid #e87352;
}
.section-title-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 75vw;
}
.menu-items {
  display: block;
  margin: 5px auto;
  padding: 5px;
  border-bottom: 2px solid #ccc;
  line-height: 1.5rem;
}
.section-cost-rate {
  padding: 5px;
  border-bottom: 2px solid #517ce8;
}
.rate-number {
  color: #517ce8;
}
.section-name {
  float: left;
  padding: 10px;
}
.menu-name {
  width: 100%;
  text-align: left;
}
.menu-price {
  width: 100%;
  display: flex;
  text-align: left;
}
.total-chart {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 10px 10px 10px;
  margin-bottom: 30px;
  text-align: center;
}
.link-text {
  color: blue;
  text-decoration: underline;
}
.link-note-text {
  display: inline-block;
  text-align: left;
  width: auto;
  margin: 0 auto;
  text-indent: -1em;
}
/* .link-note-text-indent {
  text-indent: 1em;
  display: inline-block;
} */
/*iPad用css*/
@media (min-width: 641px) {
  .menu-list-wrapper {
    width: 100%;
    padding: 2%;
    margin-top: 20px;
  }
  .back-btn {
    display: flex;
    justify-content: left;
    position: relative;
    top: -2.5vh;
    width: 100%;
  }
  .back-btn-r {
    margin-left: unset;
  }
  .book-title-wrapper {
    display: flex;
    padding: unset;
  }
  .book-title {
    display: block;
    padding: 7px 12px 8px;
    height: 5vh;
    margin-top: 20px;
    margin-bottom: 20px;
    width: calc(25%-1.2rem);
    margin-right: 0.4rem;
  }
  .book-title:nth-of-type(2n) {
    margin-right: 0.4rem;
  }
  .book-title:last-child {
    margin-right: unset;
  }
  .book-title-registered {
    background-color: #fff;
    cursor: pointer;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .book-title-not {
    font-weight: bold;
    border: 2px solid #f8d5cb;
    color: #aaa;
  }
  .book-contents {
    display: block;
    min-height: 30vh;
    background-color: #4d261b;
  }
  .book-name-price {
    padding-top: 2vw;
    display: flex;
    justify-content: space-between;
    align-content: center;
    color: #fff;
  }
  .book-name-cost-wrapper {
    width: 60%;
    margin-top: 5px;
    display: flex;
    justify-content: left;
    font-size: 1.3rem;
  }
  .edit-btn-wrapper {
    width: 40%;
    display: table-cell;
    justify-content: center;
    text-align: center;
    padding-right: 3.5vw;
    margin-bottom: 5px;
  }
  .book-name {
    display: table-cell;
    font-weight: bold;
    padding-top: 0px;
    padding-left: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 55%;
    vertical-align: middle;
    margin-left: 10px;
  }
  .book-cost-rate {
    display: table-cell;
    font-size: 1rem;
    vertical-align: middle;
    font-weight: bold;
    padding-left: 0px;
    width: auto;
    margin-top: 5px;
    font-size: 0.9rem;
  }
  .edit-btn {
    color: #fff;
    background-color: #4d261b;
    border: 2px solid #fff;
    padding: 4px 16px;
    width: 45%;
    font-size: 0.9rem;
  }
  .yellow-paper {
    min-height: 30vh;
    border-top: 1vw solid #4d261b;
    border-right: 40px solid #4d261b;
    border-bottom: 40px solid #4d261b;
    border-left: 40px solid #4d261b;
    padding: 40px #4d261b;
  }
  .section-title {
    display: flex;
    justify-content: space-between;
  }
  .section-title-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 60%;
  }
  .section-list {
    padding: 40px;
  }
  /*メニューリスト*/
  .menu-items-header {
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    padding: 5px;
    background-color: #e87352;
    color: #ffffff;
    font-weight: bold;
  }
  /*メニュータイトル*/
  .menu-name-title {
    flex-grow: 1;
    width: 100%;
    font-weight: bold;
    text-align: left;
    /* display: inline-block;*/
  }
  .menu-price-title {
    flex-grow: 1;
    display: flex;
    width: 100%;
  }
  .m-price-title {
    text-align: right;
    width: 100%;
    flex-grow: 1;
    font-weight: bold;
  }
  .m-rate-title {
    text-align: right;
    flex-grow: 1;
    width: 100%;
    font-weight: bold;
  }
  .menu-items {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    padding: 5px;
  }
  .menu-name {
    text-align: left;
    flex-grow: 1;
    padding: 5px;
    width: 100%;
    text-align: left;
  }
  .menu-price {
    flex-grow: 1;
    display: flex;
    width: 100%;
  }
  .m-price {
    text-align: right;
    width: 100%;
    flex-grow: 1;
  }
  .m-rate {
    text-align: right;
    width: 100%;
    flex-grow: 1;
  }
  .section-cost-rate {
    padding: 5px;
    border-bottom: 2px solid #517ce8;
  }
  .rate-number {
    color: #517ce8;
  }
  .section-name {
    float: left;
    padding: 10px;
  }
  .menu-name {
    padding: 5px;
    width: 100%;
    text-align: left;
  }
  .menu-name-title {
    width: 100%;
    font-weight: bold;
    display: inline-block;
    text-align: left;
  }
  .total-chart {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 10px 10px 10px;
    margin-bottom: 30px;
  }
}
/*PC用css */
@media (min-width: 1024px) {
  .menu-list-wrapper {
    width: 1024px;
    margin: 0 auto;
    margin-top: 4vh;
    padding: 0px;
    padding-top: 20px;
  }
  .back-btn {
    display: flex;
    justify-content: left;
    top: -4.2vh;
    width: 100%;
  }
  .book-title {
    height: 6vh;
  }
  .book-contents {
    display: block;
    min-height: 30vh;
    background-color: #4d261b;
  }
  .book-name-price {
    padding-top: 2vw;
    display: flex;
    justify-content: space-between;
    align-content: center;
    color: #fff;
  }
  .book-name-cost-wrapper {
    width: 70%;
    display: flex;
    justify-content: left;
    font-size: 1.3rem;
    vertical-align: middle;
  }
  .edit-btn-wrapper {
    display: table-cell;
    justify-content: center;
    text-align: center;
    padding-right: 2.5vw;
    margin-bottom: 10px;
    width: 28vw;
  }
  .book-name {
    display: table-cell;
    font-weight: bold;
    padding-top: 0px;
    padding-left: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: auto;
  }
  .book-cost-rate {
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
    padding-left: 0px;
    font-size: 1rem;
  }
  .edit-btn {
    color: #fff;
    background-color: #4d261b;
    border: 2px solid #fff;
    padding: 4px 16px;
    width: 116px;
    height: 40px;
    font-size: 1rem;
  }
  .edit-btn:hover {
    color: #4d261b;
    background-color: #fff;
    border: 2px solid #4d261b;
    padding: 4px 16px;
    width: 116px;
    height: 40px;
  }
  .yellow-paper {
    min-height: 30vh;
    border-top: 1vw solid #4d261b;
    border-right: 40px solid #4d261b;
    border-bottom: 40px solid #4d261b;
    border-left: 40px solid #4d261b;
    padding: 40px #4d261b;
  }
  .section-title {
    display: flex;
    justify-content: space-between;
  }
  .section-list {
    padding: 40px;
  }
  /*メニューリスト*/
  .menu-items-header {
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    padding: 5px;
    background-color: #e87352;
    color: #ffffff;
    font-weight: bold;
  }
  /*メニュータイトル*/
  .menu-name-title {
    flex-grow: 1;
    width: 100%;
    font-weight: bold;
    text-align: left;
    /* display: inline-block;
    text-align: center; */
  }
  .menu-price-title {
    flex-grow: 1;
    display: flex;
    width: 100%;
  }
  .m-price-title {
    text-align: right;
    width: 50%;
    flex-grow: 1;
    font-weight: bold;
  }
  .m-rate-title {
    text-align: right;
    flex-grow: 1;
    width: 50%;
    font-weight: bold;
  }
  .menu-items {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ccc;
    padding: 5px;
  }
  .menu-name {
    text-align: left;
    /* width: 60%; */
    flex-grow: 1;
    padding: 5px;
    width: 100%;
    text-align: left;
  }
  .menu-price {
    flex-grow: 1;
    /* width: 40%; */
    display: flex;
    width: 100%;
  }
  .m-price {
    text-align: right;
    width: 100%;
    flex-grow: 1;
  }
  .m-rate {
    text-align: right;
    flex-grow: 1;
    width: 100%;
  }
  .section-cost-rate {
    padding: 5px;
    border-bottom: 2px solid #517ce8;
  }
  .rate-number {
    color: #517ce8;
  }
  .section-name {
    float: left;
    padding: 10px;
  }
  .total-chart {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 10px 10px 10px;
    margin-bottom: 30px;
  }
}
</style>

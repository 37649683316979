<template>
  <div class="recipe-foodstuff-form">
    <r-modal
      class="recipe-food-stuff-form--modal"
      :step="step ? '4' : ''"
      :title="$t('recipe.recipeRegistration.recipeFoodstuffForm.formTitle')"
      :cancelHidden="!windowSizePC"
      :enter="windowSizePC && selectedFoodstuffs.length !== 0 && !editMode"
      @enter="incrementCurrentStep"
      @cancel="closeModal"
    >
      <div class="recipe-food-stuff-form--wrapper-for-web">
        <!-- PC版左側 -->
        <r-tabs class="recipe-food-stuff-form--wrapper-for-web--left">
          <!-- 取引中の食材から選ぶ -->
          <r-tab
            :label="
              $t('recipe.recipeRegistration.recipeFoodstuffForm.tradeFood1')
            "
            class="recipe-foodstuff-form--tab"
            :balloon="isWindowSizeIpad"
          >
            <!-- 取引中絞り込み方式選択 -->
            <div class="page-feed--buttons">
              <r-button
                class="page-feed--buttons--back"
                buttonStyle="primary"
                outline
                v-if="current !== ''"
                @click="back"
                >{{ $t("common.back") }}</r-button
              >
            </div>
            <r-modal-sub-header
              :title="
                $t('recipe.recipeRegistration.recipeFoodstuffForm.tradeFood2')
              "
              v-if="current === ''"
              class="recipe-foodstuff-form--sub-header recipe-foodstuff-form--trading"
            >
              <!--取引中の食材から選ぶ：Top-->
              <div class="recipe-foodstuff-form--trade--food--top">
                <r-button
                  buttonStyle="primary"
                  outline
                  full
                  @click="getSuppliers"
                  class="recipe-foodstuff-form--trade--food--top-button"
                  >{{
                    $t(
                      "recipe.recipeRegistration.recipeFoodstuffForm.getSupplier",
                    )
                  }}</r-button
                >
                <r-button
                  buttonStyle="primary"
                  outline
                  full
                  @click="getLsLists"
                  class="recipe-foodstuff-form--trade--food--top-button"
                  >{{
                    $t(
                      "recipe.recipeRegistration.recipeFoodstuffForm.tradeContent",
                    )
                  }}</r-button
                >
                <!-- 検索機能との連携 -->
                <r-search-form
                  @search="searchKeyWord"
                  clearOnly
                  class="recipe-foodstuff-form--trade--food--top-button"
                />
              </div>
            </r-modal-sub-header>
            <!-- 取引先企業から探す -->
            <r-modal-sub-header
              class="recipe-foodstuff-form--sub-header recipe-foodstuff-form--trading-food"
              :title="
                $t('recipe.recipeRegistration.recipeFoodstuffForm.getSupplier')
              "
              v-else-if="current === 'trade' && !searchFlag"
            >
              <!-- 企業選択 -->
              <div
                v-if="tradeCurrent === 0"
                class="recipe-foodstuff-form--company"
              >
                <r-button
                  round
                  outline
                  v-for="(company, index) in company"
                  :key="index"
                  @click="clickTradeCompany(1, company.name)"
                  class="recipe-foodstuff-form--trade--content"
                  >{{ company.name }}</r-button
                >
              </div>
              <!-- 大分類 -->
              <div
                v-if="tradeCurrent === 1"
                class="recipe-foodstuff-form--sub-content"
              >
                <r-search-form
                  @search="searchKeyWordBySupplier"
                  clearOnly
                  class="recipe-foodstuff-form--search"
                />
                <r-button
                  class="recipe-foodstuff-form--buttons"
                  v-for="(ls, index) in ls"
                  :key="index"
                  buttonStyle="primary"
                  full
                  customColor="#e88b51"
                  @click="clickTradeLs(2, ls)"
                  >{{ ls }}</r-button
                >
              </div>
              <!-- 中分類 -->
              <div
                v-else-if="tradeCurrent === 2"
                class="recipe-foodstuff-form--sub-content"
              >
                <r-button
                  class="recipe-foodstuff-form--buttons"
                  v-for="(ms, index) in ms"
                  :key="index"
                  buttonStyle="primary"
                  full
                  customColor="#e88b51"
                  @click="clickTradeMs(3, ms)"
                  >{{ ms }}</r-button
                >
              </div>
              <!-- 小分類 -->
              <div
                v-else-if="tradeCurrent === 3"
                class="recipe-foodstuff-form--sub-content"
              >
                <r-button
                  class="recipe-foodstuff-form--buttons"
                  v-for="(ss, index) in ss"
                  :key="index"
                  buttonStyle="primary"
                  full
                  customColor="#e88b51"
                  @click="clickTradeSs(4, ss)"
                  >{{ ss }}</r-button
                >
              </div>
              <div
                v-else-if="tradeCurrent === 4"
                class="recipe-foodstuff-form--foodstuff--wrapper"
              >
                <div
                  v-for="(foodstuff, index) in foodstuffList"
                  :key="index"
                  class="recipe-foodstuff-form--foodstuff-content"
                >
                  <r-food-stuff
                    :label="foodstuff.name"
                    :tag="foodstuff.ss"
                    :caption="foodstuff.supplier"
                    @click="pushToSelectedFoodstuff(foodstuff.name)"
                    v-if="
                      !selectedFoodstuffs
                        .map((e) => e.name)
                        .includes(foodstuff.name)
                    "
                  />
                </div>
              </div>
            </r-modal-sub-header>
            <!-- 分類から探す -->
            <r-modal-sub-header
              :title="
                $t('recipe.recipeRegistration.recipeFoodstuffForm.tradeContent')
              "
              v-else-if="current === 'category'"
              class="recipe-foodstuff-form--sub-header recipe-foodstuff-form--classification"
            >
              <!-- 大分類 -->
              <div
                class="recipe-foodstuff-form--sub-content"
                v-if="categoryCurrent === 0"
              >
                <r-button
                  class="recipe-foodstuff-form--buttons"
                  v-for="(ls, index) in ls"
                  :key="index"
                  buttonStyle="primary"
                  full
                  customColor="#e88b51"
                  @click="clickCategoryLs(1, ls)"
                  >{{ ls }}</r-button
                >
              </div>
              <!-- 中分類 -->
              <div
                v-else-if="categoryCurrent === 1"
                class="recipe-foodstuff-form--sub-content"
              >
                <r-button
                  class="recipe-foodstuff-form--buttons"
                  v-for="(ms, index) in ms"
                  :key="index"
                  buttonStyle="primary"
                  full
                  customColor="#e88b51"
                  @click="clickCategoryMs(2, ms)"
                  >{{ ms }}</r-button
                >
              </div>
              <!-- 小分類 -->
              <div
                v-else-if="categoryCurrent === 2"
                class="recipe-foodstuff-form--sub-content"
              >
                <r-button
                  class="recipe-foodstuff-form--buttons"
                  v-for="(ss, index) in ss"
                  :key="index"
                  buttonStyle="primary"
                  full
                  customColor="#e88b51"
                  @click="clickCategorySs(3, ss)"
                  >{{ ss }}</r-button
                >
              </div>
              <div
                v-else-if="categoryCurrent === 3"
                class="recipe-foodstuff-form--foodstuff--wrapper"
              >
                <div
                  v-for="(foodstuff, index) in foodstuffList"
                  :key="index"
                  class="recipe-foodstuff-form--foodstuff-content"
                >
                  <r-food-stuff
                    :label="foodstuff.name"
                    :tag="foodstuff.ss"
                    :caption="foodstuff.supplier"
                    :caption2="
                      `${foodstuff.price}` +
                      $t('common.currency') +
                      `/ ${foodstuff.quantity}${foodstuff.quantityUnit}`
                    "
                    @click="pushToSelectedFoodstuff(foodstuff.name)"
                    v-if="
                      !selectedFoodstuffs
                        .map((e) => e.name)
                        .includes(foodstuff.name)
                    "
                  />
                </div>
              </div>
            </r-modal-sub-header>
            <!--検索-->
            <r-modal-sub-header
              v-else-if="searchFlag"
              class="recipe-foodstuff-form--sub-header"
            >
              <food-stuff-search-list
                :searchedItems="searchedItems"
                :searchedSsItems="searchedSsItems"
                :selectedFoodstuffs="selectedFoodstuffs"
                :existingFoodstuffs="existingFoodstuffs"
                @select="pushToSelectedFoodstuffSearch"
              />
            </r-modal-sub-header>
          </r-tab>
          <!-- 食材を直接入力する -->
          <r-tab
            :label="$t('recipe.recipeRegistration.recipeFoodstuffForm.direct1')"
            class="recipe-foodstuff-form--tab"
            :balloon="isWindowSizeIpad"
          >
            <r-modal-sub-header
              class="recipe-foodstuff-form--sub-header"
              :title="
                $t('recipe.recipeRegistration.recipeFoodstuffForm.direct2')
              "
            >
              <span>{{ this.error }}</span>
              <div class="recipe-foodstuff-form--direct">
                <r-textbox
                  name="foodstuff-directory"
                  :placeholder="
                    $t(
                      'recipe.recipeRegistration.recipeFoodstuffForm.directInputFoodstuff',
                    )
                  "
                  v-model="foodstuffDirectory"
                ></r-textbox>
                <r-button
                  buttonStyle="secondary"
                  small
                  @click="pushToFoodstuffs"
                  >{{ $t("common.decide") }}</r-button
                >
              </div>
            </r-modal-sub-header>
          </r-tab>
          <!-- サブレシピから選ぶ -->
          <r-tab
            :label="
              $t('recipe.recipeRegistration.recipeFoodstuffForm.subRecipe1')
            "
            class="recipe-foodstuff-form--tab select-by-subrecipe"
            :balloon="isWindowSizeIpad"
            v-if="!cantAddSubRecipe"
          >
            <r-modal-sub-header
              class="recipe-foodstuff-form--sub-header"
              :title="
                $t('recipe.recipeRegistration.recipeFoodstuffForm.subRecipe2')
              "
            >
              <div class="recipe-foodstuff-form-select-sub-recipe-wrapper">
                <r-food-stuff
                  v-for="(subRecipe, index) in subRecipeList"
                  :key="index"
                  class="recipe-foodstuff-form-select-sub-recipe-content--item"
                  :label="subRecipe.name"
                  :tag="subRecipe.category"
                  :caption="
                    $t('common.currency') +
                    `${floorCost(subRecipe.totalCost)} / ${
                      subRecipe.subAmount
                    }${subRecipe.subUnit}`
                  "
                  @click="pushToFoodStuffsFromSubRecipe(index)"
                />
              </div>
            </r-modal-sub-header>
          </r-tab>
        </r-tabs>
        <!-- PC用選択した食材 -->
        <r-paper
          v-if="windowSizePC"
          round
          paperStyle="pink"
          class="recipe-food-stuff-form--wrapper-for-web--right"
        >
          <span class="recipe-food-stuff-form--wrapper-for-web--right--title">{{
            $t("recipe.recipeRegistration.recipeFoodstuffForm.selectedItem")
          }}</span>
          <div class="recipe-food-stuff--selected-item--wrapper-PC">
            <r-food-stuff-card
              class="recipe-food-stuff--selected-item"
              white
              close
              v-for="(selectedFoodstuff, index) in selectedFoodstuffs"
              :key="index"
              :label="selectedFoodstuff.name"
              :tag="selectedFoodstuff.category || selectedFoodstuff.ss"
              @close="deleteFromList(selectedFoodstuff.name)"
            />
          </div>
        </r-paper>
      </div>

      <!-- 選んだ食材 -->
      <r-selected-items
        v-if="!windowSizePC"
        :title="
          $t('recipe.recipeRegistration.recipeFoodstuffForm.selectedItem')
        "
        class="recipe-food-stuff--selected-items"
        :enter="selectedFoodstuffs.length !== 0"
        @enter="incrementCurrentStep"
        @cancel="closeModal"
      >
        <r-food-stuff-card
          class="recipe-food-stuff--selected-item"
          white
          close
          v-for="(selectedFoodstuff, index) in selectedFoodstuffs"
          :key="index"
          :label="selectedFoodstuff.name"
          :tag="selectedFoodstuff.category || selectedFoodstuff.ss"
          @close="deleteFromList(selectedFoodstuff.name)"
        />
      </r-selected-items>
    </r-modal>
  </div>
</template>

<script>
import RTabs from "@/plugins/atoms/RTabs.vue";
import RTab from "@/plugins/atoms/RTab.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RFoodStuffCard from "@/plugins/molecules/RFoodStuffCard.vue";
import RPaper from "@/plugins/atoms/RPaper";
import RModal from "@/plugins/molecules/RModal.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RFoodStuff from "@/plugins/molecules/RFoodStuffCard.vue";
import RSearchForm from "@/plugins/molecules/RSearchForm.vue";
import RSelectedItems from "@/plugins/molecules/RSelectedItems.vue";
import FoodStuffSearchList from "./FoodStuffSearchList.vue";
import auth from "@/libs/Auth.js";
import recipeManager from "@/libs/Recipe.js";
import supplierManager from "@/libs/Supplier.js";
export default {
  name: "recipe-foodstuff-form",
  props: {
    existingFoodstuffs: {
      type: Array,
      default: () => [],
    },
    recipeID: {
      type: String,
      default: "",
    },
    newRegistrationLimited: Boolean,
    editMode: Boolean,
    higherSubRecipeCount: Number,
  },
  components: {
    FoodStuffSearchList,
    // molecules
    RModal,
    RModalSubHeader,
    RFoodStuff,
    RSearchForm,
    RFoodStuffCard,
    RSelectedItems,
    // atoms
    RTabs,
    RTab,
    RButton,
    RTextbox,
    RPaper,
  },
  data() {
    return {
      // 遷移管理用の変数
      current: "",
      tradeCurrent: 0,
      categoryCurrent: 0,
      searchFlag: false,
      // 検索用一時変数
      selectedCompany: "",
      selectedLs: "",
      selectedMs: "",
      selectedSs: "",
      // 表示用企業リスト
      company: [],
      // 取引先データ
      storeSuppliers: [],
      // 表示用食材リスト
      lsItems: [],
      msItems: [],
      ssItems: [],
      // 表示用食材リスト
      items: [],
      // 表示用サブレシビリスト
      subRecipes: [],
      // キーワード検索Key
      searchKey: "",
      // キーワード検索結果
      searchedItems: [],
      // キーワード検索結果(小分類)
      searchedSsItems: [],
      // 登録用一時変数
      foodstuffDirectory: "",
      selectedFoodstuffs: [],
      serv: 0,
      // エラー表示用変数
      error: "",
      alert: "",
      width: window.innerWidth,
      step: false,
    };
  },
  computed: {
    ls() {
      const filteredLsItems = this.lsItems.filter((lsItem) => {
        //" "空文字・""・undefined・nullの場合をfiltering
        return lsItem ? true && lsItem !== " " : false;
      });
      return filteredLsItems;
    },
    ms() {
      const filteredMsItems = this.msItems.filter((msItem) => {
        //" "空文字・""・undefined・nullの場合をfiltering
        return msItem ? true && msItem !== " " : false;
      });
      return filteredMsItems;
    },
    ss() {
      const filteredSsItems = this.ssItems.filter((ssItem) => {
        //" "空文字・""・undefined・nullの場合をfiltering
        return ssItem ? true && ssItem !== " " : false;
      });
      return filteredSsItems;
    },
    foodstuffList() {
      const filteredFoodstuffList = this.items.filter((item) => {
        //" "空文字・""・undefined・nullの場合をfiltering
        return item ? true && item !== " " : false;
      });
      return filteredFoodstuffList;
    },
    isWindowSizeIpad() {
      return this.width >= 641;
    },
    windowSizePC() {
      return this.width >= 1024;
    },
    subRecipeList() {
      return this.subRecipes.filter(
        (e) => !this.selectedFoodstuffs.map((e) => e.name).includes(e.name),
      );
    },
    cantAddSubRecipe() {
      if (this.$root.privateState.level5 && this.higherSubRecipeCount >= 5) {
        return true;
      } else if (
        !this.$root.privateState.level5 &&
        this.higherSubRecipeCount >= 3
      ) {
        return true;
      }
      return false;
    },
    floorCost() {
      return function (cost) {
        return Math.floor(cost * 100) / 100;
      };
    },
  },
  methods: {
    back() {
      if (this.searchFlag) {
        this.searchFlag = false;
      }
      if (this.tradeCurrent > 0) {
        this.tradeCurrent--;
      } else if (this.categoryCurrent > 0) {
        this.categoryCurrent--;
      } else {
        this.selectedCompany = "";
        this.current = "";
      }
    },
    // 取引先一覧を取得
    async getSuppliers() {
      this.current = "trade";
      try {
        // データベースに取引先情報が存在しない場合
        if (this.storeSuppliers.length === 0) {
          // マスターから取引会社を取得
          if (this.$root.privateState.itemList.length < 1) {
            await this.$root.setItemListAction(this.customerID, this.storeID);
          }
          // マスターの取引会社リストを取得
          const itemList = this.$root.privateState.itemList;
          const suppliersArray = itemList.map((line) => {
            return line["［取引先名］"];
          });
          // 重複削除
          this.storeSuppliers = [...new Set(suppliersArray)];
        }
        if (this.storeSuppliers.length > 0) {
          this.company = this.storeSuppliers.map((e) => {
            return { name: e };
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
    },
    // 食材大分類を取得
    async getLsLists() {
      this.current = "category";
      try {
        const itemLists = this.$root.privateState.itemList;
        const getItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          let addArray = false;
          if (this.storeSuppliers.length > 0) {
            // 取引会社が登録されている場合
            if (this.storeSuppliers.indexOf(supplierName) >= 0) {
              addArray = true;
            }
          } else {
            addArray = true;
          }
          if (addArray) {
            accumulator.push(line["［食品大分類名］"]);
          }
          return accumulator;
        }, []);
        // 重複削除
        this.lsItems = [...new Set(getItems)];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
    },
    // 食材選択内での遷移
    clickTradeCompany(index, companyName) {
      // 取引会社に対応した食材選択
      try {
        const itemLists = this.$root.privateState.itemList;
        const getItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          if (companyName === supplierName) {
            accumulator.push(line["［食品大分類名］"]);
          }
          return accumulator;
        }, []);
        // 重複削除
        this.lsItems = [...new Set(getItems)];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.selectedCompany = companyName;
      this.tradeCurrent = index;
    },
    clickTradeLs(index, value) {
      // 取引先による食材検索中分類取得
      try {
        const itemLists = this.$root.privateState.itemList;
        const getItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          if (this.selectedCompany === supplierName) {
            if (line["［食品大分類名］"] === value) {
              accumulator.push(line["［食品中分類名］"]);
            }
          }
          return accumulator;
        }, []);
        // 重複削除
        this.msItems = [...new Set(getItems)];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.selectedLs = value;
      this.tradeCurrent = index;
    },
    clickTradeMs(index, value) {
      // 取引先による食材検索小分類取得
      try {
        const itemLists = this.$root.privateState.itemList;
        const getItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          if (this.selectedCompany === supplierName) {
            if (
              line["［食品大分類名］"] === this.selectedLs &&
              line["［食品中分類名］"] === value
            ) {
              accumulator.push(line["［食品小分類名］"]);
            }
          }
          return accumulator;
        }, []);
        // 重複削除
        this.ssItems = [...new Set(getItems)];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.selectedMs = value;
      this.tradeCurrent = index;
    },
    clickTradeSs(index, value) {
      // 取引先による食材情報取得
      try {
        const itemLists = this.$root.privateState.itemList;
        const getItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          if (this.selectedCompany === supplierName) {
            if (
              line["［食品大分類名］"] === this.selectedLs &&
              line["［食品中分類名］"] === this.selectedMs &&
              line["［食品小分類名］"] === value
            ) {
              accumulator.push({
                id: line["［マイカタログID］"],
                supplier: line["［取引先名］"],
                ls: line["［食品大分類名］"],
                ms: line["［食品中分類名］"],
                ss: line["［食品小分類名］"],
                name: line["［商品名］"],
                standard: line["［規格］"],
                quantity: line["［入数］"],
                quantityUnit: line["［入数単位］"],
                price: line["［単価］"],
                kana: line["［商品名カナ］"],
              });
            }
          }
          return accumulator;
        }, []);
        // 重複削除
        this.items = [...new Set(getItems)];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.selectedSs = value;
      this.tradeCurrent = index;
    },
    clickCategoryLs(index, value) {
      // 中分類取得
      try {
        const itemLists = this.$root.privateState.itemList;
        const getItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          let addArray = false;
          if (this.storeSuppliers.length > 0) {
            // 取引会社が登録されている場合
            if (this.storeSuppliers.indexOf(supplierName) >= 0) {
              addArray = true;
            }
          } else {
            addArray = true;
          }
          if (addArray) {
            if (line["［食品大分類名］"] === value) {
              accumulator.push(line["［食品中分類名］"]);
            }
          }
          return accumulator;
        }, []);
        // 重複削除
        this.msItems = [...new Set(getItems)];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.selectedLs = value;
      this.categoryCurrent = index;
    },
    clickCategoryMs(index, value) {
      // 小分類取得
      try {
        const itemLists = this.$root.privateState.itemList;
        const getItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          let addArray = false;
          if (this.storeSuppliers.length > 0) {
            // 取引会社が登録されている場合
            if (this.storeSuppliers.indexOf(supplierName) >= 0) {
              addArray = true;
            }
          } else {
            addArray = true;
          }
          if (addArray) {
            if (
              line["［食品大分類名］"] === this.selectedLs &&
              line["［食品中分類名］"] === value
            ) {
              accumulator.push(line["［食品小分類名］"]);
            }
          }
          return accumulator;
        }, []);
        // 重複削除
        this.ssItems = [...new Set(getItems)];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.selectedMs = value;
      this.categoryCurrent = index;
    },
    clickCategorySs(index, value) {
      // 食材情報取得
      try {
        const itemLists = this.$root.privateState.itemList;
        const getItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          let addArray = false;
          if (this.storeSuppliers.length > 0) {
            // 取引会社が登録されている場合
            if (this.storeSuppliers.indexOf(supplierName) >= 0) {
              addArray = true;
            }
          } else {
            addArray = true;
          }
          if (addArray) {
            if (
              line["［食品大分類名］"] === this.selectedLs &&
              line["［食品中分類名］"] === this.selectedMs &&
              line["［食品小分類名］"] === value
            ) {
              accumulator.push({
                id: line["［マイカタログID］"],
                supplier: line["［取引先名］"],
                ls: line["［食品大分類名］"],
                ms: line["［食品中分類名］"],
                ss: line["［食品小分類名］"],
                name: line["［商品名］"],
                standard: line["［規格］"],
                quantity: line["［入数］"],
                quantityUnit: line["［入数単位］"],
                price: line["［単価］"],
                kana: line["［商品名カナ］"],
              });
            }
          }
          return accumulator;
        }, []);
        // 重複削除
        this.items = [...new Set(getItems)];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.selectedSs = value;
      this.categoryCurrent = index;
    },
    searchKeyWord(keyword) {
      try {
        const itemLists = this.$root.privateState.itemList;
        // 商品名でのキーワード検索
        const getItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          let addArray = false;
          if (this.storeSuppliers.length > 0) {
            // 取引会社が登録されている場合
            if (this.storeSuppliers.indexOf(supplierName) >= 0) {
              addArray = true;
            }
          } else {
            addArray = true;
          }
          if (addArray) {
            const itemString = line["［商品名］"];
            if (itemString.indexOf(keyword) >= 0) {
              accumulator.push({
                id: line["［マイカタログID］"],
                supplier: line["［取引先名］"],
                ls: line["［食品大分類名］"],
                ms: line["［食品中分類名］"],
                ss: line["［食品小分類名］"],
                name: line["［商品名］"],
                standard: line["［規格］"],
                quantity: line["［入数］"],
                quantityUnit: line["［入数単位］"],
                price: line["［単価］"],
                kana: line["［商品名カナ］"],
              });
            }
          }
          return accumulator;
        }, []);
        // 重複削除
        this.searchedItems = [...new Set(getItems)];
        // 小分類でのキーワード検索
        const getSsItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          let addArray = false;
          if (this.storeSuppliers.length > 0) {
            // 取引会社が登録されている場合
            if (this.storeSuppliers.indexOf(supplierName) >= 0) {
              addArray = true;
            }
          } else {
            addArray = true;
          }
          if (addArray) {
            const ssString = line["［食品小分類名］"];
            if (ssString.indexOf(keyword) >= 0) {
              accumulator.push({
                id: line["［マイカタログID］"],
                supplier: line["［取引先名］"],
                ls: line["［食品大分類名］"],
                ms: line["［食品中分類名］"],
                ss: line["［食品小分類名］"],
                name: line["［商品名］"],
                standard: line["［規格］"],
                quantity: line["［入数］"],
                quantityUnit: line["［入数単位］"],
                price: line["［単価］"],
                kana: line["［商品名カナ］"],
              });
            }
          }
          return accumulator;
        }, []);
        // 重複削除
        this.searchedSsItems = [...new Set(getSsItems)];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.searchFlag = true;
      this.current = "search";
    },
    searchKeyWordBySupplier(keyword) {
      try {
        const itemLists = this.$root.privateState.itemList;
        // 商品名でのキーワード検索
        const getItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          if (this.selectedCompany === supplierName) {
            const itemString = line["［商品名］"];
            if (itemString.indexOf(keyword) >= 0) {
              accumulator.push({
                id: line["［マイカタログID］"],
                supplier: line["［取引先名］"],
                ls: line["［食品大分類名］"],
                ms: line["［食品中分類名］"],
                ss: line["［食品小分類名］"],
                name: line["［商品名］"],
                standard: line["［規格］"],
                quantity: line["［入数］"],
                quantityUnit: line["［入数単位］"],
                price: line["［単価］"],
                kana: line["［商品名カナ］"],
              });
            }
          }
          return accumulator;
        }, []);
        // 重複削除
        this.searchedItems = [...new Set(getItems)];
        // 小分類でのキーワード検索
        const getSsItems = itemLists.reduce((accumulator, line) => {
          const supplierName = line["［取引先名］"];
          if (this.selectedCompany === supplierName) {
            const ssString = line["［食品小分類名］"];
            if (ssString.indexOf(keyword) >= 0) {
              accumulator.push({
                id: line["［マイカタログID］"],
                supplier: line["［取引先名］"],
                ls: line["［食品大分類名］"],
                ms: line["［食品中分類名］"],
                ss: line["［食品小分類名］"],
                name: line["［商品名］"],
                standard: line["［規格］"],
                quantity: line["［入数］"],
                quantityUnit: line["［入数単位］"],
                price: line["［単価］"],
                kana: line["［商品名カナ］"],
              });
            }
          }
          return accumulator;
        }, []);
        // 重複削除
        this.searchedSsItems = [...new Set(getSsItems)];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.searchFlag = true;
      this.current = "trade";
      this.tradeCurrent = 2;
    },
    // キーワード検索からの食材選択
    pushToSelectedFoodstuffSearch(name) {
      let obj = this.searchedItems.filter((e) => e.name === name)[0];
      if (obj === undefined) {
        obj = this.searchedSsItems.filter((e) => e.name === name)[0];
      }
      this.selectedFoodstuffs.push({
        ref: obj.ref || null, // サブレシピのリファレンス
        name: obj.name,
        kana: obj.kana,
        ingredientID: obj.id,
        amount: 0,
        unit: "",
        ls: obj.ls,
        ms: obj.ms,
        ss: obj.ss,
        standard: obj.standard,
        quantity: obj.quantity,
        quantityUnit: obj.quantityUnit,
        price: obj.price,
        unitAmount: 0,
        cost: 0,
        displayNum: this.existingFoodstuffs
          ? this.existingFoodstuffs.length
          : 0,
      });
      this.searchFlag = false;
      this.tradeCurrent = 0;
      this.selectedCompany = "";
      this.current = "";
      // 編集のときのみ
      if (!this.newRegistrationLimited) {
        this.incrementCurrentStep();
      }
    },
    // 分類検索からの食材選択
    pushToSelectedFoodstuff(name) {
      const obj = this.items.filter((e) => e.name === name)[0];
      this.selectedFoodstuffs.push({
        ref: obj.ref || null, // サブレシピのリファレンス
        name: obj.name,
        kana: obj.kana,
        ingredientID: obj.id,
        amount: 0,
        unit: "",
        ls: obj.ls,
        ms: obj.ms,
        ss: obj.ss,
        standard: obj.standard,
        quantity: obj.quantity,
        quantityUnit: obj.quantityUnit,
        price: obj.price,
        unitAmount: 0,
        cost: 0,
        displayNum: this.existingFoodstuffs
          ? this.existingFoodstuffs.length
          : null,
      });
      //this.searchFlag = false
      this.tradeCurrent = 0;
      this.categoryCurrent = 0;
      this.selectedCompany = "";
      this.current = "";
      // 編集のときのみ
      if (!this.newRegistrationLimited) {
        this.incrementCurrentStep();
      }
    },
    // 直接入力
    pushToFoodstuffs() {
      this.error = "";
      if (this.foodstuffDirectory === "") {
        this.error = this.$i18n.t(
          "recipe.recipeRegistration.recipeFoodstuffForm.foodstuffDirect",
        );
        return;
      } else if (
        this.selectedFoodstuffs
          .map((e) => e.name)
          .includes(this.foodstuffDirectory)
      ) {
        this.error = this.$i18n.t(
          "recipe.recipeRegistration.recipeFoodstuffForm.sameFoodstuff",
        );
        return;
      }
      this.selectedFoodstuffs.push({
        ref: null,
        name: this.foodstuffDirectory,
        kana: "",
        ingredientID: 0,
        amount: 0,
        unit: "",
        ls: "",
        ms: "",
        ss: "",
        standard: "",
        quantity: 0, // 入数(master.csvから取得)
        quantityUnit: "", // 入数単位(master.csvから取得)
        price: 0, // 製品単価
        unitAmount: 0, // 製品の総量（原価計算時に登録）
        cost: 0, // 一人あたりの原価(原価計算時に登録),
        displayNum: this.existingFoodstuffs
          ? this.existingFoodstuffs.length
          : null,
      });
      this.foodstuffDirectory = "";
      this.tradeCurrent = 0;
      this.categoryCurrent = 0;
      this.current = "";
      // 編集のときのみ
      if (!this.newRegistrationLimited) {
        this.incrementCurrentStep();
      }
    },
    // サブレシピの選択
    async pushToFoodStuffsFromSubRecipe(index) {
      try {
        const obj = this.subRecipeList[index];
        const exist = await recipeManager.chekcSelfReferencingRecipe(
          this.recipeID,
          obj.ref,
        );
        if (exist) {
          alert(
            this.$i18n.t(
              "recipe.recipeRegistration.recipeFoodstuffForm.selfReferenceError",
            ),
          );
          return;
        }
        // サブレシピの選択
        this.selectedFoodstuffs.push({
          ref: obj.ref, // サブレシピのリファレンス
          name: obj.name,
          kana: obj.kana,
          ingredientID: 0,
          amount: 0,
          unit: obj.subUnit,
          ls: "",
          ms: "",
          ss: "",
          standard: "",
          quantity: obj.subAmount, // サブレシピの総量(サブレシピ登録時に設定された数値)
          quantityUnit: obj.subUnit, // サブレシピの単位(サブレシピの登録時に設定された単位)
          price: obj.totalCost, // サブレシピ人前全体の単価
          unitAmount: 0,
          cost: 0, // コストは原価計算時にセットする
          displayNum: this.existingFoodstuffs
            ? this.existingFoodstuffs.length
            : null,
        });
        // 編集のときのみ
        if (!this.newRegistrationLimited) {
          this.incrementCurrentStep();
        }
      } catch (err) {
        console.log("==pushToFoodStuffsFromSubRecipe error==", err);
      }
    },
    deleteFromList(name) {
      this.selectedFoodstuffs = this.selectedFoodstuffs.filter(
        (e) => e.name !== name,
      );
    },
    // 食材選択完了
    incrementCurrentStep() {
      if (this.selectedFoodstuffs.length === 0) {
        this.alert = this.$i18n.t(
          "recipe.recipeRegistration.recipeFoodstuffForm.foodstuffNotSelected",
        );
        return;
      }
      this.$emit("enter", this.selectedFoodstuffs);
    },
    closeModal() {
      const result = window.confirm(
        this.$i18n.t(
          "recipe.recipeRegistration.recipeFoodstuffForm.cancelChoosingFoodstuffAlert",
        ),
      );
      if (!result) {
        return;
      }
      this.$emit("closeModal");
    },
    handleResize() {
      this.width = window.innerWidth;
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }
      // DBに登録されている取引先情報を取得
      this.storeSuppliers = await supplierManager.getSuppliers(
        this.customerID,
        this.storeID,
      );
      // 食材マスターを取得
      await this.$root.setItemListAction(this.customerID, this.storeID);
      // サブレシピ一覧を取得
      this.subRecipes = await recipeManager.getSubRecipe(
        this.customerID,
        this.storeID,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.existingFoodstuffs.forEach((item) =>
      this.selectedFoodstuffs.push(item),
    );
    if (this.$route.path === "/recipe-registration") {
      this.step = true;
    }
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.recipe-foodstuff-form {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.recipe-food-stuff-form--modal {
  width: 90vw;
  padding-bottom: 250px;
}
/** サブヘッダー内のコンテンンツ */
.recipe-foodstuff-form--company {
  height: 50vh;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}
/************************** */
/** 取引先食材トップ */
.recipe-foodstuff-form--trade--food--top {
  display: block;
}
.recipe-foodstuff-form--trade--food--top-button {
  margin-bottom: 16px;
  width: 100%;
}
.recipe-foodstuff-form--trade--content {
  margin: 5px 5px 5px 5px;
  border-color: #aeaeae;
  font-weight: inherit;
}
.recipe-food-stuff--selected-item {
  margin-top: 10px;
}
.recipe-foodstuff-form--unit-form {
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}
.recipe-foodstuff-form--buttons {
  margin-top: 10px;
  max-height: 60px;
}
.recipe-foodstuff-form-foodstuff {
  margin-bottom: 16px;
}
.page-feed--buttons {
  margin-top: 20px;
  width: 100%;
}
.page-feed--buttons--back {
  width: inherit;
}
/**直接入力 */
.recipe-foodstuff-form--direct {
  display: flex;
  align-items: center;
}
/** サブレシピ */
.recipe-foodstuff-form--sub {
  box-sizing: border-box;
  margin-bottom: 16px;
}
.recipe-foodstuff-form--sub-recipe--wrapper {
  padding-bottom: 50px;
  max-height: 35vh;
  overflow-y: auto;
}
.recipe-foodstuff-form--sub-recipe {
  margin-bottom: 10px;
}
/** サブレシピのカード */
.recipe-foodstuff-form-select-sub-recipe-content {
  margin-bottom: 10px;
  height: 100px;
}
.recipe-foodstuff-form-select-sub-recipe-content:hover {
  cursor: pointer;
}
.recipe-foodstuff-form--foodstuff--wrapper {
  box-sizing: border-box;
  margin-bottom: 16px;
  width: 100%;
}
.recipe-foodstuff-form--foodstuff-content {
  margin-bottom: 10px;
}
.recipe-foodstuff-form--foodstuff-content:hover {
  cursor: pointer;
}
.recipe-foodstuff-form-select-sub-recipe-content--item {
  margin-top: 10px;
}
/***************************************************** */
@media screen and (min-width: 641px) {
  .recipe-food-stuff-form--modal {
    height: 90%;
    box-sizing: border-box;
  }
  .recipe-foodstuff-form--tab {
    margin-top: 40px;
  }
  .select-by-subrecipe >>> .r-tab--li {
    height: unset;
  }
  .recipe-foodstuff-form--trading >>> .r-modal-sub-content {
    padding: 0 20px;
  }
  .recipe-foodstuff-form--sub-header {
    width: 100%;
  }
  /*取引中の食材トップ*/
  .recipe-foodstuff-form--trade--food--top {
    display: block;
  }
  .recipe-foodstuff-form--trade--food--top-button {
    margin-bottom: 16px;
    width: 100%;
  }
  .recipe-foodstuff-form--company {
    max-height: 30vh;
    padding-bottom: 50px; /*選択した食材に隠させる領域*/
  }
  .recipe-foodstuff-form--sub-content {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    padding-bottom: 50px; /*選択した食材に隠させる領域*/
    overflow-y: auto;
    height: 20vh;
  }
  .recipe-foodstuff-form--search {
    width: 100%;
  }
  .recipe-foodstuff-form--buttons {
    width: calc(33% - 10px);
    margin-right: 10px;
  }
  /** モーダルのページ送り制御ボタン用 */
  .page-feed--buttons {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .page-feed--buttons--back {
    width: unset;
  }
  .recipe-foodstuff-form-foodstuff {
    margin-bottom: 16px;
  }
  /*食材を選ぶ領域*/
  .recipe-foodstuff-form--foodstuff--wrapper {
    max-height: 30vh;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: auto;
  }
  .recipe-foodstuff-form--foodstuff-content {
    width: 48%;
    padding: 5px;
    margin-bottom: 10px;
  }
  /** サブレシピ */
  .recipe-foodstuff-form-select-sub-recipe-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 50px; /*選択した食材に隠させる領域*/
    box-sizing: border-box;
    height: 30vh;
    overflow-y: auto;
  }
  .recipe-foodstuff-form-select-sub-recipe-content--item {
    width: 48%;
    margin-right: 10px;
    margin-top: 10px;
  }
  .recipe-foodstuff-form-select-sub-recipe-content--item:hover {
    cursor: pointer;
  }
  .recipe-foodstuff-form-select-sub-recipe-content--item:nth-child(2n) {
    margin-right: 0;
  }
  .recipe-food-stuff--selected-item {
    width: 46%;
  }
  .recipe-food-stuff--selected-item:nth-child(odd) {
    margin-right: 10px;
    margin-left: 10px;
  }
}
/***************************************************** */
@media (min-width: 1024px) {
  .recipe-food-stuff-form--modal {
    width: 1000px;
    height: 100%;
    min-height: 700px;
  }
  .recipe-food-stuff-form--wrapper-for-web {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
  }
  .recipe-food-stuff-form--wrapper-for-web--left {
    height: 100%;
    width: 70%;
    margin-right: 20px;
    box-sizing: border-box;
  }
  .recipe-food-stuff-form--wrapper-for-web--left >>> .r-tab--li {
    height: unset;
  }
  .recipe-food-stuff-form--wrapper-for-web--right {
    width: 30%;
    height: 60vh;
    position: relative;
    box-sizing: border-box;
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
  .recipe-food-stuff-form--wrapper-for-web--right--title {
    background-color: #e87352;
    color: #ffffff;
    font-weight: bold;
    display: block;
    width: 100%;
    border-radius: 5px 5px 0 0;
    box-sizing: border-box;
    padding: 5px 0;
    margin-bottom: 10px;
  }
  .recipe-food-stuff--selected-item--wrapper-PC {
    max-height: 90%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 10px;
  }
  .recipe-food-stuff--selected-items {
    height: 100%;
  }
  .recipe-food-stuff--selected-item {
    width: 100%;
  }
  .recipe-food-stuff--selected-item:nth-child(odd) {
    margin-right: 0px;
    margin-left: 0px;
  }
}
</style>

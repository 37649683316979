<template>
  <r-modal
    class="recipe-cost--paper--main--info"
    :title="$t('recipe.recipeCost.calcAdditionalFoodstuffCost')"
    :enter="inputCheck"
    @enter="enter"
    @cancel="cancel"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      class="recipe-cost--paper--main"
    >
      <template v-if="item.ref === null && item.ingredientID === 0">
        <r-modal-sub-header
          :title="item.name"
          class="recipe-cost--paper--main--left"
        >
          <div class="recipe-cost--paper--main--info--input-price-wrapper">
            <span
              >[{{ $t("recipe.recipeCost.price") }}]{{
                $t("common.currency")
              }}</span
            >
            <r-textbox
              class="recipe-cost--paper--main--info--input-price"
              type="number"
              v-model.number="item.price"
              :placeholder="$t('recipe.recipeCommon.inputFoodstuffPrice')"
            ></r-textbox>
          </div>
          <r-paper
            paperStyle="gray"
            round
            class="recipe-cost--paper--main--content"
          >
            <div class="input-box-wrapper">
              <span
                >{{ $t("recipe.recipeEdit.totalAmountOfFoodstuff")
                }}{{ item.unit
                }}{{ $t("recipe.recipeEdit.foodstuffByUnit") }}</span
              >
              <r-textbox
                type="number"
                class="input-box"
                v-model.number="item.unitAmount"
                :unit="item.unit"
              ></r-textbox>
            </div>
          </r-paper>
        </r-modal-sub-header>
      </template>

      <!--矢印アイコン-->
      <font-awesome-icon
        :icon="['fas', 'chevron-down']"
        class="recipe-cost--paper--main--content fa-chevron-down"
      ></font-awesome-icon>

      <!-- 原価表示ここから -->
      <div class="recipe-cost--paper--main--content cost--wrapper">
        <r-heading :level="4"
          >{{ item.amount }} {{ item.unit }}
          {{ $t("recipe.recipeCost.costOf") }}</r-heading
        >
        <div class="cost--inline-wrapper">
          <span class="cost--computed">{{ calcCost(index) }}</span>
          {{ $t("common.currencyYen") }}
        </div>
      </div>
    </div>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";

import recipeManager from "@/libs/Recipe.js";
export default {
  components: {
    RModal,
    RModalSubHeader,
    RTextbox,
    RPaper,
    RHeading,
  },
  props: {
    exItems: Array,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    calcCost() {
      return (index) => {
        // コスト計算
        const costValue = recipeManager.calcItemCost(this.items[index]);
        this.$set(this.items[index], "cost", costValue);
        if (costValue === Infinity || Number.isNaN(costValue)) {
          return 0;
        }
        return costValue;
      };
    },
    inputCheck() {
      let result = false;
      this.items.forEach((item) => {
        if (item.cost > 0 && item.unitAmount > 0) {
          result = true;
        }
      });
      return result;
    },
  },
  methods: {
    enter() {
      this.$emit("enter", this.items);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  created() {
    const clonedeep = require("lodash/cloneDeep");
    this.items = clonedeep(this.exItems);
  },
};
</script>

<style scoped>
.recipe-cost--paper--main--info--input-price-wrapper {
  display: flex;
  align-items: center;
}
.recipe-cost--paper--main--info--input-price {
  width: 40%;
}
.recipe-cost--paper--main {
  margin-top: 10px;
}
.recipe-cost--paper--main--content {
  margin-top: 20px;
}
/** 原価関連 */
.cost--wrapper {
  text-align: left;
}
.fa-chevron-down {
  font-size: 2.4rem;
  color: #e87352;
}
.input-box-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
@media screen and (min-width: 641px) {
  .recipe-cost--paper--main--left {
    width: 70%;
  }
  .recipe-cost--paper--main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .fa-chevron-down {
    transform: rotate(-90deg);
  }
  .cost--wrapper {
    width: 20%;
  }
  .input-box {
    width: 30%;
  }
  .input-box-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  @media screen and (min-width: 1024px) {
    .recipe-cost {
      width: 1024px;
      margin: auto;
      padding-top: 120px;
    }
    .recipe-cost--buttons {
      position: absolute;
      top: 90px;
    }
  }
}
</style>

<template>
  <div class="checkbox">
    <div class="checkbox--wrapper" v-for="(item, index) in items" :key="index">
      <button
        class="checkbox--button"
        :class="{
          'checkbox--button__true': item.bool,
          'checkbox--button__disabled': counter >= limit && item.bool === false,
        }"
        @click="click(index)"
        :disabled="counter >= limit && item.bool === false"
      >
        {{ item.name }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "r-checkbox",
  props: {
    limit: {
      type: Number,
    },
    items: {
      type: Array,
    },
    defaultCounter: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      counter: 0,
    };
  },
  methods: {
    click(index) {
      this.$emit("toggle", index);
      if (this.items[index].bool) {
        this.counter++;
      } else {
        this.counter--;
      }
    },
  },
  watch: {
    defaultCounter: {
      handler() {
        this.counter = this.defaultCounter;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.checkbox {
  --primary-color: #e87352;
  display: flex;
  flex-wrap: wrap;
}
.checkbox--button {
  border: 1px solid #e87352;
  border-radius: 16px;
  background-color: #ffffff;
  font-size: 14px;
  padding: 5px 14px;
  margin: 0.5em 0.5em;
}
.checkbox--button:focus {
  outline: 0;
}
.checkbox--button__true {
  background-color: #e87352;
  color: #ffffff;
}
.checkbox--button:hover {
  cursor: pointer;
  background-color: #e87352;
  color: #ffffff;
  transition: 0.5s;
}
.checkbox--button__disabled:hover {
  cursor: default;
  background-color: #ffffff;
  color: #cecece;
  transition: none;
}
</style>

<template>
  <r-modal
    class="recipe-target-rate-update-form"
    :title="$t('recipe.recipeEdit.editTargetRate')"
    enter
    @enter="enter"
    @cancel="cancel"
  >
    <div class="error-message">
      {{ error.targetRate }}
    </div>
    <div class="recipe-target-rate-update-form--input">
      <r-textbox
        type="tel"
        v-model.number="tmpTargetRate"
        :error="error.targetRate !== ''"
        class="text-box"
      />%
    </div>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";

export default {
  props: {
    targetRate: Number,
  },
  components: {
    RTextbox,
    RModal,
  },
  data() {
    return {
      tmpTargetRate: 0,
      error: {
        targetRate: "",
      },
    };
  },
  methods: {
    enter() {
      this.executeValidation();
      if (this.error.targetRate !== "") {
        return;
      }
      this.$emit("enter", this.tmpTargetRate);
    },
    cancel() {
      this.$emit("cancel");
    },
    isDecimalPointLengthLargeThan2(number) {
      const splitNumber = String(number).split(".");
      if (splitNumber[1]) {
        return splitNumber[1].length > 2;
      }
      return false;
    },
    executeValidation() {
      if (this.tmpTargetRate === 0 || this.tmpTargetRate === "") {
        this.$set(
          this.error,
          "targetRate",
          this.$i18n.t("recipe.recipeEdit.targetRateNotInputed"),
        );
      } else if (
        typeof this.tmpTargetRate !== "number" &&
        !this.tmpTargetRate.match(/^[0-9]+$/)
      ) {
        this.$set(
          this.error,
          "targetRate",
          this.$i18n.t("recipe.recipeCommon.validationSentence"),
        );
      } else if (this.isDecimalPointLengthLargeThan2(this.tmpTargetRate)) {
        this.$set(
          this.error,
          "targetRate",
          this.$i18n.t("recipe.recipeCommon.validationSentence"),
        );
      } else {
        this.$set(this.error, "targetRate", "");
      }
    },
  },
  watch: {
    targetRate: {
      handler() {
        this.tmpTargetRate = this.targetRate;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.recipe-target-rate-update-form--input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-message {
  color: #e85951;
  font-weight: bold;
}
.text-box {
  width: 100px;
}
</style>

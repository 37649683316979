<template>
  <r-modal
    enter
    @enter="enterFromAmount"
    @cancel="cancel"
    :title="$t('course.courseEdit.courseAmountInputTitle')"
  >
    <!-- 数量入力 -->
    <r-list class="course-menu-form--modal--quantity">
      <div class="error-message">{{ error.serv }}</div>
      <r-list-title
        :titles="[
          $t('course.courseRegistration.courseMenuForm.beenSelected'),
          $t('common.quantity'),
        ]"
      ></r-list-title>
      <div v-for="(selectedRecipe, index) in selectedRecipes" :key="index">
        <r-list-content v-if="editHidden(index)">
          <r-list-item
            class="course-menu-form--modal--quantity--list-item--left"
            :caption="`(${$t('common.cost')}: ${selectedRecipe.totalCost}${$t(
              'common.currencyYen',
            )}/1${$t('common.perPerson')})`"
            >{{ selectedRecipe.name }}</r-list-item
          >
          <r-list-item>
            <div class="course-menu-form--modal--quantity--list-item--right">
              <r-textbox
                class="course-menu-form--modal--quantity--input"
                type="number"
                v-model.number="selectedRecipes[index].serv"
              />
              <div>{{ $t("common.perPerson") }}</div>
            </div>
          </r-list-item>
        </r-list-content>
      </div>
    </r-list>

    <div v-if="edit" class="edit--cost">
      <div class="edit--cost--content">
        <span class="edit--cost--content--ex"
          >{{ $t("course.courseEdit.editCourseCostCurrent") }} : {{ exTotalCost
          }}{{ $t("common.currencyYen") }}</span
        >
        <font-awesome-icon
          class="edit--cost--content--chevron fa-chevron-down"
          :icon="['fas', 'chevron-down']"
        ></font-awesome-icon>
        <span class="edit--cost--content--current"
          >{{ totalCost }}{{ $t("common.currencyYen") }}</span
        >
      </div>

      <div class="edit--cost--content">
        <span class="edit--cost--content--ex"
          >{{ $t("course.courseEdit.editCourseCostCurrent") }} :
          {{ exCostRate }}%</span
        >
        <font-awesome-icon
          class="edit--cost--content--chevron fa-chevron-down"
          :icon="['fas', 'chevron-down']"
        ></font-awesome-icon>
        <span class="edit--cost--content--current">{{ costRate }}%</span>
      </div>
    </div>
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListTitle from "@/plugins/atoms/RListTitle.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";

export default {
  components: {
    RModal,
    RList,
    RListTitle,
    RListContent,
    RListItem,
    RTextbox,
  },
  data() {
    return {
      error: {
        serv: "",
      },
      selectedRecipes: [],
    };
  },
  props: {
    existingRecipes: Array,
    calcRecipes: Object,
    edit: Boolean,
    exTotalCost: Number,
    exCostRate: Number,
    indexs: Array,
    price: Number,
  },
  methods: {
    enterFromAmount() {
      const servs = this.selectedRecipes.map((e) => e.serv);
      if (servs.includes(0) || servs.includes("")) {
        this.error = this.$i18n.t(
          "course.courseRegistration.courseMenuForm.invalidValueInput",
        );
        return;
      }
      if (this.edit) {
        this.$emit("enter", this.selectedRecipes);
      } else {
        this.$emit("enter", this.selectedRecipes);
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  created() {
    const clonedeep = require("lodash/cloneDeep");
    this.selectedRecipes = clonedeep(this.existingRecipes);
  },
  computed: {
    totalCost() {
      if (this.edit) {
        return this.selectedRecipes.reduce((acc, factor) => {
          acc += factor.totalCost * factor.serv;

          return Number(acc.toFixed(2));
        }, 0);
      } else {
        return 0;
      }
    },
    costRate() {
      return Number(((this.totalCost / this.price) * 100).toFixed(2));
    },
    editHidden() {
      return (index) => {
        if (!this.edit) {
          return true;
        } else {
          return this.indexs.indexOf(index) !== -1;
        }
      };
    },
  },
};
</script>

<style scoped>
.error-message {
  color: #e85951;
  font-weight: bold;
}
.course-menu-form--modal--quantity {
  height: 50vh;
  overflow: auto;
}
.course-menu-form--modal--quantity--input {
  width: 100px;
}

@media screen and (min-width: 641px) {
  .course-menu-form--modal--quantity--list-item--right {
    display: flex;
    align-items: center;
  }
}

.edit--cost {
  margin-top: 20px;
  margin: auto;
}
.edit--cost--content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.edit--cost--content--ex {
  font-size: 18px;
}
.edit--cost--content--chevron {
  color: #e85951;
  font-size: 36px;
  transform: rotate(270deg);
}
.edit--cost--content--current {
  font-size: 48px;
  font-weight: bold;
  color: #517ce8;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"book-select-modal-wrapper"},[_c('div',{staticClass:"modal-overlay",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.$emit('cancel')}}},[_c('r-modal',{staticClass:"modal-content",attrs:{"title":_vm.isInputForm
          ? '' +
            `${_vm.currentName}` +
            _vm.$t('menu.menuTop.bookSelectForm.titleForInputName')
          : _vm.$t('menu.menuTop.bookSelectForm.titleForSelectBook'),"RHr":"primary","enter":_vm.isSelected ? true : false},on:{"enter":_vm.toInputBookName,"cancel":_vm.closeModal}},[(!_vm.isInputForm)?_c('div',{staticClass:"booklist-wrapper"},[_c('span',{staticClass:"explain"},[_vm._v(_vm._s(_vm.$t("menu.menuTop.bookSelectForm.explain")))]),_c('div',{staticClass:"book-wrapper"},_vm._l((_vm.menuBook),function(item,index){return _c('div',{key:index,staticClass:"book-selector",class:[
              item.name !== '' ? 'book-selector-selected' : '',
              _vm.current === index ? 'book-selector-selecting' : '',
            ],on:{"click":function($event){return _vm.selectBook(index)}}},[(item.name !== '')?_c('span',[_vm._v(_vm._s(_vm.$t("menu.menuTop.bookSelectForm.alreadyInUse"))+"["+_vm._s(item.name)+"]")]):_c('span',[_vm._v(_vm._s(_vm.$t("menu.menuTop.menuBook"))+_vm._s(index + 1))])])}),0)]):_c('div',{staticClass:"input-name-wrapper"},[_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.error.bookName))]),_c('r-textbox',{attrs:{"placeholder":_vm.$t('menu.menuTop.bookSelectForm.bookNamePlaceholder'),"error":_vm.error.bookName !== ''},model:{value:(_vm.bookName),callback:function ($$v) {_vm.bookName=$$v},expression:"bookName"}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <r-paper class="config-supplier" round>
    <r-modal-sub-header :title="$t('config.supplierForm.supplierFormTitle')">
      <r-spinner v-if="loading"></r-spinner>
      <div v-else>
        <div class="config-supplier--content">
          <r-heading :level="4">{{
            $t("config.supplierForm.inputSupplierHeader")
          }}</r-heading>
          <r-checkbox
            class="config-supplier--content--item config-supplier--content--check"
            :items="list"
            @toggle="toggleList"
          />
          <r-button
            class="config-supplier--content--item supplier-btn"
            buttonStyle="secondary"
            full
            @click="inputSupplier('set')"
            >{{ $t("config.supplierForm.inputSupplier") }}</r-button
          >
        </div>
        <div class="config-supplier--content">
          <r-heading :level="4">{{
            $t("config.supplierForm.deleteSupplierHeader")
          }}</r-heading>
          <r-checkbox
            class="config-supplier--content--item config-supplier--content--check"
            :items="select"
            @toggle="toggleSelect"
            v-if="select.length != 0"
          />
          <!--選択されている取引先がない場合-->
          <div v-if="select.length == 0" class="config-supplier--no-entry">
            {{ $t("config.supplierForm.noSupplierSelected") }}
          </div>
          <r-button
            class="config-supplier--content--item supplier-btn"
            buttonStyle="secondary"
            full
            @click="inputSupplier('del')"
            :disalbe="select.length == 0"
            >{{ $t("config.supplierForm.deleteSupplier") }}</r-button
          >
        </div>
      </div>
    </r-modal-sub-header>
  </r-paper>
</template>
<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RCheckbox from "@/plugins/atoms/RCheckbox.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import supplierManager from "@/libs/Supplier.js";

export default {
  components: {
    RButton,
    RCheckbox,
    RPaper,
    RHeading,
    RModalSubHeader,
    RSpinner,
  },
  data() {
    return {
      list: [],
      select: [],
      inputName: "",
      loading: false,
    };
  },
  methods: {
    toggleList(index) {
      this.$set(this.list[index], "bool", !this.list[index].bool);
    },
    toggleSelect(index) {
      this.$set(this.select[index], "bool", !this.select[index].bool);
    },
    async inputSupplier(mode) {
      let _this = this;

      if (mode === "set") {
        // 選択されていないときは処理をしない
        if (!this.list.map((e) => e.bool).includes(true)) {
          alert(
            this.$i18n.t("config.supplierForm.selectSupplierValidationMsg"),
          );
          return;
        }
        // listに登録してあるものを削除
        const tempListArray = this.list.filter(function (item) {
          // selectに追加
          if (item.bool) {
            _this.select.push({ name: item.name, bool: false });
          }
          return item.bool == false;
        });
        this.list = tempListArray;
      } else if (mode === "del") {
        // 選択されていないときは処理をしない
        if (!this.select.map((e) => e.bool).includes(true)) {
          alert(this.$i18n.t("config.supplierForm.selectSupplierToDelete"));
          return;
        }

        if (window.confirm(this.$i18n.t("config.supplierForm.deleteConfirm"))) {
          // select に登録しているものを削除
          const tempSelectArray = this.select.filter(function (item) {
            // listに追加
            if (item.bool) {
              _this.list.push({ name: item.name, bool: false });
            }
            return item.bool == false;
          });
          this.select = tempSelectArray;
        }
      }

      // firestoreに登録
      const setSuppliers = this.select.map((supplier) => {
        return { name: supplier.name };
      });

      try {
        await supplierManager.setSuppliers(
          this.customerID,
          this.storeID,
          setSuppliers,
        );
        this.inputName = "";
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("ERROR", error);
        throw new Error(error);
      }
    },
  },
  async created() {
    this.loading = true;
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        throw new Error("Forbidden");
      }

      // マスターから取引会社を取得
      if (this.$root.privateState.itemList.length < 1) {
        await this.$root.setItemListAction(this.customerID, this.storeID);
      }
      const itemList = this.$root.privateState.itemList;
      // 取引会社リストを取得
      const suppliersArray = itemList.map((line) => {
        return line["［取引先名］"];
      });
      let arrayMaster = [...new Set(suppliersArray)];

      let arraySuppliers = [];

      // DBに登録されている取引先情報を取得
      arraySuppliers = await supplierManager.getSuppliers(
        this.customerID,
        this.storeID,
      );
      // suplireに登録しているものはリストから削除
      arrayMaster = arrayMaster.filter((val) => !arraySuppliers.includes(val));

      // リスト作成
      if (arrayMaster) {
        this.list = arrayMaster.map((supplier) => {
          return { name: supplier, bool: false };
        });
      }
      if (arraySuppliers) {
        this.select = arraySuppliers.map((supplier) => {
          return { name: supplier, bool: false };
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.config-supplier--content {
  margin-top: 20px;
}
.config-supplier--content--item {
  margin-top: 10px;
}
.config-supplier--content--check {
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}
.config-supplier--content--check >>> .checkbox--button {
  min-height: 32px;
}
.config-supplier--no-entry {
  border: 2px solid #ccc;
  padding: 10px;
  text-align: left;
  display: flex;
}
/*iPad用css*/
@media screen and (min-width: 641px) {
  .supplier-btn {
    width: 50%;
  }
}
/*PC用css*/
@media screen and (min-width: 1024px) {
  .supplier-btn {
    width: 240px;
  }
}
</style>

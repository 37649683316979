<template>
  <div
    class="r-spinner--wrapper"
    :class="{ 'r-spinner--wrapper--harf': harfSize }"
  >
    <circles-to-rhombuses-spinner
      :animation-duration="800"
      :circles-num="3"
      :circle-size="15"
      color="#e87352"
    />
  </div>
</template>

<script>
import "epic-spinners/dist/lib/epic-spinners.min.css";
import { CirclesToRhombusesSpinner } from "epic-spinners/dist/lib/epic-spinners.min.js";

export default {
  components: {
    CirclesToRhombusesSpinner,
  },
  props: {
    harfSize: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.r-spinner--wrapper {
  height: 400px;
  width: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.r-spinner--wrapper {
  height: 200px;
}
</style>

<template>
  <r-modal
    class="recipe-update-confirm"
    :title="$t('recipe.recipeEdit.updateConfirmed')"
    enter
    @enter="enter"
    :enterLabel="$t('recipe.recipeEdit.updateConfirmedTitle')"
    @cancel="cancel"
  >
    <r-heading :level="2" class="recipe-update-confirm--main--header">{{
      recipe.name
    }}</r-heading>

    <!-- 画像ここから -->
    <div class="main-wrapper">
      <div class="main--left">
        <div
          class="recipe-update-confirm--main--image recipe-update-confirm--main--content"
          :style="{ 'background-image': 'url(' + recipe.image + ')' }"
          v-if="recipe.image"
        ></div>

        <div
          class="recipe-update-confirm--main--image main--image--default recipe-update-confirm--main--content"
          v-else
        ></div>
        <!-- 画像ここまで -->

        <!-- カテゴリーここから -->
        <r-paper class="recipe-update-confirm--main--content" round>
          <r-list-header>{{ $t("common.category") }}</r-list-header>
          <div class="recipe-update-confirm--main--category--list">
            <span
              class="recipe-update-confirm--main--category--item"
              v-text="recipe.cat1"
            />
            <span
              v-if="recipe.cat2 !== ''"
              class="recipe-update-confirm--main--category--item"
              v-text="recipe.cat2"
            />
            <span
              v-if="recipe.cat3 !== ''"
              class="recipe-update-confirm--main--category--item"
              v-text="recipe.cat3"
            />
          </div>
        </r-paper>
        <!-- カテゴリーここまで -->

        <!-- 公開設定 -->
        <r-paper
          class="recipe-update-confirm--main--content recipe-update-confirm--main--hidden"
          round
        >
          <span v-if="!recipe.hidden">{{
            $t("recipe.recipeEdit.recipeStatusPublic")
          }}</span>

          <span v-else>{{ $t("recipe.recipeEdit.recipeStatusHidden") }}</span>
        </r-paper>
      </div>

      <div class="main--right">
        <div class="main--right--inner-left">
          <!-- 食材ここから -->
          <r-paper class="recipe-update-confirm--main--content" round>
            <r-list class="recipe-update-confirm--main--content--food-list">
              <r-list-header
                >{{ $t("common.foodstuff") }}(1{{
                  $t("common.perPerson")
                }})</r-list-header
              >
              <r-list-content
                v-for="(item, index) in recipe.items"
                :key="index"
              >
                <r-list-item>{{ item.name }}</r-list-item>
                <r-list-item>{{ item.amount }}{{ item.unit }}</r-list-item>
              </r-list-content>
            </r-list>
          </r-paper>
          <!-- 食材ここまで -->

          <!-- アレルギーここから -->
          <r-paper class="recipe-update-confirm--main--content" round>
            <r-list-header>
              {{ $t("recipe.allergyItem.allergy") }}
            </r-list-header>
            <div class="recipe-update-confirm--main--content--allergy">
              <div class="recipe-update-confirm--main--content--allergy--item">
                <r-heading :level="4">
                  {{ $t("recipe.allergyItem.sevenItems") }}
                </r-heading>
                <template v-if="hasAllergySeven">
                  <span
                    v-for="(item, index) in recipe.allergy.sevenItems"
                    :key="`seven-${index}`"
                    >{{
                      $t(`recipe.allergyItem.${convertToAllergyCode(item)}`)
                    }}</span
                  >
                </template>
                <div
                  class="recipe-update-confirm--main--content--allergy"
                  v-else
                >
                  <span>{{ $t("recipe.allergyItem.nonAllergy") }}</span>
                </div>
              </div>

              <div
                class="recipe-update-confirm--main--content--allergy--item"
                v-if="allergyTwenty"
              >
                <r-heading :level="4">
                  {{ $t("recipe.allergyItem.twentyItems") }}
                </r-heading>
                <template v-if="hasAllergyTwenty">
                  <span
                    v-for="(item, index) in recipe.allergy.twentyItems"
                    :key="`twenty-${index}`"
                    >{{
                      $t(`recipe.allergyItem.${convertToAllergyCode(item)}`)
                    }}</span
                  >
                </template>
                <div
                  class="recipe-update-confirm--main--content--allergy"
                  v-else
                >
                  <span>{{ $t("recipe.allergyItem.nonAllergy") }}</span>
                </div>
              </div>
            </div>
          </r-paper>
        </div>

        <!-- 原価ここから -->
        <r-paper
          round
          class="recipe-update-confirm--main--content recipe-detail--main--cost-rate"
        >
          <r-list>
            <r-list-header>{{ $t("common.costRate") }}</r-list-header>
            <r-chart
              chartStyle="secondary"
              :label="$t('common.costRate')"
              :rate="recipe.costRate"
              v-if="hasCost"
              class="recipe-detail--main--cost-rate--item"
            />
            <div
              class="recipe-update-confirm--main--cost-rate--list-items recipe-detail--main--cost-rate--item"
            >
              <r-list-content>
                <r-list-item>
                  <span>{{ $t("common.cost") }}:</span>
                  <span v-if="hasCost">
                    {{ recipe.totalCost | localNumber
                    }}{{ $t("common.currencyYen") }}
                  </span>
                  <span v-else>
                    {{ $t("recipe.recipeDetail.notRegisterd") }}
                  </span>
                </r-list-item>
              </r-list-content>
              <r-list-content>
                <r-list-item>
                  <span>{{ $t("common.price") }}:</span>
                  <span v-if="hasCost">
                    {{ recipe.price | localNumber
                    }}{{ $t("common.currencyYen") }}
                  </span>
                  <span v-else>
                    {{ $t("recipe.recipeDetail.notRegisterd") }}
                  </span>
                </r-list-item>
              </r-list-content>
              <r-list-content>
                <r-list-item>
                  <span>{{ $t("recipe.recipeDetail.targetRate") }}:</span>
                  <span v-if="hasCost">{{ recipe.targetRate }}%</span>
                  <span v-else>
                    {{ $t("recipe.recipeDetail.notRegisterd") }}
                  </span>
                </r-list-item>
              </r-list-content>
            </div>
          </r-list>
        </r-paper>
        <!-- 原価ここまで -->
      </div>
    </div>
  </r-modal>
</template>

<script>
import RHeading from "@/plugins/atoms/RHeading.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RListHeader from "@/plugins/atoms/RListHeader.vue";
import RChart from "@/plugins/atoms/RChart.vue";
import RModal from "@/plugins/molecules/RModal.vue";

export default {
  components: {
    RHeading,
    RList,
    RListContent,
    RListItem,
    RListHeader,
    RPaper,
    RChart,
    RModal,
  },
  props: {
    recipe: Object,
    allergyTwenty: Boolean,
  },
  computed: {
    hasAllergySeven() {
      if (this.recipe.allergy.sevenItems.length !== 0) {
        return true;
      }
      return false;
    },
    hasAllergyTwenty() {
      if (this.recipe.allergy.twentyItems.length !== 0) {
        return true;
      }
      return false;
    },
    hasCost() {
      return this.recipe.targetRate !== 0;
    },
    convertToAllergyCode() {
      return (allergy) => {
        // スイッチでコードに変換する
        switch (allergy) {
          case "小麦":
            return "wheat";
          case "蕎麦":
            return "soba";
          case "乳製品":
            return "milk";
          case "卵":
            return "egg";
          case "落花生":
            return "peanuts";
          case "えび":
            return "shrimp";
          case "かに":
            return "crab";
          case "あわび":
            return "abalone";
          case "いか":
            return "squid";
          case "いくら":
            return "salmonRoe";
          case "オレンジ":
            return "orange";
          case "カシューナッツ":
            return "cashewNut";
          case "キウイフルーツ":
            return "kiwi";
          case "牛肉":
            return "beef";
          case "くるみ":
            return "walnut";
          case "ごま":
            return "sesame";
          case "さけ":
            return "salmon";
          case "さば":
            return "mackerel";
          case "大豆":
            return "soy";
          case "鶏肉":
            return "chicken";
          case "バナナ":
            return "banana";
          case "豚肉":
            return "pork";
          case "まつたけ":
            return "matsutake";
          case "もも":
            return "peaches";
          case "やまいも":
            return "yam";
          case "りんご":
            return "apple";
          case "ゼラチン":
            return "geratin";
          case "アーモンド":
            return "almond";
        }
      };
    },
  },
  methods: {
    enter() {
      this.$emit("enter");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.recipe-update-confirm {
  background-color: #f0f0f0;
  width: 100%;
  overflow: auto;
  height: 100%;
}
.recipe-update-confirm--main--content {
  margin-top: 20px;
}
.recipe-update-confirm--main--image {
  min-height: 30vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #f8d5cb;
}
.recipe-update-confirm--main--category--list,
.recipe-update-confirm--main--content--allergy {
  text-align: left;
  margin-top: 10px;
}
.main--image--default {
  background-image: url(~@/assets/images/logo4.png);
  background-size: auto 10vh;
}

.recipe-update-confirm--main--category--item:not(:last-child):after,
.recipe-update-confirm--main--content--allergy:not(:last-child):after {
  content: "・";
}
.recipe-update-confirm--main--content--button {
  margin-top: 10px;
}

.recipe-update-confirm--main--content--allergy--item {
  margin-top: 10px;
}
.recipe-update-confirm--main--content--food-list {
  margin-top: 10px;
}
.recipe-detail--main--cost-rate--item {
  margin-top: 10px;
}
.recipe-update-confirm--main--hidden {
  text-align: left;
}

@media screen and (min-width: 641px) {
  .main-wrapper {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }
  .main--left {
    width: 30%;
    margin-right: 20px;
  }
  .main--right {
    width: 70%;
  }
}

@media screen and (min-width: 1024px) {
  .main-wrapper {
    display: flex;
  }
  .main--left {
    width: 30%;
  }
  .main--right {
    width: 70%;
    display: flex;
  }
  .main--right--inner-left {
    margin-right: 20px;
    width: 80%;
  }
}
</style>

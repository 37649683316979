<template>
  <div
    class="step-nav"
    :class="{
      'step-nav--normal': step < index,
      'step-nav--selecting': step === index,
      'step-nav--complete': step > index,
    }"
    @click="clickHandler"
  >
    <span class="step-nav--heading--step">STEP {{ index + 1 }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "r-step-nav",
  props: {
    index: Number,
    step: Number,
  },
  methods: {
    clickHandler(e) {
      if (this.index === this.step) {
        this.$emit("click", e);
      }
    },
  },
};
</script>

<style scoped>
.step-nav {
  --primary-color: #e87352;
  background-color: #ffffff;
  font-weight: bold;
  border-radius: 5px;
  text-align: left;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}
.step-nav span {
  color: #e87352;
}
.step-nav--selecting {
  border-left: 5px solid #e87352;
  border-radius: 0 5px 5px 0;
  box-sizing: border-box;
}
.step-nav--selecting:hover {
  background-color: #e87352;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.5s;
}

.step-nav--selecting:hover span {
  color: #ffffff;
  transition: all 0.5s;
}
.step-nav--complete {
  background-color: #e87352;
  color: #ffffff;
  background-image: url(~@/assets/images/icon2_check.png);
  background-repeat: no-repeat;
  background-size: 12px auto;
  background-position: 4px center;
  padding: 10px 10px 10px 20px;
  box-sizing: border-box;
}
.step-nav--complete span {
  color: #ffffff;
}

@media screen and (min-width: 1024px) {
  .step-nav {
    flex-direction: row;
    padding: 15px 10px;
  }
  .step-nav--heading--step {
    margin-left: 10px;
    margin-right: 20px;
  }
}
</style>

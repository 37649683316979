<template>
  <div>
    <r-heading :level="1" :image="headingImage">
      <span>{{ $t("course.courseRegistration.courseRegistrationTitle") }}</span>
    </r-heading>
    <div class="course-registration">
      <!--店舗の選択-->
      <r-paper round class="course-registration--store-select" v-if="!isStore">
        <span>{{ $t("course.courseRegistration.selectStore") }}</span>
        <r-select
          v-if="existingValue !== ''"
          :options="stores"
          @input="storeSelect"
          :existingValue="existingValue"
        />
      </r-paper>
      <div class="course-registration-wrapper">
        <!-- step nav -->
        <div class="course-registration--step">
          <r-step-nav
            class="step--content"
            v-for="(stepNavLabel, index) in stepNavLabels"
            :key="index"
            :index="index"
            :step="currentStep"
            @click="modalOpen(index)"
            >{{ stepNavLabel }}</r-step-nav
          >
        </div>
        <!-- main -->
        <r-paper
          class="course-registration--main"
          paperStyle="pink"
          round
          :class="[
            currentStep === 0
              ? 'course-registration--main--image-center'
              : 'course-registration--main--image-right',
            { 'course-registration--main--image-none': currentStep > 3 },
          ]"
        >
          <!-- コース名ここから -->
          <div class="course-registration--main--content" v-if="courseName">
            <r-paper
              round
              class="course-registration--main--content--left course-name"
              >{{ courseName }}</r-paper
            >
            <span class="change-btn" @click="openStep1('nameOnly')"
              >【{{ $t("course.courseRegistration.courseNameChange") }}】</span
            >
          </div>
          <!-- コース名ここまで -->

          <!-- カテゴリーここから -->
          <div
            class="course-registration--main--content"
            v-if="categories.length !== 0"
          >
            <r-paper round class="course-registration--main--content--left">
              <span
                class="course-registration--main--item--category"
                v-for="(category, index) in categories"
                :key="index"
                >{{ category }}</span
              >
            </r-paper>
            <span @click="openStep1('categoryOnly')" class="change-btn"
              >【{{
                $t("course.courseRegistration.courseCategoryChange")
              }}】</span
            >
          </div>
          <!-- カテゴリーここまで -->

          <!-- 画像ここから -->
          <div
            class="course-registration--main--content"
            v-if="currentStep > 1"
          >
            <r-paper round class="course-registration--main--item--image">
              <span v-if="image">
                <img :src="image" alt class="upload-image" />
              </span>
              <div
                class="course-registration--main--item--img--default"
                v-else
              ></div>
            </r-paper>
            <span class="change-btn" @click="modalOpen(1)"
              >【{{ $t("course.courseRegistration.courseImageChange") }}】</span
            >
            <span class="change-btn" @click="deleteImage" v-if="image !== ''"
              >【{{ $t("course.courseRegistration.courseImageDelete") }}】</span
            >
          </div>
          <!-- 画像ここまで -->

          <!-- メニュー一覧ここから -->
          <div
            class="course-registration--main--content"
            v-if="selectedRecipes.length !== 0"
          >
            <r-paper round class="course-registration--main--content--left">
              <r-list>
                <r-list-content
                  v-for="(selectedRecipe, index) in selectedRecipes"
                  :key="index"
                >
                  <r-list-item
                    :caption="
                      $t('common.cost') +
                      `:${selectedRecipe.totalCost}` +
                      $t('common.currencyYen') +
                      `/` +
                      $t('common.perPerson')
                    "
                    >{{ selectedRecipe.name }}</r-list-item
                  >
                  <r-list-item
                    >{{ selectedRecipe.serv
                    }}{{ $t("common.perPerson") }}</r-list-item
                  >
                </r-list-content>
              </r-list>
            </r-paper>
            <span class="change-btn" @click="modalOpen(2)"
              >【{{ $t("course.courseRegistration.courseMenuChange") }}】</span
            >
          </div>
          <!-- メニューここまで -->

          <!-- アレルギーここから -->
          <div
            class="course-registration--main--content2"
            v-if="selectedRecipes.length !== 0"
          >
            <r-paper round>
              <div>{{ $t("common.allergy") }}</div>
              <r-hr hrStyle="sub-primary" />
              <div>
                <r-heading :level="4">{{
                  $t("recipe.allergyItem.sevenItems")
                }}</r-heading>
                <template v-if="allergiesSevenItems.length > 0">
                  <span
                    class="allergy-item"
                    v-for="(allergy, index) in allergiesSevenItems"
                    :key="`seven-${index}`"
                    >{{ $t(`recipe.allergyItem.${allergy}`) }}</span
                  >
                </template>
                <span v-else>{{ $t("course.courseDetail.notAllergy") }}</span>
              </div>

              <div v-if="allergyTwenty">
                <r-heading :level="4">{{
                  $t("recipe.allergyItem.twentyItems")
                }}</r-heading>
                <template v-if="allergiesTwentyItems.length > 0">
                  <span
                    class="allergy-item"
                    v-for="(allergy, index) in allergiesTwentyItems"
                    :key="`twenty-${index}`"
                    >{{ $t(`recipe.allergyItem.${allergy}`) }}</span
                  >
                </template>
                <span v-else>{{ $t("course.courseDetail.notAllergy") }}</span>
              </div>
            </r-paper>
          </div>
          <!-- アレルギーここまで -->

          <!-- 売値ここから -->
          <div class="course-registration--main--content" v-if="price !== 0">
            <r-paper round class="course-registration--main--content--left">
              <span
                >{{ $t("common.price") }} : {{ price
                }}{{ $t("common.currencyYen") }}</span
              >
            </r-paper>
            <span class="change-btn" @click="modalOpen(3)"
              >【{{ $t("course.courseRegistration.coursePriceChange") }}】</span
            >
          </div>

          <!-- 売値ここまで -->
          <r-button
            v-if="price !== 0"
            class="course-registration--main--content2 register-button"
            full
            buttonStyle="primary"
            @click="registCourse"
            >{{ $t("course.courseRegistration.courseRegister") }}</r-button
          >
        </r-paper>
      </div>

      <!-- modal contents -->
      <transition name="modal">
        <div
          class="course-registration--modal-bg"
          v-if="modalFlag.includes(true)"
          @mousedown.self="modalClose"
        >
          <course-name-form
            class="course-registration--modal"
            v-if="modalFlag[0]"
            :flag="step1Flag"
            :existingName="courseName"
            :existingCat="categories"
            @next="nextFromStep1"
            @enter="enterFromNameForm"
            @cancel="modalClose"
            @nameOnly="assignCourseName"
            @categoryOnly="assignCategory"
          />
          <course-image-form
            class="course-registration--modal"
            v-if="modalFlag[1]"
            :existingImage="image"
            :regist="currentStep <= 1"
            @enter="enterFromImageForm"
            @skip="skipFromImageForm"
            @cancel="modalClose"
          />
          <course-menu-form
            class="course-registration--modal course-registration--menu-form"
            v-if="modalFlag[2] && !menuAmount"
            @enter="enterFromMenuForm"
            @cancel="modalClose"
            :existingRecipes="tmpSelectedRecipes"
          />
          <course-menu-amount-form
            class="course-registration--modal"
            v-if="modalFlag[2] && !!menuAmount"
            :existingRecipes="tmpSelectedRecipes"
            @enter="enterFromAmountForm"
            @cancel="cancelFromAmount"
          />
          <course-selling-price
            class="course-registration--modal"
            v-if="modalFlag[3]"
            :items="selectedRecipes"
            :existingPrice="price"
            :totalCost="totalCost"
            @enter="enterFromPriceForm"
            @cancel="modalClose"
          />
          <course-selling-price
            class="course-registration--modal"
            v-if="modalFlag[4]"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import RStepNav from "@/plugins/atoms/RStepNav.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";

import CourseNameForm from "@/components/course/organisms/CourseNameForm.vue";
import CourseImageForm from "@/components/course/organisms/CourseImageForm.vue";
import CourseMenuForm from "@/components/course/organisms/CourseMenuForm.vue";
import CourseMenuAmountForm from "@/components/course/organisms/CourseMenuAmountForm.vue";
import CourseSellingPrice from "@/components/course/organisms/CourseSellingPrice.vue";

import auth from "@/libs/Auth.js";
import courseManager from "@/libs/Course.js";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  components: {
    RStepNav,
    RPaper,
    RList,
    RListContent,
    RListItem,
    RButton,
    RSelect,
    RHeading,
    RHr,
    CourseNameForm,
    CourseImageForm,
    CourseMenuForm,
    CourseMenuAmountForm,
    CourseSellingPrice,
  },
  data() {
    return {
      stepNavLabels: [
        this.$i18n.t("course.courseRegistration.labelForName"),
        this.$i18n.t("course.courseRegistration.labelForImage"),
        this.$i18n.t("course.courseRegistration.labelForMenu"),
        this.$i18n.t("course.courseRegistration.labelForPrice"),
      ],
      currentStep: 0,
      step1Flag: "name",
      modalFlag: [false, false, false, false],
      menuAmount: false,
      stores: {},
      customerID: "",
      storeID: "",
      allergyTwenty: false,
      courseName: "",
      categories: [],
      image: "",
      selectedRecipes: [],
      tmpSelectedRecipes: [],
      price: 0,
      costRate: 0,
      isStore: false,
      headingImage: require("../../../assets/images/courseColor.svg"),
      existingValue: "",
    };
  },
  methods: {
    storeSelect(id) {
      if (id) {
        this.storeID = id;
        const storeName = Object.keys(this.stores).find(
          (key) => this.stores[key] === id,
        );
        this.$root.setStoreInfoAction(this.storeID, storeName);
        sessionStorage.storeID = this.storeID;
      } else {
        this.storeID = "";
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";
      }

      this.courseName = "";
      this.categories = [];
      this.image = "";
      this.selectedRecipes = [];
      this.tmpSelectedRecipes = [];
      this.price = 0;
      this.costRate = 0;
      this.step1Flag = "name";

      this.currentStep = 0;
    },
    deleteImage() {
      this.image = "";
    },
    modalOpen(index) {
      this.$set(this.modalFlag, index, true);
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".course-registration--modal");
      disableBodyScroll(modal);
    },
    modalClose() {
      const index = this.modalFlag.indexOf(true);
      this.$set(this.modalFlag, index, false);
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    incrementCurrent(index) {
      if (this.currentStep === index) {
        this.currentStep++;
      }
    },
    openStep1(flag) {
      this.step1Flag = flag;
      this.modalOpen(0);
    },
    nextFromStep1() {
      this.step1Flag = "category";
    },
    enterFromNameForm(courseName, categories) {
      this.courseName = courseName;
      this.categories = categories;
      this.modalClose();
      this.incrementCurrent(0);
    },
    assignCourseName(courseName) {
      this.courseName = courseName;
      this.modalClose();
    },
    assignCategory(categories) {
      this.categories = categories;
      this.modalClose();
    },
    enterFromImageForm(value) {
      this.image = value;
      this.modalClose();
      this.incrementCurrent(1);
    },
    skipFromImageForm() {
      this.image = "";
      this.modalClose();
      this.incrementCurrent(1);
    },
    enterFromMenuForm(value) {
      this.tmpSelectedRecipes = value;
      this.menuAmount = true;
    },
    enterFromAmountForm(value) {
      this.menuAmount = false;
      this.selectedRecipes = value;
      this.incrementCurrent(2);
      this.modalClose();
    },
    cancelFromAmount() {
      this.menuAmount = false;
    },
    enterFromPriceForm(price, costRate) {
      this.price = price;
      this.costRate = costRate;
      this.incrementCurrent(3);
      this.modalClose();
    },
    async setStore() {
      if (this.customerID) {
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";
        this.stores = await auth.getStoreList(this.customerID);

        const storesKeys = Object.keys(this.stores).map(
          (key) => this.stores[key],
        );
        storesKeys.shift();

        this.existingValue = storesKeys[0];
      }
    },
    async registCourse() {
      // コースデータ登録
      let hidden = false;
      for (let recipe of this.selectedRecipes) {
        if (recipe.hidden === true) {
          hidden = true;
          break;
        }
      }

      const createUser = auth.getDisplayName();

      // コース登録T
      const registData = {
        title: this.courseName,
        categories: this.categories,
        price: this.price, // 売値
        hidden: hidden,
        image: this.image,
        factors: this.selectedRecipes,
        createUser: createUser,
      };
      // FireStoreに登録
      const courseID = await courseManager.createCourse(
        this.customerID,
        this.storeID,
        registData,
      );

      this.$router.push(
        { name: "courseDetail", params: { id: courseID } },
        () => {},
      );
    },
    convertToAllergyCode(name) {
      switch (name) {
        case "小麦":
          return "wheat";
        case "蕎麦":
          return "soba";
        case "乳製品":
          return "milk";
        case "卵":
          return "egg";
        case "落花生":
          return "peanuts";
        case "えび":
          return "shrimp";
        case "かに":
          return "crab";
        case "あわび":
          return "abalone";
        case "いか":
          return "squid";
        case "いくら":
          return "salmonRoe";
        case "オレンジ":
          return "orange";
        case "カシューナッツ":
          return "cashewNut";
        case "キウイフルーツ":
          return "kiwi";
        case "牛肉":
          return "beef";
        case "くるみ":
          return "walnut";
        case "ごま":
          return "sesame";
        case "さけ":
          return "salmon";
        case "さば":
          return "mackerel";
        case "大豆":
          return "soy";
        case "鶏肉":
          return "chicken";
        case "バナナ":
          return "banana";
        case "豚肉":
          return "pork";
        case "まつたけ":
          return "matsutake";
        case "もも":
          return "peaches";
        case "やまいも":
          return "yam";
        case "りんご":
          return "apple";
        case "ゼラチン":
          return "geratin";
        case "アーモンド":
          return "almond";
      }
    },
  },
  computed: {
    allergiesSevenItems() {
      const allergy = this.selectedRecipes.reduce((acc, recipe) => {
        return [...acc, ...recipe.allergy.sevenItems];
      }, []);
      return [...new Set(allergy)].map((name) =>
        this.convertToAllergyCode(name),
      );
    },
    allergiesTwentyItems() {
      const allergy = this.selectedRecipes.reduce((acc, recipe) => {
        return [...acc, ...recipe.allergy.twentyItems];
      }, []);
      return [...new Set(allergy)].map((name) =>
        this.convertToAllergyCode(name),
      );
    },
    totalCost() {
      return this.selectedRecipes.reduce((acc, item) => {
        acc += item.totalCost * item.quantity;
        return Number(acc.toFixed(2));
      }, 0);
    },
  },
  async created() {
    try {
      const isStoreUser = await auth.isStore();
      if (isStoreUser) {
        this.isStore = true;
        this.customerID = await auth.getCustomerID(this.$root);
        this.storeID = await auth.getStoreID(this.$root);
        if (!this.customerID || !this.storeID) {
          // customerID、StoreIDが存在しない場合はエラー ToDo: admin の場合を検討
          throw new Error("Forbidden");
        }
      } else {
        this.customerID = await auth.getCustomerID(this.$root);
        if (!this.customerID) {
          // 顧客IDが存在しない場合はエラー
          throw new Error("Forbidden");
        }

        this.storeID = "";
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";

        // 店舗一覧を表示
        this.setStore();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    // アレルギーの品目数設定を取得
    this.allergyTwenty = this.$root.privateState.allergyTwenty;
  },
};
</script>

<style scoped>
.course-registration {
  padding: 60px 10px 0 10px;
}
.course-registration--store-select {
  align-content: center;
  text-align: left;
}
/** ステップナビここから */
.course-registration--step {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.step--content {
  box-sizing: border-box;
  width: 50%;
  margin-top: 5px;
}
.step--content:nth-child(odd) {
  width: 50%;
  margin-right: 5px;
  margin-left: -5px;
  box-sizing: border-box;
}
/** ステップナビここまで */

/** 登録内容表示領域ここから */
.course-registration--main {
  margin-top: 20px;
  min-height: 30vh;
  background-image: url(~@/assets/images/logo4.png);
  background-repeat: no-repeat;
}
.course-registration--main--content {
  text-align: left;
  margin-top: 20px;
}
.course-registration--main--content2 {
  margin-top: 20px;
  text-align: left;
}
/** 登録内容表示領域ここから */

/** コース名 */
.course-name {
  font-weight: bold;
}

/** カテゴリー・アレルギー */
.course-registration--main--item--category:not(:last-child):after,
.allergy-item:not(:last-child):after {
  content: "・";
}
/** モーダル関連ここから */
.course-registration--modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  margin: auto;
  padding: 0 5%;
}
.course-registration--modal {
  width: 90%;
}
/** モーダル関連ここまで */

/** 画像（アップロード）ここから */
.upload-image {
  height: 160px;
  width: 213px;
}
.course-registration--main--image {
  height: 160px;
  width: 213px;
}
.course-registration--main--item--image {
  height: 160px;
  width: 213px;
  padding: 0;
}
.course-registration--main--item--img--default {
  height: 160px;
  width: 213px;
  background-image: url(~@/assets/images/logo4.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 8vh;
}

/** メイン背景 */
.course-registration--main--image-center {
  background-position: center center;
  background-size: auto 15vh;
}
.course-registration--main--image-right {
  background-position: bottom 10px right 10px;
  background-size: auto 5vh;
}
.course-registration--main--image-none {
  background-image: none;
}
/** 画像ここまで */

/** ボタン類ここから */
.register-button {
  text-align: center;
  margin: 60px 0px 0px 0px;
  padding: 10px;
  background-color: #fff;
  color: #e87352;
}
.register-button:hover {
  color: #fff;
  transition: 0.5s;
  background-color: #e87352;
}
.change-btn {
  color: #e87352;
  font-weight: bold;
}
.change-btn:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #ea5446;
}
/** ボタン類ここまで */

/*** タブレット *******************/
@media screen and (min-width: 641px) {
  .course-registration {
    padding-top: 100px;
  }
  .course-registration--store-select {
    margin-bottom: 20px;
  }
  .course-registration--main--content {
    display: flex;
    align-items: center;
  }
  .course-registration--main--content--left {
    width: 80%;
  }
  .course-registration--modal-bg {
    padding: 0 10%;
  }
}

/*** PC *************************/
@media screen and (min-width: 1024px) {
  .course-registration {
    margin: auto;
    padding-top: 120px;
    width: 1024px;
  }
  .course-registration-wrapper {
    display: flex;
  }
  .course-registration--store-select {
    margin-bottom: 20px;
  }
  .course-registration--step {
    flex-direction: column;
    grid-gap: 0;
    width: 30%;
    margin-right: 20px;
  }
  .step--content {
    width: 100%;
    margin-top: 20px;
  }
  .step--content:nth-child(odd) {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }
  .step--content:first-child {
    margin-top: 0;
  }
  .course-registration--main {
    margin-top: 0;
    width: 70%;
  }
  .course-registration--modal-bg {
    padding: 0 25%;
  }
  .course-registration--modal {
    min-width: 880px;
  }
}
</style>

<template>
  <div>
    <!--タイトル：メニュー管理-->
    <r-heading
      :level="1"
      class="menu-registration-heading"
      :image="headingImage"
    >
      {{ $t("menu.menuRegist.menuBook") }}「
      <span class="title-span">{{ bookTitle }}」</span>
      {{ $t("menu.menuRegist.regist") }}
    </r-heading>
    <div class="menu-registration-wrapper">
      <!--「戻る」ボタン-->
      <div class="back-btn" :class="!isStore ? ' store-name-display' : ''">
        <r-button
          buttonStyle="primary"
          :outline="true"
          @click="toMenuTop"
          class="back-btn-r"
          :class="!isStore && !windowSizePC ? ' btn--store-name-display' : ''"
          >{{ $t("common.back") }}</r-button
        >
        <div class="store" v-if="!isStore && !windowSizePC">
          <span class="store-name">{{ storeName }}</span>
        </div>
      </div>
      <!--店舗名-->
      <div class="store" v-if="!isStore && windowSizePC">
        <span class="store-name">{{ storeName }}</span>
      </div>
      <!--セクション情報表示領域-->
      <r-paper paperStyle="yellow" class="yellow-paper">
        <div
          class="section-list"
          v-for="(section, index) in sections"
          :key="index"
        >
          <div class="section-title">
            <span class="section-title-name">{{ section.name }}</span>
            <span class="section-name-change" @click="sectionNameChange(index)"
              >【{{ $t("menu.menuRegist.sectionNameChange") }}】</span
            >
          </div>
          <div class="menu-list-chart-btn-wrapper">
            <!--メニュー１個の名前、売値、原価率-->
            <div class="list-wrapper">
              <div
                class="menu-list"
                v-if="windowSizePC && section.menus.length !== 0"
              >
                <div class="menu-name-title">{{ $t("menu.menuTop.menu") }}</div>
                <div class="menu-price">
                  <div class="m-price-title">{{ $t("common.price") }}</div>
                  <div class="m-rate-title">{{ $t("common.costRate") }}</div>
                </div>
              </div>
              <div
                class="menu-list"
                v-for="(menu, menuIdx) in section.menus"
                :key="menuIdx"
              >
                <div class="menu-name">{{ menu.name }}</div>
                <div class="menu-price">
                  <div class="m-price">
                    <span v-if="!windowSizePC"
                      >{{ $t("menu.menuRegist.price") }} :</span
                    >
                    {{ $t("common.currency") }} {{ menu.price }}
                  </div>
                  <div class="m-rate">
                    <span v-if="!windowSizePC"
                      >{{ $t("menu.menuRegist.price") }} :</span
                    >
                    {{ menu.costRate }} %
                  </div>
                </div>
              </div>
            </div>
            <!--原価率チャート-->
            <div class="menu-chart" v-if="section.menus.length > 0">
              <r-chart
                chartStyle="primary"
                :rate="section.costRate"
                :label="$t('menu.menuRegist.costRate')"
                class="cost-rate-chart"
              ></r-chart>
            </div>
            <div class="update-button" v-if="section.menus.length > 0">
              <span @click="updateSection(index)" class="update-btn-1"
                >【{{ $t("menu.menuRegist.updateSectionMenu") }}】</span
              >&nbsp;
              <span @click="deleteSection(index)" class="update-btn-2"
                >【{{ $t("menu.menuRegist.deleteSection") }}】</span
              >
            </div>
          </div>
          <!--セクションメニュ選択-->
          <div
            class="section-button"
            @click="chooseSectionMenu(index)"
            v-if="section.menus.length === 0"
          >
            <i :class="['fa', 'fa-' + 'plus-circle']"></i>
            {{ $t("menu.menuRegist.chooseSectionMenus") }}
          </div>
        </div>
        <!--セクション作成-->
        <div
          class="section-button"
          @click="sectionCreate"
          v-if="needSectionCreateBtn"
        >
          <i :class="['fa', 'fa-' + 'plus-circle']"></i>
          {{ $t("menu.menuRegist.registSection") }}
        </div>
        <!--合計原価率グルプ-->
        <div class="total-chart-wrapper" v-if="this.sections.length !== 0">
          <h3>{{ $t("menu.menuRegist.totalCostRate") }}</h3>
          <r-chart
            chartStyle="secondary"
            :rate="calcTotalCostRate"
            :label="$t('menu.menuRegist.costRate')"
          />
          <!--登録ボタン-->
          <r-button
            buttonStyle="primary"
            outline
            full
            @click="registrationComplete"
            class="book-registration"
            v-if="needRegistrationBtn"
            >{{ $t("menu.menuRegist.menuRegistration") }}</r-button
          >
          <!-- v-show="this.sections.length!==0" -->
        </div>
      </r-paper>
    </div>
    <!-- loading -->
    <div class="loading" v-if="loading">
      <r-spinner></r-spinner>
    </div>
    <!-- モーダル -->
    <transition name="modal">
      <div
        v-if="sectionCreateForm || sectionMenuChoose"
        class="modal-overlay"
        @click.self="closeModal"
      >
        <!--セクション名前入力-->
        <section-create-form
          v-if="sectionCreateForm"
          @cancel="closeModal"
          @sectionCreated="sectionCreated"
          @sectionNameChanged="sectionNameChanged"
          :changeSectionName="changeSectionName"
          :selectedSectionIndex="selectedSectionIndex"
          :sectionNameForChange="this.sectionNameForMenuSelect"
          class="menu-registration-modal"
        />
        <!--メニュー選択モーダル-->
        <section-menu-form
          v-if="sectionMenuChoose"
          :sectionName="sectionNameForMenuSelect"
          :selectedMenus="selectedMenuList"
          @cancel="closeModal"
          @menuList="getMenuList"
          class="menu-registration-modal"
        />
      </div>
    </transition>
  </div>
</template>
<script>
import RButton from "@/plugins/atoms/RButton.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RChart from "@/plugins/atoms/RChart.vue";
import SectionCreateForm from "../organisms/SectionCreateForm.vue";
import SectionMenuForm from "../organisms/SectionMenuForm.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import menuManager from "@/libs/Menu.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  components: {
    RPaper,
    RButton,
    RChart,
    SectionCreateForm,
    SectionMenuForm,
    RHeading,
    RSpinner,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      //情報表示用データ
      bookTitle: "",
      bookNo: null,
      sectionNameForMenuSelect: "",
      //総合原価率,
      totalCostRate: 0,
      //操作中のセクションのインデクス
      selectedSectionIndex: 0,
      sections: [],
      selectedMenuList: [],
      sectionCreateForm: false,
      sectionMenuChoose: false,
      changeSectionName: false,
      storeName: "",
      width: window.innerWidth,
      headingImage: require("../../../assets/images/bookColor.png"),
      loading: false,
      isStore: true,
    };
  },
  methods: {
    toMenuTop() {
      if (window.confirm(this.$t("menu.menuRegist.backToMenuTop"))) {
        this.$router.push({ name: "menu" }, () => {});
      }
    },
    sectionCreate() {
      //セクション名を入力
      this.changeSectionName = false;
      this.sectionCreateForm = true;
      this.selectedMenuList = [];
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".menu-registration-modal");
      disableBodyScroll(modal);
    },
    sectionNameChange(index) {
      //セクション名を変更
      this.selectedSectionIndex = index;
      this.sectionNameForMenuSelect =
        this.sections[this.selectedSectionIndex].name;
      this.changeSectionName = true;
      this.sectionCreateForm = true;
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".menu-registration-modal");
      disableBodyScroll(modal);
    },
    sectionCreated(sectionName) {
      //入力されたセクションの名前を表示用sectionsに格納
      this.sections.push({
        num: 0, //セクション番号
        name: sectionName, //セクションの名前
        menus: [], //セクション内のメニュー
        costRate: 0, //表示用の原価率
      });
      //モーダルclose
      this.closeModal();
    },
    sectionNameChanged(sectionName) {
      this.selectedSectionIndex;
      this.sections[this.selectedSectionIndex].name = sectionName;
      //モーダルclose
      this.closeModal();
    },
    updateSection(index) {
      //セクションで選択したメニューの変更
      this.selectedSectionIndex = index;
      this.selectedMenuList = this.sections[this.selectedSectionIndex].menus;
      this.sectionNameForMenuSelect = this.sections[index].name;
      this.sectionMenuChoose = true;
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".menu-registration-modal");
      disableBodyScroll(modal);
    },
    deleteSection(index) {
      //メニューブックのセクションの削除
      if (
        window.confirm(
          this.$i18n.t("menu.menuRegist.section") +
            "「" +
            this.sections[index].name +
            "」" +
            this.$i18n.t("menu.menuEdit.confirmDeleteSection"),
        )
      ) {
        this.sections.splice(index, 1);
      }
    },
    closeModal() {
      //モダールを閉じる
      this.sectionCreateForm = false;
      this.sectionMenuChoose = false;
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    chooseSectionMenu(index) {
      //セクションのメニュー選択モーダル展開
      this.sectionNameForMenuSelect = this.sections[index].name;
      //メニューを選択するセクションを決定
      this.selectedSectionIndex = index;
      this.selectedMenuList = [];
      //メニュー選択モーダル展開
      this.sectionMenuChoose = true;
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".menu-registration-modal");
      disableBodyScroll(modal);
    },
    getMenuList(list) {
      //モーダルで選択したメニューオブジェクトを配列をsectionに格納
      this.sections[this.selectedSectionIndex].menus = list;
      //セクション原価率計算
      this.calcSectionRate(this.selectedSectionIndex, list);
      //モーダルを閉じる
      this.closeModal();
    },
    calcSectionRate(index, list) {
      const costArr = list.map((e) => e.costRate).flat();
      const sectionRate = costArr.reduce((acc, cost) => {
        acc += cost / costArr.length;
        return parseFloat(acc.toFixed(2));
      }, 0);
      this.sections[index].costRate = sectionRate;
    },
    async registrationComplete() {
      //メニューがないセクション(最近作られたセクション)のチェック#170の処理
      const lastSectionIdx = this.sections.length - 1;
      const lastSectionName = this.sections[lastSectionIdx].name;

      if (!this.needSectionCreateBtn) {
        if (
          window.confirm(
            this.$i18n.t("menu.menuRegist.section") +
              "「" +
              lastSectionName +
              "」" +
              this.$i18n.t("menu.menuRegist.hasNoMenuInSection"),
          )
        ) {
          this.loading = true;
          this.sections.splice(lastSectionIdx, 1);
        } else {
          return;
        }
      }

      //メニューブック登録
      this.loading = true;
      try {
        await menuManager.createBook(
          this.customerID,
          this.storeID,
          this.bookNo,
          this.bookTitle,
          this.sections,
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
        alert(error);
      }
      this.$router.push(
        {
          name: "menu",
          params: { isComplete: true, bookTitle: this.bookTitle },
        },
        () => {},
      );
    },
    handleResize() {
      this.width = window.innerWidth;
    },
  },
  computed: {
    calcTotalCostRate() {
      const tmpArr = this.sections.map((e) => e.menus).flat();
      const totalCostRate = tmpArr.reduce((acc, menu) => {
        acc += menu.costRate / tmpArr.length;
        return acc;
      }, 0);
      return parseFloat(totalCostRate.toFixed(2));
    },
    windowSizePC() {
      //PCサイズ対応
      return this.width >= 1024 || this.width >= 641;
    },
    needSectionCreateBtn() {
      //最近作成したセクションのメニュー有無をチェック
      if (this.sections.length <= 0) {
        //セクションが何も作成されていない状態
        return true;
      } else {
        const sectionLength = this.sections.length;
        if (this.sections[sectionLength - 1].menus == 0) {
          //最近作られたセクションの選択メニュー有無を確認
          return false;
        } else {
          return true;
        }
      }
    },
    needRegistrationBtn() {
      let result = false;
      let sectionLength = this.sections.length;
      let menuLength = this.sections[sectionLength - 1].menus.length;
      if (sectionLength == 1) {
        //最初のセクションが生成
        if (menuLength == 0) {
          result = false;
        } else {
          result = true;
        }
      } else if (sectionLength > 1) {
        result = true;
      }
      return result;
    },
  },
  async created() {
    this.customerID = await auth.getCustomerID(this.$root);
    this.storeID = await auth.getStoreID(this.$root);
    if (!this.customerID || !this.storeID) {
      // customerID、StoreIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }
    //店舗・本部ユーザの判別
    const isStoreUser = await auth.isStore();
    if (!isStoreUser) {
      this.isStore = false;
    }
    if (this.$route.params.name) {
      this.bookTitle = this.$route.params.name;
    } else {
      throw new Error("Forbidden");
    }

    if (isNaN(this.$route.params.index)) {
      throw new Error("Forbidden");
    } else {
      if (this.$route.params.index >= 0 && this.$route.params.index <= 3) {
        this.bookNo = this.$route.params.index;
      } else {
        throw new Error("Forbidden");
      }
    }

    // 店舗名称を取得
    this.storeName = await auth.getStoreName(this.customerID, this.storeID);
  },
  //PCサイズ対応
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.menu-registration-wrapper {
  font-size: 1rem;
  box-sizing: border-box;
  margin: 0 auto;
  margin-top: 60px;
}
.menu-registration-heading >>> .r-heading-1 {
  font-size: 0.9rem;
}
.title-span {
  max-width: 30vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.store {
  display: block;
  font-weight: bold;
  border: 4vw;
  padding: 4vw;
}
.store-name {
  display: block;
  text-align: left;
  background-color: #fff;
  color: #4d261b;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px;
}
.back-btn {
  display: flex;
  width: 100%;
  margin-top: 10px;
}
.store-name-display {
  display: grid;
}
.btn--store-name-display {
  width: auto;
}
.back-btn-r {
  color: #333;
  margin-left: 15px;
  width: 50px;
}
.back-btn-r:hover {
  background-color: #e87352;
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
}
.yellow-paper {
  min-height: 30vh;
  border: 4vw solid #4d261b;
  padding: 4vw #4d261b;
  margin: 10px 0px 10px;
  border-radius: 5px;
}
.section-title {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 10px;
  box-sizing: border-box;
  border-top: 2px solid #e87352;
  border-bottom: 2px solid #e87352;
}
.section-title-name {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 55vw;
}
.section-name-change {
  color: #e87352;
}
.section-name-change:hover {
  text-decoration: underline;
  color: #e4390a;
  cursor: pointer;
}
.menu-list {
  display: block;
  margin: 5px auto;
  padding: 5px;
  border-bottom: 2px solid #ccc;
  line-height: 1.5rem;
}
.menu-name {
  width: 100%;
  display: inline-block;
  text-align: left;
}
.menu-price {
  width: 100%;
  display: flex;
  text-align: left;
}
.m-price {
  text-align: left;
}
.m-rate {
  text-align: left;
}
.menu-chart {
  padding: 20px 10px 20px 10px;
  text-align: center;
}
.update-button {
  color: #e87352;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.update-btn-1:hover {
  text-decoration: underline;
  color: #e4390a;
  cursor: pointer;
}
.update-btn-2:hover {
  text-decoration: underline;
  color: #e4390a;
  cursor: pointer;
}
.section-button {
  background-color: #e87352;
  color: #fff;
  border-radius: 5px;
  margin: 20px auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 10px;
  text-align: center;
}
.section-button:hover {
  color: #333;
  transition: 0.5s;
  cursor: pointer;
}
.total-chart-wrapper {
  padding: 20px 0px 20px 0px;
  text-align: center;
}
.book-registration {
  border-radius: 5px;
  margin: 10px auto;
  margin-top: 50px;
  padding: 10px;
  color: #333;
}
.book-registration:hover {
  background-color: #e87352;
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
}
/*モーダル関連*/
.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
}
/*iPad用css*/
@media (min-width: 641px) {
  .menu-registration-wrapper {
    width: 100%;
    padding: 0px 2% 0px;
    margin-top: 80px;
  }
  .menu-registration-heading >>> .r-heading-1 {
    font-size: 1.5rem;
  }
  .title-span {
    width: 23%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .store {
    padding: 1vw 4vw 5vw;
  }
  .back-btn {
    display: flex;
    justify-content: left;
    position: relative;
    top: -5.7vh;
    width: 100%;
  }
  .back-btn-r {
    margin-left: unset;
  }
  .yellow-paper {
    min-height: 30vh;
    margin-top: 0px;
    border-top: 40px solid #4d261b;
    border-right: 40px solid #4d261b;
    border-bottom: 40px solid #4d261b;
    border-left: 40px solid #4d261b;
  }
  .section-button {
    cursor: pointer;
    height: 56px;
    line-height: 56px;
    margin: 10px auto;
    width: 320px;
    padding: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
  }
  .section-list {
    padding: 24px 24px 0px 24px;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .section-title {
    width: 100%;
  }
  .section-title-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 60%;
  }
  .menu-list {
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
  }
  .menu-name {
    text-align: left;
    width: 50%;
    flex-grow: 1;
  }
  .menu-name-title {
    width: 50%;
    text-align: center;
    font-weight: bold;
  }
  .menu-price {
    flex-grow: 1;
    width: 50%;
    display: flex;
    justify-content: center;
  }
  .m-price {
    text-align: right;
    width: 50%;
    flex-grow: 1;
  }
  .m-price-title {
    width: 50%;
    font-weight: bold;
    text-align: right;
  }
  .m-rate {
    text-align: right;
    flex-grow: 1;
    width: 50%;
  }
  .m-rate-title {
    width: 50%;
    font-weight: bold;
    text-align: right;
  }
  .menu-list-chart-btn-wrapper {
    display: grid;
    width: 100%;
  }
  .list-wrapper {
    margin: 5px;
    grid-row: 1 / 5;
    grid-column: 1 / 7;
  }
  .menu-chart {
    margin: 5px;
    grid-row: 1 / span 5;
    grid-column: 7 /8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 0px;
  }
  .update-button {
    margin: 5px;
    grid-row: 5 /6;
    grid-column: 1 / 7;
  }
  .total-chart-wrapper {
    padding: 24px;
  }
  .book-registration {
    height: 56px;
    margin-top: 40px;
    padding: 0px;
    position: relative;
  }
  .modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    padding: 0 5%;
    box-sizing: border-box;
  }
}
/*PC用css */
@media (min-width: 1024px) {
  .menu-registration-wrapper {
    width: 1024px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 0;
  }
  .menu-registration-heading >>> .r-heading-1 {
    font-size: 1.5rem;
  }
  .title-span {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .store {
    padding: 0vw 4vw 2vw;
  }
  .back-btn {
    display: flex;
    justify-content: left;
    top: -6.2vh;
    width: 100%;
  }
  .yellow-paper {
    min-height: 30vh;
    border-top: 40px solid #4d261b;
    border-right: 40px solid #4d261b;
    border-bottom: 40px solid #4d261b;
    border-left: 40px solid #4d261b;
    padding: 4vw #4d261b;
    border-radius: 5px;
  }
  .section-button {
    cursor: pointer;
    height: 56px;
    line-height: 56px;
    margin: 10px auto;
    width: 320px;
    padding: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
  }
  .section-list {
    padding: 40px 40px 0px 40px;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .section-title {
    width: 100%;
  }
  .menu-list {
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
  }

  /*メニュータイトル*/
  .menu-name-title {
    flex-grow: 1;
    width: 100%;
    font-weight: bold;
    text-align: left;
  }
  .menu-price-title {
    flex-grow: 1;
    display: flex;
    width: 100%;
  }
  .m-price-title {
    text-align: right;
    /* width: 50%; */
    flex-grow: 1;
    font-weight: bold;
  }
  .m-rate-title {
    text-align: right;
    flex-grow: 1;
    /* width: 50%; */
    font-weight: bold;
  }
  .menu-name {
    text-align: left;
    /* width: 60%; */
    flex-grow: 1;
    padding: 5px;
    width: 100%;
    text-align: left;
  }
  .menu-price {
    flex-grow: 1;
    /* width: 40%; */
    display: flex;
    width: 100%;
  }
  .m-price {
    text-align: right;
    /* width: 50%; */
    flex-grow: 1;
  }
  .m-rate {
    text-align: right;
    flex-grow: 1;
    /* width: 50%; */
  }
  .menu-list-chart-btn-wrapper {
    display: grid;
    margin-top: 10px;
    width: 100%;
  }
  .list-wrapper {
    margin: 5px;
    grid-row: 1 / 5;
    grid-column: 1 / 5;
  }
  .menu-chart {
    margin: 5px;
    grid-row: 1 / span 5;
    grid-column: 5 /6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .update-button {
    margin: 5px;
    grid-row: 5 /6;
    grid-column: 1 / 5;
  }
  .total-chart-wrapper {
    padding: 40px;
  }
  .book-registration {
    height: 56px;
    margin-top: 40px;
    padding: 0px;
    position: relative;
  }
  .modal-overlay {
    padding: 0 15%;
  }
  .menu-registration-modal {
    min-width: 880px;
  }
}
</style>

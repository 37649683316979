import {
  doc,
  getDoc,
  setDoc,
  query,
  where,
  getDocs,
  collection,
  collectionGroup,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "@/libs";

export default {
  async getUnits(customerID, storeID) {
    let units = {};
    try {
      const unitRef = doc(
        db,
        "customers",
        customerID,
        "stores",
        storeID,
        "units",
        "unit-id",
      );
      const documentSnapshot = await getDoc(unitRef);
      if (documentSnapshot.exists()) {
        const record = documentSnapshot.data();
        const unitList = record.name;
        let unitObj = {};
        unitList.forEach((unit) => {
          unitObj[unit.name] = unit.name;
        });
        units = unitObj;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    return units;
  },
  async getUnitsConfigList(customerID, storeID) {
    let units = [];
    try {
      const unitRef = doc(
        db,
        "customers",
        customerID,
        "stores",
        storeID,
        "units",
        "unit-id",
      );
      const documentSnapshot = await getDoc(unitRef);
      if (documentSnapshot.exists()) {
        const record = documentSnapshot.data();
        units = record.name.map((unit) => {
          return { name: unit.name, bool: false };
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    return units;
  },
  async setUnits(customerID, storeID, setUnits) {
    try {
      const unitRef = doc(
        db,
        "customers",
        customerID,
        "stores",
        storeID,
        "units",
        "unit-id",
      );
      await setDoc(unitRef, {
        customerID: customerID,
        storeID: storeID,
        name: setUnits,
        updateAt: serverTimestamp(),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  //単位を使用するレシピ
  async getRecipeUsingSubUnit(customerID, storeID) {
    let result = [];
    try {
      // レシピの検索(サブレシピとして登録)
      const recipeQuery = query(
        collection(db, "recipes"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
      );
      let recipeRecords = [];
      const recipeSnapshot = await getDocs(recipeQuery);
      if (!recipeSnapshot.empty) {
        recipeRecords = recipeSnapshot.docs.map((doc) => {
          const getData = doc.data();
          const data = {
            name: getData.title,
            unit: getData.subUnit,
          };
          return data;
        });
      }
      // レシピのitems(食材)に登録された単位(unit)を全て検索
      const itemsQuery = query(
        collectionGroup(db, "items"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
      );
      let itemsRecords = [];
      const itemsSnapshot = await getDocs(itemsQuery);
      if (!itemsSnapshot.empty) {
        itemsRecords = itemsSnapshot.docs.map((doc) => {
          const getData = doc.data();
          const data = {
            name: getData.name,
            unit: getData.unit,
          };
          return data;
        });
      }
      //レシピデータ
      result = [...recipeRecords, ...itemsRecords];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return result;
  },
  // レシピリストから該当するカテゴリの一覧を取得
  searchRecipesUsingUnit(recipeList, unit) {
    let searchList = [];
    if (unit) {
      for (let data of recipeList) {
        if (data.unit === unit) {
          searchList.push(data);
        }
      }
    } else {
      // 検索カテゴリが存在しない場合はそのまま返却
      searchList = recipeList;
    }
    return searchList;
  },
};

<template>
  <div>
    <r-heading :level="1" :image="headingImage">
      <span>{{ $t("course.courseList.courseListTitle") }}</span>
    </r-heading>
    <div class="course-list">
      <r-spinner v-if="loading"></r-spinner>
      <div v-else>
        <r-alert v-if="deletedCourse !== ''" class="recipe-list-delete-msg">
          <span
            >{{ $t("course.courseList.course") }}「{{ deletedCourse }}」{{
              $t("course.courseList.courseDeleteMsg")
            }}</span
          >
        </r-alert>
        <div class="course-list-button-wrapper">
          <r-button
            outline
            buttonStyle="primary"
            class="course-list--category-button"
            @click="toggleStore"
            v-show="!isStore"
            >{{ $t("recipe.recipeTop.selectStore") }}</r-button
          >
          <r-button
            outline
            class="course-list--category-button"
            buttonStyle="primary"
            @click="isShowCategory = !isShowCategory"
            >{{ $t("course.courseList.selectCategory") }}</r-button
          >
        </div>
        <div
          class="course-list-filtering-wrapper"
          :class="[
            isShowCategory ? ' margin-top' : '',
            isShowStore ? ' margin-top' : '',
          ]"
        >
          <r-paper
            round
            class="course-list--content course-list--store-list"
            v-show="isShowStore"
          >
            <r-radio :options="stores" @click="filteringRecipeByStore" />
          </r-paper>
          <!-- category -->
          <transition
            name="category"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
          >
            <r-paper
              class="course-list--content course-category-form"
              round
              v-if="isShowCategory"
            >
              <r-radio
                :options="categories"
                @click="filteringCourseByCategory"
                class="course-category-items"
              ></r-radio>
            </r-paper>
          </transition>
        </div>
        <!-- コース一覧ここから -->
        <div class="course-list--content" v-if="hasCourses">
          <recipe-list-card
            class="course-list--content--card"
            v-for="(course, index) in filteredCourses"
            :key="index"
            :title="course.title"
            :label="displayCategory(course.categories)"
            :storeID="convertFromStoreIDToName(course.storeID)"
            :image="course.image"
            @click="toCourseDetail(course.id, course.storeID)"
            :isHeadquarter="isHeadquarter"
          ></recipe-list-card>
        </div>
        <div v-else class="course-result-none">
          {{ $t("course.courseList.notRegisteredCourse") }}
        </div>

        <r-button
          v-if="hasCourses && isShowMoreButton"
          buttonStyle="primary"
          outline
          full
          class="course-list--see-more-button"
          @click="addCourseIndex"
          >{{ $t("common.showMore") }}</r-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import RRadio from "@/plugins/atoms/RRadio.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";
import RAlert from "@/plugins/atoms/RAlert.vue";
import RecipeListCard from "@/components/recipe/organisms/RecipeListCard.vue";

import auth from "@/libs/Auth.js";
import categoryManager from "@/libs/Category.js";
import courseManager from "@/libs/Course.js";

export default {
  components: {
    RRadio,
    RButton,
    RPaper,
    RHeading,
    RSpinner,
    RAlert,
    RecipeListCard,
  },
  data() {
    return {
      isShowCategory: false,
      courses: [],
      categories: [],
      currentCategory: "",
      customerID: "",
      stores: [],
      currentStore: "",
      isShowStore: false,
      storeID: "",
      isStore: true,
      viewHidden: false,
      loading: false,
      headingImage: require("../../../assets/images/viewColor.png"),
      deletedCourse: "",
      courseIndex: 8,
      isHeadquarter: false,
      storeList: [],
    };
  },
  methods: {
    async filteringCourseByCategory(index) {
      if (index > 0) {
        this.currentCategory = this.categories[index].name;
      } else {
        this.currentCategory = "";
      }
      this.courseIndex = 8;
      await this.searchCourse();
    },
    async filteringRecipeByStore(index) {
      if (index > 0) {
        this.currentStore = this.stores[index].id;

        this.categories = await categoryManager.getCategoriesForList(
          this.customerID,
          this.currentStore,
        );
      } else {
        this.currentStore = "";

        this.categories = await categoryManager.getCategoryArrayList(
          this.customerID,
        );
      }
      await this.searchCourse();
    },
    toggleStore() {
      this.isShowStore = !this.isShowStore;
    },
    toCourseDetail(selected, storeID) {
      if (selected && storeID) {
        if (this.stores.length > 0) {
          // 店舗選択する場合はstoreIDを設定していないためここでセット
          this.storeID = storeID;
          const store = this.stores.filter((e) => e.id === storeID);
          let storeName = "";
          if (store.length > 0) {
            storeName = store[0].name;
          }
          this.$root.setStoreInfoAction(this.storeID, storeName);
          sessionStorage.storeID = this.storeID;
          sessionStorage.storeName = storeName;
        }
        this.$router.push(
          { name: "courseDetail", params: { id: selected } },
          () => {},
        );
      }
    },
    async setStore() {
      if (this.customerID) {
        this.stores = await auth.getStoreArrayList(this.customerID);
      }
    },
    async searchCourse() {
      let searchStore = "";
      if (this.isStore) {
        searchStore = this.storeID;
      } else {
        searchStore = this.currentStore;
      }
      try {
        this.courses = await courseManager.getCourseList(
          this.customerID,
          searchStore,
          this.currentCategory,
          this.viewHidden,
        );
        this.courseIndex = 8;
      } catch (error) {
        throw new Error(error);
      }
    },
    // アコーディオン制御用メソッド
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = 0;
    },
    addCourseIndex() {
      this.courseIndex += 8;
    },
  },
  computed: {
    // 表示用のカテゴリーの文字列を作成する
    displayCategory() {
      return function (categories) {
        return categories.reduce((a, b) => {
          return a + "・" + b;
        });
      };
    },
    hasCourses() {
      if (this.courses.length > 0) {
        return true;
      }
      return false;
    },
    filteredCourses() {
      return this.courses.filter(
        (course, index) => index + 1 <= this.courseIndex,
      );
    },
    isShowMoreButton() {
      if (this.courses.length <= this.courseIndex) {
        return false;
      }
      return true;
    },
    convertFromStoreIDToName() {
      return (storeID) => {
        if (this.isHeadquarter) {
          const index = this.storeList.map((e) => e.id).indexOf(storeID);
          return this.storeList[index].name;
        } else {
          return "";
        }
      };
    },
  },
  async created() {
    this.loading = true;
    try {
      const isStoreUser = await auth.isStore();
      this.isHeadquarter = await auth.isHeadquarters();
      if (isStoreUser) {
        this.customerID = await auth.getCustomerID(this.$root);
        this.storeID = await auth.getStoreID(this.$root);
        if (!this.customerID || !this.storeID) {
          // customerID、StoreIDが存在しない場合はエラー ToDo: admin の場合を検討
          throw new Error("Forbidden");
        }
        // カテゴリ一覧を取得
        this.categories = await categoryManager.getCategoriesForList(
          this.customerID,
          this.storeID,
        );
      } else {
        this.isStore = false;
        this.customerID = await auth.getCustomerID(this.$root);
        if (!this.customerID) {
          // 顧客IDが存在しない場合はエラー
          throw new Error("Forbidden");
        }

        this.storeID = "";
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";
        // 店舗一覧を表示
        await this.setStore();
        // 本部の場合は全てのカテゴリを表示
        this.categories = await categoryManager.getCategoryArrayList(
          this.customerID,
        );
      }
      // viewHidden権限の取得
      this.viewHidden = await auth.getViewHidden();
      // 全てにチェック
      this.filteringCourseByCategory(0);

      // 本部ユーザのときは、各レシピの店舗名を表示する
      if (this.isHeadquarter) {
        const storeArrayList = await auth.getStoreArrayList(this.customerID);
        storeArrayList.shift();
        this.storeList = storeArrayList;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    if (this.$route.params.deletedCourse !== undefined) {
      this.deletedCourse = this.$route.params.deletedCourse;
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.course-list {
  padding: 60px 10px 0;
}
.recipe-list-delete-msg {
  margin-bottom: 20px;
}
.course-list-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}
.course-list--content {
  margin-top: 20px;
}
.course-category-form {
  display: flex;
  transition: height 0.2s linear;
  overflow: auto;
}
.course-list--store-list {
  margin-top: 20px;
}
.course-list--menu-buttons {
  display: flex;
  justify-content: flex-start;
}
.course-list--content--card {
  margin-top: 10px;
}
.course-list--content {
  transition: height 0.3s ease-in-out;
}
.course-category-items {
  align-items: baseline;
  height: auto;
  display: flex;
}
.course-category-items >>> .r-radio--button {
  min-height: 38px;
}
.course-result-none {
  margin-top: 20px;
}
/**
  カテゴリー用のトランジション
  transitionタグのname属性に'category'を付与する
 */
.category-enter-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: category-opened;
}
.category-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: category-closed;
}

@keyframes category-opened {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes category-closed {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/** タブレット・PC */
@media screen and (min-width: 641px) {
  .course-list {
    padding-top: 100px;
  }
  .course-list-button-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
  }
  .course-list-filtering-wrapper {
    margin-top: 20px;
    display: block;
  }
  .margin-top {
    margin-top: 20px !important;
  }
  .course-list--category-button {
    font-size: 1rem;
    /* margin-right: 20px; */
  }
  .course-list--content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
  }
  .course-category-form {
    margin-top: 20px;
    margin-bottom: unset;
  }
  .course-category-items {
    padding-bottom: unset;
  }
  .course-list--menu-buttons {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .course-list--content--card {
    margin-right: 24px;
    box-sizing: border-box;
    width: 32%;
    margin-right: 2%;
  }
  .course-list--content--card:nth-child(3n) {
    margin-right: 0;
  }
  .course-list--see-more-button {
    margin-top: 20px;
  }
}
/** PC */
@media screen and (min-width: 1024px) {
  .course-list {
    padding: 60px 0px 0px;
    padding-top: 120px;
    width: 1024px;
    margin: auto;
  }
  .course-list--content--card {
    width: 238px;
    margin-top: 24px;
    box-sizing: border-box;
  }
  .course-list--content--card:nth-child(3n) {
    margin-right: 24px;
  }
  .course-list--content--card:nth-child(4n) {
    margin-right: 0;
  }
}
</style>

<template>
  <span class="r-tag" v-text="label" />
</template>

<script>
export default {
  name: "r-tag",
  props: {
    label: String,
  },
};
</script>

<style scoped>
.r-tag {
  background-color: #ffffff;
  border: 1px solid #e87352;
  border-radius: 8px;
  padding: 2px 0.5em;
  max-width: 100%;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

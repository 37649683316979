import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  writeBatch,
  serverTimestamp,
  orderBy,
  onSnapshot,
  startAt,
  getDoc,
} from "firebase/firestore";
import Vue from "vue";
import { db } from "@/libs";

export default {
  async getPriceUpdate(customerID, storeID) {
    let notifications = [];

    try {
      const q = query(
        collection(db, "priceUpdate"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
        where("check", "==", false),
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        await Promise.all(
          querySnapshot.docs.map(async (docSnapshot) => {
            try {
              const data = docSnapshot.data();
              data.priceUpdateID = docSnapshot.id;
              const recipeRef = data.upperRef;

              // レシピ情報を取得
              if (recipeRef) {
                const recipeDoc = await getDoc(recipeRef);
                let priceUpdate = {
                  id: data.id,
                  priceUpdateID: data.priceUpdateID,
                  name: data.name,
                  recipeID: data.recipeID,
                  itemID: data.itemID,
                  recipeName: "",
                  recipeData: {},
                  price: data.price,
                  pastPrice: data.pastPrice,
                  costRate: data.costRate,
                  pastCostRate: data.pastCostRate,
                  customerID: customerID,
                  storeID: storeID,
                };

                if (recipeDoc.exists()) {
                  const recipeData = recipeDoc.data();
                  priceUpdate.recipeName = recipeData.name;
                  priceUpdate.recipeData = recipeData;
                }
                notifications.push(priceUpdate);
              }
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error);
            }
          }),
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("getPriceUpdate error", error);
      throw new Error(error);
    }
    return notifications;
  },
  // 更新された場合のフック
  priceUpdateOnSnapshot(customerID, storeID, notifications) {
    const q = query(
      collection(db, "priceUpdate"),
      where("customerID", "==", customerID),
      where("storeID", "==", storeID),
      orderBy("updateAt", "asc"),
      startAt(new Date()),
    );

    onSnapshot(q, async (querySnapshot) => {
      await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          data.priceUpdateID = docSnapshot.id;
          let priceUpdate = {
            itemID: data.itemID,
            priceUpdateID: data.priceUpdateID,
            name: data.name,
            recipeID: data.recipeID,
            recipeName: "",
            recipeData: {},
            price: data.price,
            pastPrice: data.pastPrice,
            costRate: data.costRate,
            pastCostRate: data.pastCostRate,
            customerID: customerID,
            storeID: storeID,
          };
          // レシピ情報を取得
          const recipeRef = data.upperRef;
          if (recipeRef) {
            const recipeDoc = await getDoc(recipeRef);
            if (recipeDoc.exists()) {
              const recipeData = recipeDoc.data();
              priceUpdate.recipeName = recipeData.name;
              priceUpdate.recipeData = recipeData;
            }
          }

          if (data.check) {
            // 配列から該当データを削除
            for (let i = 0; i < notifications.length; i++) {
              let updateRecord = notifications[i];
              if (updateRecord.priceUpdateID === priceUpdate.priceUpdateID) {
                Vue.delete(notifications, i);
                break;
              }
            }
          } else {
            let idx = -1;
            for (let i = 0; i < notifications.length; i++) {
              let updateRecord = notifications[i];
              if (updateRecord.priceUpdateID === priceUpdate.priceUpdateID) {
                idx = i;
                break;
              }
            }
            if (idx < 0) {
              notifications.push(priceUpdate);
            } else {
              Vue.set(notifications, idx, priceUpdate);
            }
          }
        }),
      );
    });
  },

  async updateCheck(priceUpdateID) {
    const priceUpdateRef = doc(db, "priceUpdate", priceUpdateID);
    await updateDoc(priceUpdateRef, {
      check: true,
      updateAt: serverTimestamp(),
    });
  },

  async batchUpdate(customerID, storeID) {
    const batch = writeBatch(db);

    const q = query(
      collection(db, "priceUpdate"),
      where("customerID", "==", customerID),
      where("storeID", "==", storeID),
      where("check", "==", false),
    );

    const batchRef = await getDocs(q);

    batchRef.docs.forEach((docSnapshot) => {
      batch.update(docSnapshot.ref, {
        check: true,
        updateAt: serverTimestamp(),
      });
    });

    await batch.commit();
  },
};

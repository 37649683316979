var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"r-badge",class:[
    _vm.badgeSize ? 'r-badge-' + _vm.badgeSize : '',
    _vm.badgeStyle ? 'r-badge-' + _vm.badgeStyle : '',
    {
      'r-badge-shadow': _vm.shadow,
      'r-badge-outline': _vm.outline,
    },
  ],style:({
    'background-color': _vm.customBackground,
  })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <r-spinner v-if="loading"></r-spinner>
  <div v-else>
    <r-heading :level="1" :image="headingImage">{{
      $t("config.configTitle")
    }}</r-heading>
    <div class="config-list">
      <r-alert class="alert-message" v-if="alertMessage !== ''">{{
        alertMessage
      }}</r-alert>
      <div class="r-card--wrapper">
        <r-paper round class="config--store-select" v-if="!isStore">
          <span>{{ $t("config.selectStore") }}</span>
          <r-select
            v-if="existingValue !== '' && !selectDisable"
            :options="stores"
            @input="storeSelect"
            :existingValue="existingValue"
          />
        </r-paper>
        <div class="config-main">
          <!-- タブここから : iphone画面の場合-->
          <r-tabs v-if="!isWindowPC">
            <r-tab :label="$t('config.recipe')" v-show="userAuthority.editCat">
              <r-list>
                <r-list-content
                  v-for="(tabContent, index) in tabContents.recipe"
                  :key="index"
                  @click="toggleConfig(index)"
                  clickable
                  :class="{ active: selectValue === index }"
                >
                  <r-list-item>{{ tabContent }}</r-list-item>
                </r-list-content>
              </r-list>
            </r-tab>
            <r-tab :label="$t('config.user')" v-show="userAuthority.editUser">
              <r-list>
                <r-list-content
                  v-for="(tabContent, index) in tabContents.user"
                  :key="index"
                  @click="toggleConfig(index + tabContents.recipe.length)"
                  clickable
                  :class="{
                    active: selectValue === index + tabContents.recipe.length,
                  }"
                >
                  <r-list-item>{{ tabContent }}</r-list-item>
                </r-list-content>
              </r-list>
            </r-tab>
            <r-tab
              :label="$t('config.supplier')"
              v-show="userAuthority.selectSup"
            >
              <r-list>
                <r-list-content
                  v-for="(tabContent, index) in tabContents.customer"
                  :key="index"
                  @click="
                    toggleConfig(
                      index +
                        tabContents.recipe.length +
                        tabContents.user.length,
                    )
                  "
                  clickable
                  :class="{
                    active:
                      selectValue ===
                      index +
                        tabContents.recipe.length +
                        tabContents.user.length,
                  }"
                >
                  <r-list-item>{{ tabContent }}</r-list-item>
                </r-list-content>
              </r-list>
            </r-tab>
          </r-tabs>
          <!--PC画面-->
          <div class="config-main--left" v-if="isWindowPC">
            <!-- レシピ -->
            <r-tab-for-pc
              class="config-main--left--items"
              :label="$t('config.recipe')"
              v-if="userAuthority.editCat"
            >
              <r-list>
                <r-list-content
                  class="config-main--left--items--item"
                  :class="{ active: selectValue === 0 }"
                  @click="toggleConfig(0)"
                  clickable
                >
                  <r-list-item>{{ $t("config.configCategory") }}</r-list-item>
                </r-list-content>

                <r-list-content
                  class="config-main--left--items--item"
                  :class="{ active: selectValue === 1 }"
                  @click="toggleConfig(1)"
                  clickable
                >
                  <r-list-item>{{ $t("config.configUnit") }}</r-list-item>
                </r-list-content>
              </r-list>
            </r-tab-for-pc>

            <!-- ユーザー -->
            <r-tab-for-pc
              class="config-main--left--items"
              :label="$t('config.user')"
              v-if="userAuthority.editUser"
            >
              <r-list>
                <r-list-content
                  class="config-main--left--items--item"
                  :class="{ active: selectValue === 2 }"
                  @click="toggleConfigCreateUser(2)"
                  clickable
                >
                  <r-list-item>{{ $t("config.configCreateUser") }}</r-list-item>
                </r-list-content>

                <r-list-content
                  class="config-main--left--items--item"
                  :class="{ active: selectValue === 3 }"
                  @click="toggleConfig(3)"
                  clickable
                >
                  <r-list-item>{{ $t("config.configUpdateUser") }}</r-list-item>
                </r-list-content>

                <r-list-content
                  class="config-main--left--items--item"
                  :class="{ active: selectValue === 4 }"
                  @click="toggleConfig(4)"
                  clickable
                >
                  <r-list-item>{{
                    $t("config.configLoginTimeStamp")
                  }}</r-list-item>
                </r-list-content>
              </r-list>
            </r-tab-for-pc>

            <!-- 取引先 -->
            <r-tab-for-pc
              class="config-main--left--items"
              :label="$t('config.supplier')"
              v-if="userAuthority.selectSup"
            >
              <r-list>
                <r-list-content
                  class="config-main--left--items--item"
                  :class="{ active: selectValue === 5 }"
                  @click="toggleConfig(5)"
                  clickable
                >
                  <r-list-item>{{ $t("config.configSupplier") }}</r-list-item>
                </r-list-content>
              </r-list>
            </r-tab-for-pc>
          </div>

          <!-- タブここまで -->

          <!-- メインコンテンツ -->
          <div class="config--main-contents">
            <r-paper paperStyle="white" v-if="selectValue < 0">
              <div class="config-context"></div>
            </r-paper>
            <config-category-form v-if="selectValue === 0" />
            <config-unit-form v-if="selectValue === 1" />
            <!--新規ユーザ登録完了メッセージの追加-->
            <config-createuser-form
              v-if="selectValue === 2"
              @displayAlert="displayAlert"
            />
            <config-selectuser-form
              v-if="selectValue === 3"
              @edit="openEditUser"
            />
            <config-login-list v-if="selectValue === 4" />
            <config-supplier-form v-if="selectValue === 5" />
          </div>

          <!-- モーダルコンテンツ -->
          <transition name="modal">
            <div
              class="modal-bg"
              v-if="editUserFlag"
              @click.self="closeEditUser"
            >
              <config-edituser-form
                class="modal"
                :editUserID="editUserID"
                v-if="editUserFlag"
                @close="closeEditUser"
                @displayAlert="displayAlert"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigCategoryForm from "@/components/config/organisms/ConfigCategoryForm.vue";
import ConfigUnitForm from "@/components/config/organisms/ConfigUnitForm.vue";
import ConfigSupplierForm from "@/components/config/organisms/ConfigSupplierForm.vue";
import ConfigCreateuserForm from "@/components/config/organisms/ConfigCreateuserForm.vue";
import ConfigEdituserForm from "@/components/config/organisms/ConfigEdituserForm.vue";
import ConfigSelectuserForm from "@/components/config/organisms/ConfigSelectuserForm.vue";
import ConfigLoginList from "@/components/config/organisms/ConfigLoginList.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import RPaper from "@/plugins/atoms/RPaper.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RList from "@/plugins/atoms/RList.vue";
import RTabs from "@/plugins/atoms/RTabs.vue";
import RTab from "@/plugins/atoms/RTab.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RTabForPc from "@/plugins/atoms/RTabForPC.vue";
import RAlert from "@/plugins/atoms/RAlert.vue";

import auth from "@/libs/Auth.js";
import userManager from "@/libs/User.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  name: "config",
  components: {
    ConfigCategoryForm,
    ConfigUnitForm,
    ConfigSupplierForm,
    ConfigCreateuserForm,
    ConfigEdituserForm,
    ConfigSelectuserForm,
    ConfigLoginList,
    RListContent,
    RListItem,
    RPaper,
    RList,
    RTabs,
    RTab,
    RSelect,
    RHeading,
    RTabForPc,
    RAlert,
    RSpinner,
  },
  data() {
    return {
      tabContents: {
        recipe: [
          this.$i18n.t("config.configCategory"),
          this.$i18n.t("config.configUnit"),
        ],
        user: [
          this.$i18n.t("config.configCreateUser"),
          this.$i18n.t("config.configUpdateUser"),
          this.$i18n.t("config.configLoginTimeStamp"),
        ],
        customer: [this.$i18n.t("config.configSupplier")],
        userCount: 0,
      },
      userAuthority: {
        editCat: false,
        editUser: false,
        selectSup: false,
      },
      customerID: "",
      storeID: "",
      customers: {},
      stores: {},
      selectValue: -1,
      isAdmin: false,
      detacher: null,
      isWindowPC: false,
      editUserFlag: false,
      editUserID: "",
      isStore: true,
      headingImage: require("../../../assets/images/settingColor.png"),
      alertMessage: "",
      loading: false,
      existingValue: "",
      selectDisable: false,
      // test data
      isOverUserLimit: true,
    };
  },
  methods: {
    async storeSelect(id) {
      if (id) {
        //店舗を選択
        this.storeID = id;
        const storeName = Object.keys(this.stores).find(
          (key) => this.stores[key] === id,
        );
        this.$root.setStoreInfoAction(this.storeID, storeName);
        sessionStorage.storeID = this.storeID;
        //現在選択中の店舗名をsessionに格納
        sessionStorage.storeName = storeName;
        this.selectValue = -1;
        //本部ユーザの場合の制限
        this.getUserCount();
      } else {
        this.storeID = "";
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";
        this.selectValue = -1;
      }
    },
    toggleConfig(index) {
      if (this.storeID) {
        this.selectValue = index;
      } else {
        alert(this.$i18n.t("config.notSelected"));
        return;
      }
    },
    toggleConfigCreateUser(value) {
      if (!this.isStoreUser) {
        this.getUserCount();
      }
      if (this.userCount >= 5) {
        alert(this.$i18n.t("config.configCreateUserLimitReach"));
        return;
      }
      if (this.storeID) {
        this.selectValue = value;
      } else {
        alert(this.$i18n.t("config.notSelected"));
        return;
      }
    },
    async setStore() {
      if (this.customerID) {
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";
        this.stores = await auth.getStoreList(this.customerID);

        const storesKeys = Object.keys(this.stores).map(
          (key) => this.stores[key],
        );
        storesKeys.shift();
        this.existingValue = storesKeys[0];
      }
    },
    handleResize() {
      if (window.innerWidth <= 641) {
        this.isWindowPC = false;
      } else {
        this.isWindowPC = true;
      }
    },
    openEditUser(value) {
      this.editUserFlag = true;
      this.editUserID = value;
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".modal");
      disableBodyScroll(modal);
    },
    closeEditUser() {
      this.editUserFlag = false;
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    displayAlert(value) {
      this.closeEditUser();
      this.alertMessage = value;
    },
    async getUserCount() {
      const userList = await userManager.getUserList(
        this.customerID,
        this.storeID,
      );
      this.userCount = userList.length;
    },
  },
  async created() {
    try {
      const isStoreUser = await auth.isStore();
      this.loading = true;
      if (isStoreUser) {
        this.customerID = await auth.getCustomerID(this.$root);
        this.storeID = await auth.getStoreID(this.$root);

        const userList = await userManager.getUserList(
          this.customerID,
          this.storeID,
        );
        this.userCount = userList.length;
        if (!this.customerID || !this.storeID) {
          // customerID、StoreIDが存在しない場合はエラー ToDo: admin の場合を検討
          throw new Error("Forbidden");
        }
      } else {
        //本部・管理者の場合
        this.isStore = false;
        this.customerID = await auth.getCustomerID(this.$root);
        if (!this.customerID) {
          //顧客IDが存在しない場合はエラー
          throw new Error("Forbidden");
        }

        this.storeID = "";
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";

        // 店舗一覧を表示
        this.setStore();
      }

      // 権限の取得
      const editCat = await auth.getEditCat();
      this.$set(this.userAuthority, "editCat", editCat);

      const editUser = await auth.getEditUser();
      this.$set(this.userAuthority, "editUser", editUser);

      const selectSup = await auth.getSelectSup();
      this.$set(this.userAuthority, "selectSup", selectSup);

      this.loading = false;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    if (this.detacher) {
      this.detacher();
    }
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    selectValue: function () {
      this.alertMessage = "";
    },
  },
};
</script>

<style scoped>
.config-list {
  padding: 60px 20px 0;
}
.alert-message {
  margin-bottom: 20px;
}
.config-context {
  min-height: 30vh;
  background-image: url("~@/assets/images/logo4.png");
  background-size: auto 15vh;
  background-position: center center;
  background-repeat: no-repeat;
}
.config--store-select {
  align-content: center;
  text-align: left;
  margin-bottom: 20px;
}
.config--main-contents {
  margin-top: 20px;
}
.active {
  color: #e87352;
}
.modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.modal {
  width: 80%;
  height: 80%;
  overflow: auto;
}

@media screen and (min-width: 641px) {
  .config-list {
    padding-top: 100px;
  }
  .config-main {
    display: flex;
  }
  .config-main--left {
    width: 30%;
    margin-right: 20px;
  }
  .config--main-contents {
    width: 70%;
    margin-top: 0;
  }
  .config-main--left--items:not(:first-child) {
    margin-top: 20px;
  }
  .config-main--left--items--item {
    font-weight: bold;
  }
}

@media screen and (min-width: 1024px) {
  .config-list {
    padding-top: 120px;
    width: 1024px;
    margin: auto;
  }
  .config-main {
    display: flex;
  }
}
</style>

import { render, staticRenderFns } from "./MenuList.vue?vue&type=template&id=d7484308&scoped=true"
import script from "./MenuList.vue?vue&type=script&lang=js"
export * from "./MenuList.vue?vue&type=script&lang=js"
import style0 from "./MenuList.vue?vue&type=style&index=0&id=d7484308&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7484308",
  null
  
)

export default component.exports
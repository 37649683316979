<template>
  <div
    class="r-heading--wrapper"
    :class="{ 'r-heading--wrapper--1': level == 1 }"
  >
    <!-- 各ページのタイトル用 -->
    <h1 v-if="level === 1" class="r-heading r-heading-1">
      <div
        class="r-heading-1--image"
        :style="{ 'background-image': `url(${image})` }"
      ></div>
      <slot></slot>
    </h1>

    <h2 v-else-if="level === 2" class="r-heading r-heading-2">
      <slot></slot>
    </h2>

    <h4 v-else-if="level === 4" class="r-heading r-heading-4">
      <slot></slot>
    </h4>
  </div>
</template>

<script>
export default {
  name: "r-heading",
  props: {
    level: Number,
    image: String,
  },
};
</script>

<style scoped>
.r-heading {
  width: 100%;
  padding-left: 5px;
  box-sizing: border-box;
  margin: 0;
}
.r-heading-1 {
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 900;
  color: #e87352;
  padding: 10px;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 16vh;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.r-heading-2 {
  background-color: #ffffff;
  border-left: 5px solid #e87352;
  text-align: left;
  padding: 10px;
  font-size: 1rem;
}

.r-heading-4 {
  text-align: left;
  color: #676767;
  margin-bottom: 6px;
}
.r-heading-4:before {
  content: "\0025b6\0020"; /*&#x25b6;*/
  color: #e87352;
}
.r-heading-1--image {
  width: 25px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  background-position: center;
}
@media screen and (min-width: 641px) {
  .r-heading-1 {
    top: 100px;
    height: 80px;
    font-size: 28px;
  }
  .r-heading-1--image {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .r-heading-1 {
    top: 80px;
    height: 100px;
  }
  .r-heading-1--image {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
}
</style>

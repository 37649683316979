<template>
  <div class="analysis-note">
    <r-heading :level="1" :image="headingImage">
      {{ $t("analysis.analysisTitle") }}</r-heading
    >
    <r-spinner v-if="loading"></r-spinner>
    <template v-else>
      <!-- 店舗選択 -->
      <r-paper
        round
        class="recipe-registration--store-select"
        v-if="isHeadquarters"
      >
        <span>{{ $t("recipe.recipeTop.selectStore") }}</span>
        <r-select
          v-if="existingValue !== '' && !selectDisable"
          :options="stores"
          @input="storeSelect"
          :existingValue="existingValue"
        />
      </r-paper>
      <div class="analysis-note--content analysis-note--top-buttons-wrapper">
        <div class="analysis-note--content analysis-note-category-button">
          <r-button buttonStyle="primary" outline @click="toggleCategory">{{
            $t("analysis.selectCategory")
          }}</r-button>
        </div>
        <div class="analysis-note--top-buttons--right">
          <!-- 期間入力フォーム -->
          <div
            class="analysis-note--date analysis-note--content period-ctrl-area"
          >
            <div class="analysis-note--date--select--area">
              <div v-if="!isMobile" class="period">
                <span class="period-sentence"
                  >{{ $t("analysis.period") }} :</span
                >
              </div>
              <datepicker
                class="analysis-note--date--item analysis-note--date--datepicker"
                :value="startDate"
                @selected="inputStartDate"
                :language="ja"
                :format="dateFormat"
                :disabled-dates="disableDates"
                input-class="datepicker"
              ></datepicker>
              <span class="analysis-note--date--item-wave-dash">〜</span>
              <datepicker
                class="analysis-note--date--item analysis-note--date--datepicker"
                :value="endDate"
                @selected="inputEndDate"
                :language="ja"
                :format="dateFormat"
                :disabled-dates="disableDates"
                input-class="datepicker"
              ></datepicker>
            </div>
            <r-button
              buttonStyle="secondary"
              small
              class="analysis-note--date--item-search-btn"
              @click="searchRegiData('term')"
              >{{ $t("analysis.search") }}</r-button
            >
          </div>

          <!-- ページ切り替えボタン -->
          <r-button
            class="analysis-note--content detail-standard-btn"
            buttonStyle="primary"
            outline
            :full="isMobile"
            v-if="showingList !== 'detail'"
            @click="toggleShowingList('detail')"
            >{{ $t("analysis.detailData") }}</r-button
          >
          <r-button
            class="analysis-note--content detail-standard-btn"
            buttonStyle="primary"
            outline
            :full="isMobile"
            v-if="showingList !== 'basic'"
            @click="toggleShowingList('basic')"
            >{{ $t("analysis.standardData") }}</r-button
          >
        </div>
      </div>

      <!-- カテゴリー選択ボタン -->
      <transition
        name="category"
        @before-enter="beforeEnter"
        @enter="enter"
        @before-leave="beforeLeave"
        @leave="leave"
      >
        <r-paper
          class="analysis-note--content analysis-note--category"
          round
          v-show="isShowCategory"
        >
          <r-radio
            :options="categories"
            @click="toggleCategoryOption"
            ref="radio"
            class="analysis-note--categories-area"
          />
          <div class="analysis-note--btn-area">
            <r-button
              buttonStyle="primary"
              outline
              @click="searchRegiData('reset')"
              class="analysis-note--btn"
              >{{ $t("analysis.reset") }}</r-button
            >
            <r-button
              buttonStyle="secondary"
              @click="searchRegiData('cate')"
              class="analysis-note--btn"
              >{{ $t("analysis.search") }}</r-button
            >
          </div>
        </r-paper>
      </transition>
      <!-- カテゴリー検索ボタンここまで -->

      <r-paper class="analysis-note--main analysis-note--content" round>
        <div class="detailed-data-list--header">
          <span class="detailed-data-list--header--date">
            {{ startDate | replaceToSlash }}
            ~
            {{ endDate | replaceToSlash }}
          </span>
          <span v-if="showingList === 'detail'">
            （
            <b class="detailed-data-list--header--ast">*</b>
            {{ $t("analysis.inCaseOfMultiResult") }}
          </span>
        </div>

        <r-spinner v-if="searching" />
        <template v-else>
          <div v-if="data.length === 0" class="detailed-data--no-regi-data">
            {{ $t("analysis.noRegiData") }}
          </div>
          <template v-else>
            <!-- 標準データここから -->
            <div
              class="analysis-note--out-number-list--wrapper"
              v-if="showingList === 'basic'"
            >
              <out-number-list :listData="data" :width="width" />

              <r-paper
                class="analysis-note--out-number-list--summary--wrapper analysis-note--content"
                paperStyle="gray"
                round
              >
                <div class="analysis-note--out-number-list--summary">
                  <span
                    class="analysis-note--out-number-list--summary--heading"
                    >{{ $t("analysis.outNumberList") }}</span
                  >
                  <span
                    class="analysis-note--out-number-list--summary--value"
                    >{{ totalOutNumber | localNumber }}</span
                  >
                </div>

                <div class="analysis-note--out-number-list--summary">
                  <span
                    class="analysis-note--out-number-list--summary--heading"
                    >{{ $t("analysis.totalSalesAmount") }}</span
                  >
                  <span class="analysis-note--out-number-list--summary--value"
                    >{{ totalSales | localNumber
                    }}{{ $t("common.currencyYen") }}</span
                  >
                </div>

                <div class="analysis-note--out-number-list--summary">
                  <span
                    class="analysis-note--out-number-list--summary--heading"
                    >{{ $t("analysis.totalProfitAmount") }}</span
                  >
                  <span class="analysis-note--out-number-list--summary--value"
                    >{{ totalGrossProfit | localNumber
                    }}{{ $t("common.currencyYen") }}</span
                  >
                </div>
                <div class="analysis-note--out-number-list--summary">
                  <span
                    class="analysis-note--out-number-list--summary--heading"
                    >{{ $t("analysis.totalCostRateSelectingPeriod") }}</span
                  >
                  <span class="analysis-note--out-number-list--summary--value"
                    >{{ duringThePeriodCost | localNumber }}%</span
                  >
                </div>
              </r-paper>
            </div>
            <!-- 標準データここまで -->

            <!-- 詳細データここから -->
            <div v-if="showingList === 'detail'">
              <detailed-data-list :displayValue="data" />
            </div>
            <!-- 詳細データここまで -->
          </template>
        </template>
      </r-paper>
    </template>
  </div>
</template>

<script>
import RHeading from "@/plugins/atoms/RHeading.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RRadio from "@/plugins/atoms/RRadio.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import OutNumberList from "@/components/analysisNotes/organisms/OutNumberList.vue";
import DetailedDataList from "@/components/analysisNotes/organisms/DetailedDataList.vue";
import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";

import auth from "@/libs/Auth.js";
import categoryManager from "@/libs/Category.js";
import analysis from "@/libs/Analysis.js";

// 日付変換用
import { UAParser } from "ua-parser-js";

const defaultDate = new Date();

export default {
  components: {
    RHeading,
    RPaper,
    RButton,
    RRadio,
    RSpinner,
    RSelect,
    OutNumberList,
    DetailedDataList,
    Datepicker,
  },
  data() {
    return {
      width: 0,
      data: [],
      // 検索用
      startDate: "",
      endDate: "",
      // disableの範囲用
      minDate: "",
      maxDate: "",
      totalOutNumber: 0,
      totalSales: 0,
      totalGrossProfit: 0,
      duringThePeriodCost: 0,
      currentCategory: [],
      // 表示切り替え用変数
      showingList: "basic",
      isShowCategory: false,
      // カテゴリー用配列
      categories: [],
      // ユーザー情報
      isHeadquarters: false,
      customerID: "",
      storeID: "",
      // 見出し画像用変数
      headingImage: require("../../../assets/images/graphColor.svg"),
      loading: false,
      stores: [],
      existingValue: "",
      selectDisable: false,
      browserType: "",
      searching: false,

      // datepickerの設定
      ja: ja,
      dateFormat: "yyyy-MM-dd",
      disableDates: {
        to: "",
        from: "",
      },
    };
  },
  methods: {
    toggleCategory() {
      // カテゴリ
      this.isShowCategory = !this.isShowCategory;
    },
    toggleShowingList(index) {
      this.showingList = index;
    },
    toggleCategoryOption(index) {
      this.currentCategory = [];

      // 「すべて」の場合
      if (index === 0) {
        return;
      }
      this.currentCategory.push(this.categories[index].name);
    },
    async setStore() {
      this.$root.clearStoreInfoAction();
      sessionStorage.storeID = "";
      sessionStorage.storeName = "";
      this.stores = await auth.getStoreList(this.customerID);

      const storesKeys = Object.keys(this.stores).map(
        (key) => this.stores[key],
      );
      storesKeys.shift();
      this.existingValue = storesKeys[0];
    },
    storeSelect(id) {
      if (id) {
        this.storeID = id;
        const storeName = Object.keys(this.stores).find(
          (key) => this.stores[key] === id,
        );
        this.$root.setStoreInfoAction(this.storeID, storeName);
        sessionStorage.storeID = this.storeID;

        this.searchRegiData();
      } else {
        this.storeID = "";
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";
      }
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    // アコーディオン制御用メソッド
    beforeEnter(el) {
      el.style.height = 0;
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = 0;
    },
    makeyyyymmdd(value) {
      if (this.browserType === "Safari" || this.browserType === "IE") {
        return value;
      }
      const objDate = new Date(value);
      const month = ("0" + (objDate.getMonth() + 1)).slice(-2);
      const date = ("0" + objDate.getDate()).slice(-2);
      return `${objDate.getFullYear()}-${month}-${date}`;
    },
    async searchRegiData(mode) {
      this.searching = true;

      if (this.startDate === "") {
        const date = this.makeyyyymmdd(defaultDate);
        this.startDate = date;
        this.endDate = date;
      }
      try {
        if (this.isHeadquarters) {
          // 本部ユーザで店舗を切り替えたときの処理
          if (mode !== "term" && mode !== "cate") {
            this.categories = await categoryManager.getCategoriesForList(
              this.customerID,
              this.storeID,
            );

            this.toggleCategoryOption(0);
          }
        }

        let analysisData = [];

        // リセット押下の場合
        if (mode === "reset") {
          this.$refs.radio.current = 0;
          this.toggleCategoryOption(0);
          analysisData = await analysis.getRegiData(
            this.customerID,
            this.storeID,
            this.startDate,
            this.endDate,
            this.currentCategory,
          );
        } else {
          analysisData = await analysis.getRegiData(
            this.customerID,
            this.storeID,
            this.startDate,
            this.endDate,
            this.currentCategory,
          );
        }

        this.data = analysisData.map((doc) => {
          const data = {
            recipe: doc.title,
            outNumber: doc.salesVol,
            price: doc.sellPrice,
            cost: doc.cost,
            multi: doc.multi,
            rank: doc.rank,
            totalRatio: doc.totalRatio,
          };
          return data;
        });

        // 合計値算出
        const calcSum = analysis.calcTotalValue(analysisData);
        this.totalOutNumber = calcSum.totalSalesVol;
        this.totalSales = calcSum.totalSalesPrice;
        this.totalGrossProfit = calcSum.totalGain;
        this.duringThePeriodCost = calcSum.totalCostRate;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw new Error(error);
      }
      this.searching = false;
    },
    inputStartDate(val) {
      const month = ("0" + (val.getMonth() + 1)).slice(-2);
      const date = ("0" + val.getDate()).slice(-2);
      if (this.browserType === "Safari" || this.browserType === "IE") {
        this.startDate = `${val.getFullYear()}/${month}/${date}`;
      } else {
        this.startDate = `${val.getFullYear()}-${month}-${date}`;
      }
    },
    inputEndDate(val) {
      const month = ("0" + (val.getMonth() + 1)).slice(-2);
      const date = ("0" + val.getDate()).slice(-2);
      if (this.browserType === "Safari" || this.browserType === "IE") {
        this.endDate = `${val.getFullYear()}/${month}/${date}`;
      } else {
        this.endDate = `${val.getFullYear()}-${month}-${date}`;
      }
    },
  },
  computed: {
    isMobile() {
      return this.width <= 641;
    },
  },
  filters: {
    replaceToSlash(val) {
      const reg = /-/gi;
      return val.replace(reg, "/");
    },
    localNumber(val) {
      if (Number.isNaN(val)) {
        return 0;
      } else {
        return parseFloat(val.toFixed(2));
      }
    },
  },
  async created() {
    this.loading = true;

    const parser = new UAParser();
    this.browserType = parser.getBrowser().name;

    try {
      this.isHeadquarters = await auth.isHeadquarters();
      this.customerID = await auth.getCustomerID(this.$root);
      if (this.isHeadquarters) {
        if (!this.customerID) {
          // 顧客IDが存在しない場合はエラー
          throw new Error("Forbidden");
        }
        // 日付による絞り込みの条件を設定
        const objDate = new Date();
        const month = ("0" + (objDate.getMonth() + 1)).slice(-2);
        const date = ("0" + objDate.getDate()).slice(-2);
        this.startDate = `${objDate.getFullYear()}/${month}/${date}`;
        this.endDate = `${objDate.getFullYear()}/${month}/${date}`;
        // 店舗一覧を表示
        this.setStore();
      } else {
        this.storeID = await auth.getStoreID(this.$root);
        if (!this.customerID || !this.storeID) {
          // customerID、StoreIDが存在しない場合はエラー
          throw new Error("Forbidden");
        }
        // カテゴリ一覧を種畜
        this.categories = await categoryManager.getCategoriesForList(
          this.customerID,
          this.storeID,
        );
        // 全てにチェック
        this.toggleCategoryOption(0);

        // 日付の最小値と最大値を取得する
        const minMaxDate = await analysis.getMinMaxDate(
          this.customerID,
          this.storeID,
        );

        // 日付による絞り込みの条件を設定
        this.startDate = this.makeyyyymmdd(minMaxDate.max);
        this.endDate = this.makeyyyymmdd(minMaxDate.max);
        this.minDate = this.makeyyyymmdd(minMaxDate.max);
        this.maxDate = this.makeyyyymmdd(minMaxDate.max);
        // 押せない範囲を設定
        this.disableDates.to = new Date(minMaxDate.min);
        this.disableDates.from = new Date(minMaxDate.max);
        // 押せない範囲を設定
        this.disableDates.to = new Date(minMaxDate.min);
        this.disableDates.to.setDate(this.disableDates.to.getDate() + -1);
        this.disableDates.from = new Date(minMaxDate.max);
        this.disableDates.from.setDate(this.disableDates.from.getDate() + 1);

        // 分析ノート検索
        const analysisData = await analysis.getRegiData(
          this.customerID,
          this.storeID,
          this.startDate,
          this.endDate,
          [], // カテゴリArray
        );

        this.data = analysisData.map((doc) => {
          const data = {
            recipe: doc.title,
            outNumber: doc.salesVol,
            price: doc.sellPrice,
            cost: doc.cost,
            multi: doc.multi,
            rank: doc.rank,
            totalRatio: doc.totalRatio,
          };
          return data;
        });

        // 合計値算出
        const calcSum = analysis.calcTotalValue(analysisData);
        this.totalOutNumber = calcSum.totalSalesVol;
        this.totalSales = calcSum.totalSalesPrice;
        this.totalGrossProfit = calcSum.totalGain;
        this.duringThePeriodCost = calcSum.totalCostRate;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.loading = false;
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.selectedFoodstuffs = this.existingFoodstuffs;
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.analysis-note {
  padding: 60px 20px 0;
  box-sizing: border-box;
}
.recipe-registration--store-select {
  align-content: center;
  text-align: left;
}
.analysis-note-category-button {
  display: flex;
  justify-content: flex-start;
}
.analysis-note--date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  vertical-align: baseline;
}
.analysis-note--date--select--area {
  display: flex;
  margin-right: 20px;
  position: relative;
  align-items: center;
}
/** リストのタイトル */
.detailed-data-list--header {
  text-align: left;
  border-bottom: 2px solid #e87352;
  box-sizing: border-box;
  padding: 15px;
}
.detailed-data-list--header--date {
  font-size: 18px;
}
.detailed-data-list--header--ast {
  color: #e87352;
}
.detailed-data--no-regi-data {
  margin-top: 35px;
  min-height: 5vh;
}
.analysis-note--content {
  margin-top: 20px;
}
.analysis-note--date--datepicker >>> .datepicker {
  border-radius: 4px;
  border: 2px solid #e6e6e6;
  padding: 8px;
  font-size: 1.2rem;
  width: 100%;
}
.period-ctrl-area {
  display: flex;
}
.analysis-note--date--item-search-btn {
  margin-right: unset;
  width: 80px !important;
}
.analysis-note--date--item-wave-dash {
  display: flex;
  vertical-align: center;
  margin: auto;
  padding: 0px 5px 0px 5px;
}
.analysis-note--category {
  display: grid;
  height: auto !important;
}
.analysis-note--categories-area {
  display: flex;
  height: auto !important;
}
.analysis-note--btn-area {
  display: flex;
  margin-top: 14px;
  justify-content: space-between;
}
.analysis-note--btn {
  width: 80px;
}
.analysis-note--out-number-list--summary--wrapper {
  display: flex;
  flex-direction: column;
}
/** 標準データ下部の総売上など  */
.analysis-note--out-number-list--summary {
  display: flex;
  flex-direction: column;
}
.analysis-note--out-number-list--summary--heading {
  font-weight: bold;
}
.analysis-note--out-number-list--summary--value {
  font-weight: bold;
  font-size: 28px;
}

.analysis-note--category {
  transition: height 0.3s ease-in-out;
}
/**
  カテゴリー用のトランジション
  transitionタグのname属性に'category'を付与する
 */
.category-enter-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: category-opened;
}
.category-leave-active {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: category-closed;
}

@keyframes category-opened {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes category-closed {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (min-width: 641px) {
  .analysis-note {
    padding-top: 100px;
  }
  .analysis-note--out-number-list--summary--wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
  .analysis-note--out-number-list--summary {
    width: 25%;
  }
  .analysis-note--out-number-list--summary--heading {
    display: inline-block;
  }
  .analysis-note--out-number-list--summary--value {
    display: inline-block;
  }
  /** カテゴリーとか検索フォームとかのラッパー */
  .analysis-note--top-buttons-wrapper {
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  .analysis-note-category-button {
    margin-bottom: 20px;
  }
  .analysis-note-category-button >>> .r-button {
    min-height: 40px;
  }
  .analysis-note--top-buttons--right {
    display: flex;
    justify-content: flex-end;
  }
  .period-ctrl-area {
    margin-top: unset;
  }
  .detail-standard-btn {
    width: 100px;
  }
  .period {
    margin: auto;
    margin-right: 10px;
  }
  .period-sentence {
    margin: 0 auto;
    position: relative;
    bottom: -3px;
  }
  .analysis-note--date--item-search-btn {
    margin-right: 20px;
    width: 80px !important;
  }
  .analysis-note--date--item-wave-dash {
    display: flex;
    vertical-align: center;
    margin: auto;
    padding: 0px 5px 0px 5px;
  }
  .analysis-note--category {
    display: grid;
    height: auto !important;
  }
  .analysis-note--categories-area {
    display: flex;
    height: auto !important;
  }
  .analysis-note--btn-area {
    display: flex;
    margin-top: 14px;
    justify-content: flex-end;
  }
  .analysis-note--btn {
    width: 80px;
    margin-left: 20px;
  }
  .detail-standard-btn {
    margin-top: unset;
    min-height: 40px;
  }

  /************************************ */
}
@media screen and (min-width: 1024px) {
  .analysis-note {
    width: 1024px;
    margin: auto;
    padding-top: 120px;
  }
  .analysis-note-category-button {
    margin-bottom: unset;
  }
  .analysis-note--top-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }
  .analysis-note-category-button {
    margin-top: unset;
  }
  .analysis-note-category-button >>> .r-button {
    min-height: 40px;
  }
}
</style>

<template>
  <div class="select-customer-main">
    <r-paper class="select-customer-main--paper" round>
      <div class="select-customer-main--title">
        <span>{{ $t("auth.selectCustomer") }}</span>
      </div>
      <span
        class="select-customer-main--content select-customer-main--caption"
        >{{ $t("auth.selectCustomerName") }}</span
      >
      <div class="select-customer-main--content">
        <r-select :options="customers" v-model="customerID" />
      </div>
      <r-button
        class="select-customer-main--content"
        buttonStyle="primary"
        full
        @click="selectedCustomer"
        >{{ $t("common.select") }}</r-button
      >
    </r-paper>
  </div>
</template>

<script>
import RPaper from "@/plugins/atoms/RPaper.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import auth from "@/libs/Auth.js";

export default {
  name: "select-customer",
  components: {
    RPaper,
    RSelect,
    RButton,
  },
  data() {
    return {
      customers: {},
      customerID: "",
    };
  },
  methods: {
    async selectedCustomer() {
      try {
        // ToDo: 入力チェック
        if (!this.customerID) {
          // 未選択エラー
          alert("顧客名称を選択してください。");
          return;
        }
        const customerName = Object.keys(this.customers).filter((key) => {
          return this.customers[key] === this.customerID;
        })[0];

        // 顧客情報を取得
        const customerInfo = await auth.getCustomerInfo(this.customerID);
        this.$root.setCustomerInfoAction(
          this.customerID,
          customerName,
          customerInfo.level5,
          customerInfo.allergyTwenty,
        );
        sessionStorage.customerID = this.customerID;
        sessionStorage.companyName = this.customerName;
        this.$router.push({ name: "recipe" }, () => {});
      } catch (e) {
        alert("select-customer error: " + e.message);
      }
    },
  },
  async created() {
    // 管理者権限以外の場合は、エラー
    const isAdmin = await auth.isAdmin();
    if (!isAdmin) {
      throw new Error("Forbidden");
    }
    // 顧客一覧を取得
    this.customers = await auth.getCustomerList();
    sessionStorage.customerID = "";
    sessionStorage.companyName = "";
    sessionStorage.storeID = "";
    sessionStorage.storeName = "";
  },
};
</script>

<style scoped>
.select-customer-main {
  width: 100%;
  min-height: 65vh;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.select-customer-main--paper {
  width: 100%;
}
.select-customer-main--title {
  text-align: left;
  padding: 10px;
  font-weight: bold;
  border-bottom: 2px solid #e87352;
}
.select-customer-main--content {
  margin-top: 20px;
}
.select-customer-main--caption {
  display: block;
  color: #686868;
  font-size: 0.8rem;
  text-align: left;
}
</style>

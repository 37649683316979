<template>
  <div class="r-tab--wrapper">
    <div class="r-tab">
      <ul class="r-tab--ul">
        <li
          class="r-tab--li"
          v-for="(child, index) in children"
          :key="index"
          :class="{ 'r-tab--li__active': index === childActive }"
        >
          <button
            class="r-tab--button"
            v-bind="child.attrs"
            type="button"
            @click="activeChild(index)"
            v-on="child.listeners"
          >
            <span v-html="child.label"></span>
          </button>
        </li>
      </ul>
    </div>
    <div class="r-tabs--slot">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "r-tabs",
  data() {
    return {
      children: [],
      childActive: 0,
    };
  },
  methods: {
    activeChild(index) {
      this.$children.forEach((item, item_index) => {
        if (item_index !== index) {
          item.active = false;
        } else {
          item.active = true;
        }
      });
      // this.$children[index].active = true
      this.childActive = index;
    },
  },
  mounted() {
    this.activeChild(this.childActive);
  },
};
</script>

<style scoped>
.r-tab--wrapper {
  width: 100%;
}
.r-tab {
  --primary-color: #e87352;
}
.r-tab--ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}
.r-tab--li {
  width: 100%;
  height: 100%;
}
.r-tab--button {
  background-color: #ffffff;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  border-bottom: 2px solid #e87352;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
}
.r-tab--button:hover {
  cursor: pointer;
}
.r-tab--button:focus {
  outline: 0;
}
.r-tab--li__active > .r-tab--button {
  background-color: #e87352;
  color: #ffffff;
}
.r-tab--line {
  background: #e87352;
}
</style>

<template>
  <div class="recipe-alter-food-form" @click.self="cancel">
    <r-modal
      class="recipe-alter-food-form--modal"
      :class="
        !isMobile && !conversionQuantityInputCheck && current === 1
          ? ' not-height-auto'
          : ''
      "
      :title="title"
      :enter="
        (!isMobile && inputCheck && current === 0) ||
        (!isMobile && conversionQuantityInputCheck && current === 1)
      "
      :cancelHidden="isMobile"
      @enter="enter"
      @cancel="cancel"
    >
      <div v-if="current === 0" class="recipe-alter-food--select-food">
        <!-- ラジオボタンチックなボタンと選択している食材 -->
        <div class="recipe-alter-food-form--modal--buttons--wrapper">
          <div
            class="recipe-alter-food-form--modal--content recipe-alter-food-form--modal--buttons"
            v-for="(existingFoodStuff, index) in existingFoodStuffs"
            :key="`r1-${index}`"
          >
            <div
              class="recipe-alter-food-form--modal--button"
              :class="{
                'recipe-alter-food-form--modal--button--selected':
                  selectedIndex === index,
              }"
              @click="toggleSelectedAlterFood(index)"
            >
              {{ existingFoodStuff.name }}
            </div>

            <div
              class="recipe-alter-food-form--modal--selected"
              v-if="selectedItems[index].name !== ''"
            >
              <div class="recipe-alter-food-form--modal--selected-item">
                <!-- 「 > 」アイコン-->
                <div class="recipe-alter-food-form--modal--selected-item-icon">
                  <font-awesome-icon
                    :icon="['fas', 'chevron-right']"
                  ></font-awesome-icon>
                </div>
                <div
                  class="recipe-alter-food-form--modal--selected-item-content"
                >
                  <!-- 切り替え対象食材情報-->
                  <div
                    class="recipe-alter-food-form--modal--selected-item--name"
                  >
                    {{ selectedItems[index].name }}
                  </div>
                  <div>{{ selectedItems[index].supplier }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <r-hr
          class="recipe-alter-food-form--modal--item"
          hrStyle="gray"
          v-if="isMobile"
        ></r-hr>

        <r-spinner v-if="loading"></r-spinner>
        <!-- 食材リスト -->
        <div class="recipe-alter-food-form--modal--food-list" v-else>
          <template v-if="items[selectedIndex].length > 0">
            <div
              class="recipe-alter-food-form--modal--item recipe-alter-food-form--modal--food-list--item"
              v-for="(item, index) in items[selectedIndex]"
              :key="`r2-${index}`"
            >
              <r-food-stuff-card
                v-if="!Object.keys(items).includes(item.ingredientID)"
                :label="item.name"
                :tag="item.ss"
                :caption="`${item.supplier}`"
                :caption2="`${togglePrice(item)}円`"
                @click="assignItem(index)"
              />
            </div>
          </template>
          <!--代替食材が０件-->
          <div v-else class="recipe-alter-food--not-existed">
            {{ $t("recipe.recipeEdit.alterFoodstuffNotExist") }}
          </div>
        </div>
        <!-- 食材リストここまで -->
      </div>
      <!-- 原価計算ここから -->
      <div
        v-else-if="current === 1"
        v-for="(selectedItem, index) in selectedItems"
        :key="index"
        class="calcCost--container"
      >
        <div class="calcCost--item calcCost--name">
          <div>{{ selectedItem.name }}</div>
          <div>{{ selectedItem.supplier }}</div>
        </div>

        <div class="calcCost--item calcCost--standard">
          <div>
            [{{ $t("recipe.recipeCost.standard") }}×{{
              $t("recipe.recipeCost.quantity")
            }}]{{ selectedItem.standard }} ×︎ {{ selectedItem.quantity
            }}{{ selectedItem.quantityUnit }}
          </div>
          <div>
            <!--単価０ではない通常の食材-->
            <div v-if="isZeroPriceItem[index]">
              <!--selectedItem.priceの条件により金額入力時入力欄が消える-->
              <div v-if="selectedItem.price !== 0 && selectedItem.unitAmount">
                [{{ $t("recipe.recipeCost.price") }}]{{ $t("common.currency")
                }}{{ selectedItem.price }}
              </div>
              <div
                v-else
                class="recipeCost-inputForm"
                v-show="!orderDataLoading[index]"
              >
                <span class="recipe-cost-price"
                  >[{{ $t("recipe.recipeCost.price") }}]{{
                    $t("common.currency")
                  }}</span
                >
                <r-textbox
                  class="recipe-cost-price input-orderingData"
                  type="number"
                  :placeholder="$t('recipe.recipeCost.getOrderingData')"
                  v-model.number="selectedItem.price"
                />
                <!--発注データ参考ボタン-->
                <r-button
                  class="get-ordering-data-btn"
                  v-if="selectedItem.price <= 0"
                  buttonStyle="secondary"
                  @click="getOrderingData(index, selectedItem.id)"
                  >{{ $t("recipe.recipeCost.getOrderingData") }}</r-button
                >
              </div>
              <r-spinner v-show="orderDataLoading[index]" harfSize></r-spinner>
            </div>
            <!--単価０ではない通常の食材-->
            <div v-else>
              <div>
                [{{ $t("recipe.recipeCost.price") }}]{{ $t("common.currency") }}
                {{ selectedItem.price }}
              </div>
            </div>
          </div>
        </div>

        <div class="calcCost--item calcCost--wrapper">
          <r-paper
            class="calcCost--item calcCost--input"
            paperStyle="gray"
            round
          >
            <span class="calcCost--label">
              {{ $t("recipe.recipeCost.totalAmountOf") }}"{{
                selectedItem.unit
              }}"{{ $t("recipe.recipeCost.converseAmount") }}
            </span>
            <div class="calcCost--input">
              <r-textbox
                :disabled="selectedItem.price <= 0"
                type="number"
                v-model.number="conversionQuantity[index]"
              />
            </div>
          </r-paper>

          <span class="calcCost--item calc-arror-icon calcCost-result">
            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
            ></font-awesome-icon>
          </span>

          <div class="calcCost--item calcCost-result">
            <span class="calcCost--label">
              {{ existingFoodStuffs[index].amount }}{{ selectedItem.unit
              }}{{ $t("recipe.recipeCost.costOf") }}
            </span>
            <div class="calcCost--input">
              <div>
                {{ cost(selectedItem.price, selectedItem.amount, index) }}
              </div>
              {{ $t("common.currencyYen") }}
            </div>
          </div>
        </div>
      </div>
    </r-modal>

    <div class="recipe-alter-food-form--buttons" v-if="isMobile">
      <r-button
        @click="enter"
        v-if="
          (isMobile && inputCheck && current === 0) ||
          (isMobile && conversionQuantityInputCheck && current === 1)
        "
        buttonStyle="primary"
        full
        class="recipe-alter-food-form--decide-btn"
        >{{ $t("common.decide") }}</r-button
      >
      <r-button @click="cancel" buttonStyle="primary" full outline>
        {{ $t("common.cancel") }}
      </r-button>
    </div>
  </div>
</template>

<script>
import RButton from "@/plugins/atoms/RButton.vue";

import RModal from "@/plugins/molecules/RModal.vue";
import RFoodStuffCard from "@/plugins/molecules/RFoodStuffCard.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import supplierManager from "@/libs/Supplier.js";
import recipeManager from "@/libs/Recipe.js";
import itemManager from "@/libs/Item.js";

export default {
  components: {
    RButton,
    RModal,
    RFoodStuffCard,
    RPaper,
    RTextbox,
    RHr,
    RSpinner,
  },
  props: {
    existingFoodStuffs: Array,
    costRate: Number,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      storeSuppliers: [],
      current: 0,
      conversionQuantity: [],
      selectedIndex: 0,
      items: [], // 2次元配列
      selectedItems: [],
      loading: false,
      width: 0,
      orderDataLoading: [],
      isZeroPriceItem: [],
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    title() {
      if (this.current === 0) {
        return this.$i18n.t("recipe.recipeCost.recipeAlterFoodFormTitle1");
      } else if (this.current === 1) {
        return this.$i18n.t("recipe.recipeCost.recipeAlterFoodFormTitle2");
      }
    },
    cost() {
      return (price, amount, index) => {
        let cost = 0;
        if (amount > 0) {
          cost = Number(
            (price / (this.conversionQuantity[index] / amount)).toFixed(2),
          );
          this.$set(
            this.selectedItems[index],
            "unitAmount",
            this.conversionQuantity[index],
          );
          if (cost === Infinity) {
            return 0;
          } else {
            this.$set(this.selectedItems[index], "cost", cost);
          }
        }
        if (Number.isNaN(cost)) {
          return 0;
        }
        return cost;
      };
    },
    //priceがundefinedのときは、製品単価は0円とする
    togglePrice() {
      return (item) => {
        if (item.price === undefined) {
          return 0;
        }
        return item.price;
      };
    },
    isMobile() {
      return this.width <= 640;
    },
    inputCheck() {
      let count = 0;
      this.selectedItems.forEach((item) => {
        if (item.id === undefined) {
          count++;
        }
      });
      return count === 0;
    },
    conversionQuantityInputCheck() {
      return this.selectedItems.length === this.conversionQuantity.length;
    },
    checkInputValue() {
      let result = true;
      this.selectedItems.forEach((item) => {
        if (!item.cost || item.cost === 0) {
          //原価を確認
          result = false;
        }
        if (!item.unitAmount || item.unitAmount === 0) {
          //入力された総量を確認
          result = false;
        }
        if (!item.price || item.price === 0) {
          //入力もしくは渡された単価を確認
          result = false;
        }
      });
      return result;
    },
  },
  methods: {
    assignItem(index) {
      this.$set(
        this.selectedItems,
        this.selectedIndex,
        this.items[this.selectedIndex][index],
      );
    },
    enter() {
      // 既存の値を元に数量を追加する
      this.existingFoodStuffs.forEach((e, index) => {
        this.selectedItems[index].id = e.id;
        this.selectedItems[index].amount = e.amount;
        this.selectedItems[index].unit = e.unit;
      });
      if (this.current === 0) {
        // 原価が未計算の場合は、そのままemit
        if (this.costRate === 0) {
          this.$emit("enter", this.selectedItems);
        }

        //選択した代替食材の単価をチェック
        this.selectedItems.forEach((item, index) => {
          if (item.price == 0) {
            //単価0の食材の場合
            this.isZeroPriceItem[index] = true;
          }
        });
        // 原価計算へ
        this.current++;
      } else if (this.current === 1) {
        //入力チェック
        if (!this.checkInputValue) {
          alert(this.$i18n.t("recipe.recipeEdit.unitFormError"));
          return;
        }
        this.$emit("enter", this.selectedItems, this.costRate);
      }
    },
    cancel() {
      if (this.current === 0) {
        this.$emit("cancel");
      } else if (this.current === 1) {
        //単価チェック配列の初期化
        this.isZeroPriceItem.fill(false);
        this.current--;
      }
    },
    toggleSelectedAlterFood(index) {
      this.selectedIndex = index;
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    async getOrderingData(index, ingredientID) {
      this.$set(this.orderDataLoading, index, true);
      const orderPrice = await recipeManager.getOrderPrice(
        this.customerID,
        this.storeID,
        ingredientID,
      );
      if (Number(orderPrice) > 0) {
        this.$set(this.items[index], "price", orderPrice);
      } else {
        alert(this.$i18n.t("recipe.recipeCost.noOrderingData"));
      }
      this.$set(this.orderDataLoading, index, false);
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  async created() {
    this.loading = true;
    try {
      // 選択した食材リストの初期化
      this.selectedItems.length = this.existingFoodStuffs.length;
      this.selectedItems.fill({});

      //r-spinner表示用配列
      this.orderDataLoading.length = this.selectedItems.length;
      this.orderDataLoading.fill(false);

      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      if (this.existingFoodStuffs.length < 1) {
        throw new Error("No item Data");
      }

      // DBに登録されている取引先情報を取得
      this.storeSuppliers = await supplierManager.getSuppliers(
        this.customerID,
        this.storeID,
      );

      // 食材リストを取得
      await this.$root.setItemListAction(this.customerID, this.storeID);

      //propsのデータを元に選択した食材の、「関連する食材一覧」を取得
      for (let selectedItem of this.existingFoodStuffs) {
        const getSimilarItems = itemManager.getSimilarItemNameList(
          this.$root.privateState.itemList,
          this.storeSuppliers,
          selectedItem,
        );

        const getGroupItems = itemManager.getGroupItemNameList(
          this.$root.privateState.itemList,
          this.storeSuppliers,
          selectedItem,
        );

        // 名前類似と分類一致のリストを結合
        const displayItemList = [...getSimilarItems, ...getGroupItems];
        // 重複削除
        const candidateList = [...new Set(displayItemList)];
        this.items.push(candidateList);
      }

      // itemsの長さが0のやつを探す
      const indexArr = this.items.reduce((acc, item, index) => {
        if (item.length === 0) {
          acc.push(index);
        }
        return acc;
      }, []);
      indexArr.forEach((index) => {
        this.$set(this.selectedItems, index, this.existingFoodStuffs[index]);
      });

      //単価0の有無をチェック
      this.isZeroPriceItem.length = this.selectedItems.length;
      this.isZeroPriceItem.fill(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.recipe-alter-food-form {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.recipe-alter-food-form--modal {
  height: 80vh;
  width: 92%;
  overflow-y: scroll;
}
.recipe-alter-food-form--modal--item {
  margin-top: 20px;
}
.recipe-alter-food-form--modal--buttons--wrapper {
  height: 30vh;
  overflow-y: auto;
}
.recipe-alter-food-form--modal--button {
  border: 1px solid #e87352;
  color: #e87352;
  border-radius: 5px;
  width: 98%;
  align-items: center;
  font-weight: bold;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.recipe-alter-food-form--modal--selected {
  margin-top: 10px;
  align-items: center;
}
.recipe-alter-food-form--modal--selected-item {
  text-align: left;
  display: flex;
}
.recipe-alter-food-form--modal--selected-item-icon {
  width: 5%;
  margin-right: 5px;
}
.recipe-alter-food-form--modal--selected-item-content {
  width: calc(95%-5px);
}
.recipe-alter-food-form--modal--selected-item--name {
  font-weight: bold;
  margin-left: 10px;
}
.recipe-alter-food-form--modal--button--selected {
  background-color: #e87352;
  color: #ffffff;
}
/** CSSの修正 */
.recipe-alter-food-form--modal--food-list {
  height: 35vh;
  align-items: center;
  overflow-y: auto;
  padding-bottom: 20px;
}
.recipe-alter-food-form--modal--list--item {
  margin-top: 10px;
  box-sizing: border-box;
}
.recipe-alter-food-form--modal--search-criteria--selected {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.recipe-alter-food-form--modal--search-criteria--selected--item {
  font-weight: bold;
  display: flex;
}
.recipe-alter-food-form--buttons {
  background: #ffffff;
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  padding: 20px;
  border-top: 2px solid #000000;
}
.recipe-alter-food--not-existed {
  margin-top: 20px;
}
.recipe-alter-food-form--decide-btn {
  margin-right: 20px;
}
.calcCost--container {
  overflow: auto;
}
.calcCost--item {
  margin-top: 20px;
  text-align: left;
}
.calc-arror-icon {
  display: flex;
  justify-content: center;
  color: #e87352;
  font-size: 36px;
}
.calcCost--input {
  display: flex;
  align-items: center;
}
.calcCost--label {
  font-size: 0.8rem;
}
.calcCost--name {
  border-bottom: 2px solid #e87352;
}
.recipeCost-inputForm {
  display: flex;
}
.recipe-cost-price {
  width: 20%;
  margin: unset;
}
.input-orderingData {
  width: 35%;
}
.get-ordering-data-btn {
  width: 50%;
  /* margin-top: 20px; */
}
@media screen and (min-width: 641px) {
  .recipe-alter-food-form {
    width: 90%;
    padding: 5%;
  }
  .recipe-alter-food-form--modal {
    width: 100%;
    padding: 40px;
    height: auto;
  }
  .not-height-auto {
    height: 80vh;
  }
  .recipe-alter-food-form--modal--buttons--wrapper {
    height: 28vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .recipe-alter-food-form--modal--buttons {
    /* margin-bottom:20px; */
    width: calc(50% - 10px);
    align-items: center;
    margin-right: 5px;
    margin-left: 5px;
    /* margin-bottom: 20px; */
  }
  .recipe-alter-food-form--modal--button {
    /* width: calc(48% - 5px); */
    height: auto;
    min-height: 3vh;
    text-align: left;
    padding: 5px 0px 5px 5px;
    display: flex;
    vertical-align: center;
  }
  .recipe-alter-food-form--modal--selected {
    display: flex;
    margin-top: 10px;
    align-items: left;
    /* width: calc(48% - 5px); */
  }
  .recipe-alter-food--not-existed {
    margin-top: 40px;
    margin-left: 20px;
  }
  .recipe-alter-food-form--modal--food-list {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: flex-start;
    height: 32vh;
  }
  .recipe-alter-food-form--modal--food-list--item {
    width: calc(50% - 10px);
    margin-right: 5px;
    margin-left: 5px;
  }
  .recipe-alter-food-form--modal--food-list--item:hover {
    cursor: pointer;
  }
  .calcCost--container {
    overflow: auto;
  }
  .calcCost--wrapper {
    height: auto;
    display: flex;
    align-items: center;
  }
  .recipeCost-inputForm {
    display: flex;
    width: auto;
  }
  .recipe-cost-price {
    width: 100px;
    margin: unset;
  }
  .input-orderingData {
    width: unset;
  }
  .get-ordering-data-btn {
    width: unset;
    /* margin-top: 20px; */
  }
}

@media screen and (min-width: 1024px) {
  .recipe-alter-food-form--modal {
    min-height: 700px;
    max-height: 750px;
    width: 1024px !important;
    /* height:auto; */
  }
  .recipe-alter-food-form--modal--buttons,
  .recipe-alter-food-form--modal--buttons--wrapper {
    display: flex;
  }
  .recipe-alter-food-form--modal--buttons--wrapper {
    width: calc(25% - 20px);
    margin-right: 20px;
    overflow-y: scroll;
    height: 40vh;
  }
  .recipe-alter-food-form--modal--buttons {
    width: calc(100% - 20px);
    align-items: center;
    margin-right: 5px;
    margin-left: 5px;
    display: block;
  }
  .recipe-alter-food-form--modal--button {
    text-align: left;
    padding: 5px 0px 5px 5px;
    display: flex;
    vertical-align: center;
    margin-top: unset;
  }
  .recipe-alter-food-form--modal--selected {
    margin-top: 10px;
  }
  .recipe-alter-food-form--modal--search-criteria--selected--item {
    width: 100%;
  }
  .recipe-alter-food-form--modal--food-list--item {
    margin-top: unset;
    margin-bottom: 20px;
  }
  .recipe-alter-food-form--modal--food-list--item:nth-of-type(2n-1) {
    margin-right: 5px;
  }
  .recipe-alter-food--select-food {
    height: 40vh;
    display: flex;
  }
  .recipe-alter-food-form--modal--food-list {
    height: 40vh;
    width: 75%;
  }
  .calcCost--container {
    overflow: auto;
    margin-bottom: 20px;
  }
  .calcCost--wrapper {
    margin-top: unset;
  }
  .calcCost-result {
    margin-left: 40px;
  }
}
</style>

<template>
  <div
    class="r-card"
    :class="{ 'r-card--round': round }"
    @click="clickHandler"
    @keyup.enter="clickHandler"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "RCard",
  props: {
    round: Boolean,
  },
  methods: {
    clickHandler(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style scoped>
.r-card {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  background-color: #ffffff;
  width: 100%;
  height: auto;
  display: block;
}
.r-card--round {
  border-radius: 10px;
}
</style>

// source Recipe
import { db, imageStorage, functions } from "@/libs";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { ref, getDownloadURL, uploadString } from "firebase/storage";

import courseManager from "@/libs/Course.js";

export default {
  async createRecipe(setData) {
    let recipeID = "";

    try {
      let imageFileName = "";
      if (setData.image !== "") {
        imageFileName = await uploadFile(
          setData.customerID,
          setData.storeID,
          setData.image,
        );
      }

      // データベースに保存
      const refRecipe = collection(db, "recipes");
      const result = await addDoc(refRecipe, {
        customerID: setData.customerID,
        storeID: setData.storeID,
        cat1: setData.categories.length >= 1 ? setData.categories[0].name : "",
        cat2: setData.categories.length >= 2 ? setData.categories[1].name : "",
        cat3: setData.categories.length >= 3 ? setData.categories[2].name : "",
        name: setData.name,
        kana: setData.kana || "",
        image: imageFileName || "",
        serv: setData.serv || 0,
        costPerServ: 0, // 1人前の原価(原価計算で登録)
        costRate: 0, // 原価率(原価計算で登録)
        pastCostRate: 0,
        totalCost: 0, //食材全体のコスト(原価計算で登録)
        targetRate: 0, // 目標原価率
        allergy: setData.allergy || [],
        price: 0,
        isSubRecipe: false,
        subAmount: 0, // サブレシピの量(サブレシピ登録で設定した値)
        subUnit: "", // サブレシピの単位(サブレシピ登録で設定した単位)
        costPerSubUnit: 0, // サブレシピの原価(1人前) totalCost/subAmount
        hidden: setData.isPrivate || false,
        createUser: setData.createUser || "",
        createAt: serverTimestamp(),
        updateAt: serverTimestamp(),
      });

      recipeID = result.id;

      const foodstuffList = setData.foodstuffs;
      // 食材情報を追加
      const refItem = collection(db, "recipes", recipeID, "items");

      await Promise.all(
        foodstuffList.map(async (item, index) => {
          await addDoc(refItem, {
            customerID: setData.customerID,
            storeID: setData.storeID,
            recipeID: recipeID,
            upperRef: result, // 上の階層のレシピのリファレンス
            ingredientID: item.ingredientID || "",
            ref: item.ref || null,
            name: item.name || "",
            kana: item.kana || "",
            amount: item.amount || 0, // 食材で一人前に使用する量
            unit: item.unit || "",
            ls: item.ls || "",
            ms: item.ms || "",
            ss: item.ss || "",
            standard: item.standard || "",
            quantity: item.quantity || 0, // 入数(食材がサブレシピの場合はrecie.subAmountが該当)
            quantityUnit: item.quantityUnit || "", // 入数単位(食材がサブレシピの場合はrecipe.subUnitが該当)
            price: item.price || 0, // 製品単価(サブレシピの場合はrecipe.totalCostが該当)
            cost: item.cost || 0, // 一人前の原価
            unitAmount: item.unitAmount || 0, // 製品の総量(例:製品の総量 ラー油500ccが unitAmount、 一人前は50ccが amount)
            displayNum: item.displayNum || index,
            createAt: serverTimestamp(),
            updateAt: serverTimestamp(),
          });
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    return recipeID;
  },
  async copyRecipeData(recipeID, distStoreID, createUser) {
    let copyRecipeID = "";
    try {
      // リファレンスを取得
      const recipeRef = doc(db, "recipes", recipeID);

      // コピー処理
      const copyRecipeRef = await _copyRecipe(
        recipeRef,
        distStoreID,
        createUser,
      );
      if (copyRecipeRef) {
        copyRecipeID = copyRecipeRef.id;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    return copyRecipeID;
  },
  // レシピ名称の取得
  async getRecipeName(recipeID) {
    try {
      const recipeDoc = await getDoc(doc(db, "recipes", recipeID));

      if (!recipeDoc.exists()) {
        throw new Error("Recipe not found");
      }

      const recipeData = recipeDoc.data();
      return recipeData.name || "";
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  // 食材情報の取得
  async getItems(recipeID) {
    let items = [];
    try {
      const itemsRef = collection(db, "recipes", recipeID, "items");
      const itemsSnapshot = await getDocs(itemsRef);

      const itemsArray = [];
      if (!itemsSnapshot.empty) {
        itemsSnapshot.forEach((doc) => {
          const itemsRes = { ...doc.data(), id: doc.id };
          itemsArray.push(itemsRes);
        });
        items = itemsArray;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return items;
  },
  // チュートリアルの登録処理
  async registTutorial(customerID, storeID, recipeID, tutorials, deleteID) {
    try {
      // 削除処理
      if (deleteID.length > 0) {
        await Promise.all(
          deleteID.map(async (id) => {
            const tutorialRef = doc(db, "recipes", recipeID, "tutorials", id);
            await deleteDoc(tutorialRef);
          }),
        );
      }

      // データベースに登録した内容を削除したときの処理
      await Promise.all(
        tutorials.map(async (tutorial) => {
          // textは必須
          if (tutorial.text) {
            // idがある場合
            if (tutorial.id !== "") {
              //  --- 更新処理 ---
              // アップロード画像の登録
              let imageFileName = "";
              let imageFile = tutorial.image;

              if (imageFile !== "" && imageFile.indexOf("base64") > -1) {
                // 画像ファイルを更新した場合
                imageFileName = await uploadFile(
                  customerID,
                  storeID,
                  imageFile,
                );
              } else {
                if (tutorial.imageFileName) {
                  imageFileName = tutorial.imageFileName;
                }
              }

              // チュートリアルを追加
              const refTutorials = doc(
                db,
                "recipes",
                recipeID,
                "tutorials",
                tutorial.id,
              );
              await updateDoc(refTutorials, {
                customerID: customerID,
                storeID: storeID,
                step: tutorial.step,
                text: tutorial.text,
                image: imageFileName,
                updateAt: serverTimestamp(),
              });

              // idがない場合
            } else {
              //  --- 新規追加処理 ---
              // アップロード画像の登録
              let imageFileName = "";
              if (tutorial.image !== "") {
                imageFileName = await uploadFile(
                  customerID,
                  storeID,
                  tutorial.image,
                );
              }
              // 追加
              const refTutorials = collection(
                db,
                "recipes",
                recipeID,
                "tutorials",
              );
              await addDoc(refTutorials, {
                customerID: customerID,
                storeID: storeID,
                step: tutorial.step,
                text: tutorial.text,
                image: imageFileName,
                createAt: serverTimestamp(),
                updateAt: serverTimestamp(),
              });
            }
          }
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
  // レシピ情報の取得
  async getRecipe(recipeID) {
    let recipeRes = {};
    try {
      const recipeRef = doc(db, "recipes", recipeID);
      const documentSnapshot = await getDoc(recipeRef);

      if (documentSnapshot.exists()) {
        recipeRes = { ...documentSnapshot.data(), id: documentSnapshot.id };

        // 食材情報一覧を取得
        const itemsRef = collection(db, "recipes", recipeID, "items");
        const itemsSnapshot = await getDocs(itemsRef);
        const items = [];
        if (!itemsSnapshot.empty) {
          itemsSnapshot.forEach((doc) => {
            let itemsRes = { ...doc.data(), id: doc.id };
            items.push(itemsRes);
          });
        }
        recipeRes.items = items;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    return recipeRes;
  },
  // レシピ更新
  async updateRecipe(setData) {
    try {
      let imageFileName = "";
      let imageFile = setData.recipe.image;
      if (imageFile !== "" && imageFile.indexOf("base64") > -1) {
        // imageの変更の場合はアップロード処理
        imageFileName = await uploadFile(
          setData.customerID,
          setData.storeID,
          imageFile,
        );
      } else if (imageFile !== "") {
        // 画像に変更がないとき
        imageFileName = setData.recipe.imageFileName;
      }

      const refRecipe = doc(db, "recipes", setData.recipeID);
      await updateDoc(refRecipe, {
        customerID: setData.customerID,
        storeID: setData.storeID,
        cat1: setData.recipe.cat1,
        cat2: setData.recipe.cat2,
        cat3: setData.recipe.cat3,
        name: setData.recipe.name,
        kana: setData.recipe.kana,
        image: imageFileName,
        serv: setData.recipe.serv,
        subAmount: setData.recipe.subAmount || 0,
        costPerServ: setData.recipe.costPerServ || 0,
        costPerSubUnit: setData.recipe.costPerSubUnit,
        costRate: setData.recipe.costRate || 0,
        pastCostRate: setData.recipe.pastCostRate || 0,
        totalCost: setData.recipe.totalCost || 0,
        targetRate: setData.recipe.targetRate || 0,
        allergy: setData.recipe.allergy,
        price: setData.recipe.price || 0,
        isSubRecipe: setData.recipe.isSubRecipe,
        hidden: setData.recipe.hidden,
        updateAt: serverTimestamp(),
      });

      const foodstuffList = setData.recipe.items;

      await Promise.all(
        foodstuffList.map(async (item) => {
          if (item.id) {
            // 食材情報を更新
            const refUpdateItem = doc(
              db,
              "recipes",
              setData.recipeID,
              "items",
              item.id,
            );
            await updateDoc(refUpdateItem, {
              customerID: setData.customerID,
              storeID: setData.storeID,
              recipeID: setData.recipeID,
              upperRef: refRecipe,
              ingredientID: item.ingredientID || "",
              ref: item.ref || null,
              name: item.name || "",
              kana: item.kana || "",
              amount: Number(item.amount) || 0,
              unit: item.unit || "",
              ls: item.ls || "",
              ms: item.ms || "",
              ss: item.ss || "",
              standard: item.standard || "",
              quantity: Number(item.quantity) || 0,
              quantityUnit: item.quantityUnit || "",
              price: Number(item.price) || 0,
              unitAmount: item.unitAmount || 0,
              cost: Number(item.cost) || 0,
              displayNum: item.displayNum,
              updateAt: serverTimestamp(),
            });
          } else {
            // 食材情報を追加
            const refAddItem = collection(
              db,
              "recipes",
              setData.recipeID,
              "items",
            );
            await addDoc(refAddItem, {
              customerID: setData.customerID,
              storeID: setData.storeID,
              recipeID: setData.recipeID, // 上の階層のレシピ
              upperRef: refRecipe, // 上の階層のレシピのレイファレンス
              ingredientID: item.ingredientID || "",
              ref: item.ref || null,
              name: item.name || "",
              kana: item.kana || "",
              amount: Number(item.amount) || 0,
              unit: item.unit || "",
              ls: item.ls || "",
              ms: item.ms || "",
              ss: item.ss || "",
              standard: item.standard || "",
              quantity: Number(item.quantity) || 0,
              quantityUnit: item.quantityUnit || "",
              price: Number(item.price) || 0,
              unitAmount: item.unitAmount || 0,
              cost: Number(item.cost) || 0,
              displayNum: item.displayNum,
              createAt: serverTimestamp(),
              updateAt: serverTimestamp(),
            });
          }
        }),
      );

      // 画面上で削除した食材を削除
      if (setData.orgRecipe.items) {
        const orgItems = setData.orgRecipe.items;
        await Promise.all(
          orgItems.map(async (orgItem) => {
            const orgID = orgItem.id;

            const distItems = setData.recipe.items;
            const targetItem = distItems.find((item) => item.id === orgID);

            if (!targetItem) {
              // 存在しない食材IDの物は削除
              const refDeleteItem = doc(
                db,
                "recipes",
                setData.recipeID,
                "items",
                orgID,
              );
              await deleteDoc(refDeleteItem);
            }
          }),
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    return;
  },
  // レシピ情報の取得
  async getRecipeWithImage(customerID, storeID, recipeID) {
    let recipeRes = {};
    try {
      const recipeRef = doc(db, "recipes", recipeID);
      const recipeSnap = await getDoc(recipeRef);

      if (recipeSnap.exists()) {
        recipeRes = recipeSnap.data();
        recipeRes.id = recipeSnap.id;

        // 画像ファイルを取得
        let imageFileName = "";
        try {
          if (recipeRes.image) {
            imageFileName = recipeRes.image;
            const imagePath = `images/${customerID}/${storeID}/${recipeRes.image}`;
            const imageURL = await getDownloadURL(ref(imageStorage, imagePath));
            recipeRes.image = imageURL;
          } else {
            recipeRes.image = "";
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log("no image");
        }

        // イメージファイル名を保存
        recipeRes.imageFileName = imageFileName;

        // 食材情報一覧を取得
        const itemsRef = collection(recipeRef, "items");
        const itemsSnap = await getDocs(itemsRef);
        const items = [];

        if (!itemsSnap.empty) {
          await Promise.all(
            itemsSnap.docs.map(async (doc) => {
              const itemsRes = doc.data();
              itemsRes.id = doc.id;

              if (itemsRes.ref) {
                // リファレンスからレシピ情報を取得
                const subRecipeSnap = await getDoc(itemsRes.ref);
                const subRecipeData = subRecipeSnap.data();

                if (subRecipeData) {
                  const subItemsRef = collection(
                    db,
                    "recipes",
                    subRecipeSnap.id,
                    "items",
                  );
                  const subItemsSnap = await getDocs(subItemsRef);

                  if (!subItemsSnap.empty) {
                    itemsRes.subItems = subItemsSnap.docs.map((subDoc) => ({
                      ...subDoc.data(),
                      id: subDoc.id,
                    }));
                  }
                } else {
                  throw new Error("No Data");
                }
              }
              items.push(itemsRes);
            }),
          );
        }
        recipeRes.items = items;

        // 作り方一覧一覧を取得
        const tutorialsRef = collection(recipeRef, "tutorials");
        const tutorialsSnap = await getDocs(tutorialsRef);
        let tutorials = [];

        if (!tutorialsSnap.empty) {
          tutorials = await Promise.all(
            tutorialsSnap.docs.map(async (doc) => {
              const tutorialRes = doc.data();
              tutorialRes.id = doc.id;

              if (tutorialRes.image) {
                try {
                  const tutorialImagePath = `images/${customerID}/${storeID}/${tutorialRes.image}`;
                  tutorialRes.image = await getDownloadURL(
                    ref(imageStorage, tutorialImagePath),
                  );
                  tutorialRes.imageFileName = tutorialRes.image;
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.log("no image");
                }
              }
              return tutorialRes;
            }),
          );
          // tutorialsをソート
          tutorials.sort((a, b) => a.step - b.step);
        }
        recipeRes.tutorials = tutorials;
      } else {
        throw new Error("No Data");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
    return recipeRes;
  },
  async deleteRecipe(recipeID) {
    try {
      // データの削除-サブコレクションはFunctionでコマンドで削除
      await deleteDoc(doc(db, "recipes", recipeID));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
  // サブレシピ一覧を取得
  async getSubRecipe(customerID, storeID) {
    try {
      const recipesRef = collection(db, "recipes");
      const q = query(
        recipesRef,
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
        where("isSubRecipe", "==", true),
      );

      const querySnapshot = await getDocs(q);
      const records = querySnapshot.docs.map((doc) => {
        console.log("==doc==", doc);

        return {
          id: doc.id,
          ref: doc.ref, // リファレンスを取得
          ...doc.data(),
        };
      });

      return records;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
  // subrecipe 登録
  async setSubRecipe(recipeID, costPerSubUnit, subUnit, subAmount) {
    try {
      const refRecipe = doc(db, "recipes", recipeID);

      await updateDoc(refRecipe, {
        costPerSubUnit: Number(costPerSubUnit),
        subAmount: Number(subAmount),
        subUnit: subUnit,
        isSubRecipe: true,
        updateAt: serverTimestamp(),
      });

      // itemsの更新も行う
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
  async unsetSubRecipe(recipeID) {
    try {
      const refRecipe = doc(db, "recipes", recipeID);

      await updateDoc(refRecipe, {
        costPerSubUnit: 0,
        subAmount: 0,
        subUnit: "",
        isSubRecipe: false,
        updateAt: serverTimestamp(),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
  async searchRecipe(
    searchWord,
    currentCategory,
    customerID,
    searchStore,
    page,
    limit,
  ) {
    try {
      const searchRecipeCallable = httpsCallable(functions, "searchRecipe");

      const result = await searchRecipeCallable({
        searchWord: searchWord,
        category: currentCategory,
        customerID: customerID,
        storeID: searchStore,
        page: page,
        limit: limit,
      });

      if (result.data) {
        const page = result.data.page ?? 1;
        const isHasMore = result.data.isHasMore ?? false;
        const recipes = await Promise.all(
          result.data.data.map(async (recipe) => {
            // 画像ファイルを取得
            let imageURL = "";

            if (recipe.image) {
              try {
                const imageRef = ref(
                  imageStorage,
                  `images/${customerID}/${recipe.storeID}/thumb_${recipe.image}`,
                );
                imageURL = await getDownloadURL(imageRef);
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log("no image");
              }
            }

            // リストデータ作成
            return {
              id: recipe.objectID || "",
              customerID: customerID,
              storeID: recipe.storeID,
              name: recipe.name || "",
              image: imageURL || "",
              cat:
                (recipe.cat1 || "") +
                (recipe.cat2 ? "・" + recipe.cat2 : "") +
                (recipe.cat3 ? "・" + recipe.cat3 : ""),
              costRate: recipe.costRate || "",
              targetRate: recipe.targetRate || "",
              tutorials: recipe.tutorials || "",
              isSubRecipe: recipe.isSubRecipe || false,
              updateAt: recipe.updateAt || "",
            };
          }),
        );

        return { recipes, page, isHasMore };
      }

      return { recipes: [], page: 1 };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  // 通知での食材の単価更新
  async updateItem(priceUpdate) {
    try {
      const customerID = priceUpdate.customerID;
      const storeID = priceUpdate.storeID;

      const recipeID = priceUpdate.recipeID;
      const recipeData = priceUpdate.recipeData;
      const pastTotalCost = recipeData.totalCost;
      const itemID = priceUpdate.itemID; // IDの後ろがItemID

      // 該当食材のコストを更新
      const refUpdateItem = doc(db, "recipes", recipeID, "items", itemID);

      const originItemdata = await getDoc(refUpdateItem);
      if (!originItemdata.exists()) {
        // すでに削除すみ
        throw new Error("Already Delete");
      }

      let originItem = originItemdata.data();

      // 更新しない
      if (!originItem.ingredientID) {
        return;
      }

      // 製品単価更新
      originItem.price = priceUpdate.price;
      let cost = 0;
      if (originItem.cost) {
        // 1人前あたりの原価を算出
        cost = calcItemCost(originItem);
      }

      // 食材のアップデート
      await updateDoc(refUpdateItem, {
        cost: Number(cost) || 0,
        price: Number(priceUpdate.price) || 0,
        updateAt: serverTimestamp(),
      });

      // レシピのアップデート
      const itemsSnapshot = await getDocs(
        collection(db, "recipes", recipeID, "items"),
      );

      if (itemsSnapshot.empty) {
        // すでに削除すみ
        throw new Error("Already Delete");
      }
      const items = itemsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // トータルコスト再計算
      const totalCost = calcTotalCost(items);

      // 原価率を更新
      let costRate = 0;
      let pastCostRate = 0;
      let costPerServ = 0;

      if (Number(recipeData.costRate) > 0) {
        // 原価計算をしている場合は再計算
        pastCostRate = Number(recipeData.costRate);
        costRate = calcCostRate(
          totalCost / Number(recipeData.serv),
          recipeData.price,
        );
      }

      // 1人前あたりの原価
      if (Number(recipeData.serv) >= 1) {
        costPerServ = calcTotalCostPerServ(items, recipeData.serv);
      }

      // レシピを更新
      // 該当食材のコストを更新
      const refUpdateRecipe = doc(db, "recipes", recipeID);
      await updateDoc(refUpdateRecipe, {
        costRate: costRate,
        pastCostRate: pastCostRate,
        costPerServ: costPerServ,
        totalCost: totalCost,
        updateAt: serverTimestamp(),
      });

      // サブレシピの場合は、上位のレシピを更新する。
      if (recipeData.isSubRecipe) {
        await updateCostHigher(customerID, storeID, recipeID);
      }

      // データ更新
      priceUpdate.totalCost = totalCost;
      priceUpdate.pastTotalCost = pastTotalCost;
      priceUpdate.costRate = costRate;
      priceUpdate.pastCostRate = pastCostRate;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return;
  },
  // コスト情報の更新
  async updateCostRate(
    recipeID, // レシピID
    sellPrice, // 売価
    costRate, // 原価率
    pastCostRate, // 前回の原価率
    costPerServ, // 目標原価率
    totalCost, // 総合原価
    setTargetRate, // 目標原価率
    items,
  ) {
    // 原価率の登録
    try {
      await updateRecipeForCostRate(
        recipeID,
        sellPrice,
        costRate,
        pastCostRate,
        costPerServ,
        totalCost,
        setTargetRate,
        items,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  async useSubRecipeCount(items) {
    let countArray = [];
    const level = 1;
    await Promise.all(
      items.map(async (item) => {
        if (item.ref) {
          const returnLevel = await getRecipeLevel(level, item.ref);
          countArray.push(returnLevel);
        }
      }),
    );
    // levelArray の中で最大のレベル
    // eslint-disable-next-line no-console
    const count = Math.max.apply(null, countArray);
    return count;
  },

  // 上位階層が何段階あるかを調べる
  async useSubRecipeCountHigher(customerID, storeID, recipeID) {
    try {
      // 対象のレシピをリスト化
      const recipeRef = doc(db, "recipes", recipeID);
      const levelArray = await selectHigherLevelList(
        customerID,
        storeID,
        recipeRef,
      );
      let flatArray = levelArray.flat();
      let count = 0;
      while (flatArray.length > 0) {
        const nextArray = flatArray.reduce((acc, item) => {
          if (Array.isArray(item) && item.length > 0) {
            // 配列の場合は処理しない
            acc.push(item);
          } else if (
            typeof item === "object" &&
            item !== null &&
            !Array.isArray(item)
          ) {
            count++;
          }
          return acc;
        }, []);
        flatArray = nextArray.flat();
      }
      return count;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },

  async getOrderPrice(customerID, storeID, ingredientID) {
    let price = 0;
    try {
      const getOrderPriceFunc = httpsCallable(functions, "getOrderPrice");
      const result = await getOrderPriceFunc({
        customerID: customerID,
        storeID: storeID,
        ingredientID: ingredientID,
      });
      if (result.data) {
        price = result.data;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return price;
  },
  // サブレシピの自己参照チェック
  async chekcSelfReferencingRecipe(recipeID, subRecipeRef) {
    let exist = false;
    if (recipeID) {
      exist = await chekcSelfReferencing(recipeID, subRecipeRef, exist);
    }
    return exist;
  },
  // 上位のレシピの原価更新処理
  async updateCostHigherRecipe(customerID, storeID, recipeID) {
    try {
      await updateCostHigher(customerID, storeID, recipeID);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return;
  },
  async isRecipeRegistCourse(customerID, storeID, recipeID) {
    try {
      // コースを取得する
      const courses = await courseManager.getCourseFactorsList(
        customerID,
        storeID,
        recipeID,
      );
      // コースに登録されているレシピのIDのリストを作成する
      // 引数のrecipeIDが上記のIDリストに含まれているかをチェック
      return courses;
    } catch (error) {
      throw new Error(error);
    }
  },
  /**
   *
   * @param {*} customerID
   * @param {*} storeID
   * @param {*} recipeID
   */
  async doesExistHigherRecipe(customerID, storeID, recipeID) {
    try {
      const recipeRef = doc(db, "recipes", recipeID);

      const q = query(
        collectionGroup(db, "items"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
        where("ref", "==", recipeRef),
      );

      const querySnapshot = await getDocs(q);

      return !querySnapshot.empty;
    } catch (error) {
      throw new Error(error);
    }
  },
  // レシピの総合原価を計算
  calcTotalCost(items) {
    return calcTotalCost(items);
  },
  // レシピの1人前の原価を計算
  calcTotalCostPerServ(items, serv) {
    return calcTotalCostPerServ(items, serv);
  },
  // 原価率の計算
  calcCostRate(totalCost, price) {
    return calcCostRate(totalCost, price);
  },
  // 食材の原価計算(サブレシピのコスト計算も含む)
  calcItemCost(item) {
    return calcItemCost(item);
  },
};

/**************** 共通ロジック **************************/
// 上位階層のレシピの原価更新
async function updateCostHigher(customerID, storeID, recipeID) {
  try {
    // 対象のレシピをリスト化
    const recipeRef = doc(db, "recipes", recipeID);
    const levelArray = await selectHigherLevelList(
      customerID,
      storeID,
      recipeRef,
    );
    let flatArray = levelArray.flat();
    // 段階的アップデート。サブレシピの場合は原価登録済み
    while (flatArray.length > 0) {
      const nextArray = await flatArray.reduce(async (pacc, higherLevel) => {
        let acc = await pacc;
        if (Array.isArray(higherLevel)) {
          // 配列の場合は処理しない
          acc.push(higherLevel);
        } else {
          const recipeData = await getDoc(higherLevel.ref);
          const recipe = recipeData.data();
          if (!recipeData.id) {
            // データが存在しないため、エラー
            throw new Error("No Data");
          }
          // レシピ情報を取得
          recipe.id = recipeData.id;

          // 該当レシピの食材情報をアップデート
          const itemsRef = collection(
            db,
            "recipes",
            higherLevel.recipeID,
            "items",
          );
          const itemsData = await getDocs(itemsRef);
          // 食材を取得
          const items = itemsData.docs;
          if (items.length == 0) {
            // データが存在しないため、エラー
            throw new Error("No Data");
          }
          const updateItems = await Promise.all(
            items.map(async (doc) => {
              // サブレシピのデータを取得して更新
              const item = doc.data();
              item.id = doc.id;
              if (item.ref) {
                const subRecipeData = await getDoc(item.ref);
                const subRecipe = subRecipeData.data();
                item.quantity = subRecipe.subAmount;
                item.quantityUnit = subRecipe.subUnit;
                item.price = subRecipe.totalCost;
                if (item.unitAmount) {
                  // 該当レシピが原価登録済の場合はコスト再計算
                  item.cost = calcItemCost(item);
                }
              }
              return item;
            }),
          );
          let costRate = 0; // 原価率
          let pastCostRate = 0; // 前回の原価率
          let totalCost = 0; // 総合コスト
          let costPerServ = 0; // 1人前のコスト

          if (recipe.costRate) {
            // 該当レシピが原価更新済みの場合は再計算
            pastCostRate = recipe.totalCost;
            totalCost = calcTotalCost(updateItems);
            costRate = calcCostRate(totalCost, recipe.price);
            costPerServ = calcTotalCostPerServ(updateItems, recipe.serv);
          }

          // データを更新
          await updateRecipeForCostRate(
            recipe.id,
            recipe.price,
            costRate,
            pastCostRate,
            costPerServ,
            totalCost,
            recipe.targetRate,
            updateItems,
          );
        }
        return acc;
      }, []);
      flatArray = nextArray.flat();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error(error);
  }
  return;
}

// レシピの総合原価を計算
function calcTotalCost(items) {
  const totalCost = items.reduce((acc, item) => {
    acc = acc + Number(item.cost);
    return acc;
  }, 0);

  return totalCost;
}

// レシピの1人前の原価を計算
function calcTotalCostPerServ(items, serv) {
  if (serv <= 0) {
    return 0;
  }
  const totalCost = items.reduce((acc, item) => {
    acc = acc + Number(item.cost);
    return acc;
  }, 0);

  const costPerServ = totalCost / serv;
  return Number(costPerServ.toFixed(2));
}

// 原価率の計算
function calcCostRate(totalCost, price) {
  if (Number(totalCost) <= 0 || Number(price) <= 0) {
    return 0;
  }
  const costRate = (Number(totalCost) / Number(price)) * 100;
  return Number(costRate.toFixed(2));
}

//  食材の原価
function calcItemCost(item) {
  let cost = 0;
  const price = Number(item.price); //食材の製品単価
  const amount = Number(item.amount); // レシピで使用する食材の量
  const unitAmount = Number(item.unitAmount); // 食材の総量量

  // 製品単価 / (食材の総量 / レシピで使用する食材の量)
  cost = price / (unitAmount / amount);
  return Number(cost.toFixed(2));
}

// 原価および原価率の更新
async function updateRecipeForCostRate(
  recipeID,
  setPrice,
  costRate,
  pastCostRate,
  costPerServ,
  totalCost,
  setTargetRate,
  items,
) {
  try {
    const recipeRef = doc(db, "recipes", recipeID);

    // 原価率の登録
    await updateDoc(recipeRef, {
      price: setPrice,
      costRate: costRate,
      pastCostRate: pastCostRate,
      costPerServ: costPerServ,
      totalCost: totalCost,
      targetRate: setTargetRate,
      updateAt: serverTimestamp(),
    });

    // 食材のコスト情報の更新
    await Promise.all(
      items.map(async (item) => {
        if (item.id) {
          const itemRef = doc(db, "recipes", recipeID, "items", item.id);
          await updateDoc(itemRef, {
            unitAmount: item.unitAmount,
            cost: item.cost, // 人前分のコスト
            price: item.price, // 製品単価
            updateAt: serverTimestamp(),
          });
        }
      }),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error(error);
  }
}

async function uploadFile(customerID, storeID, image) {
  let imageFileName = "";
  const imageInfoArray = image.split(",");

  try {
    if (imageInfoArray.length !== 2) {
      // imageデータではないエラー
      throw new Error("not picture file");
    }

    const imageInfo = imageInfoArray[0];
    const imageBase64 = imageInfoArray[1];

    if (!imageInfo.indexOf("image")) {
      // imageデータではないエラー
      throw new Error("not picture file");
    }

    // Content Typeを取得
    const contentType = imageInfo.slice("data:".length, imageInfo.indexOf(";"));

    // ファイル名をランダム文字列として設定
    imageFileName = `${new Date().getTime().toString(32)}${Math.random()
      .toString(32)
      .substring(2)}`;

    // ファイルが存在する場合、画像をStorageにアップロード(base64)
    const storageRef = ref(
      imageStorage,
      `images/${customerID}/${storeID}/${imageFileName}`,
    );
    await uploadString(storageRef, imageBase64, "base64", { contentType });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error(error);
  }
  return imageFileName;
}

async function _copyImage(image, customerID, storeID, distStoreID) {
  let imageFileName = "";
  if (!image) {
    return imageFileName;
  }
  try {
    // 画像のコピー
    // functionでコピー処理を実行
    if (storeID === distStoreID) {
      const copyImageFile = httpsCallable(functions, "copyImageFile");
      // 店舗内でレシピのコピーを行う場合
      const result = await copyImageFile({
        customerID: customerID,
        storeID: storeID,
        fileName: image,
      });
      if (result.data) {
        // コピーした画像ファイル名を設定
        imageFileName = result.data;
      } else {
        throw new Error("image copy error");
      }
    } else {
      // 店舗間レシピのコピー
      const copyFile = httpsCallable(functions, "copyFile");
      const result = await copyFile({
        customerID: customerID,
        storeID: storeID,
        distStoreID: distStoreID,
        fileName: image,
      });
      if (result.data) {
        // コピーした画像ファイル名を設定
        imageFileName = result.data;
      } else {
        throw new Error("image copy error");
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw new Error(error);
  }
  return imageFileName;
}
async function _copyRecipe(recipeRef, distStoreID, createUser) {
  let copyRecipeRef = null;
  try {
    if (!recipeRef) {
      return copyRecipeRef;
    }
    const recipeSnapShot = await getDoc(recipeRef);
    if (recipeSnapShot.exists()) {
      // コピー元のレシピID
      const srcRecipeID = recipeSnapShot.id;

      // レシピのデータが存在した場合は、コピー
      const copyRecord = recipeSnapShot.data();

      const srcStoreID = copyRecord.storeID;
      // 画像のコピー
      // 同じ店舗内での画像コピー(srcStoreID !== distStoreID)の条件削除
      if (copyRecord.image) {
        copyRecord.image = await _copyImage(
          copyRecord.image,
          copyRecord.customerID,
          srcStoreID,
          distStoreID,
        );
      }

      copyRecord.createUser = createUser;
      copyRecord.storeID = distStoreID;
      copyRecord.name = `${copyRecord.name}のコピー`;
      copyRecord.createAt = serverTimestamp();
      copyRecord.updateAt = serverTimestamp();

      // コピーしたデータを保存
      const recipesRef = collection(db, "recipes");
      copyRecipeRef = await addDoc(recipesRef, copyRecord);
      const copyRecipeID = copyRecipeRef.id;

      // 食材のコピー
      const itemsRef = collection(db, "recipes", srcRecipeID, "items");
      const itemSnapShot = await getDocs(itemsRef);
      if (!itemSnapShot.empty) {
        // 食材が登録されている場合
        await Promise.all(
          itemSnapShot.docs.map(async (doc) => {
            // それぞれの食材を登録
            const copyItem = doc.data();
            if (copyItem.ref !== null && srcStoreID !== distStoreID) {
              // さらに下位のサブレシピがある場合はそれもコピー
              const downRecipeRef = await _copyRecipe(
                copyItem.ref,
                distStoreID,
                createUser,
              );
              copyItem.ref = downRecipeRef;
            }

            copyItem.storeID = distStoreID;
            copyItem.recipeID = copyRecipeID;
            copyItem.upperRef = copyRecipeRef;
            copyItem.createAt = serverTimestamp();
            copyItem.updateAt = serverTimestamp();

            const newItemRef = collection(db, "recipes", copyRecipeID, "items");
            await addDoc(newItemRef, copyItem);
          }),
        );
      }

      // 作り方のコピー
      const tutorialsRef = collection(db, "recipes", srcRecipeID, "tutorials");
      const tutorialSnapShot = await getDocs(tutorialsRef);
      if (!tutorialSnapShot.empty) {
        await Promise.all(
          tutorialSnapShot.docs.map(async (doc) => {
            const copyTutorial = doc.data();

            // 画像ファイルコピー
            // 同じ店舗内での画像コピー(srcStoreID !== distStoreID)の条件削除
            if (copyTutorial.image) {
              copyTutorial.image = await _copyImage(
                copyTutorial.image,
                copyRecord.customerID,
                srcStoreID,
                distStoreID,
              );
            }

            copyTutorial.storeID = distStoreID;
            copyTutorial.createAt = serverTimestamp();
            copyTutorial.updateAt = serverTimestamp();

            const newTutorialRef = collection(
              db,
              "recipes",
              copyRecipeID,
              "tutorials",
            );
            await addDoc(newTutorialRef, copyTutorial);
          }),
        );
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("ERROR", error);
    throw new Error(error);
  }

  // コピーを作成したリファレンスを返却
  return copyRecipeRef;
}

// サブレシピ階層チェック
async function getRecipeLevel(level, recipeRef) {
  let recipeLevel = level;
  if (recipeLevel >= 5) {
    return recipeLevel;
  } else {
    const subRecipeSnapShot = await getDoc(recipeRef);
    if (subRecipeSnapShot.exists()) {
      const subRecipe = subRecipeSnapShot.data();
      if (subRecipe.isSubRecipe) {
        // サブレシピの食材情報を取得
        const itemsRef = collection(db, "recipes", recipeRef.id, "items");
        const qs = await getDocs(itemsRef);
        if (qs.docs.length > 0) {
          // 確認
          let levelDepthArr = [];
          let levelDepth = recipeLevel;
          await Promise.all(
            qs.docs.map(async (doc) => {
              const item = doc.data();
              // サブレシピが存在する場合は下の階層のレシピを検索
              if (item.ref) {
                // サブレシピか食材として利用されている場合 : レシピ段階を増やしてgetRecipeLevelを呼び出す。
                const returnLevel = await getRecipeLevel(
                  levelDepth + 1,
                  item.ref,
                );
                if (returnLevel > levelDepth) {
                  levelDepth = returnLevel;
                }
                levelDepthArr.push(levelDepth);
              }
            }),
          );
          // levelArray の中で最大のレベル
          recipeLevel = Math.max.apply(null, levelDepthArr);
        }
      }
    }
    return recipeLevel;
  }
}

/**
 * サブレシピの自己参照チェック
 * @param {*} orgRecipeID チェック対象のレシピID
 * @param {*} recipeRef 1階層めのサブレシピのリファレンス
 * @param {*} exist true: 自己参照レシピあり, false: 自己参照レシピなし
 */
async function chekcSelfReferencing(orgRecipeID, recipeRef, exist) {
  try {
    if (!recipeRef) {
      throw new Error("recipeRef is undefined or null");
    }
    if (exist === true) {
      return exist;
    } else {
      const subRecipeSnapShot = await getDoc(recipeRef);
      if (subRecipeSnapShot.exists()) {
        if (subRecipeSnapShot.id == orgRecipeID) {
          return true;
        } else {
          const subRecipe = subRecipeSnapShot.data();
          if (subRecipe.isSubRecipe) {
            // サブレシピの食材情報を取得
            const itemsRef = collection(db, "recipes", recipeRef.id, "items");
            const qs = await getDocs(itemsRef);
            if (qs.docs.length > 0) {
              // 確認
              await Promise.all(
                qs.docs.map(async (doc) => {
                  const item = doc.data();
                  // サブレシピが存在する場合は下の階層のレシピを検索
                  if (item.ref) {
                    const returnExit = await chekcSelfReferencing(
                      orgRecipeID,
                      itemsRef,
                      exist,
                    );
                    if (returnExit === true) {
                      exist = true;
                    }
                  }
                }),
              );
            }
          }
        }
      }
      return exist;
    }
  } catch (err) {
    console.log("==chekcSelfReferencing err==", err);
  }
}

/**
 * サブレシピに関連するレシピの更新
 * @param {*} customerID 顧客ID
 * @param {*} storeID 店舗ID
 * @param {*} recipeRef 自分自身のレシピリファレンス
 */
async function selectHigherLevelList(customerID, storeID, recipeRef) {
  let recipeList = [];
  // 自分自身を使用している上位のレシピIDを取得
  const qs = await getDocs(
    query(
      collectionGroup(db, "items"),
      where("customerID", "==", customerID),
      where("storeID", "==", storeID),
      where("ref", "==", recipeRef),
    ),
  );

  if (qs.docs.length > 0) {
    // 確認
    recipeList = await Promise.all(
      qs.docs.map(async (doc) => {
        let higherArray = [];
        // 所属している該当のレシピを取得
        if (doc.exists()) {
          const item = doc.data();
          const upperRef = item.upperRef;
          // 上位のレシピID
          higherArray.push({ ref: upperRef, recipeID: item.recipeID });
          const reternArray = await selectHigherLevelList(
            customerID,
            storeID,
            upperRef,
          );
          higherArray.push(reternArray);
        }
        return higherArray;
      }),
    );
  }
  return recipeList;
}

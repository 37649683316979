<template>
  <r-paper class="config-unit" round>
    <r-modal-sub-header :title="$t('config.unitForm.registUnitHeader')">
      <div class="config-unit--content">
        <r-heading :level="4">{{ $t("config.unitForm.registUnit") }}</r-heading>
        <div class="config--unit--registration--form">
          <div class="config-category-input-form">
            <r-textbox
              class="config-unit--content--item"
              type="string"
              :placeholder="$t('config.unitForm.unitPlaceholder')"
              v-model="inputName"
              name="inputName"
            />
          </div>
          <div class="config-btn-wrapper">
            <r-button
              class="config-unit--content--item config-category-btn"
              buttonStyle="secondary"
              full
              @click="inputUnit('set')"
              >{{ $t("config.unitForm.inputUnit") }}</r-button
            >
          </div>
        </div>
      </div>
      <div class="config-unit--content">
        <r-heading :level="4">{{
          $t("config.unitForm.deleteUnitTitle")
        }}</r-heading>
        <div class="config--unit--delete--form">
          <r-checkbox
            class="config-unit--content--item config-unit--content--cat"
            :items="units"
            @toggle="toggle"
          />
          <div class="config-btn-wrapper">
            <r-button
              class="config-unit--content--item config-category-btn"
              buttonStyle="secondary"
              full
              @click="inputUnit('del')"
              >{{ $t("config.unitForm.deleteUnit") }}</r-button
            >
          </div>
        </div>
      </div>
    </r-modal-sub-header>
  </r-paper>
</template>
<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RCheckbox from "@/plugins/atoms/RCheckbox.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RModalSubHeader from "@/plugins/molecules/RModalSubHeader.vue";

import auth from "@/libs/Auth.js";
import unitManager from "@/libs/Unit.js";

export default {
  components: {
    RTextbox,
    RButton,
    RCheckbox,
    RHeading,
    RPaper,
    RModalSubHeader,
  },
  data() {
    return {
      units: [],
      inputName: "",
    };
  },
  computed: {},
  methods: {
    toggle(index) {
      this.$set(this.units[index], "bool", !this.units[index].bool);
    },
    async inputUnit(mode) {
      if (mode === "set") {
        // 未入力の場合
        if (this.inputName === "") {
          alert(this.$i18n.t("config.unitForm.inputUnitValidationMsg"));
          return;
        }
        // 重複の場合
        if (this.units.map((e) => e.name).includes(this.inputName)) {
          alert(this.$i18n.t("config.unitForm.duplicateUnitMsg"));
          return;
        }
        this.units.push({ name: this.inputName, bool: false });
      } else if (mode === "del") {
        //削除の場合
        if (this.units.filter((e) => e.bool).length === 0) {
          alert(this.$i18n.t("config.unitForm.selectUnitToDelete"));
          return;
        }
        //選択中単位の配列を取得
        const selectedUnits = this.units.filter(function (unit) {
          return unit.bool == true;
        });
        //該当店舗ID・顧客IDでレシピ検索
        let recipeList = [];
        recipeList = await unitManager.getRecipeUsingSubUnit(
          this.customerID,
          this.storeID,
        );
        //該当単位を利用しているレシピを配列する配列を準備
        let result = [];
        //削除しない単位を格納する配列
        let notDeleteUnitArr = [];
        selectedUnits.forEach((unit) => {
          result = unitManager.searchRecipesUsingUnit(recipeList, unit.name);
          //選択したカテゴリーがレシピもしくはコースに利用されている場合
          if (result.length > 0) {
            //削除しない
            alert(
              "！「" +
                unit.name +
                "」" +
                this.$i18n.t("config.unitForm.deleteNotAvailableUsingUnit"),
            );
            notDeleteUnitArr.push(unit);
          } else {
            const deleteConfirm = window.confirm(
              this.$i18n.t("config.unitForm.unit") +
                "「" +
                unit.name +
                "」" +
                this.$i18n.t("config.unitForm.deleteConfirmMsg"),
            );
            if (!deleteConfirm) {
              //削除しないカテゴリ
              notDeleteUnitArr.push(unit);
            }
          }
        });
        //削除しないカテゴリーを選択解除
        this.units.forEach((unit) => {
          notDeleteUnitArr.forEach((notDelUnit) => {
            if (unit.name == notDelUnit.name) {
              unit.bool = false;
            }
          });
        });
        //最終的に選択されていない単位をcategoryに格納
        const tempArray = this.units.filter(function (item) {
          return item.bool == false;
        });
        this.units = tempArray;
      } else {
        return;
      }

      // firestoreに登録
      const setUnits = this.units.map((unit) => {
        return { name: unit.name };
      });
      try {
        await unitManager.setUnits(this.customerID, this.storeID, setUnits);
        this.inputName = "";
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw new Error(error);
      }
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        throw new Error("Forbidden");
      }

      this.units = await unitManager.getUnitsConfigList(
        this.customerID,
        this.storeID,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("ERROR", error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
.config-unit--content {
  margin-top: 20px;
}
.config-unit--content--item {
  margin-top: 10px;
}
.config-unit--content--cat {
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}
.config-unit--content--cat >>> .checkbox--button {
  min-height: 32px;
}
/*iPad用css*/
@media screen and (min-width: 641px) {
  .main--content--item {
    margin-top: unset;
  }
  .config--unit--registration--form {
    margin-top: 10px;
    display: grid;
    vertical-align: middle;
    grid-template-columns: 5fr 1fr;
  }
  .config-category-input-form {
    grid-template-columns: 5fr;
  }
  .config-category-input-form >>> .r-textbox--input {
    margin-right: 10px;
  }
  .config--unit--delete--form {
    margin-top: 10px;
    display: grid;
    gap: 5px;
    grid-template-columns: 5fr 1fr;
  }
  .config-unit--content--cat {
    grid-template-columns: 5fr;
    margin-right: 10px;
  }
  .config-btn-wrapper {
    grid-template-columns: 1fr;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
  }
  .config-category-btn {
    height: 40px;
  }
}
/*PC用css*/
@media screen and (min-width: 1024px) {
}
</style>

<template>
  <div>
    <r-heading :level="1" :image="headingImage">
      <!--タイトル：メニュー管理-->
      <span class="title-span">{{ $t("menu.menuTop.menuManagement") }}</span>
    </r-heading>
    <div class="menu-top">
      <div class="menu-top-wrapper">
        <!--登録完了メッセージ-->
        <r-alert class="registration-msg" v-if="$route.params.isComplete">
          {{ $t("menu.menuTop.menu") }}「{{ $route.params.bookTitle }}」{{
            $t("menu.menuTop.isRegistrated")
          }}
        </r-alert>
        <r-alert class="registration-msg" v-if="$route.params.isDeleteComplete">
          {{ $t("menu.menuTop.menu") }}「{{ $route.params.deleteBook }}」{{
            $t("menu.menuTop.deleteBookComplete")
          }}
        </r-alert>
        <!--店舗の選択-->
        <div class="select-store" v-if="!isStore">
          {{ $t("menu.menuTop.selectStore") }}:
          <r-select
            v-if="existingValue !== '' && !selectDisable"
            :options="stores"
            @input="storeSelect"
            :existingValue="existingValue"
          />
        </div>
        <!--body: メニューブックを作る-->
        <div class="btn-wrapper">
          <r-button
            class="menu-btn"
            buttonStyle="primary"
            full
            @click="openSelectForm"
          >
            <!--メニューブック選択 > 名前入力 > 作る画面に繊維順でモーダル展開-->
            <img :src="buttonImage" alt class="button-image" />
            &nbsp;&nbsp;
            {{ $t("menu.menuTop.registrateMenuBook") }}
          </r-button>
          <!--body: メニューブックを見る-->
          <r-button
            class="menu-btn"
            buttonStyle="primary"
            full
            @click="toMenuList"
            :disabled="!isBookRegistered"
            :class="isBookRegistered ? '' : 'menu-btn-disable'"
          >
            <img :src="buttonImage" alt class="button-image" />
            &nbsp;&nbsp;
            {{ $t("menu.menuTop.toMenuList") }}
          </r-button>
        </div>
        <!--レジ連携設定-->
        <div class="btn-wrapper-regi">
          <r-button
            class="regi-link-btn"
            buttonStyle="primary"
            full
            outline
            @click="bookRegiLink"
            :disabled="!isBookRegistered"
            :class="isBookRegistered ? '' : 'menu-btn-disable'"
            >{{ $t("menu.menuTop.toRegiLink") }}</r-button
          >
        </div>
        <!--メニューブック選択&名前入力モーダル-->
        <transition name="modal">
          <book-select-form
            v-if="selectModal"
            @cancel="closeModal"
            :customerID="customerID"
            :storeID="storeID"
            :menuBook="books"
            :store="selectedStore"
            class="book-select-modal"
          ></book-select-form>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import RAlert from "@/plugins/atoms/RAlert.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RSelect from "@/plugins/atoms/RSelect.vue";
import BookSelectForm from "../organisms/BookSelectForm.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";

import auth from "@/libs/Auth.js";
import menuManager from "@/libs/Menu.js";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

import _ from "lodash";
export default {
  components: {
    RAlert,
    RButton,
    RSelect,
    BookSelectForm,
    RHeading,
  },
  data() {
    return {
      customerID: "",
      storeID: "",
      // 権限
      isStore: true,
      //モーダル操作
      selectModal: false, //bookSelectForm
      inputModal: false, //
      isComplete: false, //登録完了メッセージ表示
      stores: {}, //店舗表示用object
      selectedBook: {},
      selectedIndex: 0,
      selectedStore: "", //選択したStoreID
      //メニューブック
      books: [],
      headingImage: require("../../../assets/images/bookColor.png"),
      buttonImage: require("../../../assets/images/book.png"),
      existingValue: "",
      selectDisable: false,
    };
  },
  methods: {
    async storeSelect(id) {
      if (id) {
        this.storeID = id;
        const storeName = Object.keys(this.stores).find(
          (key) => this.stores[key] === id,
        );
        this.$root.setStoreInfoAction(this.storeID, storeName);
        sessionStorage.storeID = this.storeID;
        // メニュー情報を取得する
        this.books = await menuManager.getMenuList(
          this.customerID,
          this.storeID,
        );
      } else {
        this.storeID = "";
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";
      }
    },
    openSelectForm() {
      //店舗選択を確認
      if (!this.isStore && this.storeID == "") {
        alert(this.$i18n.t("menu.menuTop.confirmSelectStore"));
        return;
      }
      //モーダル展開
      this.selectModal = true;
      //モーダルコンポーネント指定、モーダル背景固定処理
      const modal = document.querySelector(".book-select-modal");
      disableBodyScroll(modal);
    },
    toMenuList() {
      //店舗選択を確認
      if (!this.isStore && this.storeID == "") {
        alert(this.$i18n.t("menu.menuTop.confirmSelectStore"));
        return;
      }
      this.$router.push({ name: "menuList" }, () => {});
    },
    closeModal() {
      this.selectModal = false;
      //モーダル背景固定解除
      clearAllBodyScrollLocks();
    },
    bookRegiLink() {
      //店舗選択を確認
      if (!this.isStore && this.storeID == "") {
        alert(this.$i18n.t("menu.menuTop.confirmSelectStore"));
        return;
      }
      this.$router.push({ name: "menuRegiLink" }, () => {});
    },
    handleResize: _.debounce(function () {
      this.width = window.innerWidth;
    }, 1000),
    async setStore() {
      if (this.customerID) {
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";
        this.stores = await auth.getStoreList(this.customerID);

        const storesKeys = Object.keys(this.stores).map(
          (key) => this.stores[key],
        );
        storesKeys.shift();
        this.existingValue = storesKeys[0];
      }
    },
  },
  async created() {
    try {
      const isStoreUser = await auth.isStore();
      if (isStoreUser) {
        this.customerID = await auth.getCustomerID(this.$root);
        this.storeID = await auth.getStoreID(this.$root);
        if (!this.customerID || !this.storeID) {
          //customerID、StoreIDが存在しない場合はエラー
          throw new Error("Forbidden");
        }
        //メニュー情報を取得する
        this.books = await menuManager.getMenuList(
          this.customerID,
          this.storeID,
        );
      } else {
        this.isStore = false;
        this.customerID = await auth.getCustomerID(this.$root);
        if (!this.customerID) {
          //顧客IDが存在しない場合はエラー
          throw new Error("Forbidden");
        }

        this.storeID = "";
        this.$root.clearStoreInfoAction();
        sessionStorage.storeID = "";
        sessionStorage.storeName = "";

        //店舗一覧を表示
        this.setStore();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  computed: {
    isBookRegistered() {
      let isBook = false;
      //登録されたブックの情報確認
      this.books.map((book) => {
        if (book.name !== "") {
          isBook = true;
        }
      });
      return isBook;
    },
  },
  //PCサイズ対応
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style scoped>
.menu-top-wrapper {
  font-size: 1rem;
  box-sizing: border-box;
  margin: 0 auto;
  padding-top: 60px;
}
.title-span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.registration-msg {
  background-color: #fff;
  border-radius: 5px;
  margin: 15px 15px 15px 15px;
}
.select-store {
  background-color: #fff;
  border-radius: 5px;
  padding: 16px;
  margin: 0px 16px 16px;
  text-align: left;
}
.btn-wrapper {
  margin: 15px;
}
.btn-wrapper-regi {
  margin-top: 20px;
  width: 80%;
  margin: auto;
}
.button-image {
  width: 30px;
  height: 30px;
}
.menu-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 16px;
}
.menu-btn:hover {
  background-color: #ea5446;
  cursor: pointer;
  transition: all 0.5s;
}
.regi-link-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 20px;
  color: #333;
  border-radius: 10px;
  background-color: transparent;
}
.regi-link-btn:hover {
  background-color: #e87352;
  color: #fff;
  border: 2px solid #fff;
  transition: all 0.5s;
  cursor: pointer;
}
.menu-btn-disable {
  pointer-events: none;
  opacity: 0.5;
}
.book-select-modal {
  box-sizing: border-box;
}
/*iPad用css*/
@media (min-width: 641px) {
  .menu-top {
    padding-top: 80px;
  }
  .menu-top-wrapper {
    margin-top: 0px;
  }
  .title-span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .registration-msg {
    margin: 0px 20px 20px 20px;
  }
  .select-store {
    margin: 0px 20px 20px;
    padding: 15px;
  }
  .btn-wrapper {
    margin: 0px 20px 20px;
  }
  .menu-btn {
    color: #fff;
    padding: 32px;
    margin-bottom: 20px;
  }
  .regi-link-btn {
    margin: 0 auto;
    margin-top: 40px;
    width: 50%;
    padding: 16px;
    color: #333;
  }
  .btn-wrapper-regi {
    width: 100%;
  }
}
/*PC用css */
@media (min-width: 1024px) {
  .menu-top {
    padding-top: 100px;
    width: 1024px;
    margin: 0 auto;
  }
  .menu-top-wrapper {
    display: block;
  }
  .registration-msg {
    display: block;
    margin: unset;
    margin-bottom: 20px;
  }
  .select-store {
    background-color: #fff;
    border-radius: 5px;
    margin: 0px 0px 20px 0px;
    text-align: left;
  }
  .btn-wrapper {
    margin: 0px 0px 0px;
    display: flex;
  }
  .menu-btn {
    color: #fff;
    height: 104px;
    font-size: 1.2rem;
    margin-right: 20px;
  }
  .menu-btn:nth-of-type(2n) {
    margin-right: unset;
  }
  .book-select-modal {
    box-sizing: border-box;
  }
}
</style>

import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "@/libs";

const db = getFirestore();

export default {
  // メニュー一覧を取得
  async getMenuList(customerID, storeID) {
    let books = [];
    try {
      // メニュー情報を取得する
      const q = query(
        collection(db, "books"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
      );
      const querySnapshot = await getDocs(q);

      let records = new Array(
        { name: "", bookNo: 1, id: "" },
        { name: "", bookNo: 2, id: "" },
        { name: "", bookNo: 3, id: "" },
        { name: "", bookNo: 4, id: "" },
      );

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const index = Number(data.bookNo);
          data.id = doc.id;
          records[index - 1] = data;
        });
      }
      books = records;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return books;
  },

  async getSectionList(bookID) {
    let sectionList = [];
    try {
      const sectionSnapshot = await getDocs(
        collection(db, "books", bookID, "sections"),
      );

      let records = [];
      if (!sectionSnapshot.empty) {
        await Promise.all(
          sectionSnapshot.docs.map(async (doc) => {
            const sectionData = doc.data();
            const sectionID = doc.id;
            const record = {
              id: sectionID,
              no: sectionData.no,
              name: sectionData.name,
            };

            const menusSnapshot = await getDocs(
              collection(db, "books", bookID, "sections", sectionID, "menus"),
            );

            const menuArray = [];
            if (!menusSnapshot.empty) {
              await Promise.all(
                menusSnapshot.docs.map(async (doc) => {
                  const menuData = doc.data();
                  if (menuData.recipeRef) {
                    // recipeリファレンスからデータを取得
                    try {
                      const menuMetaData = await getDoc(menuData.recipeRef);
                      if (menuMetaData.exists()) {
                        const menu = menuMetaData.data();
                        menu.menuID = doc.id;
                        menu.recipeRef = menuData.recipeRef || null;
                        menu.courseRef = menuData.courseRef || null;
                        menuArray.push(menu);
                      }
                    } catch (e) {
                      // 何もしない
                    }
                  } else if (menuData.courseRef) {
                    console.log("==menuData==", menuData);

                    // courseリファレンスからデータを取得
                    const menuMetaData = await getDoc(menuData.courseRef);
                    if (menuMetaData.exists()) {
                      const menu = menuMetaData.data();
                      // 該当のコースのトータルコストおよび原価率の計算をする
                      const refFactorSnapshot = await getDocs(
                        collection(db, "courses", menuMetaData.id, "factors"),
                      );
                      // 登録したレシピのトータルコストを取得
                      if (refFactorSnapshot.empty) {
                        throw new Error("no factor data");
                      }

                      let totalCost = 0;
                      await Promise.all(
                        refFactorSnapshot.docs.map(async (factorDoc) => {
                          const factor = factorDoc.data();
                          const recipeRef = await getDoc(factor.recipeRef);
                          if (!recipeRef.exists()) {
                            throw new Error("no recipe data");
                          }
                          const recipe = recipeRef.data();
                          totalCost += Number(recipe.totalCost);
                        }),
                      );

                      // 原価率の計算
                      let costRate;
                      if (menu.price === 0) {
                        costRate = 0;
                      } else {
                        costRate =
                          (Number(totalCost) / Number(menu.price)) * 100;
                      }
                      menu.menuID = doc.id;
                      menu.name = menu.title;
                      menu.recipeRef = null;
                      menu.courseRef = menuData.courseRef;
                      menu.costRate = Number(costRate).toFixed(2);
                      menuArray.push(menu);
                    }
                  } else {
                    throw new Error("no data");
                  }
                }),
              );
            }
            // メニュー番号(メニューID)順にソート
            menuArray.sort((a, b) => {
              const menuIDa = a.menuID.toUpperCase();
              const menuIDb = b.menuID.toUpperCase();
              return menuIDa.localeCompare(menuIDb);
            });
            // 表示用原価率を設定
            let costRate = 0;
            const costArr = menuArray.map((e) => e.costRate).flat();
            costRate = costArr.reduce((acc, cost) => {
              acc += cost / costArr.length;
              return parseFloat(acc.toFixed(2));
            }, 0);
            record.costRate = costRate;
            // メニュー情報を登録
            record.menus = menuArray;
            records.push(record);
          }),
        );
      }
      // セクション番号順にソート
      records.sort((a, b) => a.no - b.no);
      sectionList = records;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return sectionList;
  },

  // メニュー情報を作成
  async createBook(customerID, storeID, bookNo, bookTitle, sections) {
    try {
      const refBook = collection(db, "books");
      const result = await addDoc(refBook, {
        customerID,
        storeID,
        bookNo: Number(bookNo) + 1,
        name: bookTitle,
        createAt: serverTimestamp(),
        updateAt: serverTimestamp(),
      });

      const bookID = result.id;

      // セクションの登録
      const refSection = collection(db, "books", bookID, "sections");
      await Promise.all(
        sections.map(async (section, index) => {
          const resSection = await addDoc(refSection, {
            no: Number(index) + 1,
            customerID,
            storeID,
            name: section.name,
            createAt: serverTimestamp(),
            updateAt: serverTimestamp(),
          });

          // 登録したセクションIDを設定
          const sectionID = resSection.id;
          // レシピのリファレンスを登録
          const refMenu = collection(
            db,
            "books",
            bookID,
            "sections",
            sectionID,
            "menus",
          );
          const menus = section.menus;

          await Promise.all(
            menus.map(async (menu) => {
              await addDoc(refMenu, {
                customerID,
                storeID,
                recipeRef: menu.recipeRef || null,
                courseRef: menu.courseRef || null,
                regiID: "",
                createAt: serverTimestamp(),
                updateAt: serverTimestamp(),
              });
            }),
          );
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return;
  },

  // メニュー情報を更新を取得
  async updateBook(
    customerID,
    storeID,
    bookID,
    orgBookTitle,
    bookTitle,
    sections,
    orgSections,
  ) {
    try {
      if (orgBookTitle !== bookTitle) {
        // メニューブック名称更新の場合update
        const refBook = doc(db, "books", bookID);
        await updateDoc(refBook, {
          name: bookTitle,
        });
      }

      // セクションの登録
      const refSection = collection(db, "books", bookID, "sections");
      await Promise.all(
        sections.map(async (section, index) => {
          // セクションID
          let sectionID = section.id;
          if (sectionID) {
            const refSectionDoc = doc(
              db,
              "books",
              bookID,
              "sections",
              sectionID,
            );
            await updateDoc(refSectionDoc, {
              no: Number(index) + 1,
              name: section.name,
              updateAt: serverTimestamp(),
            });
          } else {
            const resSection = await addDoc(refSection, {
              customerID,
              storeID,
              no: Number(index) + 1,
              name: section.name,
              createAt: serverTimestamp(),
              updateAt: serverTimestamp(),
            });
            sectionID = resSection.id;
          }

          // 更新
          const refMenu = collection(
            db,
            "books",
            bookID,
            "sections",
            sectionID,
            "menus",
          );
          // レシピのリファレンスを登録
          const menus = section.menus;
          await Promise.all(
            menus.map(async (menu) => {
              if (menu.menuID) {
                const refMenuDoc = doc(
                  db,
                  "books",
                  bookID,
                  "sections",
                  sectionID,
                  "menus",
                  menu.menuID,
                );
                await updateDoc(refMenuDoc, {
                  recipeRef: menu.recipeRef || null,
                  courseRef: menu.courseRef || null,
                  updateAt: serverTimestamp(),
                });
              } else {
                await addDoc(refMenu, {
                  customerID,
                  storeID,
                  recipeRef: menu.recipeRef || null,
                  courseRef: menu.courseRef || null,
                  regiID: "",
                  createAt: serverTimestamp(),
                  updateAt: serverTimestamp(),
                });
              }
            }),
          );
        }),
      );
      // 削除処理
      await Promise.all(
        orgSections.map(async (oldSection) => {
          const oldID = oldSection.id;
          if (!sections.find((section) => section.id === oldID)) {
            const refSectionDoc = doc(db, "books", bookID, "sections", oldID);
            await deleteDoc(refSectionDoc);
          } else {
            // セクションが存在している場合は、menuが削除されているか確認
            const oldMenus = oldSection.menus;
            // 該当セクションのメニューを取得
            const targetMenus = sections.find(
              (section) => section.id === oldID,
            ).menus;
            await Promise.all(
              oldMenus.map(async (oldMenu) => {
                if (
                  !targetMenus.find((menu) => menu.menuID === oldMenu.menuID)
                ) {
                  const refMenuDoc = doc(
                    db,
                    "books",
                    bookID,
                    "sections",
                    oldID,
                    "menus",
                    oldMenu.menuID,
                  );
                  await deleteDoc(refMenuDoc);
                }
              }),
            );
          }
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  async getRegiInfo(bookID) {
    let sections = [];
    try {
      const sectionSnapshot = await getDocs(
        collection(db, "books", bookID, "sections"),
      );

      let records = [];
      if (!sectionSnapshot.empty) {
        await Promise.all(
          sectionSnapshot.docs.map(async (doc) => {
            const sectionData = doc.data();
            const sectionID = doc.id;
            const record = {
              id: sectionID,
              no: sectionData.no,
              name: sectionData.name,
            };

            const menusSnapshot = await getDocs(
              collection(db, "books", bookID, "sections", sectionID, "menus"),
            );

            const menuArray = [];
            if (!menusSnapshot.empty) {
              await Promise.all(
                menusSnapshot.docs.map(async (menuDoc) => {
                  const menuData = menuDoc.data();
                  menuData.menuID = menuDoc.id;

                  if (menuData.recipeRef) {
                    try {
                      // recipeリファレンスからデータを取得
                      const recipeData = await getDoc(menuData.recipeRef);
                      if (recipeData.exists()) {
                        const recipe = recipeData.data();
                        menuData.name = recipe.name;
                        menuArray.push(menuData);
                      }
                    } catch (e) {
                      // Handle error if needed
                    }
                  } else if (menuData.courseRef) {
                    try {
                      // courseリファレンスからデータを取得
                      const courseData = await getDoc(menuData.courseRef);
                      if (courseData.exists()) {
                        const course = courseData.data();
                        menuData.name = course.title;
                        menuArray.push(menuData);
                      }
                    } catch (e) {
                      // Handle error if needed
                    }
                  } else {
                    throw new Error("no data");
                  }
                }),
              );
            }
            // メニュー情報を登録
            record.menus = menuArray;
            records.push(record);
          }),
        );
      }
      sections = records;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return sections;
  },
  async getMenuInfo(customerID, storeID, i18n) {
    let bookList = [];
    try {
      // BOOKSを取得
      const q = query(
        collection(db, "books"),
        where("customerID", "==", customerID),
        where("storeID", "==", storeID),
      );
      const querySnapshot = await getDocs(q);

      let records = {};
      records[i18n.t("menu.menuRegiLink.selectMenuBook")] = "";
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          records[data.name] = doc.id;
        });
      }
      bookList = records;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
    return bookList;
  },
  async setRegiInfo(bookID, sections) {
    try {
      await Promise.all(
        sections.map(async (section) => {
          const menus = section.menus;
          await Promise.all(
            menus.map(async (menu) => {
              const refMenu = doc(
                db,
                "books",
                bookID,
                "sections",
                section.id,
                "menus",
                menu.menuID,
              );

              const menuSnapshot = await getDoc(refMenu);
              if (menuSnapshot.exists()) {
                await updateDoc(refMenu, {
                  regiID: menu.regiID || "",
                  smaregiCategoryID: menu.smaregiCategoryID || "",
                  updateAt: serverTimestamp(),
                });
                if (menu.recipeRef) {
                  await updateDoc(menu.recipeRef, {
                    regiID: menu.regiID || "",
                    smaregiCategoryID: menu.smaregiCategoryID || "",
                    updateAt: serverTimestamp(),
                  });
                }
              } else {
                // eslint-disable-next-line no-console
                console.warn(
                  "Document does not exist:",
                  `bookID: ${bookID}`,
                  `sectionID: ${section.id}`,
                  `menuID: ${menu.menuID}`,
                );
              }
            }),
          );
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  // レジID登録
  async linkSmaRegi(customerID, storeID, bookID) {
    try {
      // スマレジ登録
      const updateSmaregi = httpsCallable(functions, "updateSmaregi");
      await updateSmaregi({
        customerID: customerID,
        storeID: storeID,
        bookID: bookID,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  // メニューの削除
  async deleteBook(bookID) {
    try {
      // データの削除-サブコレクションはFunctionでコマンドで削除
      const refBook = doc(db, "books", bookID);
      await deleteDoc(refBook);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  //重複レジIDをチェックして該当重複レジIDを返す。
  checkDuplicateRegiID(books) {
    const regiIDArr = books.flatMap((book) =>
      book.sections.flatMap((section) =>
        section.menus
          .filter((menu) => menu.regiID)
          .map((menu) => ({
            bookID: book.bookID,
            sectionID: section.id,
            menuID: menu.menuID,
            regiID: menu.regiID,
          })),
      ),
    );

    const regiIDs = regiIDArr.map((e) => e.regiID);
    const result = regiIDs.filter(
      (id, _index, self) => self.indexOf(id) !== self.lastIndexOf(id),
    );

    //重複しているregiIDを格納している配列を返す
    return [...new Set(result)];
  },
  checkDuplicateSmaregiCategoryID(books) {
    const categoryIDArr = books.flatMap((book) =>
      book.sections.flatMap((section) =>
        section.menus
          .filter((menu) => menu.smaregiCategoryID)
          .map((menu) => ({
            bookID: book.bookID,
            sectionID: section.id,
            menuID: menu.menuID,
            smaregiCategoryID: menu.smaregiCategoryID,
          })),
      ),
    );

    const categoryIDs = categoryIDArr.map((e) => e.smaregiCategoryID);
    const result = categoryIDs.filter(
      (id, _index, self) => self.indexOf(id) !== self.lastIndexOf(id),
    );

    //重複しているregiIDを格納している配列を返す
    return [...new Set(result)];
  },
};

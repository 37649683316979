<template>
  <r-modal
    class="config-edituser"
    :title="
      $t('config.edituserForm.user') +
      `「${displayName}」` +
      $t('config.edituserForm.userEdit')
    "
    cancelHidden
  >
    <!-- ユーザーの編集画面 -->
    <div class="config-edituser--main" v-if="!confirm">
      <div class="config-edituser--main--about-password">
        {{ $t("config.edituserForm.passwordNotVisibleOption") }}
      </div>
      <!--ユーザーID-->
      <div class="config-edituser--main--content">
        <r-heading :level="4"
          >{{ $t("config.createEditForm.userID") }}*</r-heading
        >
        <div class="config-edituser-id--form">
          <div class="config-userid-input-form">
            <r-textbox
              type="string"
              v-model="userID"
              name="userID"
              :error="error.userID !== ''"
            />
          </div>
        </div>
        <div class="error-message">{{ error.userID }}</div>
      </div>
      <!--パスワード-->
      <div class="config-edituser--main--content">
        <r-heading :level="4">{{ $t("common.password") }}</r-heading>
        <div class="config-createuser-pass--registration--form">
          <div class="config-userpass-input-form">
            <form>
              <r-textbox
                class="config-edituser--main--content--item config-userpass-input-form"
                type="password"
                v-model="password"
                name="password"
                v-if="!display"
                :placeholder="$t('config.edituserForm.leaveEmptyNotEditPW')"
              />
              <r-textbox
                class="config-edituser--main--content--item config-userpass-input-form"
                v-model="password"
                name="displayPassword"
                v-if="display"
              />
            </form>
          </div>
          <div class="config-btn-wrapper">
            <r-button
              class="config-edituser--main--content--item config-create-password-btn"
              buttonStyle="secondary"
              full
              type="password"
              @click="generatePassword"
              >{{ $t("config.createEditForm.generatePassword") }}</r-button
            >
            <r-button
              class="config-edituser--main--content--item config-create-password-btn"
              buttonStyle="secondary"
              full
              @click="displayPassword"
              v-if="!display"
              >{{ $t("config.createEditForm.displayPassword") }}</r-button
            >
            <r-button
              class="config-edituser--main--content--item config-create-password-btn"
              buttonStyle="secondary"
              full
              @click="hiddenPassword"
              v-if="display"
              >{{ $t("config.createEditForm.hiddenPassword") }}</r-button
            >
          </div>
        </div>
        <div class="error-message">{{ error.password }}</div>
      </div>
      <!--ユーザー名-->
      <div class="config-edituser--main--content">
        <r-heading :level="4"
          >{{ $t("config.createEditForm.userName") }}*</r-heading
        >
        <div class="config-edituser-display--form">
          <div class="config-userdisplayname-input-form">
            <r-textbox
              v-model="displayName"
              name="displayName"
              :error="error.displayName !== ''"
            />
          </div>
        </div>
        <div class="error-message">{{ error.displayName }}</div>
      </div>

      <div class="config-edituser--main--content">
        <r-heading :level="4">{{
          $t("config.createEditForm.giveUserAuthority")
        }}</r-heading>
        <r-list>
          <r-list-content>
            <r-list-item>
              <input type="checkbox" id="viewRp" v-model="viewRp" />
              {{ $t("config.createEditForm.viewRp") }}
            </r-list-item>
          </r-list-content>
          <r-list-content>
            <r-list-item>
              <input type="checkbox" id="viewOtherRp" v-model="viewOtherRp" />
              {{ $t("config.createEditForm.viewOtherRp") }}
            </r-list-item>
          </r-list-content>
          <r-list-content>
            <r-list-item>
              <input type="checkbox" id="createRp" v-model="createRp" />
              {{ $t("config.createEditForm.createRp") }}
            </r-list-item>
          </r-list-content>
          <r-list-content>
            <r-list-item>
              <input type="checkbox" id="viewCost" v-model="viewCost" />
              {{ $t("config.createEditForm.viewCost") }}
            </r-list-item>
          </r-list-content>
          <r-list-content>
            <r-list-item>
              <input type="checkbox" id="viewBook" v-model="viewBook" />
              {{ $t("config.createEditForm.viewBook") }}
            </r-list-item>
          </r-list-content>
          <r-list-content>
            <r-list-item>
              <input type="checkbox" id="createBook" v-model="createBook" />
              {{ $t("config.createEditForm.createBook") }}
            </r-list-item>
          </r-list-content>
          <r-list-content>
            <r-list-item>
              <input type="checkbox" id="viewInfo" v-model="viewInfo" />
              {{ $t("config.createEditForm.viewInfo") }}
            </r-list-item>
          </r-list-content>
          <r-list-content>
            <r-list-item>
              <input type="checkbox" id="editCat" v-model="editCat" />
              {{ $t("config.createEditForm.editCat") }}
            </r-list-item>
          </r-list-content>
          <r-list-content>
            <r-list-item>
              <input type="checkbox" id="editUser" v-model="editUser" />
              {{ $t("config.createEditForm.editUser") }}
            </r-list-item>
          </r-list-content>
          <r-list-content>
            <r-list-item>
              <input type="checkbox" id="selectSup" v-model="selectSup" />
              {{ $t("config.createEditForm.selectSup") }}
            </r-list-item>
          </r-list-content>
          <r-list-content>
            <r-list-item>
              <input type="checkbox" id="viewHidden" v-model="viewHidden" />
              {{ $t("config.createEditForm.viewHidden") }}
            </r-list-item>
          </r-list-content>
        </r-list>
      </div>

      <r-button
        class="config-edituser--main--content"
        buttonStyle="primary"
        full
        @click="inputConfirm"
        >{{ $t("config.createEditForm.inputConfirm") }}</r-button
      >
      <r-button
        class="config-edituser--main--content"
        buttonStyle="primary"
        outline
        full
        @click="close"
        >{{ $t("config.edituserForm.cancelBtn") }}</r-button
      >
    </div>
    <!-- ユーザーの編集画面ここまで -->
    <!-- 確認画面ここから -->
    <div class="config-edituser--main" v-else>
      <r-spinner v-if="loading" />
      <template v-else>
        {{ $t("config.createEditForm.inputEssentialItem") }}
        <div class="config-edituser--main--content">
          <r-heading :level="4"
            >{{ $t("config.createEditForm.userID") }} *</r-heading
          >
          <span>{{ userID }}</span>
        </div>

        <div class="config-edituser--main--content">
          <r-heading :level="4">{{ $t("common.password") }}</r-heading>
          <div v-if="password">
            <div v-if="!displayConfirm">
              {{ maskPassword }}
              <r-button
                buttonStyle="secondary"
                @click="displayConfirmPassword"
                >{{ $t("config.createEditForm.displayPassword") }}</r-button
              >
            </div>
            <div v-else>
              {{ password }}
              <r-button
                buttonStyle="secondary"
                @click="hiddenConfirmPassword"
                >{{ $t("config.createEditForm.hiddenPassword") }}</r-button
              >
            </div>
          </div>
          <div v-else>{{ $t("config.edituserForm.noEditInPassword") }}</div>
        </div>

        <div class="config-edituser--main--content">
          <r-heading :level="4">{{
            $t("config.createEditForm.userName")
          }}</r-heading>
          {{ displayName }}
        </div>

        <div class="config-edituser--main--content">
          <r-heading :level="4">{{
            $t("config.createEditForm.giveUserAuthority")
          }}</r-heading>
          <span>{{ $t("config.createEditForm.explain") }}</span>
          <r-list>
            <r-list-content v-if="viewRp">
              <r-list-item>{{
                $t("config.createEditForm.viewRp")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="viewOtherRp">
              <r-list-item>{{
                $t("config.createEditForm.viewOtherRp")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="createRp">
              <r-list-item>{{
                $t("config.createEditForm.createRp")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="viewCost">
              <r-list-item>{{
                $t("config.createEditForm.viewCost")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="viewBook">
              <r-list-item>{{
                $t("config.createEditForm.viewBook")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="createBook">
              <r-list-item>{{
                $t("config.createEditForm.createBook")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="viewInfo">
              <r-list-item>{{
                $t("config.createEditForm.viewInfo")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="editCat">
              <r-list-item>{{
                $t("config.createEditForm.editCat")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="editUser">
              <r-list-item>{{
                $t("config.createEditForm.editUser")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="selectSup">
              <r-list-item>{{
                $t("config.createEditForm.selectSup")
              }}</r-list-item>
            </r-list-content>
            <r-list-content v-if="viewHidden">
              <r-list-item>{{
                $t("config.createEditForm.viewHidden")
              }}</r-list-item>
            </r-list-content>
          </r-list>
        </div>

        <div class="config-edituser--main--content">
          <r-button
            class="config-edituser--main--content--button"
            buttonStyle="primary"
            full
            @click="regist"
            >{{ $t("config.createEditForm.regist") }}</r-button
          >
          <r-button
            class="config-edituser--main--content--button"
            buttonStyle="primary"
            full
            outline
            @click="inputCorrection"
            >{{ $t("config.createEditForm.inputCorrection") }}</r-button
          >
        </div>
      </template>
    </div>
    <!-- 確認画面ここまで -->
  </r-modal>
</template>
<script>
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RModal from "@/plugins/molecules/RModal.vue";
import RHeading from "@/plugins/atoms/RHeading.vue";
import RSpinner from "@/plugins/atoms/RSpinner.vue";

import auth from "@/libs/Auth.js";
import userManager from "@/libs/User.js";

export default {
  components: {
    RTextbox,
    RButton,
    RList,
    RListContent,
    RListItem,
    RModal,
    RHeading,
    RSpinner,
  },
  props: {
    editUserID: String,
  },
  data() {
    return {
      display: false,
      displayConfirm: false,
      confirm: false,
      id: "",
      userID: "",
      password: "",
      displayName: "",
      viewRp: "",
      viewOtherRp: "",
      createRp: "",
      viewCost: "",
      viewBook: "",
      createBook: "",
      viewInfo: "",
      editCat: "",
      editUser: "",
      selectSup: "",
      viewHidden: "",
      error: {
        userID: "",
        displayName: "",
        password: "",
      },
      loading: false,
    };
  },
  computed: {
    maskPassword() {
      return "*".repeat(this.password.length);
    },
  },
  watch: {
    createRp: function () {
      if (this.createRp) {
        this.viewCost = true;
      }
    },
    createBook: function () {
      if (this.createBook) {
        this.viewCost = true;
        this.viewBook = true;
      }
    },
    viewInfo: function () {
      if (this.viewInfo) {
        this.viewCost = true;
      }
    },
    viewCost: function () {
      if (!this.viewCost) {
        this.createRp = false;
        this.createBook = false;
        this.viewInfo = false;
      }
    },
    viewBook: function () {
      if (!this.viewBook) {
        this.createBook = false;
      }
    },
  },
  methods: {
    generatePassword() {
      this.password = this.generateRandomString(10);
    },
    displayPassword() {
      this.display = true;
    },
    hiddenPassword() {
      this.display = false;
    },
    displayConfirmPassword() {
      this.displayConfirm = true;
    },
    hiddenConfirmPassword() {
      this.displayConfirm = false;
    },
    inputConfirm() {
      // バリデーションを実行
      this.executeValidation();
      if (this.error.userID !== "" || this.error.displayName !== "") {
        return;
      }

      this.confirm = true;
    },
    executeValidation() {
      // userIDのバリデーション
      if (this.userID === "") {
        this.$set(
          this.error,
          "userID",
          this.$i18n.t("config.edituserForm.inputValidationMsg"),
        );
      } else if (!this.userID.match(/^[A-Za-z0-9]*$/)) {
        this.$set(
          this.error,
          "userID",
          this.$i18n.t("config.edituserForm.halfSizeEngIntInputValidation"),
        );
      } else {
        this.$set(this.error, "userID", "");
      }

      // nameのバリデーション
      if (this.displayName === "") {
        this.$set(
          this.error,
          "displayName",
          this.$i18n.t("config.edituserForm.inputValidationMsg"),
        );
      } else {
        this.$set(this.error, "displayName", "");
      }
    },
    inputCorrection() {
      this.confirm = false;
    },
    async regist() {
      this.loading = true;
      try {
        // ユーザ情報をupdate
        const userInfo = {
          id: this.id,
          userID: this.userID,
          password: this.password,
          displayName: this.displayName,
          customerID: this.customerID,
          storeID: this.storeID,
          viewRp: this.viewRp || false,
          viewOtherRp: this.viewOtherRp || false,
          createRp: this.createRp || false,
          viewCost: this.viewCost || false,
          viewBook: this.viewBook || false,
          createBook: this.createBook || false,
          viewInfo: this.viewInfo || false,
          editCat: this.editCat || false,
          editUser: this.editUser || false,
          selectSup: this.selectSup || false,
          viewHidden: this.viewHidden || false,
        };

        // ユーザ情報を取得
        await userManager.updateUser(userInfo);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR", error);
      }
      this.loading = false;
      this.close();
    },
    generateRandomString(limit) {
      return userManager.generateRandomString(limit);
    },
    close() {
      //compnayName(会社名)、customerName(顧客名)に修正
      const message =
        `${this.$root.privateState.customerName}` +
        this.$i18n.t("config.edituserForm.userOf") +
        `「${this.displayName}」` +
        this.$i18n.t("config.edituserForm.editUserComplete");
      this.$emit("displayAlert", message);
    },
  },
  async created() {
    this.customerID = await auth.getCustomerID(this.$root);
    this.storeID = await auth.getStoreID(this.$root);
    if (!this.customerID || !this.storeID) {
      // customerID、StoreIDが存在しない場合はエラー
      throw new Error("Forbidden");
    }

    try {
      // ユーザ情報を取得
      const userInfo = await userManager.getUserInfo(this.editUserID);
      // データをセット
      this.id = userInfo.id;
      this.userID = userInfo.userID;
      this.displayName = userInfo.displayName;
      this.viewRp = userInfo.viewRp;
      this.viewOtherRp = userInfo.viewOtherRp;
      this.createRp = userInfo.createRp;
      this.viewCost = userInfo.viewCost;
      this.viewBook = userInfo.viewBook;
      this.createBook = userInfo.createBook;
      this.viewInfo = userInfo.viewInfo;
      this.editCat = userInfo.editCat;
      this.editUser = userInfo.editUser;
      this.selectSup = userInfo.selectSup;
      this.viewHidden = userInfo.viewHidden;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
.config-edituser--main {
  text-align: left;
}
.config-edituser--main--about-password {
  color: #e85951;
  text-decoration: underline;
}
.config-edituser--main--content--button {
  margin-top: 10px;
}
.config-edituser--main--content {
  margin-top: 20px;
}
.config-edituser--main--content--item {
  margin-top: 10px;
}

.error-message {
  height: 10px;
  text-align: left;
  color: #e85951;
  font-weight: bold;
}
.config-unit--content--cat >>> .checkbox--button {
  min-height: 32px;
}
/*iPad用css*/
@media screen and (min-width: 641px) {
  .config-edituser--main--content--item {
    margin-top: unset;
  }
  .config-edituser-id--form {
    margin-top: 10px;
    display: grid;
    vertical-align: middle;
    grid-template-columns: 4fr 2fr;
    gap: 10px;
  }
  .config-userid-input-form {
    grid-template-columns: 4fr;
  }
  /*パスワード*/
  .config-createuser-pass--registration--form {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 4fr 2fr;
    gap: 10px;
    vertical-align: middle;
  }
  .config-userpass-input-form {
    grid-template-columns: 4fr;
  }
  /*ユーザー名*/
  .config-edituser-display--form {
    margin-top: 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 4fr 2fr;
    gap: 10px;
  }
  .config-userdisplayname-input-form {
    grid-template-columns: 4fr;
  }
  .config-btn-wrapper {
    display: flex;
  }
  .config-create-password-btn {
    width: 50%;
  }
  .config-create-password-btn:nth-of-type(2n-1) {
    margin-right: 5px;
  }
}
/*PC用css*/
@media screen and (min-width: 1024px) {
}
</style>

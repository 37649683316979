<template>
  <div class="detailed-data-list">
    <table class="detailed-data-list--table">
      <thead>
        <tr class="detailed-data-list--items" ref="tableHeader">
          <th class="detailed-data-list--item">{{ $t("common.menu") }}</th>
          <th
            class="detailed-data-list--head sortable"
            @click="sortBy('price')"
          >
            <div class="detailed-data-list--head--inner">
              {{ $t("analysis.salesPrice") }}
              <font-awesome-icon class="sort-icon" :icon="['fas', 'sort']" />
            </div>
          </th>
          <th
            class="detailed-data-list--head sortable"
            @click="sortBy('outNumber')"
          >
            <div class="detailed-data-list--head--inner">
              {{ $t("analysis.salesQuantity") }}
              <font-awesome-icon class="sort-icon" :icon="['fas', 'sort']" />
            </div>
          </th>
          <th
            class="detailed-data-list--head sortable"
            @click="sortBy('amountOfSales')"
          >
            <div class="detailed-data-list--head--inner">
              {{ $t("analysis.salesAmount") }}
              <font-awesome-icon class="sort-icon" :icon="['fas', 'sort']" />
            </div>
          </th>
          <th
            class="detailed-data-list--head sortable"
            @click="sortBy('compositionRatio')"
          >
            <div class="detailed-data-list--head--inner">
              {{ $t("analysis.sales") }}
              <br />
              {{ $t("analysis.compositionRatio") }}
              <font-awesome-icon class="sort-icon" :icon="['fas', 'sort']" />
            </div>
          </th>
          <th
            class="detailed-data-list--head sortable"
            @click="sortBy('totalSalesCompositionRatio')"
          >
            <div class="detailed-data-list--head--inner">
              {{ $t("analysis.cumulativeSales") }}
              <br />
              {{ $t("analysis.compositionRatio") }}
              <font-awesome-icon class="sort-icon" :icon="['fas', 'sort']" />
            </div>
          </th>
          <th class="detailed-data-list--head">
            {{ $t("analysis.abcRanking") }}
          </th>
          <th class="detailed-data-list--head sortable" @click="sortBy('cost')">
            <div class="detailed-data-list--head--inner">
              {{ $t("analysis.cost") }}
              <font-awesome-icon class="sort-icon" :icon="['fas', 'sort']" />
            </div>
          </th>
          <th class="detailed-data-list--head sortable" @click="sortBy('rate')">
            <div class="detailed-data-list--head--inner">
              {{ $t("analysis.costRate") }}
              <font-awesome-icon class="sort-icon" :icon="['fas', 'sort']" />
            </div>
          </th>
          <th
            class="detailed-data-list--head sortable"
            @click="sortBy('income')"
          >
            <div class="detailed-data-list--head--inner">
              {{ $t("analysis.netIncome") }}
              <font-awesome-icon class="sort-icon" :icon="['fas', 'sort']" />
            </div>
          </th>
        </tr>
      </thead>

      <tr
        v-for="(data, index) in data"
        :key="index"
        class="detailed-data-list--items detailed-data-list--value"
      >
        <td class="detailed-data-list--item td-1" align="left">
          {{ data.recipe }}
          <b class="detailed-data-list--item--ast" v-if="data.multi">*</b>
        </td>
        <td class="detailed-data-list--item td-1">
          {{ data.price | localNumber }}
        </td>
        <td class="detailed-data-list--item td-1">
          {{ data.outNumber | localNumber }}
        </td>
        <td class="detailed-data-list--item td-1">
          {{ data.amountOfSales | localNumber }}
        </td>
        <td class="detailed-data-list--item td-1">
          {{ data.compositionRatio | localNumber }}
        </td>
        <td class="detailed-data-list--item td-1">
          {{ data.totalSalesCompositionRatio }}%
        </td>
        <td class="detailed-data-list--item td-1">{{ data.abc }}</td>
        <td class="detailed-data-list--item td-1">
          {{ data.cost | localNumber }}
        </td>
        <td class="detailed-data-list--item td-1">
          {{ data.rate | localNumber }}
        </td>
        <td class="detailed-data-list--item td-1">
          {{ data.income | localNumber }}
        </td>
      </tr>

      <tfoot>
        <tr class="detailed-data-list--items">
          <td class="detailed-data-list--item td-2"></td>
          <td class="detailed-data-list--item td-2">-</td>
          <td class="detailed-data-list--item td-2">
            {{ sumOfSalesQuantity | localNumber }}
          </td>
          <td class="detailed-data-list--item td-2">
            {{ sumOfAmountOfSales | localNumber }}
          </td>
          <td class="detailed-data-list--item td-2">-</td>
          <td class="detailed-data-list--item td-2">-</td>
          <td class="detailed-data-list--item td-2">-</td>
          <td class="detailed-data-list--item td-2">-</td>
          <td class="detailed-data-list--item td-2">{{ totalRate }}</td>
          <td class="detailed-data-list--item td-2">
            {{ sumOfIncome | localNumber }}
          </td>
        </tr>
      </tfoot>
    </table>
    <r-button
      outline
      full
      buttonStyle="primary"
      class="detailed-data--content"
      @click="downloadCSV"
      v-if="isDeviceTypePC"
    >
      {{ $t("analysis.saveDataAsCSVFile") }}</r-button
    >
  </div>
</template>

<script>
import RButton from "@/plugins/atoms/RButton.vue";

import { UAParser } from "ua-parser-js";

export default {
  components: {
    RButton,
  },
  props: {
    displayValue: {
      type: Array,
    },
  },
  data() {
    return {
      isDeviceTypePC: false,
      width: 0,
      data: [],
    };
  },
  computed: {
    isShowDownloadButton() {
      if (this.userAgent.includes("ipad")) {
        return "ipad";
      } else {
        return "hogehoge";
      }
    },
    sumOfSalesQuantity() {
      return this.data.reduce((acc, val) => {
        acc += val.outNumber;
        return acc;
      }, 0);
    },
    sumOfAmountOfSales() {
      return this.data.reduce((acc, val) => {
        acc += val.amountOfSales;
        return acc;
      }, 0);
    },
    sumOfIncome() {
      return this.data.reduce((acc, val) => {
        acc += val.income;
        return acc;
      }, 0);
    },
    totalRate() {
      return "";
    },
  },
  methods: {
    // 売上高
    calcAmountOfSales(price, outNumber) {
      return price * outNumber;
    },
    // 総売上高
    calcAmountOfSalesTotal() {
      const amountOfSalesArray = this.displayValue.map((e) =>
        this.calcAmountOfSales(e.price, e.outNumber),
      );
      return amountOfSalesArray.reduce((a, b) => {
        return a + b;
      });
    },
    // 売上構成比
    calcCompositionRatio(price, outNumber) {
      return Number(
        (
          (this.calcAmountOfSales(price, outNumber) /
            this.calcAmountOfSalesTotal()) *
          100
        ).toFixed(2),
      );
    },
    judgeDeviceTypePC() {
      const parser = new UAParser();
      const deviceType = parser.getDevice().type;
      const browserType = parser.getBrowser().name;
      if (browserType === "Safari") {
        const ua = window.navigator.userAgent.toLowerCase();
        if (
          ua.indexOf("ipad") > -1 ||
          (ua.indexOf("macintosh") > -1 && "ontouchend" in document)
        ) {
          this.isDeviceTypePC = false;
        } else {
          this.isDeviceTypePC = true;
        }
      } else {
        this.isDeviceTypePC =
          deviceType !== "mobile" && deviceType !== "tablet";
      }
    },
    // 原価率
    calcRate(price, cost) {
      return Number(((cost / price) * 100).toFixed(2));
    },
    // 売上純利益
    calcIncome(price, outNumber, cost) {
      return this.calcAmountOfSales(price, outNumber) - cost * outNumber;
    },
    createdListData(arr) {
      return arr.reduce((acc, val) => {
        acc.push({
          recipe: val.recipe,
          price: val.price,
          outNumber: val.outNumber,
          multi: val.multi,
          amountOfSales: this.calcAmountOfSales(val.price, val.outNumber),
          compositionRatio: this.calcCompositionRatio(val.price, val.outNumber),
          totalSalesCompositionRatio: Math.round(val.totalRatio * 10000) / 100,
          abc: val.rank,
          cost: val.cost,
          rate: this.calcRate(val.price, val.cost),
          income: this.calcIncome(val.price, val.outNumber, val.cost),
        });
        return acc;
      }, []);
    },
    // 並べ替え
    sortBy(key) {
      // 並び順を制御
      if (this.data[0][key] > this.data[this.data.length - 1][key]) {
        this.direction = "asc";
      } else {
        this.direction = "des";
      }

      if (this.direction === "asc") {
        this.data = this.data.sort((a, b) => {
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
          return 0;
        });
      } else {
        this.data = this.data.sort((a, b) => {
          if (a[key] < b[key]) return 1;
          if (a[key] > b[key]) return -1;
          return 0;
        });
      }
    },
    downloadCSV() {
      const Encoding = require("encoding-japanese");
      const FileSaver = require("file-saver");

      // 出力するオブジェクトのキー
      const keys = Object.keys(this.data[0]).filter((e) => e !== "multi");
      let formatCSV = this.data.reduce((acc, val) => {
        // 行を作成
        let line = keys.reduce((acc2, key) => {
          acc2 += `"${val[key]}",`;
          return acc2;
        }, "");
        line = line.slice(0, -1) + "\n";
        acc += line;
        return acc;
      }, "メニュー,売値,販売個数,売上高,売上構成比,累計売上構成比,ABCランク,原価,原価率,売上純利益\n");

      // 文字列をutfの配列に変換
      const unicodeList = [];
      for (let i = 0; i < formatCSV.length; i += 1) {
        unicodeList.push(formatCSV.charCodeAt(i));
      }
      // エンコード
      const sjisList = Encoding.convert(unicodeList, "SJIS", "UNICODE");

      // 変換
      const utf8Array = new Uint8Array(sjisList);

      let blob = new Blob([utf8Array], { type: "text/csv" });
      FileSaver.saveAs(blob, "recipro.csv");
    },
    handleResize() {
      this.width = window.innerWidth;
    },
  },
  created() {
    // 初期データを作成
    if (this.displayValue) {
      this.data = this.createdListData(this.displayValue);
    }

    this.judgeDeviceTypePC();
  },
  watch: {
    displayValue: {
      handler() {
        this.data = this.createdListData(this.displayValue);
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.detailed-data-list {
  width: 100%;
  overflow-x: auto;
}

/** リストメインコンテンツ */
.detailed-data-list--table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  box-sizing: border-box;
}
.detailed-data-list--head {
  margin-right: 10px;
}
.detailed-data-list--head--inner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
.detailed-data-list--item {
  padding: 10px;
}
.td-1 {
  border-top: 1px solid #999999;
}
.detailed-data-list--item--ast {
  color: #e87352;
}
.td-2 {
  border-top: 3px double #999999;
}
.sort-icon {
  color: #ccc;
}
.sortable:hover {
  cursor: pointer;
}

.detailed-data--content {
  margin-top: 20px;
}
</style>

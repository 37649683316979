import { auth, db, functions } from "@/libs";
import { httpsCallable } from "firebase/functions";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

export default {
  async getUid() {
    const user = auth.currentUser;
    return user ? user.uid : null;
  },

  async signInCheck(username, password) {
    try {
      // ログイン処理
      await signInWithEmailAndPassword(
        auth,
        `${username}@reci-pro.com`,
        password,
      );

      const idTokenResult = await auth.currentUser.getIdTokenResult();

      const { claims } = idTokenResult;
      return claims.role;
    } catch (error) {
      throw new Error(error.code);
    }
  },

  async isAdmin() {
    try {
      checkAuth("isAdmin");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.role === "admin";
    } catch (error) {
      throw new Error(error);
    }
  },

  async getCustomerID(root) {
    try {
      checkAuth("getCustomerID");
      // console.log('==idTokenResult==', idTokenResult);

      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      // claims.customerID = '8TJ3rcztK5tKFz3pjt97';

      // console.log('==idTokenResult==', idTokenResult);

      if (claims.role === "admin") {
        return sessionStorage.customerID || root.privateState.customerID || "";
      } else {
        return claims.customerID || "";
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async getStoreID(root) {
    try {
      checkAuth("getStoreID");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      // claims.role = 'headquarters';

      if (claims.role === "admin" || claims.role === "headquarters") {
        return sessionStorage.storeID || root.privateState.storeID || "";
      } else {
        return claims.storeID || "";
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  getDisplayName() {
    checkAuth("getDisplayName");
    return auth.currentUser.displayName || "";
  },

  async isHeadquarters() {
    try {
      checkAuth("isHeadquarters");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.role === "headquarters" || claims.role === "admin";
    } catch (error) {
      throw new Error(error);
    }
  },

  // 店舗権限確認 (修正版)
  async isStore() {
    try {
      checkAuth("isStore");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;

      if (!claims.role) return false;
      return claims.role === "store";
    } catch (error) {
      throw new Error(error);
    }
  },

  async getViewRp() {
    try {
      checkAuth("getViewRp");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.viewRp || false;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getViewOtherRp() {
    try {
      checkAuth("getViewOtherRp");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.viewOtherRp || false;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getCreateRp() {
    try {
      checkAuth("getCreateRp");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.createRp || false;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getViewCost() {
    try {
      checkAuth("getViewCost");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.viewCost || false;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getViewBook() {
    try {
      checkAuth("getViewBook");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.viewBook || false;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getCreateBook() {
    try {
      checkAuth("getCreateBook");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.createBook || false;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getViewInfo() {
    try {
      checkAuth("getViewInfo");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.viewInfo || false;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getEditCat() {
    try {
      checkAuth("getEditCat");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.editCat || false;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getEditUser() {
    try {
      checkAuth("getEditUser");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.editUser || false;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getSelectSup() {
    try {
      checkAuth("getSelectSup");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.selectSup || false;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getViewHidden() {
    try {
      checkAuth("getViewHidden");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.viewHidden || false;
    } catch (error) {
      throw new Error(error);
    }
  },

  async getCreationDate() {
    try {
      checkAuth("getCreationDate");
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      return claims.creationDate || "";
    } catch (error) {
      throw new Error(error);
    }
  },

  async getLastSignInDate() {
    try {
      checkAuth("getLastSignInDate");
      const user = auth.currentUser;
      if (!user) throw new Error("No user logged in");
      const lastSignInTime = user.metadata.lastSignInTime;
      const dt = new Date(lastSignInTime);
      const y = dt.getFullYear();
      const mon = ("00" + (dt.getMonth() + 1)).slice(-2);
      const d = ("00" + dt.getDate()).slice(-2);
      const h = ("00" + dt.getHours()).slice(-2);
      const m = ("00" + dt.getMinutes()).slice(-2);
      const s = ("00" + dt.getSeconds()).slice(-2);

      const strDate = `${y}/${mon}/${d} ${h}:${m}:${s}`;
      return strDate;
    } catch (error) {
      throw new Error(error);
    }
  },
  // 顧客名称の一覧を取得
  async getCustomerList() {
    try {
      checkAuth("getCustomerList");
      const querySnapshot = await getDocs(collection(db, "customers"));
      const records = { 顧客名称を選択してください: "" };
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        records[data.name] = doc.id;
      });

      return records;
    } catch (error) {
      throw new Error(error);
    }
  },

  // 顧客情報を取得
  async getCustomerInfo(id) {
    try {
      checkAuth("getCustomerInfo");

      if (!id) throw new Error("No ID");

      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      // アドミンの場合
      if (claims.role === "admin") {
        const getAdminCustomerInfo = httpsCallable(
          functions,
          "getAdminCustomerInfo",
        );
        const resp = await getAdminCustomerInfo({ id });

        if (resp.data) {
          const { name, corpNo, level5, allergyTwenty, expireDate } = resp.data;

          return {
            id,
            name,
            corpNo,
            level5,
            allergyTwenty,
            expireDate: expireDate,
          };
        } else {
          throw new Error("Customer not found");
        }
      } else {
        const documentSnapshot = await getDoc(doc(db, "customers", id));

        if (documentSnapshot.exists()) {
          const data = documentSnapshot.data();
          const convDate = data.expireDate.toDate();
          const expireDate = data.expireDate
            ? `${convDate.getFullYear()}/${
                convDate.getMonth() + 1
              }/${convDate.getDate()}`
            : "";

          return {
            id,
            name: data.name,
            corpNo: data.corpNo,
            level5: data.level5,
            allergyTwenty: data.allergyTwenty || [],
            expireDate,
          };
        } else {
          throw new Error("Customer not found");
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  // 店舗名称の一覧を取得
  async getStoreList(customerID) {
    try {
      checkAuth("getStoreList");

      if (!customerID) throw new Error("Customer ID is required");

      const querySnapshot = await getDocs(
        collection(db, "customers", customerID, "stores"),
      );
      const records = { 店舗名称を選択してください: "" };
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        records[data.name] = doc.id;
      });
      return records;
    } catch (error) {
      throw new Error(error);
    }
  },
  // 店舗名称の一覧を取得
  async getStoreArrayList(customerID) {
    try {
      checkAuth("getStoreArrayList");
      const querySnapshot = await getDocs(
        collection(db, "customers", customerID, "stores"),
      );
      const records = [{ name: "すべて", id: "" }];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        records.push({ name: data.name, id: doc.id });
      });
      return records;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getCustomerName(customerID) {
    try {
      checkAuth("getCustomerName");
      // 店舗名を取得
      const documentSnapshot = await getDoc(doc(db, "customers", customerID));
      return documentSnapshot.data() && documentSnapshot.data().name
        ? documentSnapshot.data().name
        : "";
    } catch (error) {
      throw new Error(error);
    }
  },
  async getStoreName(customerID, storeID) {
    try {
      checkAuth("getStoreName");
      if (!customerID || !storeID) {
        throw new Error("Customer ID and Store ID are required");
      }

      // 店舗名を取得
      const documentSnapshot = await getDoc(
        doc(db, "customers", customerID, "stores", storeID),
      );

      if (documentSnapshot.exists()) {
        return documentSnapshot.data() && documentSnapshot.data().name
          ? documentSnapshot.data().name
          : "";
      } else {
        throw new Error("Store not found");
      }
    } catch (error) {
      throw new Error("Unable to fetch store name");
    }
  },
  // 自分自身の編集中アプリの取得
  async getEditingMyRecipe() {
    try {
      checkAuth("getEditingMyRecipe");
      const user = auth.currentUser;
      if (!user) throw new Error("No user logged in");
      // 自分自身のみ取得可能な security rule
      const documentSnapshot = await getDoc(doc(db, "users", user.uid));
      const data = documentSnapshot.data();
      return data && data.editStatus ? data.editStatus : "";
    } catch (error) {
      throw new Error(error);
    }
  },
  // 編集状況取得
  async getEditingRecipe(customerID, recipeID) {
    try {
      checkAuth("getEditingRecipe");
      const getEditingRecipe = httpsCallable(functions, "getEditingRecipe");
      const result = await getEditingRecipe({ customerID, recipeID });
      return result.data || "";
    } catch (error) {
      throw new Error(error);
    }
  },
  async lockEditStatus(recipeID) {
    try {
      checkAuth("lockEditStatus");
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No user is logged in");
      }
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;
      if (claims.role === "admin") {
        // 管理者の場合は何もしない
        return;
      }
      // 自分自身のみ更新可能な security rule
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        editStatus: recipeID,
        updateAt: serverTimestamp(),
      });
    } catch (error) {
      throw new Error("Unable to lock edit status");
    }
  },
  async getAllegyTwenty(customerID) {
    try {
      checkAuth("getAllegyTwenty");
      const documentSnapshot = await getDoc(doc(db, "customers", customerID));
      const data = documentSnapshot.data();
      return data && data.allergyTwenty ? data.allergyTwenty : [];
    } catch (error) {
      throw new Error(error);
    }
  },
  // アンロック
  async unLockEditStatus() {
    try {
      checkAuth("unLockEditStatus");
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No user is logged in");
      }

      // 自分自身のみアンロック可能な security rule
      const userDocRef = doc(db, "users", user.uid);
      await updateDoc(userDocRef, {
        editStatus: "",
        updateAt: serverTimestamp(),
      });
    } catch (error) {
      throw new Error("Unable to unlock edit status");
    }
  },
  async signOut() {
    try {
      checkAuth("signOut");

      await auth.signOut();
    } catch (error) {
      throw new Error(error);
    }
  },

  async isRoute(to) {
    try {
      const routeName = to.name;
      checkAuth("isRoute");
      // カスタムクレーム取得
      const idTokenResult = await auth.currentUser.getIdTokenResult();
      const { claims } = idTokenResult;

      if (claims.role === "admin") {
        return true;
      }

      switch (routeName) {
        case "selectCustomer":
          return claims.role === "admin";
        case "menu":
        case "menuRegistration":
        case "menuEdit":
        case "menuRegiLink":
        case "analysis":
          return claims.createBook;
        case "menuList":
          return claims.viewBook;
        case "recipe":
          return true;
        case "recipeRegistration":
        case "recipeEdit":
        case "recipeProcedure":
        case "recipeCost":
        case "courseRegistration":
        case "courseEdit":
          return claims.createRp;
        case "recipeList":
        case "recipeDetail":
        case "courseList":
        case "courseDetail":
        case "courseTop":
          return claims.viewRp;
        case "config":
          return claims.editCat || claims.editUser || claims.selectSup;
        default:
          return false;
      }
    } catch (error) {
      throw new Error(error);
    }
  },
};

const checkAuth = (functionName) => {
  if (!auth.currentUser) {
    // eslint-disable-next-line no-console
    console.log("==Error auth func", functionName);
    throw new Error("User not logged in");
  }
};

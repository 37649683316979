<template>
  <r-modal
    class="cource-name-form"
    :title="pageTitle"
    :step="step ? '1' : ''"
    enter
    @enter="enter"
    @cancel="cancel"
  >
    <!-- コース名入力ここから -->
    <div v-if="flag === 'name' || flag === 'nameOnly'">
      <div class="error-message">{{ error.name }}</div>
      <r-textbox
        class="cource-name-form--name"
        :placeholder="
          $t('course.courseRegistration.courseNameCatForm.nameInput')
        "
        v-model="courseName"
        :error="errorForName !== ''"
      ></r-textbox>
    </div>
    <!-- コース名入力ここまで -->

    <!-- カテゴリー入力ここから -->
    <div
      class="cource-name-form--category"
      v-if="flag === 'category' || flag === 'categoryOnly'"
    >
      <div class="error-message">{{ error.cat }}</div>
      <span>{{
        $t("course.courseRegistration.courseNameCatForm.incaseofNotSelected")
      }}</span>
      <span
        >({{
          $t("course.courseRegistration.courseNameCatForm.utilThree")
        }})</span
      >
      <div class="cource-name-form--category--checkbox">
        <r-checkbox
          :items="categories"
          :limit="3"
          :defaultCounter="defaultCounter"
          @toggle="toggle"
        ></r-checkbox>
      </div>
    </div>
    <!-- カテゴリー入力ここまで -->
  </r-modal>
</template>

<script>
import RModal from "@/plugins/molecules/RModal.vue";
import RTextbox from "@/plugins/atoms/RTextbox.vue";
import RCheckbox from "@/plugins/atoms/RCheckbox.vue";

import auth from "@/libs/Auth.js";
import categoryManager from "@/libs/Category.js";

export default {
  components: {
    RModal,
    RTextbox,
    RCheckbox,
  },
  props: {
    flag: {
      type: String,
      default: "name",
    },
    existingCat: {
      type: Array,
      default: () => [],
    },
    existingName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      courseName: "",
      categories: [],
      customerID: "",
      storeID: "",
      errorForName: "",
      errorForCat: "",
      error: {
        name: "",
        cat: "",
      },
      step: false,
    };
  },
  methods: {
    toggle(index) {
      this.$set(this.categories[index], "bool", !this.categories[index].bool);
    },
    enter() {
      if (this.flag === "name") {
        this.executeValidation();
        if (this.error.name !== "") {
          return;
        }

        this.$emit("next");
      } else if (this.flag === "category") {
        if (this.selectedCategories.length === 0) {
          this.selectedCategories.push("コース");
        }
        this.$emit("enter", this.courseName, this.selectedCategories);
      } else if (this.flag === "nameOnly") {
        this.executeValidation();
        if (this.error.name !== "") {
          return;
        }

        this.$emit("nameOnly", this.courseName);
      } else if (this.flag === "categoryOnly") {
        if (this.selectedCategories.length === 0) {
          this.selectedCategories.push("コース");
        }
        this.$emit("categoryOnly", this.selectedCategories);
      }
    },
    cancel() {
      if (this.courseName !== "") {
        //コース名入力(コース名が入力されている)後カテゴリー未選択(キャンセル)の場合
        this.$emit("nameOnly", this.courseName);
      }
      this.$emit("cancel");
    },
    executeValidation() {
      if (this.courseName === "") {
        this.$set(
          this.error,
          "name",
          this.$i18n.t(
            "course.courseRegistration.courseNameCatForm.courseNameNotInput",
          ),
        );
      } else if (this.courseName.length >= 50) {
        this.$set(
          this.error,
          "name",
          this.$i18n.t(
            "course.courseRegistration.courseNameCatForm.courseNameLengthLimit",
          ),
        );
      } else {
        this.$set(this.error, "name", "");
      }
    },
  },
  computed: {
    selectedCategories() {
      return this.categories.reduce((acc, category) => {
        if (category.bool) {
          acc.push(category.name);
        }
        return acc;
      }, []);
    },
    defaultCounter() {
      const arrayMaster = this.existingCat.filter((val) => val);
      if (arrayMaster[0] === "コース") {
        return 0;
      }
      return arrayMaster.length;
    },
    //eslint-disable-next-line vue/return-in-computed-property
    pageTitle() {
      if (this.flag === "name" || this.flag === "nameOnly") {
        return this.$i18n.t(
          "course.courseRegistration.courseNameCatForm.nameInputTitle",
        );
      } else if (this.flag === "category" || this.flag === "categoryOnly") {
        return this.$i18n.t(
          "course.courseRegistration.courseNameCatForm.catTitle",
        );
      }
    },
  },
  async created() {
    try {
      this.courseName = this.existingName;
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);

      if (!this.customerID || !this.storeID) {
        throw new Error("Forbidden");
      }

      // カテゴリ一覧を取得
      this.categories = await categoryManager.getCategoryFormList(
        this.customerID,
        this.storeID,
        this.existingCat,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error(error);
    }
  },
  mounted() {
    if (this.$route.path === "/course-registration") {
      this.step = true;
    }
  },
};
</script>

<style scoped>
.error-message {
  text-align: left;
  color: #e85951;
  font-weight: bold;
}
</style>

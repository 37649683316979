<template>
  <div class="r-list-title">
    <div class="r-list-title--wrapper">
      <h3 v-for="(title, index) in titles" :key="index">{{ title }}</h3>
    </div>

    <r-hr hrStyle="sub-primary" />
  </div>
</template>

<script>
import RHr from "@/plugins/atoms/RHorizontalRule.vue";

export default {
  name: "r-list-title",
  components: {
    RHr,
  },
  props: {
    titles: Array,
  },
};
</script>

<style scoped>
.r-list-title--wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  text-align: left;
}
</style>

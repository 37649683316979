<template>
  <div>
    <!-- ページタイトル -->
    <r-heading :level="1" :image="headingImage">{{
      $t("course.courseEdit.editCourseNameOrMenu")
    }}</r-heading>
    <div class="course-edit">
      <!-- メニューボタン -->
      <div class="course-edit--menu-buttons">
        <r-button buttonStyle="primary" outline @click="toCourseDetail">{{
          $t("course.courseEdit.backToCourseDetail")
        }}</r-button>
      </div>
      <!-- コース編集メイン -->
      <div>
        <div class="course-edit--main">
          <!-- コース名 -->
          <div class="course-edit--main--content course-edit--main--name">
            <r-heading :level="2" class="course-title-heading">
              <div class="title-wrapper">
                <img :src="subHeadingImage" alt class="sub-heading-image" />
                {{ course.title }}
              </div>
            </r-heading>
            <r-button
              class="name-edit-button"
              buttonStyle="secondary"
              @click="openModal(0)"
              >{{ $t("course.courseEdit.changeCourseName") }}</r-button
            >
          </div>

          <div>
            <!-- 画像 -->
            <div class="course-edit--main--content">
              <div
                class="course-edit--main--image"
                :style="{ 'background-image': 'url(' + course.image + ')' }"
                v-if="course.image"
              />

              <div
                class="course-edit--main--image course-edit--main--image--default"
                v-else
              />
              <r-button
                class="course-edit--main--content--button"
                buttonStyle="secondary"
                full
                @click="openModal(1)"
                >{{ $t("course.courseEdit.changeCourseImage") }}</r-button
              >
              <r-button
                class="course-edit--main--content--button"
                buttonStyle="secondary"
                v-if="course.image"
                full
                @click="deleteImage"
                >{{ $t("course.courseEdit.deleteCourseImage") }}</r-button
              >
            </div>

            <!-- カテゴリー -->
            <r-paper class="course-edit--main--content" round>
              <r-list-header>{{ $t("common.category") }}</r-list-header>
              <div>
                <span
                  v-for="(category, index) in course.categories"
                  :key="index"
                  class="course-edit--main--category--item"
                  >{{ category }}</span
                >
              </div>

              <r-button
                class="course-edit--main--content--button"
                buttonStyle="secondary"
                full
                @click="openModal(2)"
                >{{ $t("course.courseEdit.changeCourseCategory") }}</r-button
              >
            </r-paper>
          </div>

          <div>
            <!-- 料理 -->
            <r-paper class="course-edit--main--content" round>
              <r-list>
                <r-list-header>{{ $t("common.recipe") }}</r-list-header>
                <r-list-content
                  v-for="(recipe, index) in course.factors"
                  :key="index"
                >
                  <r-list-item>
                    <input
                      type="checkbox"
                      v-model="selectedRecipesFlag[index]"
                    />
                    {{ recipe.name }}
                  </r-list-item>
                  <r-list-item
                    >{{ recipe.serv }}{{ $t("common.perPerson") }}</r-list-item
                  >
                </r-list-content>
              </r-list>

              <div class="course-edit--main--content--recipe--inner">
                <span>{{ $t("course.courseEdit.ofCheckedDish") }}</span>
                <r-button
                  class="course-edit--main--content--button"
                  buttonStyle="secondary"
                  full
                  @click="openQuantityForm"
                  >{{ $t("course.courseEdit.changeDishAmount") }}</r-button
                >
              </div>

              <r-hr
                class="course-edit--main--content--recipe--inner"
                hrStyle="sub-primary"
              />

              <div class="course-edit--main--content--recipe--inner">
                <span>{{ $t("course.courseEdit.checkedDish") }}</span>
                <r-button
                  class="course-edit--main--content--button"
                  buttonStyle="secondary"
                  full
                  @click="deleteFromRecipes"
                  >{{ $t("course.courseEdit.deleteFromRecipe") }}</r-button
                >
              </div>

              <r-hr
                class="course-edit--main--content--recipe--inner"
                hrStyle="sub-primary"
              />

              <div class="course-edit--main--content--recipe--inner">
                <r-button
                  class="course-edit--main--content--button"
                  buttonStyle="secondary"
                  full
                  @click="openModal(4)"
                  >{{ $t("course.courseEdit.addCourseDish") }}</r-button
                >
              </div>
            </r-paper>

            <!-- アレルギー -->
            <r-paper class="course-edit--main--content" round>
              <r-list-header>{{ $t("common.allergy") }}</r-list-header>
              <div>
                <r-heading :level="4">
                  {{ $t("recipe.allergyItem.sevenItems") }}
                </r-heading>
                <template v-if="allergySevenItems.length > 0">
                  <span
                    class="course-edit--main--allergies--item"
                    v-for="(allergy, index) in allergySevenItems"
                    :key="`seven-${index}`"
                    >{{
                      $t(`recipe.allergyItem.${convertToAllergyCode(allergy)}`)
                    }}</span
                  >
                </template>
                <span v-else>{{ $t("course.courseEdit.notAllergy") }}</span>
              </div>

              <div v-if="allergyTwenty">
                <r-heading :level="4">
                  {{ $t("recipe.allergyItem.twentyItems") }}
                </r-heading>
                <template v-if="allergyTwentyItems.length > 0">
                  <span
                    class="course-edit--main--allergies--item"
                    v-for="(allergy, index) in allergyTwentyItems"
                    :key="`twenty-${index}`"
                    >{{
                      $t(`recipe.allergyItem.${convertToAllergyCode(allergy)}`)
                    }}</span
                  >
                </template>
                <span v-else>{{ $t("course.courseEdit.notAllergy") }}</span>
              </div>
            </r-paper>
          </div>

          <!-- 原価率 -->
          <r-paper
            class="course-edit--main--content course-edit--main--cost"
            round
          >
            <r-list-header>{{ $t("common.cost") }}</r-list-header>
            <r-chart
              class="course-edit--main--content--chart"
              chartStyle="secondary"
              :label="$t('common.costRate')"
              :rate="costRate"
            />
            <r-list>
              <r-list-content>
                <r-list-item>
                  {{ $t("common.cost") }} : {{ totalCost | localNumber
                  }}{{ $t("common.currencyYen") }}
                </r-list-item>
              </r-list-content>
              <r-list-content>
                <r-list-item>
                  {{ $t("common.price") }} : {{ course.price | localNumber
                  }}{{ $t("common.currencyYen") }}
                </r-list-item>
              </r-list-content>
              <r-list-content>
                <r-list-item>
                  {{ $t("common.author") }} :
                  {{ course.createUser }}
                </r-list-item>
              </r-list-content>
            </r-list>
            <r-button
              class="course-edit--main--content--button"
              buttonStyle="secondary"
              full
              @click="openModal(5)"
              >{{ $t("course.courseEdit.changeCoursePrice") }}</r-button
            >
          </r-paper>
        </div>
        <r-button
          class="course-edit--main--content--button course-confirm-edit-btn"
          buttonStyle="primary"
          outline
          full
          @click="openModal(6)"
          >{{ $t("course.courseEdit.confrimCourseEdit") }}</r-button
        >

        <!-- モーダルコンテンツ -->
        <transition name="modal">
          <div
            class="modal-bg"
            v-if="modalFlag.includes(true)"
            @mousedown.self="closeModal"
          >
            <!-- コース名変更モーダル -->
            <course-name-form
              class="modal"
              flag="nameOnly"
              v-if="modalFlag[0]"
              :existingName="course.title"
              @nameOnly="enterFromName"
              @cancel="closeModal"
            ></course-name-form>
            <!-- 画像変更モーダル -->
            <course-image-form
              v-if="modalFlag[1]"
              @enter="setImage"
              @cancel="closeModal"
            />
            <!-- カテゴリー変更モーダル -->
            <course-name-form
              class="modal"
              flag="categoryOnly"
              v-if="modalFlag[2]"
              @categoryOnly="enterFromcategory"
              @cancel="closeModal"
              :existingCat="course.categories"
            />
            <!-- 数量変更モーダル -->
            <course-menu-amount-form
              class="modal"
              v-if="modalFlag[3]"
              quantityForm
              :existingRecipes="course.factors"
              :indexs="selectedRecipesIndex"
              :price="course.price"
              :calcRecipes="course"
              edit
              :exTotalCost="totalCost"
              :exCostRate="costRate"
              @enter="enterFromQuantityForm"
              @cancel="closeModal"
            ></course-menu-amount-form>
            <!-- 料理追加モーダル -->
            <course-menu-form
              class="modal"
              v-if="modalFlag[4] && !menuAmount"
              :existingRecipes="course.factors"
              @enter="enterFromAddMenu"
              @cancel="closeModal"
            ></course-menu-form>
            <course-menu-amount-form
              class="modal"
              v-if="modalFlag[4] && !!menuAmount"
              :existingRecipes="tmpRecipes"
              @enter="enterFromMenuAmount"
              @cancel="cancelFromMenuAmount"
            />
            <!-- 売値変更モーダル -->
            <course-selling-price
              class="modal"
              v-if="modalFlag[5]"
              :items="course.factors"
              :existingPrice="course.price"
              :totalCost="totalCost"
              @enter="enterFromPrice"
              @cancel="closeModal"
            />
            <!-- 確認モーダル -->
            <course-confirm-form
              class="modal"
              v-if="modalFlag[6]"
              :course="course"
              :sevenItems="allergySevenItems"
              :twentyItems="allergyTwentyItems"
              :totalCost="totalCost"
              :allergyTwenty="allergyTwenty"
              @enter="updateCourse"
              @cancel="closeModal"
            />
            <recipe-cost-update-modal
              :exCost="totalCost"
              :cost="tmpTotalCost"
              :exCostRate="costRate"
              :costRate="tmpCostRate"
              @enter="confirmCostUpdate"
              @cancel="cancelCostUpdate"
              v-if="modalFlag[7]"
            ></recipe-cost-update-modal>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import RHeading from "@/plugins/atoms/RHeading.vue";
import RButton from "@/plugins/atoms/RButton.vue";
import RPaper from "@/plugins/atoms/RPaper.vue";
import RList from "@/plugins/atoms/RList.vue";
import RListContent from "@/plugins/atoms/RListContent.vue";
import RListHeader from "@/plugins/atoms/RListHeader.vue";
import RListItem from "@/plugins/atoms/RListItem.vue";
import RChart from "@/plugins/atoms/RChart.vue";
import RHr from "@/plugins/atoms/RHorizontalRule.vue";

import CourseNameForm from "@/components/course/organisms/CourseNameForm.vue";
import CourseImageForm from "@/components/course/organisms/CourseImageForm.vue";
import CourseMenuForm from "@/components/course/organisms/CourseMenuForm.vue";
import CourseMenuAmountForm from "@/components/course/organisms/CourseMenuAmountForm.vue";
import CourseSellingPrice from "@/components/course/organisms/CourseSellingPrice.vue";
import CourseConfirmForm from "@/components/course/organisms/CourseConfirmForm.vue";
import RecipeCostUpdateModal from "@/components/recipe/organisms/RecipeCostUpdateModal.vue";

import auth from "@/libs/Auth.js";
import courseManager from "@/libs/Course.js";
import storeManager from "@/libs/Store.js";

import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  components: {
    RHeading,
    RButton,
    RPaper,
    RList,
    RListHeader,
    RListContent,
    RListItem,
    RChart,
    RHr,
    CourseNameForm,
    CourseImageForm,
    CourseMenuForm,
    CourseMenuAmountForm,
    CourseSellingPrice,
    CourseConfirmForm,
    RecipeCostUpdateModal,
  },
  data() {
    return {
      // 表示制御用変数
      selectedRecipesFlag: [],
      modalFlag: [false, false, false, false, false, false, false, false],
      course: {
        categories: [],
        cost: 0,
        image: "",
        price: 0,
        factors: [],
        title: "",
      },
      orgCourse: {},
      courseID: "",
      allergyTwenty: false,
      headingImage: require("../../../assets/images/editColor.png"),
      subHeadingImage: require("../../../assets/images/courseColor.svg"),
      menuAmount: false,
      tmpRecipes: [],
      tmpTotalCost: 0,
      tmpCostRate: 0,
      backModalIndex: 0,
    };
  },
  computed: {
    selectedRecipesIndex() {
      return this.selectedRecipesFlag.reduce((acc, val, index) => {
        if (val) {
          acc.push(index);
        }
        return acc;
      }, []);
    },
    notSelectedRecipesIndex() {
      return this.selectedRecipesFlag.reduce((acc, val, index) => {
        if (!val) {
          acc.push(index);
        }
        return acc;
      }, []);
    },
    selectedRecipes() {
      const arr = [];
      this.selectedRecipesIndex.forEach((e) =>
        arr.push(this.course.factors[e]),
      );
      const clonedeep = require("lodash/cloneDeep");
      let arr2 = clonedeep(arr);
      return arr2;
    },
    courseAllergy() {
      return [...new Set(this.course.factors.map((e) => e.allergy).flat())];
    },
    allergySevenItems() {
      if (this.course.factors) {
        const allergy = this.course.factors.reduce((acc, recipe) => {
          return [...acc, ...recipe.allergy.sevenItems];
        }, []);
        return [...new Set(allergy)];
      } else {
        return [];
      }
    },
    allergyTwentyItems() {
      if (this.course.factors) {
        const allergy = this.course.factors.reduce((acc, recipe) => {
          return [...acc, ...recipe.allergy.twentyItems];
        }, []);
        return [...new Set(allergy)];
      } else {
        return [];
      }
    },
    totalCost() {
      if (this.course.factors) {
        return this.course.factors.reduce((acc, factor) => {
          acc += factor.totalCost * factor.serv;

          return Number(acc.toFixed(2));
        }, 0);
      } else {
        return 0;
      }
    },
    costRate() {
      return Number(((this.totalCost / this.course.price) * 100).toFixed(2));
    },
    convertToAllergyCode() {
      return (name) => {
        switch (name) {
          case "小麦":
            return "wheat";
          case "蕎麦":
            return "soba";
          case "乳製品":
            return "milk";
          case "卵":
            return "egg";
          case "落花生":
            return "peanuts";
          case "えび":
            return "shrimp";
          case "かに":
            return "crab";
          case "あわび":
            return "abalone";
          case "いか":
            return "squid";
          case "いくら":
            return "salmonRoe";
          case "オレンジ":
            return "orange";
          case "カシューナッツ":
            return "cashewNut";
          case "キウイフルーツ":
            return "kiwi";
          case "牛肉":
            return "beef";
          case "くるみ":
            return "walnut";
          case "ごま":
            return "sesame";
          case "さけ":
            return "salmon";
          case "さば":
            return "mackerel";
          case "大豆":
            return "soy";
          case "鶏肉":
            return "chicken";
          case "バナナ":
            return "banana";
          case "豚肉":
            return "pork";
          case "まつたけ":
            return "matsutake";
          case "もも":
            return "peaches";
          case "やまいも":
            return "yam";
          case "りんご":
            return "apple";
          case "ゼラチン":
            return "geratin";
          case "アーモンド":
            return "almond";
        }
      };
    },
    isUsingSmaregi() {
      return this.customer.smaregiStoreID ? true : false;
    },
  },
  methods: {
    toCourseDetail() {
      this.$router.push(
        {
          name: "courseDetail",
          id: this.courseID,
        },
        () => {},
      );
    },
    openModal(index) {
      this.$set(this.modalFlag, index, true);
      const modal = document.querySelector(".modal");
      disableBodyScroll(modal);
    },
    closeModal() {
      const index = this.modalFlag.indexOf(true);
      this.$set(this.modalFlag, index, false);
      clearAllBodyScrollLocks();
    },
    refreshRecipesFlag() {
      this.selectedRecipesIndex.forEach((index) =>
        this.$set(this.selectedRecipesFlag, index, false),
      );
    },
    createSelectedFoodStuffs() {
      this.selectedRecipesFlag.length = this.course.factors.length;
      this.selectedRecipesFlag.fill(false);
    },
    //  コース名
    enterFromName(value) {
      this.course.title = value;
      this.closeModal();
    },
    // 画像関連
    setImage(image) {
      this.$set(this.course, "image", image);
      this.closeModal();
    },
    deleteImage() {
      this.$set(this.course, "image", "");
    },
    // カテゴリー
    enterFromcategory(value) {
      this.course.categories = value;
      this.closeModal();
    },
    // 数量変更
    openQuantityForm() {
      if (this.selectedRecipes.length === 0) {
        return;
      }
      this.openModal(3);
    },
    enterFromQuantityForm(values) {
      this.course.factors = [];
      values.forEach((value) => {
        this.course.factors.push(value);
      });
      this.refreshRecipesFlag();
      this.closeModal();
    },
    // 食材追加
    enterFromAddMenu(value) {
      this.tmpRecipes = value;
      this.menuAmount = true;
    },
    enterFromMenuAmount(value) {
      this.course.factors = value;
      this.createSelectedFoodStuffs();
      this.menuAmount = false;
      this.closeModal();
    },
    cancelFromMenuAmount() {
      this.menuAmount = false;
    },
    deleteFromRecipes() {
      if (this.selectedRecipes.length === 0) {
        return;
      } else if (this.course.factors.length <= 1) {
        alert(this.$i18n.t("course.courseEdit.courseDeleteLimit"));
        return;
      }
      //tmpの配列に追加する
      this.tmpArray = this.notSelectedRecipesIndex.reduce((acc, index) => {
        acc.push(this.course.factors[index]);
        return acc;
      }, []);
      //this.course.factors = newRecipes;
      // 暫定的な合計原価を算出
      this.tmpTotalCost = this.tmpArray.reduce((acc, factor) => {
        acc += factor.totalCost * factor.serv;

        return Number(acc.toFixed(2));
      }, 0);
      // 暫定的な原価率を算出
      this.tmpCostRate = Number(
        ((this.tmpTotalCost / this.course.price) * 100).toFixed(2),
      );

      this.closeModal();
      // 原価更新モーダルを開く
      this.openModal(7);
    },
    // 原価更新の確認押下後の処理
    confirmCostUpdate() {
      this.course.factors = this.tmpArray;
      this.closeModal();
      this.createSelectedFoodStuffs();
      this.refreshRecipesFlag();
    },
    // 原価更新キャンセル押下後の処理
    cancelCostUpdate() {
      this.closeModal();
      this.createSelectedFoodStuffs();
      this.refreshRecipesFlag();
    },
    enterFromPrice(value) {
      this.course.price = value;
      this.closeModal();
    },
    async updateCourse() {
      if (this.isUsingSmaregi) {
        if (
          !window.confirm(
            this.$i18n.t("course.courseEdit.confirmUpdateCourseSmaregi"),
          )
        ) {
          return;
        }
      }

      // courseの更新
      await courseManager.updateCourse(this.course, this.orgCourse);
      this.closeModal();

      this.$router.push(
        {
          name: "courseDetail",
          params: { id: this.courseID },
        },
        () => {},
      );
    },
  },
  async created() {
    try {
      this.customerID = await auth.getCustomerID(this.$root);
      this.storeID = await auth.getStoreID(this.$root);
      if (!this.customerID || !this.storeID) {
        // customerID、StoreIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      if (this.$route.params.id) {
        this.courseID = this.$route.params.id;
      } else if (sessionStorage.courseID) {
        // 存在しない場合はlocal storageから取得
        this.courseID = sessionStorage.courseID;
      } else {
        // recipeIDが存在しない場合はエラー
        throw new Error("Forbidden");
      }

      // 該当IDのコースを登録
      this.course = await courseManager.getCourseDetailWithImage(
        this.customerID,
        this.storeID,
        this.courseID,
      );

      // アレルギーの品目数設定を取得
      this.allergyTwenty = this.$root.privateState.allergyTwenty;

      // 更新前のオブジェクトを保存
      const clonedeep = require("lodash/cloneDeep");
      this.orgCourse = clonedeep(this.course);

      // Session Strageに保存
      sessionStorage.courseID = this.courseID;

      // 選んだ料理用の配列(bool)を用意
      this.createSelectedFoodStuffs();

      // 顧客情報を保存
      const customer = await storeManager.getStoreInfo(
        this.customerID,
        this.storeID,
      );
      this.customer = customer;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("ERROR", error);
      throw new Error(error);
    }
  },
};
</script>

<style scoped>
.course-edit {
  padding: 60px 20px 0;
}
.course-edit--menu-buttons {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.name-edit-button {
  margin-top: 10px;
  width: 100%;
}
.title-wrapper {
  display: flex;
  align-items: center;
}
.sub-heading-image {
  width: 36px;
  height: auto;
  margin-right: 10px;
}
.course-edit--main {
  padding: 0 20px;
}
.course-edit--main--image {
  min-height: 30vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #f8d5cb;
}
.course-edit--main--image--default {
  background-image: url(~@/assets/images/logo4.png);
  background-size: auto 15vh;
}
.course-edit--main--content {
  margin-top: 20px;
  text-align: left;
}
.course-edit--main--content--button {
  margin-top: 10px;
}
.course-edit--main--content--recipe--inner {
  margin-top: 10px;
  text-align: left;
}
.course-edit--main--content--chart {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.course-confirm-edit-btn {
  margin-top: 20px;
  margin-bottom: 20px;
}
.course-edit--main--category--item:not(:last-child):after,
.course-edit--main--allergies--item:not(:last-child):after {
  content: "・";
}
.modal-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 0 5%;
  margin: auto;
  box-sizing: border-box;
}
.modal {
  max-height: 90%;
  overflow: auto;
  width: 90%;
}

@media screen and (min-width: 641px) {
  .course-edit {
    padding-top: 100px;
  }
  .course-edit--menu-buttons {
    position: absolute;
    top: 100px;
  }
  .course-edit--main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.2fr 0.8fr;
    gap: 20px;
  }
  .course-edit--main--name {
    grid-column: span 2;
    position: relative;
  }
  .name-edit-button {
    position: absolute;
    top: 5px;
    right: 10px;
    margin-top: unset;
    width: unset;
  }
  .course-edit--main--cost {
    grid-column: 2;
  }
  .modal-bg {
    padding: 0 10%;
  }
}
@media screen and (min-width: 1024px) {
  .course-edit {
    padding-top: 120px;
    width: 1024px;
    margin: auto;
  }
  .course-edit--menu-buttons {
    top: 90px;
  }
  .course-title-heading >>> .r-heading-2 {
    padding: 12px;
  }
  .course-edit--main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 0.8fr;
    gap: 20px;
  }
  .course-edit--main--name {
    grid-column: span 3;
  }
  .course-edit--main--cost {
    grid-column: 3;
  }
  .modal-bg {
    padding: 0 25%;
  }
  .modal {
    min-width: 880px;
  }
}
</style>

<template>
  <transition>
    <div class="r-alert" :class="[alertStyle ? 'r-alert--' + alertStyle : '']">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: "r-alert",
  props: {
    alertStyle: String,
  },
};
</script>

<style scoped>
.r-alert {
  background-color: #ffffff;
  padding: 20px 20px;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.26);
  text-align: left;
  margin-top: 20px;
}
/*.r-alert--success {
  border: 1px solid #a0ff00;
}*/
.r-alert--success--icon {
  color: #a0ff00;
}
/*.r-alert--danger {
  border: 1px solid #e87352;
}*/
.r-alert--danger--icon {
  color: #e87352;
}
</style>

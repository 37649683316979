<!-- step3 画像選択 -->
<template>
  <r-modal
    :step="step ? '3' : ''"
    :title="$t('recipe.recipeRegistration.recipeImageForm.formTitle')"
    enter
    :skip="step && regist"
    @enter="enter"
    @skip="skip"
    @cancel="cancel"
  >
    <div class="error-message">{{ error }}</div>
    <r-fileupload
      @change="changeImage"
      :image="image"
      class="recipe-image-form--file-up"
      >{{
        $t("recipe.recipeRegistration.recipeImageForm.selectFile")
      }}</r-fileupload
    >
  </r-modal>
</template>

<script>
import RFileupload from "@/plugins/atoms/RFileupload.vue";
import RModal from "@/plugins/molecules/RModal.vue";

export default {
  name: "recipe-image-form",
  components: {
    RFileupload,
    RModal,
  },
  props: {
    regist: Boolean,
  },
  data() {
    return {
      image: "",
      error: "",
      step: false,
    };
  },
  methods: {
    enter() {
      if (this.image === "") {
        this.error = this.$t(
          "recipe.recipeRegistration.recipeImageForm.notSelected",
        );
        return;
      }
      this.$emit("enter", this.image);
    },
    skip() {
      this.$emit("skip");
    },
    cancel() {
      this.$emit("cancel");
    },
    changeImage(value) {
      this.image = value;
    },
  },
  mounted() {
    if (this.$route.path === "/recipe-registration") {
      this.step = true;
    }
  },
};
</script>

<style scoped>
.recipe-image-form--file-up {
  min-height: 30vh;
}
.image-form--content {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e87352;
  padding: 80px 20px;
}
.error-message {
  height: 10px;
  margin: 0 0 10px 0;
  color: #e85951;
  font-weight: bold;
}
</style>

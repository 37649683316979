var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"r-button",class:[
    _vm.buttonStyle ? 'r-button--' + _vm.buttonStyle : '',
    _vm.outline ? 'r-button--' + _vm.buttonStyle + '--outline' : '',
    {
      'r-button--round': _vm.round,
      'r-button--full': _vm.full,
      'r-button--icon': _vm.icon,
      'r-button--middle': _vm.middle,
      'r-button--small': _vm.small,
      'r-button--disabled': _vm.disabled,
    },
  ],style:({
    'background-color': _vm.customColor,
    'border-color': _vm.customColor,
  }),attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.clickHandler}},[(_vm.icon)?_c('i',{class:['fa', 'fa-' + _vm.icon]}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }